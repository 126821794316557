import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Image } from '@themesberg/react-bootstrap';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useLocation, useParams, useHistory } from "react-router-dom";
import AppContext from '../components/AppContext';
import axios from "axios";
import { isEmpty } from "../AppConfig";
import { menuItemsDetais } from "../utils/sidebar";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';

function SSOLogin(props) {
  const contextData = useContext(AppContext);
  let { token } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const parts = pathname.split('/');
  const emailid = parts[4];
  const ipaddr = parts[3];
  const ssoToken = parts[2];

  // Path redirecting to CI must be in this format pagename/bypasslogin/token/ipaddr/email/okta
  // http://localhost:3000/#/isSSOLogin/bypasslogin/New Text Document.txt/14.194.133.58/vaibhav.patel@investisdigital.com/okta

  const parameter = { emailAddress: emailid, IPAddress: ipaddr, SSOToken: ssoToken };

  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  // const [showMeRedirect, setShowMeRedirect] = useState(false);
  // const [showMePassword, setShowMePassword] = useState(false);
  // const [showMeLogin, setShowMeLogin] = useState(false);
  // const [showMeDetails, setShowMeDetails] = useState(false);
  // const [showMeInvalid, setShowMeInvalid] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [checkBypassLogin, setCheckBypassLogin] = useState(false)

  let isssoLogin = false;

  //using request
  useEffect(() => {
    isssoLogin = true;
    try {
      if (!checkBypassLogin) {
        request(baseURL + `/Home/BypassLogin`, METHODS.POST, parameter,
          {
            "content-type": "application/json"
          }).then((response) => {
            if (response) {
              contextData.changeUerId(response.userid);
              contextData.changeCompanyId(response.companyId);
              handleSubmitbySSO();
              if (response.userId > 0) {
                setCheckBypassLogin(true);
              }
            }
          })
      }

    } catch (error) {
      console.log("login error from sso", error);
    } finally {
      setShowLoader(false);
    }
  }, [checkBypassLogin])

  //using axios
  // useEffect(() => {
  //   const issso = async () => {
  //     isssoLogin = true;
  //     try {
  //       if (!checkBypassLogin) {
  //         const response = await axios.post(`${process.env.REACT_APP_API_KEY}/Home/BypassLogin`, parameter)
  //         if (response.data) {
  //           contextData.changeUerId(response.data.userid);
  //           contextData.changeCompanyId(response.data.companyDetail.companyId);
  //           handleSubmitbySSO();
  //           if (response.data.userId > 0) {
  //             setCheckBypassLogin(true);
  //           }
  //         }
  //       }

  //     } catch (error) {
  //       console.log("login error from sso", error);
  //     } finally {
  //       setShowLoader(false);
  //     }


  //     // if (!checkBypassLogin) {
  //      //   axios
  //      //     .post(
  //      //       `${process.env.REACT_APP_API_KEY}/Home/BypassLogin`, parameter

  //      //     )
  //      //     .then(response => {
  //      //       // if(response.data.userToken == null){     //if email not found it would return to login page.
  //      //       //   return history.push('/')
  //      //       // }
  //      //       if (response.data) {
  //      //         contextData.changeUerId(response.data.userid);
  //      //         contextData.changeCompanyId(response.data.companyDetail.companyId);
  //      //         handleSubmitbySSO();
  //      //         setCheckBypassLogin(true);
  //      //       }
  //      //     })
  //      //     .catch(error => {
  //      //       console.log("login error from sso", error);
  //      //     });
  //      // }
  //   };

  //   if (!checkBypassLogin) {
  //     issso();
  //   }

  // }, [checkBypassLogin])


  useEffect(() => {
    if (token === undefined || token === null) {
      if (contextData.userId > 0 && contextData.companyId > 0) {
        {
          getMenu();
        }
      }
    }
  }, [contextData.userId, contextData.companyId]);


  const handleSubmitbySSO = async () => {
    let Password = '';
    let EmailAddress = emailid;
    const UserCrdentials = { EmailAddress: EmailAddress, Password: Password, isssoLogin: isssoLogin };
    const response = await axios.post(`${process.env.REACT_APP_API_KEY}/Home/ValidateLogin`, UserCrdentials);

    const { userToken, userId, languagePreference, firstName, lastName, email } = response.data;
    const companies = response.data.resultData;
    let companyId = companies && Array.isArray(companies) && companies.length > 0 ? companies[0].companyId : 0;
    let userName = (!isEmpty(firstName) && !isEmpty(lastName)) ? firstName.concat(lastName) : "";
    let langPref;
    if (isLanguageChanged) {
      langPref = (selectedLanguage && Object.keys(selectedLanguage).length > 0) ? selectedLanguage.id : languagePreference;
    }
    else {
      langPref = languagePreference;
    }
    //vp
    localStorage.setItem("sessionTime", new Date());
    localStorage.setItem("token", userToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("userName", userName);
    localStorage.setItem("companies", JSON.stringify(companies));
    localStorage.setItem("email", email);
    localStorage.setItem("langPref", langPref);
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("userlangPref", languagePreference);

    contextData.changeFirstName(localStorage.getItem("firstName"));
    contextData.changeLastName(localStorage.getItem("lastName"));
    contextData.changeUserName(localStorage.getItem("userName"));
    contextData.changeEmail(localStorage.getItem("email"));
    contextData.changeUserToken(localStorage.getItem("token"));
    contextData.changeUerId(localStorage.getItem("userId"));
    contextData.changeLanguagePref(localStorage.getItem("langPref"));
    contextData.changeCompanyId(localStorage.getItem("companyId"));
    contextData.setCompanies(localStorage.getItem("companies"));
  }

  const getMenu = () => {
    setShowLoader(true);
    //1stroundchange change in whole method
    // setBlurLoader(true)
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/User/getMainMenuByClientUser`,
        {
          params: {
            userId: contextData.userId,
            companyid: contextData.companyId,
            websiteid: contextData.websiteId
          }
        },
      )
      .then(response => {
        setShowLoader(false);
        if (response && response.data && response.data.resultData.menuDetails) {
          //let menuDta = getMenusAndSubMenuDetails(response.data.resultData.menuDetails);
          // contextData.setMenu(menuDta);
          let menuDta = response.data.resultData.menuDetails;
          menuDta = menuDta.filter((x) => { return x.id !== 4 && x.id !== 13 && x.id !== 14 })



          if (Array.isArray(menuDta) && menuDta.length > 0) {
            let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];

            if (defaultMenuData) {
              let settingDta = {
                id: 0,
                dispIndex: 13,
                isActionPoint: false,
                isActive: true,
                subMenu: [],
                subSections: null,
                title: "Settings"
              };
              menuDta.push(settingDta);
              contextData.setMenu(menuDta);
            }
            else {
              let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
              { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
              { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }];

              let settingDta = {
                id: 0,
                dispIndex: 13,
                isActionPoint: false,
                isActive: true,
                subMenu: submenuarray,
                subSections: null,
                title: "Settings"
              };
              menuDta.push(settingDta);

              // newobject.subMenu = submenuarray;
              contextData.setMenu(menuDta);

              localStorage.setItem("selectedTab", "Settings")
              contextData.changeSelectedTab("Settings");
              props.history.push(`/settings/myaccount-page`);
            }



            if (defaultMenuData.id === 1) {
              localStorage.setItem("selectedTab", "Website")
              contextData.changeSelectedTab("Website");
              props.history.push(`/website`);
            }
            else if (defaultMenuData.id === 8) {
              request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?companyid=` + contextData.companyId,
                METHODS.GET,
                {},
                {
                  Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
                })
                .then((res) => {

                  if (res && res.result.length > 0) {

                    let wid = res.result[0].WebsiteId;
                    request(baseURL + `/User/getSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                      METHODS.GET,
                      {},
                      {
                        Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
                      })
                      .then((res) => {
                        let menuDta = res[0].subSections;

                        let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                        if (subMenuDetails.link) {
                          localStorage.setItem("selectedTab", "Alerts")
                          contextData.changeSelectedTab("Alerts");
                          props.history.push(subMenuDetails.link);
                        }
                        else {
                          props.history.push(`/website`);
                        }
                      })
                      .catch((err) => {

                      })

                  }
                  else {
                    localStorage.setItem("selectedTab", "Alerts")
                    contextData.changeSelectedTab("Alerts");
                    props.history.push("/alert-marketing");
                  }
                })
                .catch((err) => {

                })
            }
            else if (defaultMenuData.id === 5) {

              request(baseURL + `/User/getSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=0`,
                METHODS.GET,
                {},
                {
                  Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
                })
                .then((res) => {
                  let menuDta = res[0].subSections;

                  let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                  if (subMenuDetails.link) {
                    localStorage.setItem("selectedTab", "Webcasting & Video")
                    contextData.changeSelectedTab("Webcasting & Video");
                    props.history.push(subMenuDetails.link);
                  }
                  else {
                    props.history.push(`/website`);
                  }
                })
                .catch((err) => {

                })


            }
            else if (defaultMenuData.id === 6) {
              request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?companyid=` + contextData.companyId,
                METHODS.GET,
                {},
                {
                  Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
                })
                .then((res) => {
                  if (res && res.result.length > 0) {

                    let wid = res.result[0].WebsiteId;
                    request(baseURL + `/User/getSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                      METHODS.GET,
                      {},
                      {
                        Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
                      })
                      .then((res) => {
                        let menuDta = res[0].subSections;

                        let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                        if (subMenuDetails.link) {
                          localStorage.setItem("selectedTab", "Tools")
                          contextData.changeSelectedTab("Tools");
                          props.history.push(subMenuDetails.link);
                        }
                        else {
                          props.history.push(`/website`);
                        }
                      })
                      .catch((err) => {

                      })

                  }
                  else {
                    localStorage.setItem("selectedTab", "Alerts")
                    contextData.changeSelectedTab("Alerts");
                    props.history.push("/tools-marketing");
                  }

                })
                .catch((err) => {

                })
            }
            else if (defaultMenuData.id === 10) {
              localStorage.setItem("selectedTab", "Reports")
              contextData.changeSelectedTab("Reports");
              props.history.push(`/reports`);
            }
            else if (defaultMenuData.id === 11) {
              localStorage.setItem("selectedTab", "Service Centre")
              contextData.changeSelectedTab("Service Centre");
              props.history.push(`/service-centre-pages`);
            }
          }
        }
      })
      .catch(error => {
        console.log("login error", error);
      });
  }

  return (
    <div>
      {showLoader ?
        (<div id='blurdiv' className="blurbackground service-center-modal-backdrop">
          <div className={`preloader loader-center-align`}>
            <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
            <h3>SSO Login</h3>
          </div>
        </div>) : (<></>)}
    </div>
  )
}

export default SSOLogin