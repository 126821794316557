import React, { useContext, useEffect, useState, useMemo } from 'react';
import EventCalendar from '../components/EventCalender';
import { isMobile } from "react-device-detect";
import { eventCalender } from "../stubs/ComponentJson/tools";
import ChartRenderer from '../components/ChartRenderer';
import ChartSummary from '../components/CardSummary';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from '../components/DashboardItem';
import { Button, Alert, Row, Col, } from '@themesberg/react-bootstrap'
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO, TABLE_TOTAL_ROWS } from "../AppConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";
import AppContext from '../components/AppContext';
import request from '../apis/request';
import { METHODS, baseURL } from '../apis/utilities/constant';
import Filters from "../components/Filters";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined
});

const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 8,
    minW: 4,
    minH: 8,
});

const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
});

export const EventCalendarPage = () => {

    const contextData = useContext(AppContext);
    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const [attachmentView, setAttachmentView] = useState(false);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [eventCalenderData, seteventCalenderData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [editData, setEditData] = useState(undefined);
    const [blurLoader, setBlurLoader] = useState(false);
    const [isPageLoad, setIsPageLoad] = useState(false);
    const [searchData, setSearchData] = useState("");
    const [searchWaterMark, setSearchWaterMark] = useState("Search event title");
    const [t, i18n] = useTranslation();
    const [getTotalResults, setGetTotalResults] = useState(0);


    const [dropdownYearList, setDropdownYearList] = useState([]);
    const [selectedDropdownYearItem, setSelectedDropdownYearItem] = useState({ Year: 'All' });

    const [dropdownCategoryList, setDropdownCategoryList] = useState([]);
    const [selectedDropdownCategoryItem, setSelectedDropdownCategoryItem] = useState({ CategoryId: 0, CategoryName: "All" })

    const [selectedFilterItem, setSelectedFilterItem] = useState({});
    const [tempSearchData, setTempSearchData] = useState("");

    const [filterShow, setFilterShow] = useState(false);

    useEffect(() => {
        if (!editData) {
            setAttachmentView(false);
        }
    }, [editData])

    useEffect(() => {
        if (isPageLoad) {
            setIsPageLoad(false);
            const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
            if (pageNo === 0) {
                setPageNo(1);
            }
            let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, 0, 0, offset);
            seteventCalenderData(dta);
            setSelectedDropdownCategoryItem({ CategoryId: 0, CategoryName: "All" });
            setSelectedDropdownYearItem({ Year: 'All' });
            setSearchData("")
            setFilterShow(false)
        }
    }, [isPageLoad]);

    useEffect(() => {

        setSearchData("");
        const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
        if (pageNo === 0) {
            setPageNo(1);
        }


        let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, 0, 0, offset);
        seteventCalenderData(dta);


    }, [contextData.websiteId, localStorage.getItem("EventCalendarProfileId"), selectedLanguage]);

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    };

    const renderSummary = (cardSummary) => {
        return (
            <ChartSummary
                summaryPrimary={cardSummary.summaryPrimary}
                summarySecondary={cardSummary.summarySecondary}
                isCarouselCard={cardSummary.isCarouselCard}
                showPercentageForPrimary={cardSummary.showPercentageForPrimary}
                showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
                showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
                showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
                carousalMainSummary={cardSummary.carousalMainSummary}
            />
        );

    };

    const onEditEvent = (e, row, rowIndex, type) => {
        e.preventDefault();

        let eventId = row["EventTable.EventId"];
        let language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
        let websiteId = contextData.websiteId;
        let companyId = contextData.companyId;
        let userId = contextData.userId;

        if (eventId) {
            setBlurLoader(true);
            request(baseURL + `/EventCalendar/GetEventDetailByEventId?CompanyId=${companyId}&UserId=${userId}&eventId=${eventId}&WebsiteId=${websiteId}&LanguageCode=${language}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                }
            )
                .then((res) => {

                    if (type == "Attachments") {
                        setAttachmentView(true);
                    }
                    setTimeout(() => {
                        setEditData(res);
                    }, 10);
                    setBlurLoader(false);
                })
                .catch((err) => {
                    setEditData(undefined);
                    setBlurLoader(false);
                })
        }
    }

    useEffect(() => {
        let language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
        let websiteId = contextData.websiteId;
        let companyId = contextData.companyId;
        let userId = contextData.userId;

        //getYearList for dropdown
        if (contextData.websiteId > 0 && language !== null) {
            request(baseURL + `/EventCalendar/GetEventYearList?CompanyId=${companyId}&UserId=${userId}&WebsiteId=${websiteId}&LanguageCode=${language}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    let resultData = []
                    if (res.result !== null && res.result?.length) {
                        resultData = [
                            { Year: "All" },
                            ...res.result.map(Year => ({ Year: Year }))
                        ]
                    }
                    setDropdownYearList(resultData)
                })
        }

        //getCategoryForDropdown
        if (contextData.websiteId > 0) {
            request(baseURL + `/EventCalendar/GetEventCategoryByClient?CompanyId=${companyId}&UserId=${userId}&WebsiteId=${websiteId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    let resultData = [];
                    if (res.result !== null && res.result?.length) {
                        resultData = [
                            { CategoryId: 0, CategoryName: "All" },
                            ...res.result.map(Category => ({ CategoryId: Category.CategoryId, CategoryName: Category.CategoryName }))
                        ]
                    }
                    setDropdownCategoryList(resultData)
                })
        }

        setSelectedDropdownYearItem({ Year: 'All' })
        setSelectedDropdownCategoryItem({ CategoryId: 0, CategoryName: "All" })
        setSearchData("")
        setFilterShow(false)


    }, [contextData.websiteId, selectedLanguage])

    function onYearDropdownChange(data) {
        if (data !== undefined && data !== null) {
            setSelectedDropdownYearItem(data)
        }
    }

    function onCategoryDropdownChange(data) {
        if (data !== undefined && data !== null) {
            setSelectedDropdownCategoryItem(data)
        }
    }

    const setFilterEnable = (data) => {
        if (data && data.data?.length > 0) {
            setFilterShow(true);
        }
    }

    function applyfilter1() {
        const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
        var selectedYear = selectedDropdownYearItem.Year === "All" ? 0 : selectedDropdownYearItem.Year;
        var selectedCategoryId = selectedDropdownCategoryItem.CategoryId;

        let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, selectedYear, selectedCategoryId, offset);

        let x = searchData;

        seteventCalenderData(dta);
        if (x) {
            setSearchData(x);
        }
    }

    function cancelfilter1() {
        const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
        // var selectedYear = selectedDropdownYearItem.Year === "All" ? 0 : selectedDropdownYearItem.Year;
        // var selectedCategoryId = selectedDropdownCategoryItem.CategoryId;
        let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, 0, 0, offset);
        seteventCalenderData(dta);

        setSelectedDropdownYearItem({ Year: 'All' });
        setSelectedDropdownCategoryItem({ CategoryId: 0, CategoryName: "All" });

    }

    const createFilter = useMemo(
        () => (
            <>
                <Filters
                    iconText={"Filter"}
                    applyFilters={applyfilter1}
                    cancelFilter={cancelfilter1}
                    showHeader={false}
                    btnOneText={t("header.ToolsEventCalFilterButton")}
                    btnTwoText={t("header.ToolsEventCalClearFilterButton")}
                    dataSource={[
                        {
                            name: "Year",
                            data: dropdownYearList,
                            selectedItem: selectedDropdownYearItem,
                            isVisible: true,
                            label: t("header.ToolsEventCalFilterYear"),
                            callback: onYearDropdownChange,
                            displayText: "Year",
                        },
                        {
                            name: "Category",
                            data: dropdownCategoryList,
                            selectedItem: selectedDropdownCategoryItem,
                            isVisible: dropdownCategoryList.length ? true : false,
                            label: t("header.ToolsEventCalFilterCategory"),
                            callback: onCategoryDropdownChange,
                            displayText: "CategoryName",
                        }
                    ]}
                ></Filters>
            </>
        ),
        [selectedDropdownYearItem, dropdownYearList, selectedDropdownCategoryItem, dropdownCategoryList]
    );



    const dashboardItem = (item) => (
        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            {
                selectedLanguage && selectedLanguage["Languages.Languagecode"] ?
                    <DashboardItem
                        key={item.id}
                        itemId={item.id}
                        
                        title={t("header.ToolsEventCalEventListDesktopInfo")}

                        customTitle={"eventcalender"}
                    >
                       
                        {<Row className="">
                            <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
                            <div className="pe-4 d-inline-block pull-right">
                            {filterShow ? createFilter :
                                    <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                                      <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                        <span className="icon-nav-box  ms-0 disabled"  >
                                          <svg className="icon-nav">
                                            <title>{t("header.Icon_Tooltip_Filter")}</title>
                                            <use href={iconSprite + `#Filter`} />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  }
                                </div>
                            </Col>
                        </Row>}
                        {<Row>
                            <Col xs={12} className="text-end position-relative">
                                <div className="float-right">
                                    <div className="mt-3">
                                        <Search
                                            setsearchData={setSearchData}
                                            searchData={searchData}
                                            isAutoComplete={false}
                                            searchWaterMark={t("header.ToolsEventCalSearchPlaceholder")}
                                            type={'client'}>
                                        </Search>
                                    </div>
                                </div>
                            </Col>
                        </Row>}

                        <ChartRendererTable
                            totalRecords={numberOfRecords}
                            setNumberOfRecords={setNumberOfRecords}
                            onEditEvent={(e, row, index, type) => { onEditEvent(e, row, index, type) }}
                            minimumRows={10}
                            //summaryTooltip={showTooltips(item)}
                            summaryBesideText={t("header.ToolsEventCalEventListTotalEvents")}
                            showPagination={true}
                            apiUrl={item.apiUrl}
                            showNumberOfRows={10}
                            SearchText={searchData}
                            setSearchText={setSearchData}
                            Exportdata={setFilterEnable}
                            setGetTotalResults={(value) => setGetTotalResults(value)}
                        />
                    </DashboardItem> :
                    <div className='text-center no-data position-relative mt-5'>
                        {t("header.contentUnavailable")}
                    </div>
            }

        </div>
    );

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            <h2>{t("header.noChartsonDashboard")}</h2>
            {/* <Link to="/explore"> */}
            <Button variant="secondary" className="m-1">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("header.addChart")}
            </Button>
            {/* </Link> */}
        </div>
    );

    return !eventCalenderData || eventCalenderData.length > 0 ? (
        <EventCalendar
            setIsPageLoad={setIsPageLoad}
            attachmentView={attachmentView}
            setBlurLoader={setBlurLoader}
            blurLoader={blurLoader}
            editData={editData}
            setEditData={setEditData}
            dashboardItems={eventCalenderData}
            selectedWebsiteId={selectedWebsiteId}
            setSelectedWebsiteId={setSelectedWebsiteId}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            setPageNo={setPageNo}
            setOffset={setOffset}
        >

            {eventCalenderData.map(deserializeItem).map(dashboardItem)}
        </EventCalendar>
    ) : (
        <Empty />
    );
};


export default EventCalendarPage;