import React, { useState, useEffect,useContext } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useMutation } from '@apollo/react-hooks';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import { UPDATE_DASHBOARD_ITEM } from '../graphql/mutations';
import { Row, Col, Button, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import Loader from '../components/VoltLoader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import '../i18n';

const ReactGridLayout = WidthProvider(RGL);


const Dashboard = ({ children, dashboardItems, history, location }) => {
  const contextData =useContext(AppContext);
  // const [websiteId, setWebsiteId] = useState(0);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  // const [profileId, setProfileId] = useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState({});
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [datePickerValue, setDatePickerValue] = useState("");
  const [selectedDatePicker, setSelectedDatePicker] = useState({});
  const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
  const [toggle, setToggle] = useState(false);
  const [t, i18n ] = useTranslation();

  const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS,
      },
    ],
  });

  const showLeftIcon = isMobile ? true : false;

  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );

  useEffect(() => {
    const lastThirtyDays = `${moment().add(-30, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).format("MMM DD,YYYY")}`;
    const lastSixtyDays = `${moment().add(-60, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).format("MMM DD,YYYY")}`;
    const getDropdownPickerDetails = dashbordDateRangeList.find(dta => dta.key === lastThirtyDays);
    // const endDate = moment().subtract(1, 'day').format("MMM DD,YYYY");
    setSelectedDatePicker(getDropdownPickerDetails);
    setDatePickerValue(lastThirtyDays);
    setDatePickerCompareValue(lastSixtyDays);
    setSelectedWebsiteId(DEFAULT_WEBSITE_DASHBORD);
    setSelectedProfileId(DEFAULT_PROFILE_DASHBORD);
    // setEndDate(endDate);

  }, []);

  useEffect(() => {
    if (startDate) {
      setDefaultPickerValueOnDateChange("startDate");
    }

  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setDefaultPickerValueOnDateChange("endDate");
    }
  }, [endDate]);

  useEffect(() => {
    if (contextData.userId > 0 && contextData.companyId > 0) {
       const websiteId = {
        "WebsiteList.WebsiteId": 0,
        "WebsiteList.WebsiteName": "Select"
      };
      setSelectedWebsiteId(websiteId);
      setSelectedProfileId({});
    }

}, [contextData.userId, contextData.companyId]);


  //#region object

  //#endregion



  useEffect(() => {
    renderWebsiteProfiles(selectedWebsiteId);
  }, [selectedWebsiteId]);



  const onWebsiteDropdownChange = (data) => {

    // if( JSON.stringify(selectedWebsiteId) === JSON.stringify(data) ){
    //   setSelectedProfileId({});
    // }

    if (!object_equals(selectedWebsiteId, data)) {
      setSelectedProfileId({});
    }
    const websiteId = data["WebsiteList.WebsiteId"];
    contextData.changeWebsiteId(websiteId);
    setSelectedWebsiteId(data);
  

  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileId(data);
  };

  const setDefaultPickerValueOnDateChange = (dateType) => {
    const datePickerSplit = datePickerValue.split("-");
    let datePickerVle;
    if (dateType == "startDate") {
      datePickerVle = `${moment(startDate).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`;
      setDtPickerCompareValueOnDatePickerChange(startDate, moment(datePickerSplit[1]));
    }
    else if (dateType == "endDate") {
      datePickerVle = `${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(endDate).format("MMM DD,YYYY")}`;
      setDtPickerCompareValueOnDatePickerChange(moment(datePickerSplit[0]), endDate);
    }
    setDatePickerValue(datePickerVle);

  };

  const setDtPickerCompareValueOnDatePickerChange = (startDate, endDate) => {
    let diffDays = endDate.diff(startDate, 'days');
    let previousEndDate = moment(startDate).subtract(1, 'days').format("MMM DD,YYYY");
    let previousStartDate = moment(previousEndDate).subtract(diffDays, 'days').format("MMM DD,YYYY");
    let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
    setDatePickerCompareValue(datePickerCmpr);
  };






  const onLayoutChange = (newLayout) => {
    newLayout.forEach((l) => {
      const item = dashboardItems.find((i) => i.id.toString() === l.i);
      const toUpdate = JSON.stringify({
        x: l.x,
        y: l.y,
        w: l.w,
        h: l.h,
      });

      if (item && toUpdate !== item.layout) {
        updateDashboardItem({
          variables: {
            id: item.id,
            input: {
              layout: toUpdate,
            },
          },
        });
      }
    });
  };


  const rendersite = ({ resultSet, error, pivotConfig, request }) => {

    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Loader show={false} />;
    }


    return (
      // <DropdownComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayKey="WebsiteList.WebsiteId"
      //   arrayValue="WebsiteList.WebsiteName"
      //   dropdownValue={websiteId}
      //   onChange={setWebsiteId}
      //   showLabel={false}
      // />

      <DropdownWithIconComponent
        dropdownData={resultSet.tablePivot(pivotConfig)}
        arrayValue="WebsiteList.WebsiteName"
        selectedDropdown={selectedWebsiteId}
        onChange={onWebsiteDropdownChange}
        showLabel={false}
        showIconInDropdownItem={false}
      />
    );

  };

  const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
          return <Loader show={false} />;
    }
    // if(resultSet){
    //   setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0]);
    // }

    //  setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0]);

    return (
      // <DropdownComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayKey="WebsiteProfiles.AccountId"
      //   arrayValue="WebsiteProfiles.DisplayName"
      //   dropdownValue={profileId}
      //   onChange={setProfileId}
      //   showLabel={false}
      // />

      <DropdownWithIconComponent
        dropdownData={resultSet.tablePivot(pivotConfig)}
        arrayValue="WebsiteProfiles.DisplayName"
        selectedDropdown={Object.keys(selectedProfileId).length > 0 ? selectedProfileId : resultSet.tablePivot(pivotConfig)[0]}
        // selectedDropdown={selectedProfileId}
        onChange={onProfileDropdownChange}
        showLabel={false}
        showIconInDropdownItem={false}
      />

    );

  };

  const onDatePickerDropdownChange = (data) => {
    setStartDate("");
    setEndDate("");
    setDatePickerCompareValue("");
    if (data && Object.keys(data).length > 0) {
      if (data.compareOn) {
        const datePickerSplit = data.key.split("-");
        const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
        const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
        const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
        setDatePickerCompareValue(compareOnValue);
      }
      if (data.showDatePicker)
        setShowCustomDatePicker(true);
      else
        setShowCustomDatePicker(false);
    }
    // if (value) {
    setDatePickerValue(data.key);
    setSelectedDatePicker(data)
    // }

  };

  const validStartDate = (current) => {
    let currentDay = moment(endDate).format("MM/DD/YYYY");
    return current.isBefore(currentDay);
  };

  const validEndDate = (current) => {
    // let currentDay = moment().format();
    let yesterday = moment().subtract(1, 'day');
    return current.isBefore(yesterday);
  };

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const onLeftIconClick = (e) => {
    e.preventDefault();
    history.goBack();
    // window.history.back();
    // history.push(`${location.pathname}`);
  };


  return (
    <>
      <Row className="align-items-center mt-3 mb-3">

        <Col>
          {/* {showLeftIcon &&
            <a onClick={(e) => onLeftIconClick(e)}> <FontAwesomeIcon icon={faChevronLeft} /> Back </a>
          } */}
          <h3>{t("header.WOWebsiteInsightsMainTab")}</h3>
        </Col>
        {/* <Col>
          <FormCheck type="switch">
            <FormCheck.Input type="checkbox" id="billingSwitch" value={toggle} onChange={toggleChange} />
            <FormCheck.Label> {toggle ? "On" : "Off"} </FormCheck.Label>
          </FormCheck> 
        </Col>*/}
        <Col className="text-end">
          {/* <Button className="m-1 btn btn-primary">Edit Website</Button> */}
          <ButtonComponent
            isIcon={false}
            isPrimary={true}
            btnText="Edit Website" />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          {/* <QueryRenderer
            query={{
              "dimensions": [
                "WebsiteList.WebsiteId",
                "WebsiteList.WebsiteName"
              ],
              "timeDimensions": [],
              "order": {
                "WebsiteList.WebsiteId": "asc"
              },
              "filters": [
                {
                  "member": "WebsiteList.CompanyId",
                  "operator": "equals",
                  "values": [`${contextData.companyId}`]
                },
                {
                  "member": "WebsiteList.UserId",
                  "operator": "equals",
                  "values": [`${contextData.userId}`]
                }
              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) => rendersite({
              ...props,
              chartType: 'dropdown',
              pivotConfig: {
                "x": [
                  "WebsiteList.WebsiteId",
                  "WebsiteList.WebsiteName"

                ],
                "y": ["WebsiteList.WebsiteName"],
                "fillMissingDates": true,
                "joinDateRange": false
              }
            })}
          /> */}

        </Col>
        {(selectedWebsiteId && Object.keys(selectedWebsiteId).length >0) &&
        <Col xs={12} sm={6} xl={4} className="mb-4">
          {/* <QueryRenderer
            query={{
              "dimensions": [
                
                "WebsiteProfiles.DisplayName",
                "WebsiteProfiles.IntelConfigId"
              ],
              "timeDimensions": [],
              "order": {
                "WebsiteProfiles.IntelConfigId": "asc"
              },
              "filters": [
                {
                  "member": "WebsiteProfiles.WebsiteId",
                  "operator": "equals",
                  "values": [`${selectedWebsiteId["WebsiteList.WebsiteId"]}`]
                },
                {
                  "member": "WebsiteProfiles.CompanyId",
                  "operator": "equals",
                  "values": [`${contextData.companyId}`]
                },
              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={true}
            render={(props) => renderWebsiteProfiles({
              ...props,
              chartType: 'dropdown',
              pivotConfig: {
                "x": [
                  "WebsiteProfiles.AccountId",
                  "WebsiteProfiles.DisplayName",
                  "WebsiteProfiles.IntelConfigId"

                ],
                "y": ["WebsiteProfiles.DisplayName"],
                "fillMissingDates": true,
                "joinDateRange": false
              }
            })}
          /> */}

        </Col>
}
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <DropdownWithIconComponent
            dropdownData={dashbordDateRangeList}
            // arrayKey="key"
            arrayValue="value"
            selectedDropdown={selectedDatePicker}
            onChange={onDatePickerDropdownChange}
            showLabel={false}
            showIconInDropdownItem={false}
          />

          {showCustomDatePicker &&
            <Form>
              <Form.Group className="mb-3">
                <Datetime
                  timeFormat={false}
                  closeOnSelect={false}
                  onChange={setStartDate}
                  isValidDate={validStartDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={startDate ? moment(startDate).format("MMM DD,YYYY") : ""}
                        placeholder={t("header.startDatePlaceHolder")}
                        onFocus={openCalendar}
                        onChange={() => { }} />
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    </InputGroup>
                  )} />

                <Datetime
                  timeFormat={false}
                  closeOnSelect={false}
                  onChange={setEndDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={endDate ? moment(endDate).format("MMM DD,YYYY") : ""}
                        placeholder={t("header.endDatePlaceHolder")}
                        onFocus={openCalendar}
                        onChange={() => { }} />
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    </InputGroup>
                  )} />
              </Form.Group>
            </Form>
          }

        </Col>
      </Row>
      <div className="d-block mb-4 mb-md-0">
        <center>
          <p className="mb-0">

            <small>  {t("header.InsightDateLabel")}: {datePickerValue} </small>
            <small>  {t("header.WOComparedToText")} {datePickerCompareValue} </small>
          </p>



        </center>
      </div>
      {/* Note:  ReactGridLayout 's 
      isDraggable is for the Card Draggable
     isResizable is for the Card Resizing  */}
      <ReactGridLayout cols={12} rowHeight={50}
        isDraggable={false} isResizable={false}
        onLayoutChange={onLayoutChange}
      >
        {children}
      </ReactGridLayout>
    </>
  );
};

export default withRouter(Dashboard);
