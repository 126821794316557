import React from "react";
import PropTypes from 'prop-types';
import { Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconSprite from "../assets/Icon/icon-sprite.svg";

const ButtonsComponent = ({
    isIcon,
    isCustomIcon,
    iconName,
    onClick,
    isPrimary,
    btnText,
    btn_size,
    btn_class,
    dissable,
    isClose
}) => {

    const buttonClass = btn_class ? btn_class : "mb-2";

    const renderIcon = () => (
        isIcon &&
        (isCustomIcon ?
            <svg className="me-2 dropdown-primary-icon">
                <use href={iconSprite + `#${iconName}`} />
            </svg>
            : <FontAwesomeIcon
                icon={iconName} className="me-2" />
        )

    );

    return (
        <>
            {
                isPrimary ?
                    <Button variant="primary" disabled={dissable ? true : false} type="submit" size={btn_size} className={buttonClass} onClick={onClick && onClick}>  {renderIcon()} {btnText} </Button>
                    : isClose ?
                        <Button variant="close" size="xs" onClick={onClick && onClick} />
                        :
                        <Button variant="outline-primary" disabled={dissable ? true : false} type="submit" size={btn_size} className={buttonClass} onClick={onClick && onClick}>  {btnText}</Button>

            }
        </>
    );

};

ButtonsComponent.propTypes = {
    isIcon: PropTypes.bool,
    isPrimary: PropTypes.bool,
    onClick: PropTypes.func,
    btnText: PropTypes.string,
    isCustomIcon: PropTypes.bool,
    iconName: PropTypes.string,
    btn_size: PropTypes.string,
    btn_class: PropTypes.string,
    dissable: PropTypes.bool,
    isClose: PropTypes.bool

};
ButtonsComponent.defaultProps = {
    isIcon: false,
    isPrimary: true,
    onClick: null,
    btnText: '',
    isCustomIcon: false,
    iconName: '',
    btn_size: '',
    btn_class: '',
    dissable: false,
    isClose: false
}

export default ButtonsComponent;