import React, { useEffect, useState, useContext } from 'react';
import { isMobile } from "react-device-detect";
// import ChartRenderer from '../components/ChartRenderer';
import DashboardItem from '../components/DashboardItem';
// import { DEFAULT_PAGE_NO, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
// import SummaryRenderer from '../components/SummaryRenderer';
import moment from "moment-timezone";
import AppContext from '../components/AppContext';
import Visitoranalytics from '../components/Visitoranalytics';
// import MultilineToggleComponent from '../components/MultilineToggleComponent';
import ApiChartRenderer from '../components/ApiChartRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from '@themesberg/react-bootstrap'



import {
  getVisitorAnalyticsChartData,
  getVisitorAnalyticsTableData, getWatchlistData, getVisitorCategoryData, getVisitorCountryData,
  getVisitorTrafficSourceData, getVisitorAnalyticsVisitorWiseTableData, getWatchlistVisitorwiseData, apiChartsVisitorInsightsService, getAllPagesData,
  getLandingPagesData, getExitPagesData

} from "../stubs/ComponentJson/Visitoranalytics";


const deserializeItem = (i) => ({
  ...i,
  layout: i.layout ? JSON.parse(i.layout) : {},
  summary: i.summary ? JSON.parse(i.summary) : {},
  vizState: i.vizState ? JSON.parse(i.vizState) : {},
  apiUrl: i.apiUrl ? i.apiUrl : undefined,
  isResultShownFromApi: i.isResultShownFromApi ? i.isResultShownFromApi : false,
  chartType: i.chartType ? i.chartType : undefined,
  extraSummary: i.extraSummary ? JSON.parse(i.extraSummary) : {}
});

const VisitorAnalyticsPage = () => {
  const contextData = useContext(AppContext);
  // const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  // const [searchText, setSearchText] = useState("");
  // const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  // const [selectedProfileId, setSelectedProfileId] = useState({});
  const [visitorChartData, setVisitorChartData] = useState([]);
  const [visitorTableData, setVisitorTableData] = useState([]);
  const [visitorCategoryData, setVisitorCategoryData] = useState([]);
  const [visitorCountryData, setVisitorCountryData] = useState([]);
  const [visitorTrafficSourceData, setVisitorTrafficSourceData] = useState([]);
  const [watchlistData, setWatchlistData] = useState([]);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const websiseSection = { "Profilesection.displayname": "Website", "Section.sectionid": 0 }
  // const [selectedSectionId, setSelectedSectionId] = useState({});
  // const [isRefresh, setIsRefresh] = useState(false);
  const [d3, setd3] = useState();
  // const [offset, setOffset] = useState(0);
  // const [offsetAll, setOffsetAll] = useState(0);
  // const [pageNoAll, setPageNoAll] = useState(0);
  // const [visitorId, setVisitorId] = useState(null);
  // const [offsetWatchlist, setOffsetWatchlist] = useState(0);
  // const [pageNoWatchlist, setPageNoWatchlist] = useState(0);
  // const [queryValue, setQueryValue] = useState("");
  // const [selectedVisitorCompany, setSelectedVisitorCompany] = useState();
  // const [selectedVisitorCategory, setSelectedVisitorCategory] = useState();
  // const [selectedWatchlistCompany, setSelectedWatchlistCompany] = useState();
  // const [selectedWatchlistCategory, setSelectedWatchlistCategory] = useState();
  // const [formFields, setFormFields] = useState({
  //   companyName: '',
  //   country: {},
  //   notificationName: '',
  //   duration: '',
  //   threshold: {},
  //   customThreshold: '',
  //   customDurationStart: '',
  //   customDurationEnd: '',
  //   isCustomDuration: false
  // });
  // const [formError, setFormError] = useState({
  //   companyName: '',
  //   country: '',
  //   notificationName: '',
  //   duration: '',
  //   threshold: '',
  //   customThreshold: '',
  //   customDurationStart: '',
  //   customDurationEnd: '',
  //   selectedCompany: ''
  // });
  const [selectedCategoryId, setSelectedCategoryId] = useState({ sectionId: 0 });
  const [allPagesData, setAllPagesData] = useState([]);
  const [landingPagesData, setLandingPagesData] = useState([]);
  const [exitPagesData, setExitPagesData] = useState([]);
  const { iswatchlist } = useParams();
  const [t] = useTranslation();

  const [alertVisibleMessage, setAlertVisibleMessage] = useState(null);


  useEffect(() => {

    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    const culture = localStorage.getItem("i18nextLng");
    // const userSelectedSectionId = selectedSectionId;

    if (contextData.selectedTab == "Website") {

      let tabledta = getVisitorAnalyticsTableData(
        // contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate,
        // contextData.userId, contextData.companyId, offsetAll, (selectedVisitorCompany !== undefined && selectedVisitorCompany.id !== "All") ? selectedVisitorCompany.id : undefined, (selectedVisitorCategory !== undefined && selectedVisitorCategory.id !== "All") ? selectedVisitorCategory.id : undefined
      );
      setVisitorTableData(tabledta);

      if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {

        let chartdta = getVisitorAnalyticsChartData(
          // contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, contextData.categoryId
        );
        //let chartdta = (selectedSectionId === undefined || Object.keys(selectedSectionId).length === 0 || selectedSectionId["Section.sectionid"] == 0) ? getVisitorAnalyticsChartData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate)
        //  : getVisitorAnalyticsChartDataSectionWise(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, userSelectedSectionId["Section.sectionid"]);
        setVisitorChartData(chartdta);

        let categoryData = getVisitorCategoryData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, selectedCategoryId.sectionId);
        setVisitorCategoryData(categoryData);

        let countryData = getVisitorCountryData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, selectedCategoryId.sectionId, culture);
        setVisitorCountryData(countryData);

        let visitorTrafficSourceData = getVisitorTrafficSourceData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, selectedCategoryId.sectionId, contextData.companyId, culture);
        setVisitorTrafficSourceData(visitorTrafficSourceData);

        let wactchlistdta = getWatchlistData();
        setWatchlistData(wactchlistdta);
      }


      let apdata = getAllPagesData();
      setAllPagesData(apdata);

      let lpdata = getLandingPagesData();
      setLandingPagesData(lpdata);

      let epdata = getExitPagesData();
      setExitPagesData(epdata);
    }
    else {
      setVisitorTableData([])
    }

    // if (searchText !== "" && searchText !== formFields.companyName) {
    //   formFields.companyName = searchText;
    // }
  },
    //[selectedWatchlistCompany, selectedWatchlistCategory, selectedVisitorCategory, formFields, searchText, selectedVisitorCompany, contextData.companyId, contextData.selectedProfile, contextData.selectedWebsite, contextData.startDate, contextData.endDate, selectedSectionId, offsetAll, pageNoAll, visitorId, contextData.userId, offsetWatchlist]
    [contextData.startDate, contextData.selectedTab, contextData.endDate, contextData.selectedProfile["WebsiteProfiles.ProfileId"]]
  );
  // const onPageNoChange = (number) => {
  //   setPageNo(number);

  // };
  const onDropdownChange = (data) => {
    contextData.changeVisitorCategory(data)
    setSelectedCategoryId(data)
    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    const culture = localStorage.getItem("i18nextLng");
    let categoryData = getVisitorCategoryData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, data.sectionId);
    setVisitorCategoryData(categoryData);

    let countryData = getVisitorCountryData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, data.sectionId, culture);
    setVisitorCountryData(countryData);

    let visitorTrafficSourceData = getVisitorTrafficSourceData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, data.sectionId, contextData.companyId, culture);
    setVisitorTrafficSourceData(visitorTrafficSourceData);
  };

  //useEffect(() => { 
  // let chartdta = getVisitorAnalyticsChartData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], null, null, contextData.categoryId);
  // setVisitorChartData(chartdta);
  //}, [selectedCategoryId]);

  // const changeSelectedWatchlistCategory = (value) => {
  //   setPageNoWatchlist(1);
  //   setOffsetWatchlist(0);
  //   setSelectedWatchlistCategory(value);
  // }

  // const changeWatchlist = () => {

  //   let wactchlistdta = (visitorId !== undefined && visitorId !== null && visitorId !== 0) ? getWatchlistVisitorwiseData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offsetWatchlist, visitorId, (selectedWatchlistCompany !== undefined && selectedWatchlistCompany.id !== "All") ? selectedWatchlistCompany.id : undefined, (selectedWatchlistCategory !== undefined && selectedWatchlistCategory.id !== "All") ? selectedWatchlistCategory.id : undefined) : getWatchlistData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offsetWatchlist, (selectedWatchlistCompany !== undefined && selectedWatchlistCompany.id !== "All") ? selectedWatchlistCompany.id : undefined, (selectedWatchlistCategory !== undefined && selectedWatchlistCategory.id !== "All") ? selectedWatchlistCategory.id : undefined);
  //   setWatchlistData(wactchlistdta);
  // }
  // const changeVisitor = () => {
  //   const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
  //   const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
  //   let tabledta = (visitorId !== undefined && visitorId !== null && visitorId !== 0) ? getVisitorAnalyticsVisitorWiseTableData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, contextData.userId, contextData.companyId, offsetAll, visitorId, (selectedVisitorCompany !== undefined && selectedVisitorCompany.id !== "All") ? selectedVisitorCompany.id : undefined, (selectedVisitorCategory !== undefined && selectedVisitorCategory.id !== "All") ? selectedVisitorCategory.id : undefined) : getVisitorAnalyticsTableData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate,
  //     contextData.userId, contextData.companyId, offsetAll, (selectedVisitorCompany !== undefined && selectedVisitorCompany.id !== "All") ? selectedVisitorCompany.id : undefined, (selectedVisitorCategory !== undefined && selectedVisitorCategory.id !== "All") ? selectedVisitorCategory.id : undefined);
  //   setVisitorTableData(tabledta);
  // }
  // const onSearchBoxChange = (visitor) => {


  // }
  const dashboardItem = (item) => (
    <div className="visitor-analytics-switch-toggle rgl-website-analytics" key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)} >

      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}
      // tooltipText={item.tooltipText!==undefined?item.tooltipText:""}
      >
        {
          item.apiUrl !== undefined &&
          <>
            <ChartRendererTable
              minimumRows={5}
              showCount={false}
              summaryBesideText={item.id == 8 ? " Alerts" : ""}
              showPagination={true}
              apiUrl={item.apiUrl}
              showNumberOfRows={6}
            />
          </>
        }

        {(item.isResultShownFromApi && item.chartType && item.chartType != "table") &&
          <ApiChartRenderer
            setAlertVisibleMessage={setAlertVisibleMessage}
            isLegendShown={item.isLegendShown} isSummeryShown={item.isSummeryShown} services={apiChartsVisitorInsightsService}
            chartType={item.chartType} itemId={item.id}
            name={item.nameatbottom}
            legendPercentage={item.legendPercentage}
            tooltipText={item.tooltipText}
            cardProperty={item.layout}
            //pageNo={pageNo}
            //onPageNoChange={onPageNoChange}
            selectedCategoryId={selectedCategoryId}
            legends={item.legends}
            cardName={item.cardName}
          // pageName = {"visitoranalytics"}
          />
        }
        {/* {item.chartType === undefined &&
          <ChartRenderer vizState={item.vizState} cardProperty={item.layout}
            legends={item.legends} setPageNo={setPageNo}
            isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} />
        } */}
      </DashboardItem>

    </div>
  );

  // const watchlistItemData = (item) => (
  //   <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
  //     <DashboardItem
  //       key={item.id}
  //       itemId={item.id}
  //       title={item.name}
  //       enableOption={item.enableOption}
  //     >
  //       {Object.keys(item.summary).length > 0 &&
  //         <SummaryRenderer vizState={item.summary} title={item.name} />
  //       }
  //       <ChartRenderer vizState={item.vizState} cardProperty={item.layout}
  //         showSearchTextBox={true} onSearchBoxChange={onSearchBoxChange} legends={item.legends} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination}
  //         offset={offset} setOffset={setOffset} searchText={searchText} setSearchText={setSearchText} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} />
  //       {item.extraSummary.query !== undefined && <MultilineToggleComponent d3={d3} setd3={setd3} extraSummary={item.extraSummary.query}></MultilineToggleComponent>}
  //     </DashboardItem>
  //   </div>
  // );
  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.getConnectIdIntelligence")}</h2>
    </div>
  );

  return !visitorTableData || visitorTableData.length > 0 ? (

    <>
      {alertVisibleMessage && (<div className="fade modal-backdrop show"></div>)}
      <Alert
        className="toast-custom"
        variant="success"
        show={alertVisibleMessage}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {alertVisibleMessage}
          </div>
          <Button variant="close" size="xs" onClick={() => setAlertVisibleMessage(null)} />

        </div>
      </Alert>
      <Visitoranalytics
        visitorItems={visitorTableData}
        selectedCategoryId={selectedCategoryId}
        onDropdownChange={onDropdownChange}
        // setVisitorId={setVisitorId}
        // setPageNoAll={setPageNoAll}
        // pageNoAll={pageNoAll}
        // setIsRefresh={setIsRefresh}
        // watchlistItemData={watchlistItemData}
        watchlistItems={watchlistData}
        dashboardItem={dashboardItem}
        // setSearchText={setSearchText}
        visitorCategoryData={visitorCategoryData}
        visitorCountryData={visitorCountryData}
        visitorTrafficSourceData={visitorTrafficSourceData}
        deserializeItem={deserializeItem}
        // selectedProfileId={contextData.selectedProfile}
        //setSelectedProfileId={setSelectedProfileId}
        //selectedWebsiteId={contextData.selectedWebsite}
        //setSelectedWebsiteId={setSelectedWebsiteId}
        // startDate={contextData.startDate}
        // endDate={contextData.endDate}
        // setStartDate={setStartDate}
        // setEndDate={setEndDate}
        // selectedSectionId={selectedSectionId}
        // setSelectedSectionId={setSelectedSectionId}
        // websiseSection={websiseSection}
        // offsetAll={offsetAll}
        // setOffsetAll={setOffsetAll}
        // offsetWatchlist={offsetWatchlist}
        // setOffsetWatchlist={setOffsetWatchlist}
        // changeWatchlist={changeWatchlist}
        // changeVisitor={changeVisitor}
        // formFields={formFields}
        // setFormFields={setFormFields}
        // setFormError={setFormError}
        // formError={formError}
        // setPageNoWatchlist={setPageNoWatchlist}
        // pageNoWatchlist={pageNoWatchlist}
        // setSelectedVisitorCompany={setSelectedVisitorCompany}
        // setSelectedVisitorCategory={setSelectedVisitorCategory}
        // setSelectedWatchlistCompany={setSelectedWatchlistCompany}
        // setSelectedWatchlistCategory={changeSelectedWatchlistCategory}
        allPagesItems={allPagesData}
        landingPagesItems={landingPagesData}
        exitPagesItems={exitPagesData}
        iswatchlist={iswatchlist == "watchlist" ? true : false}
      >
        {visitorChartData.map(deserializeItem).map(dashboardItem)}
      </Visitoranalytics>
    </>
  ) : (
    <Empty />
  );
};

export default VisitorAnalyticsPage;
