import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Image, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import Loader from '../components/VoltLoader';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import AppContext from '../components/AppContext';
import { dashbordDateRangeList } from "../utils/datetime";
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);


const WebcastManageUsers = ({ children, dashboardItems, selectedevent, setSelectedevent, selectedPresention, setSelectedPresentation, selectedWebsiteId, setSelectedWebsiteId, history, location }) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [updateKey, setUpdateKey] = useState("");
  const [manageEventsNew, setManageEventsNew] = useState("");
  const [selectedPresentionNew, setSelectedPresentionNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false)
  const [selectedEventNew, setSelectedEventNew] = useState({})
  const [eventPresentationsList, setEventPresentationsList] = useState([])
  const [blurLoader, setBlurLoader] = useState(false);
  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );
  useEffect(() => {
    // Fetching values from Local storage in case of Page Refresh

    if (localStorage.getItem("IsPageRefresh") == "1") {
      if (contextData && contextData.hasEvents == 1) {
        if (localStorage.getItem("webcastEventId")) {
          setSelectedevent(JSON.parse(localStorage.getItem("webcastEventId")));
        }
      } else {

        setSelectedPresentionNew(JSON.parse(localStorage.getItem("PresentationId")))
      }



    }

  }, []);
  useEffect(() => {

    if (eventPresentationsList != null && eventPresentationsList.length) {
      if (contextData.hasEvents === 1) {
        let preesentationsData = eventPresentationsList.filter((e) => { return e["Presentations.eventid"] === manageEventsNew["Events.id"] });
        //let presentationsUpdated = preesentationsData.map((e)=>({["Presentations.name"]:e.Name,["Presentations.id"]:e.Slug,["Presentations.clientslug"]:e.ClientId,["Presentations.slug"]:e.Url,["Presentations.eventid"]:e.EventId}));
        //if(selectedWebsiteIdNew["WebsiteList.WebsiteId"]){
        renderWebsiteProfiles({ resultSet: preesentationsData })
        //}
      } else {
        renderWebsiteProfiles({ resultSet: eventPresentationsList })
      }


    }

    //renderWebsiteProfiles({resultSet:eventPresentationsList})
  }, [manageEventsNew, eventPresentationsList])
  const onWebsiteDropdownChange = (data) => {

    // if (!object_equals(selectedWebsiteId, data)) {
    //   setSelectedProfileId({});
    // }
    // const websiteId = data["WebsiteList.WebsiteId"];
    // contextData.changeWebsiteId(websiteId);
    setManageEventsNew(data);


  };
  const onPresentationDropdownChange = (data) => {
    setSelectedPresentionNew(data)
    // contextData.changePresentationId(data["Presentations.id"]);




  };
  const onEventsDropdownChange = (data) => {
    // contextData.changeEventId(data["Events.id"]);
    // setSelectedevent(data);
    setManageEventsNew(data)


  };
  useEffect(() => {
    if (contextData.companyId > 0) {
      getWebsites();
    }

    //setSelectedProfileIdNew({})
  }, [contextData.userId, contextData.companyId]);

  const getWebsites = () => {
    setBlurLoader(true)
    request(baseURL + `/Common/GetWebcastPresentationByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setBlurLoader(false)
        let resultData = [];
        let presentationsData = [];
        if (res !== null) {
          resultData = res.Events.map((e) => ({ ["Events.title"]: e.EventTitle, ["Events.id"]: e.EventId }))
          presentationsData = res.EventPresentations.map((e) => ({ ["Presentations.name"]: e.Name, ["Presentations.id"]: e.Slug, ["Presentations.clientslug"]: e.ClientId, ["Presentations.slug"]: e.Slug, ["Presentations.eventid"]: e.EventId, ["Presentations.url"]: e.Url, ["Presentations.clientid"]: e.ClientId }))
        }
        setEventPresentationsList(presentationsData)
        rendersite({ resultSet: resultData })
      })
      .catch((err) => {

      })
  }

  const applyFilters = () => {
    setBlurLoader(false)
    if (contextData && contextData.hasEvents === 1) {
      contextData.changeEventId(manageEventsNew["Events.id"]);
      setSelectedevent(manageEventsNew);
      localStorage.setItem("webcastEventId", JSON.stringify(manageEventsNew));// Adding EventId to local stoarge in case of Page Refresh
    } else {
      localStorage.setItem("PresentationId", JSON.stringify(selectedPresentionNew));
      setSelectedPresentation(selectedPresentionNew)
    }

  }
  const cancelFilters = () => {
    if (contextData && contextData.hasEvents === 1) {
      onWebsiteDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
    } else {
      onPresentationDropdownChange(JSON.parse(localStorage.getItem("PresentationId")))
    }

  }
  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="webcast" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Events', data: JSON.parse(localStorage.getItem("eventsData")), selectedItem: manageEventsNew, isVisible: true, label: t("header.filterEventLabel"), callback: onEventsDropdownChange, displayText: "Events.title" }
  ]}
    title={t("header.manageusersTitle")} showDate={false} applyFilters={applyFilters} />, [updateKey, manageEventsNew, autoLoad]);

  const commonHeaderForIL = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="webcast" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Presenration', data: JSON.parse(localStorage.getItem("presentationData")), selectedItem: selectedPresentionNew, isVisible: true, label: t("header.filterPresentationLabel"), callback: onPresentationDropdownChange, displayText: "Presentations.name" }
  ]}
    title={t("header.manageusersTitle")} showDate={false} applyFilters={applyFilters} />, [selectedPresentionNew, autoLoad]);

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }
    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }

    if (resultSet !== null && resultSet.length) {
      localStorage.setItem("eventsData", JSON.stringify(resultSet));

      //if(manageEventsNew !== "" && Object.keys(manageEventsNew).length <= 0){
      if (JSON.parse(localStorage.getItem("webcastEventId")) !== null && Object.keys(JSON.parse(localStorage.getItem("webcastEventId"))).length > 0) {
        onEventsDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
      } else {
        onEventsDropdownChange(resultSet[0])
      }
      setUpdateKey(JSON.stringify(Math.random()))


      //}




      //
      // if (Object.keys(manageEventsNew) > 0) 
      {
        setAutoLoad(true)
      }

    }

    return (
      <></>
      // <DropdownWithIconComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayValue="Events.title"
      //   selectedDropdown={selectedevent}
      //   onChange={onEventsDropdownChange}
      //   showLabel={false}
      //   showIconInDropdownItem={false}
      //   customClassName="full-width"
      // />

    );
  }
  const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }
    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }

    localStorage.setItem("presentationData", JSON.stringify([]));
    localStorage.setItem("presentationData", JSON.stringify(resultSet))
    if (resultSet !== null && resultSet.length) {
      if (selectedPresentionNew !== null && Object.keys(selectedPresentionNew).length <= 0) {
        if (localStorage.getItem("PresentationId")) {
          onPresentationDropdownChange(JSON.parse(localStorage.getItem("PresentationId")))
        } else {
          onPresentationDropdownChange(resultSet[0]);
        }
        //setSelectedPresentionNew(resultSet.tablePivot(pivotConfig)[0])
        //contextData.changePresentationId(resultSet.tablePivot(pivotConfig)[0]["Presentations.id"]);

        //localStorage.setItem("presentationData",JSON.stringify(resultSet.tablePivot(pivotConfig)))
      } else {
        //    if (!object_equals(selectedPresentionNew,resultSet.tablePivot(pivotConfig))[0]) {
        //   //setSelectedPresentionNew(resultSet.tablePivot(pivotConfig)[0]);
        // } 
      }


      // onPresentationDropdownChange(resultSet.tablePivot(pivotConfig)[0]); 
      // for(let item in resultSet.tablePivot(pivotConfig)){
      //   if (!object_equals(selectedPresentionNew, item)) {
      //     onPresentationDropdownChange(resultSet.tablePivot(pivotConfig)[0]);  

      //   } 
      // }
      setUpdateKey(JSON.stringify(resultSet))
      //if (Object.keys(selectedPresentionNew).length <= 0) 
      {


        setAutoLoad(true)
      }

    }


    return (

      <></>
      // <DropdownWithIconComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayValue="Presentations.name"
      //   selectedDropdown={selectedpresentation}
      //   onChange={onPresentationDropdownChange}
      //   showLabel={false}
      //   showIconInDropdownItem={false}
      //   customClassName="full-width text-overflow"
      // />

    );

  };

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {(contextData && contextData.hasEvents === 1) ? commonHeader : commonHeaderForIL}
      {
        contextData && contextData.hasEvents === 0 &&
        <Row className="ps-3">
          <Col xs={12} sm={12}>
            <p>{t("header.webcastManageUsersMessage")}
            </p>
          </Col>
        </Row>
      }

      <Row className="ps-3">
        {/* {contextData && contextData.hasEvents === 1?'':<Col sm={8} md={8}><h2>Manage Users</h2></Col>} */}


        <Col xs={12} sm={4} className="mb-1">

          {/* <QueryRenderer
                            query={{
                                "dimensions": [
                                    "Events.title",
                                    "Events.id",
                                    "Events.clientslug",
                                    "Events.clientid"
                                ],
                                "timeDimensions": [],
                                "order": {
                                    "events.title": "asc"
                                },
                                "filters": [
                                    {
                                        "member": "Events.clientid",
                                        "operator": "equals",
                                        "values": [`${contextData.webcastClientId}`]
                                    },
                                    {
                                        "member": "EventsPresentationids.count",
                                        "operator": "gt",
                                        "values": [
                                            "0"
                                        ]
                                    }
                                ],
                            }}
                            cubejsApi={cubejsApi}
                            resetResultSetOnChange={false}
                            render={(props) => rendersite({
                                ...props,
                                chartType: 'dropdown',
                                pivotConfig: {
                                    "x": [
                                        "Events.title",
                                        "Events.id",
                                        "Events.clientslug",
                                        "Events.clientid"
                                    ],
                                    "y": ["events.title"],
                                    "fillMissingDates": true,
                                    "joinDateRange": false
                                }
                            })}
                        /> */}
        </Col>


        {/* {(contextData && contextData.webcastClientId && contextData.hasEvents == 0) &&
        <>
            <QueryRenderer
              query={{
                "dimensions": [
                  "Presentations.name",
                  "Presentations.id",
                  "Presentations.polltype",
                    "Presentations.clientslug",
                    "Presentations.slug"
                ],
                "timeDimensions": [],
                "order": {
                  "Presentations.name": "asc"
                },
                "filters": [
                  {
                    "member": "Presentations.clientid",
                    "operator": "equals",
                    "values": [`${contextData.webcastClientId}`]
                  },
                  {
                    "member": "Presentations.myinvestis_flag",
                    "operator": "equals",
                    "values": [
                      "1"
                    ]
                  }

                ],
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={true}
              render={(props) => renderWebsiteProfiles({
                ...props,
                chartType: 'dropdown',
                pivotConfig: {
                  "x": [
                    "Presentations.name",
                    "Presentations.id",
                    "Presentations.pollType",
                    "Presentations.clientslug",
                    "Presentations.slug"

                  ],
                  "y": ["Presentations.name"],
                  "fillMissingDates": true,
                  "joinDateRange": false
                }
              })}
            />

          
          </>
        } */}
      </Row>
      {(contextData && contextData.hasEvents === 1) ?
        <div className="d-inline-block p-3 pe-0 w-100">
          <div className="shadow-soft border rounded border-light w-100 nested-card-container webcast-manage-users Notification-container">
            {children}
          </div>
        </div>
        : <div className="d-inline-block p-3 pe-0 w-100">
          <div className="shadow-soft border rounded border-light w-100 nested-card-container webcast-manage-users  Notification-container">
            {children}
          </div>
        </div>
      }

    </>
  );
};

export default withRouter(WebcastManageUsers);
