import React from 'react'
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { OTPExpireTime } from "../utils/common";


const LoginCountdownTimer = (props) => {
    // const { initialMinute = OTPExpireTime, initialSeconds = 0 } = props;
    // const [minutes, setMinutes] = useState(initialMinute);
    // const [seconds, setSeconds] = useState(initialSeconds);
    // const [happened, sethappened] = useState(false);
    // let sec = seconds < 10 ? `0${seconds}` : seconds;

    // useEffect(() => {
    //     let myInterval = setInterval(() => {

    //         if (seconds > 0) {
    //             setSeconds(seconds - 1);
    //         }
    //         if (seconds === 0) {
    //             if (minutes === 0) {
    //                 clearInterval(myInterval)
    //             } else {
    //                 setMinutes(minutes - 1);
    //                 setSeconds(59);
    //             }
    //         }
    //     }, 1000)

    //     return () => {
    //         clearInterval(myInterval);
    //     };
    // });



    // Define the initial time in seconds
    const initialTime = OTPExpireTime * 60;

    const [time, setTime] = useState(initialTime);

    // Create a function to decrement the time
    const decrementTime = () => {
        if (time > 0) {
            setTime(time - 1);
        }
        else {
            props.timeoutExpired();
        }
    };

    // Use useEffect to start the timer and update the time
    useEffect(() => {
        const timer = setInterval(decrementTime, 1000);

        // Clean up the timer when the component unmounts
        return () => {
            clearInterval(timer);
        };
    }, [time]);

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    useEffect(() => {

        setTime(initialTime);

    }, [props.reload]);


    return (
        <>

            <b>   {minutes}:{seconds < 10 ? `0${seconds}` : seconds}s</b>

        </>
    )
}

export default LoginCountdownTimer;