import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from "react-i18next";

function SettingsPages() {
    const [t] = useTranslation();
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12}>
                <h2 className="h2 mb-0">{t("header.SettingsTitle")}</h2>
            </Col>
        </Row>
        
        <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <div className="card-custom-title mb-0">{t("header.SettingsMessage")}</div>
                        
                    </div>
                </Col>
            </Row>
        

        </div>
    )
}

export default SettingsPages