import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Image, Row, Tooltip, OverlayTrigger, Button, Alert } from "@themesberg/react-bootstrap";
import ChartsComponent from "./Charts";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { getWebsiteURL } from "../utils/common";
import AppContext from "./AppContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { pageValuesByKey } from "../utils/constant";
import { isMobile } from "react-device-detect";
import { hideMetricsList } from "../AppConfig";
import { convertTranslatinString } from "../utils/common";
import DataChartsComponent from "./DataCharts";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DataChartRenderer = ({ barChartClickEvent, ...props }) => {

    let {
        itemId,
        chartType,
        cardProperty,
        data
    } = props;

    //const contextData = useContext(AppContext);
    const [t, i18n] = useTranslation();
    // const [summaryBesideTextValue, setSummaryBesideTextValue] = React.useState("");
    const [apiRes, setApiRes] = React.useState(undefined);
    const [summeryData, setSummeryData] = React.useState([]);
    // const [noDataContent, setNoDataContent] = React.useState(false);
    // const [isLoaderShown, setIsLoaderShown] = React.useState(true);
    // const [apiMessage, setApiMessage] = React.useState("");
    const [tooltipelementremoval, setToolTipElementRemoval] = React.useState([]);
    // const [cookieCardTitle, setCookieCardTitle] = useState(undefined);

    const [alertVisibleMessage, setAlertVisibleMessage] = useState(null);


    if (isMobile && chartType === "pie") {
        cardProperty.h = 15
    }

    useEffect(() => {

        if (chartType === "multiline") {
            hideLinesInGrap(parseDataDynamic(data));
        }
        else if (chartType === "pie") {
            setApiRes(addingTooltipToData(data));
        }
        else {
            setApiRes(data);
        }

    }, [data])

    const addingTooltipToData = (data) => {
        let newResult = [];
        let itemToSum = Array.isArray(data) ? data.map(x => x.TotalOpenCount) : [];
        let total = itemToSum.reduce((a, b) => a + b, 0)

        data && Array.isArray(data) && data.map((item) => {
            let toolTip = item?.ToolTipTitle ? item?.ToolTipTitle : "";
            let obj = {
                ...item,
                toolTip: Math.round(item.TotalOpenCount * 100 / total) + "% " + toolTip
            }

            newResult.push(obj);
        })
        return newResult;
    }

    const parseDataDynamic = (res) => {

        let data = [];
        let sData = [];
        let type = res?.type;
        res && Object.keys(res).map((item, i) => {
            if (typeof res[item] == 'object') {
                let s = {
                    type: type,
                    key: item.split('_').join(' '),
                    values: [],
                    copyValues: [],
                    show: 1
                }
                res[item].map((i) => {
                    s.values.push({ "value": i.Count, "category": i.ProcessDate, "xvalue": i.ProcessDate, "x": i.ProcessDate })
                })

                data.push(s);
            }
            else {
                let formatSummery = {
                    "shortTitle": item,
                    "value": res[item],
                }

                typeof res[item] != "string" && sData.push(formatSummery);
            }
        })
        setSummeryData(sData);

        return data;
    }
    const hideLinesInGrap = (data) => {
        let apiDataUpdated = [];
        let array = [];

        //  setToolTipElementRemoval([]);
        if (data?.length > 0) {
            if (chartType === "multiline") {
                data.map((e) => {
                    if (e?.key) {
                        // pageValuesByKey.find((element) => {
                        //     if (e.key === element.key) {

                        //         if (element.defaultCheck === 1) {
                        //             e.show = 1
                        //             apiDataUpdated.push(e);
                        //         } else {
                        //             e.show = 0
                        //             apiDataUpdated.push(e);
                        //             array.push(element.key)
                        //         }
                        //     }
                        // }
                        // )
                        apiDataUpdated.push(e);
                    }
                })
            }
        }
        setApiRes(apiDataUpdated);
        //  setToolTipElementRemoval(array);
    }

    const TypeToChartComponent = {
        line: (props) => {
            return <DataChartsComponent type="line"   {...props} />;
        },
        pie: (props) => {
            return <DataChartsComponent type="pie" legendPercentage={true} {...props} />;
        },
        multiline: (props) => {
            return <DataChartsComponent type="multiline" datafortooltip={tooltipelementremoval} {...props} />;
        },
        map: (props) => {
            return <DataChartsComponent type="map" datafortooltip={tooltipelementremoval} {...props} />;
        },
        bar: (props) => {
            return <DataChartsComponent type="bar" legendPercentage={true} barChartClickEvent={barChartClickEvent} {...props} />
        },
        areachart: (props) => {
            return <DataChartsComponent type="areachart"  {...props} />
        }
    };

    const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
        .map((key) => ({
            [key]: React.memo(TypeToChartComponent[key]),
        }))
        .reduce((a, b) => ({ ...a, ...b }));

    const component = TypeToMemoChartComponent[chartType];

    const createTooltip = (t, tooltipKey, placement) => {

        if (tooltipKey !== "") {

            return (
                <OverlayTrigger
                    placement={placement ? placement : "right"}
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(tooltipKey, {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }


    const renderProps = props.data;


    function formatCount(count) {
        if (count >= 1000000000) {
            return (count / 1000000000).toFixed(2) + "B";
        } else if (count >= 1000000) {
            return (count / 1000000).toFixed(2) + "M";
        } else if (count >= 1000) {
            return (count / 1000).toFixed(2) + "K";
        }
        return count.toString();
    }

    const onSwitchChangeMultilineChart = (e, legend) => {
        let updatedData = [];
        let labels = [...tooltipelementremoval]
        let data = [...apiRes]
        let toggleState = e.target.checked;
        if (data?.length > 0) {
            data.map((e) => {
                if (e.key === legend) {
                    if (toggleState) {
                        e.show = 1
                        let index = labels.findIndex((e) => e === legend)
                        if (index > (-1)) {
                            labels.splice(index, 1)
                        }

                    } else {
                        e.show = 0
                        labels.push(legend);
                    }
                }
                e.isDisable = false;
                if (data?.length == 3) {
                    if (data.filter(x => x.show === 1).length === 2) {
                        data.find(x => x.show === 1).isDisable = true;
                        setAlertVisibleMessage(t("header.LastPendingToggleMessage", "At least one option needs to be enabled."));
                        setTimeout(() => { setAlertVisibleMessage(null) }, 3000)
                    } else {
                        data.find(x => x.show === 1).isDisable = false;
                        setAlertVisibleMessage(null)
                    }
                } else {
                    if (data.filter(x => x.show === 1).length === 1) {
                        data.find(x => x.show === 1).isDisable = true;
                        setAlertVisibleMessage(t("header.LastPendingToggleMessage", "At least one option needs to be enabled."));
                        setTimeout(() => { setAlertVisibleMessage(null) }, 3000)
                    } else {
                        data.find(x => x.show === 1).isDisable = false;
                        setAlertVisibleMessage(null)
                    }
                }
                updatedData.push(e);
            })
        }
        setToolTipElementRemoval(labels)
        setApiRes(updatedData)
    };

    const renderChart =
        (Component) =>
            ({
                cardProperty,
                tableCaption,
            }) => {
                return (
                    <>

                        {apiRes ?
                            <>
                                {alertVisibleMessage && (<div className="fade modal-backdrop show"></div>)}
                                <Alert
                                    className="toast-custom"
                                    variant="success"
                                    show={alertVisibleMessage}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                            {alertVisibleMessage}
                                        </div>
                                        <Button variant="close" size="xs" onClick={() => setAlertVisibleMessage(null)} />
                                    </div>
                                </Alert>
                                <Component
                                    //setD3Data={setD3Data}
                                    isSummeryShown={false}
                                    // cardName="test"
                                    data={apiRes}
                                    resultSet={apiRes}
                                    isResFromAPI={true}
                                    cardProperty={cardProperty}

                                />
                                {chartType === "multiline" &&
                                    <Row>
                                        <Col>
                                            <div className="switch-toggle-component w-100 tools-chart-renderer">
                                                {props.customAttributes.titleAboveToggles && <div className="row">
                                                    <div className="col-12">
                                                        <h5 className="mt-4">
                                                            <div style={{ textAlign: "center" }}>
                                                                {t("header.title_" + convertTranslatinString(props.customAttributes.titleAboveToggles), props.customAttributes.titleAboveToggles)}
                                                                {props.customAttributes.tooltipText && createTooltip(t, props.customAttributes.tooltipText)}
                                                            </div>
                                                        </h5>
                                                    </div>
                                                </div>}
                                                {apiRes?.length > 0 &&
                                                    summeryData.map((summery, index) => (
                                                        <div className="d-inline-block switch-toggle-component-item">
                                                            <div className="metrics-label-container">
                                                                <label className={'widget-summary-metrics-label d-inline-block label-' + (index + 1)}>{apiRes[index]?.key}</label>
                                                                <div className={'widget-summary-metrics metrics-' + (index + 1)}>{formatCount(summery.value) ?? 0}</div>
                                                            </div>
                                                            <div className="toggle-switch-container">
                                                                {pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.IsSwitch !== 0 && <Form className="d-inline-block ms-2">
                                                                    <Form.Check type="switch"
                                                                        className={apiRes[index]?.show === 1 ? "switch-checked" : "switch-uncheck"}
                                                                        defaultChecked={pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.defaultCheck === 1 || props.customAttributes.isDefaultMultilineValuesEnabled ? true : false}
                                                                        onChange={(e, title) => onSwitchChangeMultilineChart(e, apiRes[index].key)}
                                                                        label=""
                                                                        id="switch1"
                                                                        htmlFor="switch1"
                                                                        disabled={apiRes[index]?.isDisable}
                                                                    />
                                                                </Form>}
                                                            </div>
                                                        </div>
                                                    ))}


                                            </div>
                                        </Col>
                                    </Row>} </> : <>{chartType=="areachart" &&<Component

                                        isResFromAPI={true}

                                    />}</>}

                    </>
                );
            };

    return (
        component &&
        renderChart(component)({
            itemId,
            ...renderProps,
            cardProperty,
            // items,
            // setItems,
            // onItemChange,
            // onPageNoChange,
            // showSearchTextBox,
            // searchText,
            // setSearchText,
            // onSearchBoxChange,
            // tableCaption,
        })
    );
};

DataChartRenderer.defaultProps = {
    cardProperty: {},
    summaryBesideText: "",
}

DataChartRenderer.propTypes = {
    cardProperty: PropTypes.object,
    summaryBesideText: PropTypes.func,
};

export default React.memo(DataChartRenderer);