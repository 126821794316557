import React, { useEffect, useState, useContext } from 'react';
import WebcastManageUsers from '../components/WebcastManageUsers';
import { isMobile } from "react-device-detect";
import { webCastUserList, webCastUserListVE } from "../stubs/ComponentJson/webcast";
import ChartRenderer from '../components/ChartRenderer';
import ChartSummary from '../components/CardSummary';
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from '../components/DashboardItem';
import { Button, Alert, Row, Col, Image } from '@themesberg/react-bootstrap'
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO, TABLE_TOTAL_ROWS } from "../AppConfig";
import AppContext from '../components/AppContext';
import SummaryRenderer from '../components/SummaryRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { convertBase64 } from "../utils/common";
import { useTranslation } from "react-i18next";
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  summary: JSON.parse(i.summary) || {},
  vizState: JSON.parse(i.vizState)
});

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8,
});

const defaultLayoutMobile = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: 12,
  // w: i.layout.w || 4,
  h: i.layout.h + 2 || 12,
  minW: 4,
  minH: 8,
});

export const WebcastManageUserListPage = () => {
  const { eventId } = useContext(AppContext);
  const [searchData, setSearchData] = useState("");
  const [selectedPresentation, setSelectedPresentation] = useState({});
  const [userData, setUserData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [selectedevent, setSelectedevent] = useState({});
  const contextData = useContext(AppContext);
  const [searchResult, setSearchResult] = useState({})
  const [searchResultBackup, setSearchResultBackup] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [updateString, setUpdateString] = useState("");
  const [blurLoader, setBlurLoader] = useState(false);
  const [getTotalResults, setGetTotalResults] = useState(0);
  const [t] = useTranslation();

  useEffect(() => {
    if (pageNo === 0) {
      setPageNo(1);
    }
    // const contextData = useContext(AppContext);
    // let dta = alertStats(companyId);


    // const eventID= eventId;
    //  const  eventID = "5f0eb3f7ca5e800c00b7a407";
    let dta = [];
    if (selectedevent["Events.id"] !== undefined || selectedPresentation["Presentations.clientslug"] !== undefined) {
      if (contextData.hasEvents === 1) {
        const eventID = selectedevent["Events.id"];
        dta = webCastUserListVE(eventID, offset);
      } else {
        const clientId = selectedPresentation["Presentations.clientslug"];
        const slug = selectedPresentation["Presentations.id"];
        const url = selectedPresentation["Presentations.slug"];
        //if(clientId !== undefined){
        dta = webCastUserList(clientId, slug, url);
        //}

      }


    }
    setUserData(dta);
    setSearchData("")

  }, [selectedevent, selectedPresentation]);
  const getUsersResult = (apiUrl) => {
    setBlurLoader(true);
    setSearchResult({});
    localStorage.setItem("pageNo", 1)
    if (apiUrl !== undefined) {
      const token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
      request(apiUrl.url,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${token}`, "content-type": "application/json"
        })
        .then((res) => {
          setBlurLoader(false);
          setSearchResult(res)
          setSearchResultBackup(res)
        })
        .catch((err) => {
        })
    }

  }
  //eventId,offset,pageNo,
  const userSearch = (result, searchText) => {
    // searchText = searchText?.match(/[^\\~!#$%^&*()+|]/g)?.join("")
    // searchText = searchText?.trim()
    setSearchResult({});
    if (searchText !== undefined && searchText !== "") {
      let resultObject = result;
      if (result !== null) {

        if (resultObject.data !== null && resultObject.data?.length > 0) {

          // let resultData=[];

          // resultObject.data.forEach((element) => {

          //     if(element["email"] !== undefined){
          //       if (element["email"].match(new RegExp(searchText, 'gi'))) {
          //         resultData.push(element)
          //       }
          //     }

          // });
          let filterResult = resultObject?.data?.filter(item => item?.email?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1);
          resultObject.data = filterResult;
        }
        //resultObject.data = result.data.slice(0, 10);
        //setCurrentPageNo(1);

        return resultObject;
      }

    } else {
      return result
    }

  }
  useEffect(() => {

    // const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    // const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    //   setSearchData(getSearchPage(selectedProfileId["WebsiteProfiles.ProfileId"],selectedstartdate,selectedenddate));      
  }, [userData]);
  useEffect(() => {

    // if(userData){
    //   setUpdateString((Math.random()).toString())

    if (userData[0] !== undefined) {
      getUsersResult(userData[0].apiUrl)
    }


    // }

  }, [userData])
  useEffect(() => {
    localStorage.setItem("pageNo", 1)
    let searchResultData = userSearch({ ...searchResultBackup }, searchData)
    setSearchResult(searchResultData);
  }, [searchData]);
  const renderSummary = (cardSummary) => {
    return (
      <ChartSummary
        summaryPrimary={cardSummary.summaryPrimary}
        summarySecondary={cardSummary.summarySecondary}
        isCarouselCard={cardSummary.isCarouselCard}
        showPercentageForPrimary={cardSummary.showPercentageForPrimary}
        showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
        showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
        showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
        carousalMainSummary={cardSummary.carousalMainSummary}
      />
    );

  };
  // const tableData = useMemo(()=><><ChartRendererTable
  // summaryTooltip={item.tooltip}
  // showPagination={true}
  // resultSet={url}
  // showNumberOfRows={15}
  // summaryBesideText={" Total Registered Users"}
  // /></>,[]);
  const DownloadManageUsersExcelFileWithEPPLus = async () => {
    // let providerName = ""
    // if(contextData.webcastType === 1){
    //   providerName = webcastTypeEnum.LIVE;
    // }
    // else if(contextData.webcastType === 2){
    //   providerName = webcastTypeEnum.CHORUS;
    // }
    // else{
    //   providerName = webcastTypeEnum.METRO;
    // }
    setBlurLoader(true)
    const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
    let paramsList = {}
    let url = ""
    let sortingObject = "";
    let orderBy = "";
    let orderByAsc = "";
    if (localStorage.getItem("sorting") !== null) {
      sortingObject = JSON.parse(localStorage.getItem("sorting"));
      orderBy = sortingObject.key;
      orderByAsc = sortingObject.sortOrder === "desc" ? true : false
    } else {
      orderBy = "registrationDate"
      orderByAsc = false;
    }

    if (contextData.hasEvents === 1) {
      url = "ExportVirtualEventRegisterUserEPPlus"
      paramsList = {
        eventName: selectedevent["Events.title"],
        eventId: selectedevent["Events.id"],
        companyName: selectedComapnyData.companyName,
        orderBy: orderBy,
        orderByAsc: orderByAsc,
        culture: localStorage.getItem('i18nextLng')
      }
    } else {
      url = "ExportWebcastRegisterUserEPPlus";
      paramsList = {
        WebcastName: selectedPresentation["Presentations.name"],
        ClientID: selectedPresentation["Presentations.clientid"],
        Slug: selectedPresentation["Presentations.id"],
        Url: selectedPresentation["Presentations.url"],
        companyName: selectedComapnyData.companyName,
        orderBy: orderBy,
        orderByAsc: orderByAsc,
        culture: localStorage.getItem('i18nextLng')
      }
    }

    request(baseURL + `/Website/${url}?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
      })
      .then((res) => {
        if (res) {
          let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
          let a = document.createElement("a");
          a.href = fetchDataModified;
          a.download = contextData.hasEvents === 1 ? 'Virtual_Event_User_' + selectedevent["Events.title"] : 'Webcast_User_' + selectedPresentation["Presentations.name"] + '.xlsx';
          a.click();
        }
        setBlurLoader(false);
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating Excel:', err);
      })
  };

  const dashboardItem = (item) => (
    <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        hideTitle={true}
      >
        {<Row className="mb-2">
          <Col className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
            <div className="d-inline-block float-end page-search-box manage-users-page">

              <div className="icon-nav-list svg-icon-nav-list">

                <span className={getTotalResults > 0 ? "icon-nav-box hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"} onClick={() => DownloadManageUsersExcelFileWithEPPLus()}>
                  <svg className="icon-nav">
                    <title>{t("header.Icon_Tooltip_DownloadExcel")}</title>
                    <use href={iconSprite + `#Download`} />
                  </svg>
                </span>
              </div>
            </div>

            <div className="d-inline-block float-end position-relative webcast-manage-users-search search-box-container-box mt-5">

              <div className="d-inline-block float-end search-box-container">
                <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
              </div>
            </div>
          </Col>
        </Row>}

        <Row>
          <Col>
            {/* {tableData} */}
            <ChartRendererTable
              pageNo={localStorage.getItem("pageNo") != undefined ? parseInt(localStorage.getItem("pageNo")) : 1}
              summaryTooltip={item.tooltip}
              showPagination={true}
              resultSet={searchResult}
              showNumberOfRows={15}
              summaryBesideText={t("header.webcastManageUserBesideText")}
              searchText={updateString}
              setGetTotalResults={(value) => setGetTotalResults(value)}
            />
          </Col>


        </Row>

        {/* {Object.keys(item.summary).length > 0 && <SummaryRenderer summaryTooltip={'header.manageUsersUsersTooltipText'} vizState={item.summary} title={item.name} />}
                <ChartRenderer vizState={item.vizState} cardProperty={item.layout} items={items} setItems={setItems}  legends={item.legends} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination}
                   offset={offset} setOffset={setOffset}
                   /> */}

      </DashboardItem>
    </div>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.noChartsonDashboard")}</h2>
      {/* <Link to="/explore"> */}
      <Button variant="secondary" className="m-1">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        {t("header.addChart")}
      </Button>
      {/* </Link> */}
    </div>
  );

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <WebcastManageUsers dashboardItems={userData}
        selectedPresentation={selectedPresentation}
        setSelectedPresentation={setSelectedPresentation}
        selectedevent={selectedevent}
        setSelectedevent={setSelectedevent}
      >
        {!userData || userData.length > 0 ? userData.map(deserializeItem).map(dashboardItem) : ''}
      </WebcastManageUsers>
    </>
  )
  // : (
  //     <Empty />
  // );

};


export default WebcastManageUserListPage;