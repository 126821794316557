import React from 'react';
import { Card, Accordion } from '@themesberg/react-bootstrap';
import { Tooltip, OverlayTrigger, Button } from '@themesberg/react-bootstrap';

const AccordionComponent = (props) => {
  const { defaultKey, data = [], className = "", buttonClick } = props;
  const AccordionItem = (item) => {
    const { eventKey, title, url, description, displayName, tooltip, descriptiontooltip } = item;
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Button  variant="link" className={description.length ? "w-100 d-flex justify-content-between hide" : "w-100 d-flex justify-content-between hide-btn"}>
          {tooltip != undefined || tooltip != "" ? <OverlayTrigger
            placement="right"
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip>{tooltip}</Tooltip>
            }>
            {url ? <div variant="white" className="btn-help border-0 p-2" dangerouslySetInnerHTML={{ __html: title }} onClick={(e) => buttonClick(url)}></div> : <Button variant="white" className="btn-help border-0 p-2" dangerouslySetInnerHTML={{ __html: title }}></Button>}
          </OverlayTrigger> : description.length > 0 ? <div dangerouslySetInnerHTML={{ __html: title }} onClick={(e) => buttonClick(url)} /> : <div dangerouslySetInnerHTML={{ __html: title }} />}
        </Accordion.Button>
        {description.length > 0 ? 
        <Accordion.Body>
          <Card.Body className="py-2 px-0">

            {typeof description == "string" ?
              <Card.Text className="mb-0">
                <p>{description}</p>
              </Card.Text>
              :
              Array.isArray(description) && description.length > 0 &&
              <ul className="list-unstyled auto-dropdown-menu">
                {description.map((items, i) =>
                  <>
                    <li onClick={(e) => buttonClick(url)}>
                      {descriptiontooltip.length ? <OverlayTrigger
                        placement="right"
                        trigger={['hover', 'focus']}
                        overlay={
                          <Tooltip>{descriptiontooltip[i]}</Tooltip>
                        }>
                        <a dangerouslySetInnerHTML={{ __html: items }}  ></a>
                      </OverlayTrigger> : ""}
                    </li>
                  </>
                )}
              </ul>
            }
          </Card.Body>
        </Accordion.Body> : <></>}
      </Accordion.Item>
    );
  };

  return (
    <Accordion className={className} defaultActiveKey={defaultKey}>
      {data.map(d => <AccordionItem key={`accordion-${d.id}`} {...d} />)}
    </Accordion>
  );
};

export default AccordionComponent;