import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import * as d3 from 'd3';
import { queue } from 'd3-queue';
import moment from "moment-timezone";
import { world } from '../topojson/world/world';
import {
  CHART_HEIGHT,
  CHART_COLORS_SERIES,
  PIE_CHART_HEIGHT,
  LINE_CHART_HEIGHT,
  MULTILINE_CHART_HEIGHT,
  doubleBarChartData,
  BAR_CHART_HEIGHT,
  CHART_SIZE,
  chartMargin,
  getChartWidth,
  getChartHeight,
  multiLineChartData,
  LineChartLegendData,
  multiLineChartDt,
  PIE_CHART_HEIGHT_MOBILE,
  DONUT_CHART_HEIGHT_MOBILE,
  TOOLTIP_HIDE_TIMEOUT,
  chartMarginMobile,
  chartMarginMobileMultiLineChart,
  chartMarginMobileBarChart
} from "../utils/chartsConfig";
import { useTranslation } from 'react-i18next';
import '../i18n';
import { doTypesOverlap } from 'graphql';
import { CHART_TYPE } from '../apis/utilities/constant';
import { pageValuesByKey, } from '../utils/constant';
import { countryCodes, convertTranslatinString } from '../utils/common'

const ChartsComponent = ({ isResFromAPI, data, isLegendShown = true, apiUrl, resultSetNew, resultSet, cardProperty, type, setd3, setD3Data, tableCaption, datafortooltip, barChartClickEvent, legendPercentage, ...props }) => {
  const [t, i18n] = useTranslation();

  const getNameOnValue = (arrayList, valueName) => {
    let name = "";
    arrayList.forEach(x => x.value = parseInt(x.value));
    let selectedData = arrayList.find(dta => dta.value === valueName);

    if (selectedData && Object.keys(selectedData).length > 0) {
      name = selectedData.x;
    }

    return name;
  };

  const getTooltipHtml = (displayLabel, value, islabel, subjecttext) => {

    if (subjecttext !== undefined) {
      return "<Tooltip class='custom-tooltip'>" + displayLabel + "<br/>"
        + t("header.Singleline_Tooltip_" + convertTranslatinString(subjecttext)) + ": " + "<strong>" + value + "</strong>"
        + "</Tooltip>";
    }
    else if (islabel) {
      return "<Tooltip class='custom-tooltip'>" +
        t("header.map_country_" + convertTranslatinString(displayLabel)) + ": " + "<strong>" + value + "</strong>"
        + "</Tooltip>";
    }
    else {
      var name = displayLabel;//getNameOnValue(displayLabel, parseInt(value))
      return "<Tooltip class='custom-tooltip'>" +
        name + ": " + "<strong>" + value + "</strong>"
        + "</Tooltip>";
    }
  }

  const getTooltipHtmlForLine = (displayLabel, value) => {
    var name = displayLabel;//getNameOnValue(displayLabel, parseInt(value))
    return "<Tooltip class='custom-tooltip'>" +
      name + ": " + "<strong>" + value + "</strong>"
      + "</Tooltip>";
  }

  const getTooltipHtmlForBar = (value, islabel) => {
    return "<Tooltip class='custom-tooltip'>" +
      "<strong>" + value + "</strong>"
      + "</Tooltip>";
  }

  const getTooltipHtmlForPieChart = (displayLabel, res) => {
    let value = res.find(x => x.title == displayLabel).toolTip;
    let toolTipTitle = res.find(x => x.title == displayLabel).ToolTipTitle;
    if (toolTipTitle !== undefined && value.indexOf(toolTipTitle) !== -1) {
      value = parseInt(value) + "% " + t("header.tooltip_suffix_" + convertTranslatinString(toolTipTitle), toolTipTitle);
    }
    return "<Tooltip class='custom-tooltip'>" + t("header.legend_" + convertTranslatinString(displayLabel), displayLabel) + ": " + "<strong>" + value + "</strong>" + "</Tooltip>";
  }

  const shouldshowtooltip = (keynode, value, allData) => {

    if (datafortooltip && !datafortooltip.includes(keynode)) {

      return 1;
    }
    else {
      let isoperationneeded = allData.filter(elem => elem.key !== keynode && elem.values.length > 0);
      let obj = allData.find(elem => elem.key === keynode);
      let index = obj.values.map(function (e) { return e.value; }).indexOf(value);

      let existvalueotherobject = isoperationneeded[0]?.values[index].value;

      if (value === existvalueotherobject) {
        let keys = pageValuesByKey.filter((elem) => allData.find(({ key }) => elem.key === key) && elem.IsSwitch === 1);

        if (datafortooltip.length === keys.length) {
          return 0;
        }
        else {
          return 1;
        }
      }
      else {
        return 0;

      }

    }
  }

  const getTooltipHtmlForMultilineChart = (dateToShow, allData, type) => {
    var formatLineDate = type != "monthly" ? d3.timeFormat("%d %b %Y") : d3.timeFormat("%b %Y")
    let displayDate = formatLineDate(dateToShow);

    let displayObj = [];

    allData && Array.isArray(allData) && allData.map((data) => {
      let key = pageValuesByKey.find(x => x?.key == data?.key)?.value || data.key;
      let values = data?.values?.filter((v) => { return formatLineDate(v.x) == formatLineDate(dateToShow) });

      if (pageValuesByKey.find(x => x?.key == data?.key)?.isToolTip !== 0 && (datafortooltip && !datafortooltip.includes(data?.key))) {
        displayObj.push({
          key,
          value: values[0].value
        })
      }
    })

    var html = "<Tooltip class='custom-tooltip'>" +
      "<span><b>" + displayDate + "</b></span>" + "&nbsp" +
      displayObj.map((d) => (
        "<span>" + t("header.Multiline_Tooltip_" + convertTranslatinString(d.key)) + ": " + d.value + "</span>"
      ))
      + "</Tooltip>"
    return html;
  }

  var is_date = function (input) {
    return `${moment(input).format("MMM")} ${moment(input).format("DD")}, ${moment(input).format("YYYY")}`;
  };

  const drawPieChart = (node, resultSetNew, cardProperty, options) => {

    if (resultSet.series()[0] == undefined)
      return (<div className="text-center no-data">{t("header.NoDataFound")}</div>);

    const data = resultSet.series()[0].series.map((s) => parseInt(s.value));
    const data_ready = d3.pie()(data);
    d3.select(node).html(''); // The radius of the pieplot is half the width or half the height (smallest one).
    const pieHeight = isMobile ? PIE_CHART_HEIGHT_MOBILE : PIE_CHART_HEIGHT;
    const radius = isMobile ? pieHeight / 2 - 50 : pieHeight / 2 - 40; // Seprate container to center align pie chart

    const labels = resultSet.series()[0].series.map((s) => s.x);
    const displayLabel = resultSet.series()[0].series.map((s) => s);
    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', node.clientWidth)
      .attr('height', pieHeight)
      .append('g')
      .attr(
        'transform',
        'translate(' + node.clientWidth / 2 + ',' + pieHeight / 2 + ')'
      );

    let pieRadus;
    if (cardProperty.w > 6 && !isMobile) {
      pieRadus = svg
        .append("g")
        .attr(
          'transform',
          'translate(' + radius / 4 + ',' + radius / 6 + ')'
        );
    }
    else if (cardProperty.w <= 6 && !isMobile) {
      pieRadus = svg
        .append("g")
        .attr(
          'transform',
          'translate(' + radius / 2 + ',' + radius / 6 + ')'
        );
    }
    else {
      pieRadus = svg
        .append("g")
        .attr(
          'transform',
          'translate(' + radius / 2 + ',' + radius / 2 + ')'
        );
    }

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    pieRadus.selectAll('pieArcs')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', d3.arc().innerRadius(0).outerRadius(radius))
      .attr('fill', (d) => CHART_COLORS_SERIES[d.index])
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .style("opacity", 1)
      .on("mousemove", function (event, d) {

        div
          .html(getTooltipHtml(displayLabel, d.value))
          .style("left", (event.pageX + 12) + "px")
          .style("top", (event.pageY - 10) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () { div.html(" ").style("display", "none") });

    if (cardProperty.w > 6 && !isMobile) {

      const legendHolder = svg.append('g')
        .attr(
          'transform',
          'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
        )
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(displayLabel)
        .enter().append("g")
        .attr("class", "legend")

      legend.append('circle')
        .attr('cx', -cardProperty.w * 40)
        .attr('cy', function (d, i) {
          return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);

        })
        .attr("r", 5)
        .attr("width", 5)
        .attr("height", 5)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        .attr('x', (-cardProperty.w * 40) + CHART_SIZE * 1)
        .attr('y', function (d, i) {
          return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("dy", "0.32em")
        .text((d) => `${d.x} `)
        .style("font-size", "12px")
        .style("font-weight", "400")
        .append("tspan")
        .text((d) => parseInt(d.value))
        .style("font-weight", "700");

    }
    else if (cardProperty.w <= 6 && !isMobile) {
      const legendHolder = svg.append('g')
        .attr(
          'transform',
          'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
        )
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(displayLabel)
        .enter().append("g")
        .attr("class", "legend")

      legend.append('circle')
        .attr('cx', -cardProperty.w * 35)
        .attr('cy', function (d, i) {
          return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);

        })
        .attr("r", 5)
        .attr("width", 5)
        .attr("height", 5)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        .attr('x', (-cardProperty.w * 35) + CHART_SIZE * 1)
        .attr('y', function (d, i) {
          return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("dy", "0.32em")
        .text((d) => `${d.x} `)
        .style("font-size", "12px")
        .style("font-weight", "400")
        .append("tspan")
        .text((d) => parseInt(d.value))
        .style("font-weight", "700");
    }
    else {
      // NOTE: For Mobile
      const legendHolder = svg.append('g')
        .attr(
          'transform',
          'translate(' + -(CHART_SIZE / 2) + ',' + -(CHART_SIZE / 4) + ')'
        )
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(displayLabel)
        .enter().append("g")
        .attr("class", "legend")

      legend.append('circle')
        .attr('cx', -pieHeight / 2)
        .attr('cy', function (d, i) {
          return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("r", 5)
        .attr("width", 5)
        .attr("height", 5)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        .attr('x', (-pieHeight / 2) + CHART_SIZE * 1)
        .attr('y', function (d, i) {
          return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("dy", "0.32em")
        .text((d) => `${d.x} `)
        .style("font-size", "12px")
        .style("font-weight", "400")
        .append("tspan")
        .text((d) => d.value)
        .style("font-weight", "700");
    }
  };

  const apiDrawPieChart = (node, resultSetNew, cardProperty, options) => {
    let text = t("header.NoDataFound");
    if (!resultSetNew || resultSetNew.length == 0) {
      <div className="text-center no-data">{t("header.NoDataFound")}</div>
    }
    else {
      if (resultSetNew !== undefined && Array.isArray(resultSetNew)) {


        const othersItem = resultSetNew.find((item) => item.title === "Others")
        const data = resultSetNew.length ? resultSetNew.sort((a, b) => b.TotalOpenCount - a.TotalOpenCount).map(s => s.TotalOpenCount) : [];
        const data_ready = d3.pie()(data);
        d3.select(node).html(''); // The radius of the pieplot is half the width or half the height (smallest one).
        const pieHeight = isMobile ? PIE_CHART_HEIGHT_MOBILE : PIE_CHART_HEIGHT;
        const radius = isMobile ? pieHeight / 2 - 50 : pieHeight / 2 - 40; // Seprate container to center align pie chart

        const labels = [];
        const displayLabel = resultSetNew.length ? resultSetNew.map(s => s.title) : [];
        let legendY = 0;
        let legendTextY = 0;
        const svg = d3
          .select(node)
          .append('svg')
          .attr('width', node.clientWidth)
          .attr('height', pieHeight)
          .style('padding-top', 0)
          .append('g')
          .attr(
            'transform',
            'translate(' + node.clientWidth / 2 + ',' + pieHeight / 2 + ')'
          );

        let pieRadus;
        if (cardProperty.w > 6 && !isMobile) {
          pieRadus = svg
            .append("g")
            .attr(
              'transform',
              'translate(' + radius / 4 + ',' + radius / 6 + ')'
            );
        }
        else if (cardProperty.w <= 6 && !isMobile) {
          pieRadus = svg
            .append("g")
            .attr(
              'transform',
              'translate(' + radius / 2 + ',' + radius / 6 + ')'
            );
        }
        else {
          pieRadus = svg
            .append("g")
            .attr(
              'transform',
              'translate(' + 0 + ',' + (displayLabel.length * 15) + ')'
            );
        }

        let div = d3.select("body")
          .append("div")
          .attr("class", isMobile ? "tooltip mobile-tooltip" : "tooltip")
          .style("opacity", 0);


        pieRadus.selectAll('pieArcs')
          .data(data_ready)
          .enter()
          .append('path')
          .attr('d', d3.arc().innerRadius(0).outerRadius(radius))
          .attr('fill', (d) => CHART_COLORS_SERIES[d.index])
          .attr("stroke", "white")
          .style("stroke-width", "2px")
          .style("opacity", 1)
          .on("mousemove", function (event, d) {
            d3.selectAll(".tooltip").style("display", "none").style("z-index", "9999");
            div
              .html(getTooltipHtmlForPieChart(displayLabel[d.index], resultSetNew))
              .style("left", (event.pageX - (event.pageX > node.clientWidth ? 200 : 0)) + "px")
              .style("top", (event.pageY - (-20)) + "px")
              .style("opacity", 1)
              .style("display", "block");
          })
          .on("mouseout", function () { div.html(" ").style("display", "none") });

        if (cardProperty.w > 6 && !isMobile) {
          const legendHolder = svg.append('g')
            .attr(
              'transform',
              'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
            )
            .attr('class', 'legendHolder');

          const legend = legendHolder.selectAll(".legend")
            .data(displayLabel)
            .enter().append("g")
            .attr("class", "legend")

          legend.append('circle')
            .attr('cx', -cardProperty.w * 40)
            .attr('cy', function (d, i) {

              return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);

            })
            .attr("r", 5)
            .attr("width", 5)
            .attr("height", 5)
            .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

          const totalCounts = data_ready?.reduce((total, d) => total + d.value, 0);
          legend.append("text")
            .attr('x', (-cardProperty.w * 40) + CHART_SIZE * 1)
            .attr('y', function (d, i) {
              return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
            })
            .attr("dy", "0.32em")
            .text((d) => {
              return `${t("header.legend_" + convertTranslatinString(d), d)}`
            })
            .style("font-size", "12px")
            .style("font-weight", "400")
            .append("tspan")
            .text((d, i) => {
              return (": " + (legendPercentage ? Math.round((data[i] / totalCounts) * 100) + "%" : parseInt(data[i])))
            }
            )
            .style("font-weight", "700");

        }
        else if (cardProperty.w <= 6 && !isMobile) {
          const legendHolder = svg.append('g')
            .attr(
              'transform',
              'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
            )
            .attr('class', 'legendHolder');

          const legend = legendHolder.selectAll(".legend")
            .data([0])
            .enter().append("g")
            .attr("class", "legend")

          displayLabel.forEach((item, index) => {
            let labelArray = item.match(/.{1,18}/g)
            let legendYNew;
            if (legendY === 0) {
              legendY = -(pieHeight / 2) + cardProperty.w + index * (CHART_SIZE);
            }
            legendYNew = legendY + 20
            legendY = legendY + (labelArray?.length) * 20;
            legend.append('circle')
              .attr('cx', -cardProperty.w * 35)
              .attr('cy', function (d, i) {
                return legendYNew
              })
              .attr("r", 5)
              .attr("width", 5)
              .attr("height", 5)
              .style('fill', (d, i) => CHART_COLORS_SERIES[index]);
            labelArray?.forEach((element, indexNew) => {

              const totalCounts = data_ready?.reduce((total, d) => total + d.value, 0);
              legend.append("text")
                .attr('x', (-cardProperty.w * 35) + CHART_SIZE * 1)
                .attr('y', function (d, i) {
                  return legendYNew + (indexNew * 20)
                })
                .attr("dy", "0.32em")
                .text((d, i) => {
                  return `${t("header.legend_" + convertTranslatinString(element), element)}`
                })
                .style("font-size", "12px")
                .style("font-weight", "400")
                .append("tspan")
                .text((d, i) => {
                  return labelArray.length === (indexNew + 1) ? " : " + (legendPercentage ? Math.round((data_ready[index].value / totalCounts) * 100) + "%" : parseInt(data_ready[index].value)) : ""
                }
                )
                .style("font-weight", "700");
            });
          })
        }
        else {
          // NOTE: For Mobile
          const legendHolder = svg.append('g')
            .attr(
              'transform',
              'translate(' + -(CHART_SIZE / 0) + ',' + -(CHART_SIZE / 4) + ')'
            )
            .attr('class', 'legendHolder');

          const legend = legendHolder.selectAll(".legend")
            .data(displayLabel)
            .enter().append("g")
            .attr("class", "legend")

          legend.append('circle')
            .attr('cx', -pieHeight / 2)
            .attr('cy', function (d, i) {
              return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
            })
            .attr("r", 5)
            .attr("width", 5)
            .attr("height", 5)
            .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

          const totalCounts = data_ready?.reduce((total, d) => total + d.value, 0);
          legend.append("text")
            .attr('x', (-pieHeight / 2) + CHART_SIZE * 1)
            .attr('y', function (d, i) {
              return -(pieHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
            })
            .attr("dy", "0.32em")
            .text((d) => `${d} `)
            .style("font-size", "12px")
            .style("font-weight", "400")
            .append("tspan")
            .text((d, i) => {
              return (": " + (legendPercentage ? Math.round((data[i] / totalCounts) * 100) + "%" : parseInt(data[i])))
            }
            )
            .style("font-weight", "700");
        }
      }
    };
  }
  const drawDonetChart = (apiUrl, node, resultSetNew, resultSet, cardProperty, options) => {


    if (resultSet.series()[0] == undefined)
      return (<div className="text-center no-data">{t("header.NoDataFound")}</div>);


    let data = resultSet.series()[0].series.map((s) => parseInt(s.value));
    // overwrite for rest api
    let dataSet = [];

    if (resultSetNew !== undefined && resultSetNew.data !== undefined && apiUrl !== "")
      data = resultSetNew.data.map((element) => element[Object.keys(element)[1]]);

    const data_ready = d3.pie()(data);
    const labels = resultSet.series()[0].series.map((s) => s.x);
    let displayLabel = resultSet.series()[0].series.map((s) => s).reverse();
    //overwrite for rest api
    if (resultSetNew !== undefined && apiUrl !== "") {


      displayLabel = [];
      for (let i = 0; i < resultSetNew.data.length; i++) {
        displayLabel.push({ x: resultSetNew.data[i][Object.keys(resultSetNew.data[i])[0]], category: resultSetNew.data[i][Object.keys(resultSetNew.data[i])[0]], value: resultSetNew.data[i][Object.keys(resultSetNew.data[i])[1]] });
      }
    }
    //displayLabel = resultSetNew.loadResponses[0].data.map(((element)=>element));




    const donutHeight = isMobile ? DONUT_CHART_HEIGHT_MOBILE : CHART_HEIGHT;



    d3.select(node).html(''); // The radius of the pieplot is half the width or half the height (smallest one).

    const radius = donutHeight / 2 - 40; // Seprate container to center align pie chart
    const arc = d3.arc().innerRadius(radius * 0.29).outerRadius(radius * 0.75);
    const outerArc = d3.arc().innerRadius(radius * 0.9).outerRadius(radius * 0.9);
    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', node.clientWidth)
      .attr('height', donutHeight)
      .append('g')
      .attr(
        'transform',
        'translate(' + node.clientWidth / 2 + ',' + donutHeight / 2 + ')'
      );

    let donutRadus;

    let Tooltip = d3.select("#div_template")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");



    // Three function that change the tooltip when user hover / move / leave a cell
    let mouseover = function (event, d) {
      Tooltip
        .style("opacity", 1)
      d3.select(this)
        .style("stroke", "black")
        .style("opacity", 1)
    }

    let mousemove = function (event, d) {
      Tooltip
        .html("The exact value of<br>this cell is: " + d.value)
        .style("left", (d3.pointer(event)[0] + 70) + "px")
        .style("top", (d3.pointer(event)[1]) + "px")
    }

    let mouseleave = function (d) {
      Tooltip
        .style("opacity", 0)
      d3.select(this)
        .style("stroke", "none")
        .style("opacity", 0.8)
    }


    if (cardProperty.w > 6 && !isMobile) {
      donutRadus = svg
        .append("g")
        .attr(
          'transform',
          'translate(' + radius / 6 + ',' + -radius / 4 + ')'
        );
    }
    else if (cardProperty.w <= 6 && !isMobile) {
      donutRadus = svg
        .append("g")
        .attr(
          'transform',
          'translate(' + radius / 2 + ',' + -radius / 8 + ')'
        );
    }
    else {
      donutRadus = svg
        .append("g")
        .attr(
          'transform',
          'translate(' + radius / 4 + ',' + radius / 2 + ')'
          // 'translate(' + -pieHeight / 5 + ',' + pieHeight / 5  + ')'
        );
    }

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    donutRadus
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => CHART_COLORS_SERIES[d.index])
      .attr('stroke', '#fff')
      .attr('stroke-width', '10')
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .style("opacity", 1)
      .on("mousemove", function (event, d) {
        div
          .html(getTooltipHtml(displayLabel, d.value))
          .style("left", (event.pageX - 50) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 65) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });
    // .on("click",function(d){
    //   if(d3.select(this).attr("transform") == null){
    //   d3.select(this).attr("transform","translate(42,0)");
    //   }else{
    //     d3.select(this).attr("transform",null);
    //   }
    // });
    // .on("mouseover", mouseover)
    // .on("mousemove", mousemove)
    // .on("mouseleave", mouseleave);


    if (cardProperty.w > 6 && !isMobile) {

      const legendHolder = svg.append('g')
        .attr(
          'transform',
          'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
        )
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(displayLabel)
        .enter().append("g")
        .attr("class", "legend");
      //.attr('transform', function (d, i) { return "translate(" + donutHeight / 3 * i + "," + 0 + ")"; })
      //.attr("width", 10);
      legend.append('circle')
        .attr('cx', -cardProperty.w * 40)
        .attr('cy', function (d, i) {
          return -(donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);

        })
        .attr("r", 5)
        .attr("width", 5)
        .attr("height", 5)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        .attr('x', (-cardProperty.w * 40) + CHART_SIZE * 1)
        .attr('y', function (d, i) {
          return -(donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("dy", "0.32em")
        .text((d) => `${d.x} `)
        .style("font-size", "12px")
        .style("font-weight", "400")
        // .style("fill", "#1f3357")
        .append("tspan")
        .text((d) => parseInt(d.value))
        .style("font-weight", "700");

      // legend.append('circle')
      //   .attr('cx', donutHeight / 5)
      //   .attr('cy', -donutHeight / 2)
      //   .attr("r", 5)
      //   .attr("width", 5)
      //   .attr("height", 5)
      //   .style('fill', (d, i) => CHART_COLORS_SERIES[i]);



      // legend.append("text")
      //   .attr('x', (donutHeight / 5) + CHART_SIZE * 1)
      //   .attr('y', -donutHeight / 2)
      //   .attr("dy", "0.32em")
      //   .text((d) => `${d.x} `)
      //   .style("font-size", "13px")
      //   .style("font-weight", "400")
      //   // .style("fill", "#1f3357")
      //   .append("tspan")
      //   .text((d) => d.value)
      //   .style("font-weight", "700");
    }
    else if (cardProperty.w <= 6 && !isMobile) {
      const legendHolder = svg.append('g')
        .attr(
          'transform',
          'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
        )
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(displayLabel.reverse())
        .enter().append("g")
        .attr("class", "legend")
      // .attr('transform', function (d, i) { return "translate(" + -donutHeight / 200 * i + "," + 0 + ")"; });

      legend.append('circle')
        .attr('cx', -cardProperty.w * 35)
        .attr('cy', function (d, i) {
          return -(donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);

        })
        .attr("r", 5)
        .attr("width", 5)
        .attr("height", 5)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        .attr('x', (-cardProperty.w * 35) + CHART_SIZE * 1)
        .attr('y', function (d, i) {
          return -(donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("dy", "0.32em")
        .text((d) => `${d.x} `)
        .style("font-size", "12px")
        .style("font-weight", "400")
        // .style("fill", "#1f3357")
        .append("tspan")
        .text((d) => parseInt(d.value))
        .style("font-weight", "700");

    }
    else {
      //For Mobile
      const legendHolder = svg.append('g')
        .attr(
          'transform',
          'translate(' + (-CHART_SIZE) + ',' + CHART_SIZE / 2 + ')'
        )
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(displayLabel.reverse())
        .enter().append("g")
        .attr("class", "legend")
      // .attr('transform', function (d, i) { return "translate(" + -donutHeight / 200 * i + "," + 0 + ")"; });

      legend.append('circle')
        .attr('cx', -donutHeight / 3)
        .attr('cy', function (d, i) {
          return -(donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);

        })
        .attr("r", 5)
        .attr("width", 5)
        .attr("height", 5)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);



      legend.append("text")
        .attr('x', (-donutHeight / 3) + CHART_SIZE * 1)
        .attr('y', function (d, i) {
          return -(donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 13);
        })
        .attr("dy", "0.32em")
        .text((d) => `${d.x} `)
        .style("font-size", "12px")
        .style("font-weight", "400")
        // .style("fill", "#1f3357")
        .append("tspan")
        .text((d) => parseInt(d.value))
        .style("font-weight", "700");
    }


    // else {
    //   svg
    //     .selectAll('mydots')
    //     .data(labels)
    //     .enter()
    //     .append('circle')
    //     .attr('cx', donutHeight / 10)
    //     .attr('cy', function (d, i) {
    //       // return -130 + i * (CHART_SIZE + 12);
    //       // return (-cardProperty.h * 15) + i * (CHART_SIZE + 12);
    //       return (-donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 10);
    //     })
    //     .attr("r", 5)
    //     .attr('width', CHART_SIZE)
    //     .attr('height', CHART_SIZE)
    //     .style('fill', (d, i) => CHART_COLORS_SERIES[i]);


    //   svg
    //     .selectAll('allLabels')
    //     .data(displayLabel)
    //     .enter()
    //     .append('text')
    //     .attr("dy", ".50em")
    //     .attr('x', (donutHeight / 10) + CHART_SIZE * 1)
    //     .attr('y', function (d, i) {
    //       // return -138 + i * (CHART_SIZE + 12) + CHART_SIZE / 2;
    //       // return (-cardProperty.h * 15) + i * (CHART_SIZE + 12);
    //       return (-donutHeight / 2) + cardProperty.w + i * (CHART_SIZE + 10);
    //     })
    //     .text((d) => {
    //       return `${d.x} `
    //     })
    //     .style("font-size", "13px")
    //     .style("font-weight", "400")
    //     .style("fill", "#1f3357")
    //     .append("tspan")
    //     .text((d) => {
    //       return d.value
    //     })
    //     .style("font-weight", "700");
    // }

  };
  const getCountryCode = (value) => {
    let countryCode = [{ code: "" }]
    let countryName = "";
    countryCode = countryCodes.filter((item) => {
      countryName = item.name;
      if (t("header.map_country_" + convertTranslatinString(countryName)).toLowerCase() === value.toLowerCase()) {
        return item
      }
    });
    if (countryCode.length && countryCode[0].code !== undefined) {
      return countryCode[0].code;
    } else {
      return ""
    }

  }
  // const Mapvalue = (resultSetNew, node, resultSet, cardProperty, options) => {
  //   const margin = isMobile ? chartMarginMobile : chartMargin;
  //   // const width = getChartWidth(node.clientWidth, margin), //node.clientWidth - chartMargin.left - chartMargin.right,
  //   //  height = getChartHeight(LINE_CHART_HEIGHT, margin); //LINE_CHART_HEIGHT - chartMargin.top - chartMargin.bottom;
  //   d3.select(node).html('');
  //   const svg = d3.select(node)
  //     .append('svg').attr("width", node.offsetWidth)
  //     .attr("height", 700),
  //     width = +svg.attr("width"),
  //     height = +svg.attr("height");;
  //   // svg.attr('width','100%');
  //   //svg.attr('height','800');
  //   // Map and projection
  //   const path = d3.geoPath();
  //   const projection = d3.geoMercator()
  //     .center([0, 0])
  //     .rotate([-10, 0])
  //     .translate([width / 2, 1000 / 2]);

  //   // Data and color scale
  //   const rangeValues = [];
  //   let data = new Map()
  //   const colorScale = d3.scaleThreshold()
  //     .range(d3.schemeBlues[7]);
  //   var colors = d3.scaleQuantize()
  //     .domain(rangeValues)
  //     //  .range(['#818388','#faaf6c', '#f37b21','#c95e0e','#fcca9b','#f7943c','#fde4cd','#979797','#969696','#d9d9d9','#f6ab15','#dddee2']);
  //     .range(['#15a699', '#fd7c21', '#9ec62b', '#2e8ac6', '#af1f72', '#213540', '#505e79', '#c95e0e']);
  //   //.range(['#979797','#c95e0e', '#f37b21','#f7943c','#faaf6c','#fcca9b','#fde4cd']);

  //   // Load external data and boot
  //   Promise.all([

  //     //d3.json(world),
  //     //d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson"),
  //     // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world_population.csv", function(d) {
  //     //     data.set(d.code, +d.pop)
  //     // })



  //     // resultSet.series()[0].series.map((a) => {
  //     //   data.set(a.x, +a.value);

  //     // })

  //   ]).then(function (loadData) {
  //     if (resultSet.data !== undefined && resultSet.data.length) {
  //       let countryCode = "";
  //       resultSet.data.map((element) => {
  //         countryCode = "";
  //         countryCode = getCountryCode(element["User.Country"]);
  //         if (countryCode !== "") {

  //           data.set(countryCode, element["User.Users"])
  //         }

  //       })
  //     }



  //     let dta = world();
  //     let topo = JSON.parse(dta)
  //     setTimeout(() => {
  //       const allElements = topo.features.map((element) => element.total)
  //       const minValue = Math.min(...allElements)
  //       const maxValue = Math.max(...allElements)
  //       const avarageValue = maxValue - minValue
  //       const rangeValue = Math.round(avarageValue / 6)
  //       rangeValues.push(minValue)
  //       rangeValues.push(minValue + 1)
  //       let oldValue = 0;

  //       for (let i = 0; i < 6; i++) {
  //         let range = rangeValue + oldValue;
  //         rangeValues.push(range)
  //         oldValue = range
  //       }
  //       rangeValues.push(maxValue)

  //       const colors = d3.scaleThreshold()
  //         .domain(rangeValues)
  //         //  .range(['#818388','#faaf6c', '#f37b21','#c95e0e','#fcca9b','#f7943c','#fde4cd','#979797','#969696','#d9d9d9','#f6ab15','#dddee2']);
  //         .range(['#15a699', '#fd7c21', '#9ec62b', '#2e8ac6', '#af1f72', '#213540', '#505e79', '#c95e0e']);
  //       let mouseOver = function (event, d) {
  //         d3.selectAll(".Country")
  //           .transition()
  //           .duration(200)
  //           .style("opacity", .5)
  //         d3.select(this)
  //           .transition()
  //           .duration(200)
  //           .style("opacity", 1)
  //           .style("stroke", "white");
  //         div.style("display", "none");
  //         div
  //           .html(getTooltipHtml(d.properties.name, d.total, true))
  //           .style("left", (event.pageX - 50) + "px")
  //           .style("right", "auto")
  //           .style("top", (event.pageY - 65) + "px")
  //           .style("opacity", 1)
  //           .style("display", "block");
  //       }

  //       let mouseLeave = function (d) {
  //         d3.selectAll(".Country")
  //           .transition()
  //           .duration(200)
  //           .style("opacity", .8)
  //         d3.select(this)
  //           .transition()
  //           .duration(200)
  //           .style("stroke", "transparent");
  //         div.html(" ").style("display", "none");
  //       }

  //       // Draw the map
  //       const g = svg.append("g");
  //       g.selectAll("path")
  //         .data(topo.features)
  //         .enter()
  //         .append("path")
  //         // draw each country
  //         .attr("d", d3.geoPath()
  //           .projection(projection)
  //         )
  //         // set the color of each country
  //         .attr("fill", function (d) {
  //           d.total = data.get(d.id) || 0;
  //           return colors(d.total);
  //         })
  //         .style("stroke", "white")
  //         .attr("class", function (d) { return "Country" })
  //         .style("opacity", .8)
  //         .on("mouseover", mouseOver)
  //         .on("mouseleave", mouseLeave);
  //       let div = d3.select("body")
  //         .append("div")
  //         .attr("class", "tooltip")
  //         .style("opacity", 0);
  //       var zoom = d3.zoom()
  //         .scaleExtent([0, 8])
  //         .on('zoom', function (event) {
  //           g.selectAll('path')
  //             .attr('transform', event.transform);
  //           g.selectAll("circle")
  //             .attr('transform', event.transform);
  //         });
  //       // var dragBehavior = d3.drag()
  //       // .on("drag",function(){
  //       //   dragBehavior.on("drag",null);
  //       // });

  //       // if(isMobile === false){
  //       //   svg.call(dragBehavior)
  //       // }



  //       //  g.selectAll("path").remove()

  //       svg.call(zoom);



  //     }, 1000)



  //     function createMap() {
  //       let mouseOver = function (event, d) {
  //         d3.selectAll(".Country")
  //           .transition()
  //           .duration(200)
  //           .style("opacity", .5)
  //         d3.select(this)
  //           .transition()
  //           .duration(200)
  //           .style("opacity", 1)
  //           .style("stroke", "white");
  //         div.style("display", "none");
  //         div
  //           .html(getTooltipHtml(d.properties.name, d.total, true))
  //           .style("left", (event.pageX - 50) + "px")
  //           .style("right", "auto")
  //           .style("top", (event.pageY - 65) + "px")
  //           .style("opacity", 1)
  //           .style("display", "block");
  //       }

  //       let mouseLeave = function (d) {
  //         d3.selectAll(".Country")
  //           .transition()
  //           .duration(200)
  //           .style("opacity", .8)
  //         d3.select(this)
  //           .transition()
  //           .duration(200)
  //           .style("stroke", "transparent");
  //         div.html(" ").style("display", "none");
  //       }

  //       // Draw the map
  //       const g = svg.append("g");
  //       g.selectAll("path")
  //         .data(topo.features)
  //         .enter()
  //         .append("path")
  //         // draw each country
  //         .attr("d", d3.geoPath()
  //           .projection(projection)
  //         )
  //         // set the color of each country
  //         .attr("fill", function (d) {
  //           d.total = data.get(d.id) || 0;
  //           return colors(d.total);
  //         })
  //         .style("stroke", "white")
  //         .attr("class", function (d) { return "Country" })
  //         .style("opacity", .8)
  //         .on("mouseover", mouseOver)
  //         .on("mouseleave", mouseLeave);
  //       let div = d3.select("body")
  //         .append("div")
  //         .attr("class", "tooltip")
  //         .style("opacity", 0);
  //       var zoom = d3.zoom()
  //         .scaleExtent([0, 8])
  //         .on('zoom', function (event) {
  //           g.selectAll('path')
  //             .attr('transform', event.transform);
  //           g.selectAll("circle")
  //             .attr('transform', event.transform);
  //         });
  //       // var dragBehavior = d3.drag()
  //       // .on("drag",function(){
  //       //   dragBehavior.on("drag",null);
  //       // });

  //       // if(isMobile === false){
  //       //   svg.call(dragBehavior)
  //       // }



  //       g.selectAll("path").remove()

  //       svg.call(zoom);
  //     }
  //     createMap()


  //   });
  // }

  const getRangeArray = (maxValue, rate) => {
    const rangeValues = [1];
    for (var i = 1; i < rate + 1; i++) {
      let incrementRate = i / rate.toFixed(3);
      const val = maxValue * incrementRate;
      rangeValues.push(val)

    }
    return rangeValues;
  }

  const getRangeArray2 = (maxValue) => {
    const rateOfChange = [2, 7, 15, 30, 50, 80, 100];
    const rangeValues = [1];
    rateOfChange.forEach(element => {
      const val = Math.round((element / 100) * maxValue)
      rangeValues.push(val)
    });
    return rangeValues;
  }

  const Mapvalue = (resultSetNew, node, resultSet, cardProperty, options) => {
    const margin = isMobile ? chartMarginMobile : chartMargin;
    // const width = getChartWidth(node.clientWidth, margin), //node.clientWidth - chartMargin.left - chartMargin.right,
    //  height = getChartHeight(LINE_CHART_HEIGHT, margin); //LINE_CHART_HEIGHT - chartMargin.top - chartMargin.bottom;
    d3.select(node).html('');
    const svg = d3.select(node)
      .append('svg').attr("width", node.offsetWidth)
      .attr("height", 700),
      width = +svg.attr("width"),
      height = +svg.attr("height");;
    // svg.attr('width','100%');
    //svg.attr('height','800');
    // Map and projection
    const path = d3.geoPath();
    const projection = d3.geoMercator()
      .center([0, 0])
      .rotate([-10, 0])
      .translate([width / 2, 1000 / 2]);

    // Data and color scale
    const rangeValues = [];
    let data = new Map()
    const colorScale = d3.scaleThreshold()
      .range(d3.schemeBlues[7]);
    var colors = d3.scaleQuantize()
      .domain(rangeValues)
      //  .range(['#818388','#faaf6c', '#f37b21','#c95e0e','#fcca9b','#f7943c','#fde4cd','#979797','#969696','#d9d9d9','#f6ab15','#dddee2']);
      .range(['#15a699', '#fd7c21', '#9ec62b', '#2e8ac6', '#af1f72', '#213540', '#505e79', '#c95e0e']);
    //.range(['#979797','#c95e0e', '#f37b21','#f7943c','#faaf6c','#fcca9b','#fde4cd']);

    // Load external data and boot
    Promise.all([

      //d3.json(world),
      //d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson"),
      // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world_population.csv", function(d) {
      //     data.set(d.code, +d.pop)
      // })



      // resultSet.series()[0].series.map((a) => {
      //   data.set(a.x, +a.value);

      // })

    ]).then(function (loadData) {
      if (resultSet.data !== undefined && resultSet.data.length) {
        let countryCode = "";
        resultSet.data.map((element) => {
          countryCode = "";
          countryCode = getCountryCode(element["User.Country"]);
          if (countryCode !== "") {

            data.set(countryCode, element["User.Users"])
          }

        })
      }



      let dta = world();
      let topo = JSON.parse(dta)
      setTimeout(() => {
        const allElements = topo.features.map((element) => element.total)
        const minValue = Math.min(...allElements)
        const maxValue = Math.max(...allElements)

        // const rangeArray = getRangeArray(maxValue, 8); // value increase at equal range
        const rangeArray = getRangeArray2(maxValue); // value increase at discrete range


        // for (let i = 0; i < 6; i++) {
        //   let range = rangeValue + oldValue;
        //   rangeValues.push(range)
        //   oldValue = range
        // }
        // rangeValues.push(maxValue)
        
        // for (let i = 0; i < 6; i++) {
        //   let range = rangeValue + oldValue;
        //   rangeValues.push(range)
        //   oldValue = range
        // }
        // rangeValues.push(maxValue)
        const colors = d3.scaleThreshold()
          .domain(rangeArray)
          // .range(["#cbcbcb", "#d7c2b5", "#e2b79c", "#e7b392", "#edab81", "#f3a470", "#f99c5d", "#fc9750"]) //from grey to orange
          // .range(['#15a699', '#fd7c21', '#9ec62b', '#2e8ac6', '#af1f72', '#213540', '#505e79', '#c95e0e']); // original
        .range(['#ADD8E6', '#87CEEB', '#00BFFF', '#1E90FF', '#4169E1', '#0000CD', '#00008B', '#000080']); //shade of blue
        // .range(['#FFE4B5', '#FFDAB9', '#FFC87C', '#FFB347', '#FFA500', '#FF8C00', '#FF7F50', '#FF6347']); //shade of orange
        // .range(['#90EE90', '#98FB98', '#3CB371', '#2E8B57', '#66CDAA', '#7FFFD4', '#00FA9A', '#006400']); //shade of green
        let mouseOver = function (event, d) {
          d3.selectAll(".Country")
            .transition()
            .duration(200)
            .style("opacity", .5)
          d3.select(this)
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("stroke", "white");
          div.style("display", "none");
          div
            .html(getTooltipHtml(d.properties.name, d.total, true))
            .style("left", (event.pageX - 50) + "px")
            .style("right", "auto")
            .style("top", (event.pageY - 65) + "px")
            .style("opacity", 1)
            .style("display", "block");
        }

        let mouseLeave = function (d) {
          d3.selectAll(".Country")
            .transition()
            .duration(200)
            .style("opacity", .8)
          d3.select(this)
            .transition()
            .duration(200)
            .style("stroke", "transparent");
          div.html(" ").style("display", "none");
        }

        // Draw the map
        const g = svg.append("g");
        g.selectAll("path")
          .data(topo.features)
          .enter()
          .append("path")
          // draw each country
          .attr("d", d3.geoPath()
            .projection(projection)
          )
          // set the color of each country
          .attr("fill", function (d) {
            d.total = data.get(d.id) || 0;
            return colors(d.total);
          })
          .style("stroke", "white")
          .attr("class", function (d) { return "Country" })
          .style("opacity", .8)
          .on("mouseover", mouseOver)
          .on("mouseleave", mouseLeave);
        let div = d3.select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);

        var zoom = d3.zoom()
          .scaleExtent([0, 8])
          .on('zoom', function (event) {
            g.selectAll('path').attr('transform', event.transform);
          });

        svg.call(zoom);

        svg.on("wheel.zoom", null);

        var drag = d3.drag()
          .on("drag", function (event) {
            g.attr("transform", `translate(${event.x}, ${event.y})`);
          });

        svg.call(drag);

        // Create a rectangle block to hold the buttons
        svg.append("rect")
          .attr("x", 10)
          .attr("y", 500)
          .attr("width", 170)
          .attr("height", 150)
          .style("fill", "#F7F6EE") //  background same as side bar 
          .style("stroke-width", "2px");

        // Zoom-in button
        svg.append("rect")
          .attr("x", 20)
          .attr("y", 510)
          .attr("width", 30)
          .attr("height", 30)
          .attr("class", "zoomButton")
          .style("fill", "#0CAE86") // Background color for zoom-in button
          .style("cursor", "pointer")
          .style("rx", "5px") // Rounded corners
          .on("click", function () {
            svg.transition().duration(500).call(zoom.scaleBy, 1.2); // Zoom-in by 20%
          });

        // Zoom-in text (+)
        svg.append("text")
          .attr("x", 35)
          .attr("y", 530)
          .attr("class", "zoomButton")
          .text("+")
          .style("font-size", "20px")
          .style("fill", "#fff") // Text color
          .style("pointer-events", "none") // Disable pointer events for text to allow clicking on the rectangle
          .style("text-anchor", "middle"); // Center text

        // Zoom-out button
        svg.append("rect")
          .attr("x", 60)
          .attr("y", 510)
          .attr("width", 30)
          .attr("height", 30)
          .attr("class", "zoomButton")
          .style("fill", "#0CAE86") // Background color for zoom-out button
          .style("cursor", "pointer")
          .style("rx", "5px") // Rounded corners
          .on("click", function () {
            svg.transition().duration(500).call(zoom.scaleBy, 0.8); // Zoom-out by 20%
          });

        // Zoom-out text (-)
        svg.append("text")
          .attr("x", 75)
          .attr("y", 530)
          .attr("class", "zoomButton")
          .text("-")
          .style("font-size", "20px")
          .style("fill", "#fff") // Text color
          .style("pointer-events", "none") // Disable pointer events for text to allow clicking on the rectangle
          .style("text-anchor", "middle"); // Center text


        // Add Title below buttons
        svg.append("text")
          .attr("x", 70) 
          .attr("y", 570) 
          .text("Users by Country") //text
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .style("fill", "#333") // Text color
          .style("text-anchor", "middle"); // Center the text

        // Define the gradient
        var gradient = svg.append("defs")
          .append("linearGradient")
          .attr("id", "gradient1")
          .attr("x1", "0%")
          .attr("x2", "100%");
          // .attr("y1", "0%")
          // .attr("y2", "0%");

        gradient.append("stop")
          .attr("offset", "0%")
          .attr("stop-color", "#ADD8E6"); // Lightblue color (first color)

        gradient.append("stop")
          .attr("offset", "100%")
          .attr("stop-color", "#000080"); // Darkblue color (last color)

        // Add a rectangle with the gradient fill
        svg.append("rect")
          .attr("x", 20)  // Adjust x position
          .attr("y", 600) // Adjust y position
          .attr("width", 140) // Adjust width
          .attr("height", 20) // Adjust height
          .style("fill", "url(#gradient1)"); // Use the defined gradient

        // Add labels on both ends
        svg.append("text")
          .attr("x", 20) // Left position
          .attr("y", 635) // Just below the gradient bar
          .text("0") // Label for the minimum value
          .style("font-size", "12px")
          .style("fill", "#333"); // Text color

        svg.append("text")
          .attr("x", 150) // Right position (same as gradient width)
          .attr("y", 635) // Just below the gradient bar
          .text("Max.") // Label for the maximum value
          .style("font-size", "12px")
          .style("fill", "#333") // Text color
          .style("text-anchor", "end"); // Align the text to the right

        //#region Zoom Buttons
        // // Zoom buttons
        // svg.append("rect")
        //   .attr("x", 15)
        //   .attr("y", 10)
        //   .attr("width", 30)
        //   .attr("height", 30)
        //   .attr("class", "zoomButton")
        //   .style("fill", "#0CAE86") // Background color for zoom in button
        //   .style("cursor", "pointer")
        //   .style("rx", "5px") // Rounded corners
        //   .on("click", function () {
        //     svg.transition().duration(500).call(zoom.scaleBy, 1.2); // Zoom in by 20%
        //   });

        // svg.append("text")
        //   .attr("x", 30)
        //   .attr("y", 30)
        //   .attr("class", "zoomButton")
        //   .text("+")
        //   .style("font-size", "20px") // Adjust font size
        //   .style("fill", "#fff") // Text color
        //   .style("pointer-events", "none") // Disable pointer events for text to allow clicking on the rectangle
        //   .style("text-anchor", "middle") // Center text
        //   .attr("pointer-events", "none");

        // svg.append("rect")
        //   .attr("x", 15)
        //   .attr("y", 50)
        //   .attr("width", 30)
        //   .attr("height", 30)
        //   .attr("class", "zoomButton")
        //   .style("fill", "#0CAE86") // Background color for zoom out button
        //   .style("cursor", "pointer")
        //   .style("rx", "5px") // Rounded corners
        //   .on("click", function () {
        //     svg.transition().duration(500).call(zoom.scaleBy, 0.8); // Zoom out by 20%
        //   });

        // svg.append("text")
        //   .attr("x", 30)
        //   .attr("y", 70)
        //   .attr("class", "zoomButton")
        //   .text("-")
        //   .style("font-size", "20px") // Adjust font size
        //   .style("fill", "#fff") // Text color
        //   .style("pointer-events", "none") // Disable pointer events for text to allow clicking on the rectangle
        //   .style("text-anchor", "middle") // Center text
        //   .attr("pointer-events", "none");
        //#endregion


      }, 1000)



      function createMap() {
        let mouseOver = function (event, d) {
          d3.selectAll(".Country")
            .transition()
            .duration(200)
            .style("opacity", .5)
          d3.select(this)
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("stroke", "white");
          div.style("display", "none");
          div
            .html(getTooltipHtml(d.properties.name, d.total, true))
            .style("left", (event.pageX - 50) + "px")
            .style("right", "auto")
            .style("top", (event.pageY - 65) + "px")
            .style("opacity", 1)
            .style("display", "block");
        }

        let mouseLeave = function (d) {
          d3.selectAll(".Country")
            .transition()
            .duration(200)
            .style("opacity", .8)
          d3.select(this)
            .transition()
            .duration(200)
            .style("stroke", "transparent");
          div.html(" ").style("display", "none");
        }

        // Draw the map
        const g = svg.append("g");
        g.selectAll("path")
          .data(topo.features)
          .enter()
          .append("path")
          // draw each country
          .attr("d", d3.geoPath()
            .projection(projection)
          )
          // set the color of each country
          .attr("fill", function (d) {
            d.total = data.get(d.id) || 0;
            return colors(d.total);
          })
          .style("stroke", "white")
          .attr("class", function (d) { return "Country" })
          .style("opacity", .8)
          .on("mouseover", mouseOver)
          .on("mouseleave", mouseLeave);
        let div = d3.select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);
        var zoom = d3.zoom()
          .scaleExtent([0, 8])
          .on('zoom', function (event) {
            g.selectAll('path')
              .attr('transform', event.transform);
            g.selectAll("circle")
              .attr('transform', event.transform);
          });

        g.selectAll("path").remove()

        // svg.call(zoom);
      }
      createMap()


    });
  }

  const drawMultiBar = (resultSetNew, node, cardProperty) => {
    const margin = isMobile ? chartMarginMobile : chartMargin;
    const width = getChartWidth(node.clientWidth, margin), //node.clientWidth - chartMargin.left - chartMargin.right,
      height = getChartHeight(LINE_CHART_HEIGHT, margin); //LINE_CHART_HEIGHT - chartMargin.top - chartMargin.bottom;

    // 

    const x0 = d3.scaleBand()
      .paddingInner(0.6);

    const x1 = d3.scaleBand()
      .padding(0.05);

    const y = d3.scaleLinear()
      .rangeRound([height, 0]);



    const data = JSON.parse(doubleBarChartData);
    const keys = Object.keys(data[0]).slice(1);
    const maxData = d3.max(data, function (d) { return d3.max(keys, function (key) { return d[key]; }); });
    // const padding = (maxData - 0) * .05;

    d3.select(node).html('');
    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    x0.domain(data.map(function (d) { return d.Race; })).rangeRound([0, width]);
    x1.domain(keys).rangeRound([0, x0.bandwidth()]);
    y.domain([0, maxData]).nice();



    // const maxDataX = d3.max(data.map((s) => d3.max(s.values, (i) => i.x.length)));

    svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + height + ")")
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
      .call(d3.axisBottom(x0));

    const maxDataX = d3.max(data, (d) => d.Race.length);
    if (cardProperty.w <= 6 && !isMobile && maxDataX > 6) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else if (isMobile && maxDataX > 2) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
      .append('g').call(d3.axisLeft(y).tickSize(-width));

    // svg.append("g")
    //   .attr("class", "grid axis")
    //   // .attr('transform', `translate(${chartMargin.left}, ${chartMargin.top})`)
    //   // .attr("stroke-width", 1)
    //   // .attr("fill", "none")
    //   .call(d3.axisLeft(y).tickSize(-width));



    svg.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat("")
      );


    //For Bars
    svg.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "state")
      // .attr("transform", function (d) { return "translate(" + height + ")"; })
      .attr("transform", function (d) { return "translate(" + x0(d.Race) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return keys.map(function (key) {
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function (d) { return x1(d.key); })
      .attr("y", function (d) { return y(d.value); })
      .attr("width", Math.min(x1.bandwidth() - 2, 100))
      .attr("height", function (d) { return height - y(d.value); })
      .style('fill', (d, i) => CHART_COLORS_SERIES[i])
      .on("mousemove", function (event, d) {
        d3.selectAll(".tooltip").style("display", "none");
        div.style("display", "none");
        div
          .html(getTooltipHtml(d.key, d.value, true))
          .style("left", (event.pageX - 50) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 65) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });




    svg.append("text")
      .attr("x", 2)
      .attr("y", y(y.ticks().pop()) + 0.5)
      .attr("dy", "0.32em")
      .attr("class", "column-label")
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "start");
    // .text("Population");



    const legendHolder = svg.append('g')
      // translate the holder to the right side of the graph
      // .attr('transform', "translate(" + (-width) + "," + (-chartMargin.top) + ")")
      .attr('transform', "translate(" + (-width / 4) + "," + (-margin.top) + ")")
      .attr('class', 'legendHolder');



    if (cardProperty.w > 6 && !isMobile) {
      const legend = legendHolder.selectAll(".legend")
        .data(keys.slice())
        .enter().append("g")
        .attr("class", "legend")
        .attr('transform', function (d, i) { return "translate(" + -(height / 2) * i + "," + 0 + ")"; })
      // .attr("width", 36);

      legend.append('circle')
        .attr('cx', (d, i) => (width / 4) + (height * i))
        .attr('cy', height / 20)
        // .attr('cy', 5)
        .attr("r", 5)
        // .attr("width", 20)
        // .attr("height", 20)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        .attr("x", (d, i) => ((width / 4) + (height * i) + 10))
        .attr("y", height / 20)
        // .attr("dy", "0.32em")
        .text((d) => d)
        .attr("alignment-baseline", "middle");
    }
    else {
      const legend = legendHolder.selectAll(".legend")
        .data(keys.slice())
        .enter().append("g")
        .attr("class", "legend")
        .attr('transform', function (d, i) { return "translate(" + -(height / 2) * i + "," + 0 + ")"; })
      // .attr("width", 36);

      legend.append('circle')
        // .attr('cx', (d, i) => width + (height * i))
        .attr('cx', (d, i) => (width / 8) + (height * i))
        .attr('cy', height / 20)
        // .attr('cy', 5)
        .attr("r", 5)
        // .attr("width", 20)
        // .attr("height", 20)
        .style('fill', (d, i) => CHART_COLORS_SERIES[i]);

      legend.append("text")
        // .attr("x", function (d, i) { return (width + (height * i) + 10) })
        .attr("x", (d, i) => (width / 8) + (height * i) + 10)
        .attr("y", height / 20)
        // .attr("dy", "0.32em")
        .text((d) => d)
        .attr("alignment-baseline", "middle");
    }

  };

  const drawMultiLineChart = (resultSetNew, node, cardProperty, options) => {
    const margin = isMobile ? chartMarginMobile : chartMargin;

    const width = getChartWidth(node.clientWidth, margin),
      height = getChartHeight(LINE_CHART_HEIGHT, margin);
    let data = resultSet.series().map((series) => ({
      key: resultSet.tableColumns().find(x => x.key == series.key) == undefined ? series.key : resultSet.tableColumns().find(x => x.key == series.key).shortTitle,
      values: series.series
    }));


    if (apiUrl !== "") {
      let valueObject = [];
      let keys = Object.keys(resultSetNew);

      let dataNew = keys.map((element) => {
        valueObject = [];
        let dateObject = ""
        for (let i = 0; i < resultSetNew[element].length; i++) {
          dateObject = new Date(resultSetNew[element][i].ProcessDate);
          let dateObjectNew = new Date(resultSetNew[element][i].ProcessDate);
          dateObjectNew = new Date(dateObjectNew.setDate(dateObjectNew.getDate() - 1));
          valueObject.push({
            "value": resultSetNew[element][i].Count,
            "x": dateObjectNew.getFullYear() + "-" + dateObjectNew.getDate() + "-" + dateObjectNew.getMonth() + "T00:00:00.000",
            "category": dateObject.getFullYear() + "-" + dateObject.getDate() + "-" + dateObject.getMonth() + "T00:00:00.000"

          });
        }
        return ({
          "key": element,
          "values": valueObject
        })
      });
      data = dataNew;
    }

    var formatLineDate = d3.timeFormat("%b %Y")
    const totaltick = data !== undefined && data !== null && data.length > 0 ? data[0].values.length : 0;
    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");
    data.forEach(function (d) {
      d.values.forEach(function (d) {
        d.x = parseDate(d.x);
        d.value = +d.value;
      });
    });

    const color = d3.scaleOrdinal().domain(data).range(CHART_COLORS_SERIES);

    d3.select(node).html('');
    let marginLeft = isMobile ? margin.left / 2 : margin.left;
    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + marginLeft + ',' + margin.top + ')');

    // Add X axis --> it is a date format
    const xExtent = d3.extent(data[0].values, d => d.x);
    const x = d3.scaleTime()
      .domain(xExtent)
      .range([0, width]);

    const maxX = d3.max(data[0].values, d => d.x);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
      .call(d3.axisBottom(x).tickSize(15)
        .tickFormat(function (d) { return formatLineDate(d) }).ticks(totaltick));

    if (cardProperty.w <= 6 && !isMobile) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else if (isMobile) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }

    // // Add Y axis
    let maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));
    const padding = (maxData - 0) * .05;
    const y = d3.scaleLinear().domain([0, maxData]).nice().range([height, 0]);

    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
      .append('g').call(d3.axisLeft(y).tickSize(-width));

    svg.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat("")
      );


    // Add the lines
    let line = d3.line()
      .x(d => x(d.x))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);



    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg.selectAll("myLines")
      .data(data)
      .enter()
      .append("path")
      .attr("class", function (d) { return d.key.replace(' ', '') })
      .attr("d", (d) => line(d.values))
      .attr("stroke", (d) => color(d.key))
      .style("stroke-width", 3.5)
      .style("fill", "none");

    // Add the points
    svg
      // First we need to enter in a group
      .selectAll("myDots")
      .data(data)
      .enter()
      .append('g')
      .attr("class", function (d) { return d.key.replace(' ', '') })
      .style("fill", (d) => color(d.key))
      // Second we need to enter in the 'values' part of this group
      .selectAll("myPoints")
      .attr("class", function (d) { return d.key.replace(' ', '') })
      .data((d) => d.values)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.x))
      .attr("cy", (d) => y(d.value))
      .attr("r", 5)
      .attr("stroke", "white")
      .on("mousemove", function (event, d) {
        div.style("display", "none");
        div
          .html(getTooltipHtml(d.x, d.value, true))
          .style("left", (event.pageX - 50) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 65) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });

    //append legends


    if (cardProperty.w > 6 && !isMobile) {
      const legendHolder = svg.append('g')
        // translate the holder to the right side of the graph
        .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
        .attr('class', 'legendHolder');
      const legend = legendHolder.selectAll(".legend")
        .data(data)
        .enter().append("g")
        .attr("class", "legend")
        .attr('transform', function (d, i) { return "translate(" + 0 + "," + 0 + ")"; });

      legend
        .append('circle')
        // .attr('cx', width - 50)
        .attr('cx', (d, i) => (width / 2) + (height * i))
        .attr('cy', height / 20)
        .attr("r", 5)
        // .attr("width", 19)
        // .attr("height", 19)
        .style('fill', (d) => color(d.key));

      legend.append("text")
        .attr("x", (d, i) => (width / 2) + (height * i) + 10)
        .attr("y", height / 20)
        .text(function (d) {
          return d.key
        })
        .attr("alignment-baseline", "middle")
      // .on("click", function(e, d){
      //   const  currentOpacity = d3.selectAll("." + d.key.replace(' ','')).style("opacity");
      //   d3.selectAll("." + d.key.replace(' ','')).transition().style("opacity", currentOpacity == 1 ? 0:1)
      //   });
      // .attr("transform", "translate(15,9)"); //align texts with boxes
    }
    else {
      const legendHolder = svg.append('g')
        // translate the holder to the right side of the graph
        .attr('transform', "translate(" + (-width / 1.7) + "," + (-margin.top) + ")")
        .attr('class', 'legendHolder');

      const legend = legendHolder.selectAll(".legend")
        .data(data)
        .enter().append("g")
        .attr("class", "legend")
      // .attr('transform', function (d, i) { return "translate(" + -(height / 20) * i + "," + 0 + ")"; });
      legend
        .append('circle')
        // .attr('cx', width - 50)
        .attr('cx', (d, i) => (width / 2) + ((height / 2) * i))
        .attr('cy', height / 20)
        .attr("r", 5)
        // .attr("width", 10)
        // .attr("height", 19)
        .style('fill', (d) => color(d.key));

      legend.append("text")
        .attr("x", (d, i) => (width / 2) + ((height / 2) * i) + 10)
        .attr("y", height / 20)
        .attr("font-size", 10)
        .text((d) => d.key)
        .attr("alignment-baseline", "middle");
    }
    // if (setd3) {
    //   setD3Data(d3);
    // }
    // legend.append('rect')
    //   .attr('x', width - 20)
    //   .attr('y', function (d, i) {
    //     return i * 20;
    //   })
    //   .attr('width', 10)
    //   .attr('height', 10)
    //   .style('fill', function (d) {
    //     return color(d.name);
    //   });

    // legend.append('text')
    //   .attr('x', width - 8)
    //   .attr('y', function (d, i) {
    //     return (i * 20) + 9;
    //   })
    //   .text(function (d) {
    //     return d.name;
    //   });

  };

  const apiDrawMultiLineChart = (resultSetNew, node, cardProperty, options) => {
    const type = resultSetNew && resultSetNew[0]?.type;
    const margin = isMobile ? chartMarginMobileMultiLineChart : chartMargin;

    const width = getChartWidth(node.clientWidth, margin);
    let height = getChartHeight(MULTILINE_CHART_HEIGHT, margin);
    let showItems = isMobile ? 1 : 4;

    let data = resultSetNew;
    let legendItems = 0;
    legendItems = Math.ceil(data?.length / showItems) * 30;
    let linesData = resultSetNew.filter((e) => e.show === 1)
    let enableArrays = [];
    // linesData.forEach((e)=>{
    //   enableArrays.push(key)
    // })

    //var formatLineDate = d3.timeFormat("%d %b %Y")
    var formatLineDate = (type == "weekly" || type == "weeklystartingfrommonday" || type == "firstDayIsMonday" || type == "daily") ? d3.timeFormat("%d %b %Y") : d3.timeFormat("%b %Y")
    const totaltick = data !== undefined && data !== null && data.length > 0 ? data[0].values.length : 0;
    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");

    data.forEach(function (d) {
      if (d.show) {
        enableArrays.push(d.key);
      }
      d.values.forEach(function (dd) {
        dd.x = typeof dd.x == 'string' ? parseDate(dd.x) : dd.x;
        dd.key = d.key;
        dd.value = +dd.value;
      });
    });

    // const color = d3.scaleOrdinal().domain(keys).range(CHART_COLORS_SERIES);
    const color = d3.scaleOrdinal().domain(data).range(CHART_COLORS_SERIES);


    d3.select(node).html('');
    let marginLeft = isMobile ? margin.left / 2 : margin.left;
    const svg = d3
      .select(node)
      .append('svg')
      .attr('class', "svgselector")
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      // .attr('height', height + margin.top + margin.bottom + legendItems) // use this line to get space below chart
      .append('g')
      .attr('transform', 'translate(' + marginLeft + ',' + (margin.top) + ')');
    // .attr('transform', 'translate(' + marginLeft + ',' + (margin.top+legendItems) + ')'); // use this line to get legends at the top

    // const parseTime = d3.timeParse("%Y");

    // multiLineChartData.forEach(function (d) {
    //   d.year = parseTime(d.year);
    // });

    // Add X axis --> it is a date format
    const xExtent = data && data.length ? d3.extent(data[0].values, d => d.x) : [];
    const x = d3.scaleTime()
      .domain(xExtent)
      .range([0, width]);

    const maxX = data && data.length ? d3.max(data[0].values, d => d.x) : [];
    //const maxDataX = moment(maxX).format("yyyy").length;
    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
      .call(d3.axisBottom(x).tickValues(
        data && data.length && data[0].values.map(function (d) {
          return d.x;
        }
        ))
        .tickPadding(10)
        .tickSize(7)
        .tickFormat((d, i) => {
          if (type != "weekly" || i > 0) {
            return formatLineDate(d);
          }
          else if (type == "weekly" && i == 0) {
            return "";
          }
        }))
    if (cardProperty.w <= 6 && !isMobile) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else if (cardProperty.w > 6 && !isMobile) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else if (isMobile) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }

    // // Add Y axis
    let maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));
    // let maxData = d3.max(data[2].values, d => d.value);
    const padding = (maxData - 0) * .05;
    const y = d3.scaleLinear().domain([0, maxData]).nice().range([height, 0]);

    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
    //.append('g').call(d3.axisLeft(y).tickSize(-width));

    // svg.append("g")
    //   // .attr("transform", 'translate(50,0)') 
    //   .attr("class", "grid")
    //   .call(d3.axisLeft(y)
    //     .tickSize(-width)

    //     .tickFormat(d3.format(".2~s"))
    //     .tickPadding(10)
    //   );

    if (maxData > 9) {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)

          .tickFormat(d3.format("~s"))
          .tickPadding(10)
        );
    }
    else {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)
          .ticks(maxData)
          .tickFormat(d3.format("d"))
          .tickPadding(10)
        );

    }


    // Add the lines
    let line = d3.line()
      .x(d => x(d.x))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);



    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    // svg
    // .append("path")
    // .datum(multiLineChartDt)
    // .attr('fill', 'none')
    // .attr('stroke', (d) => color(d.key))
    // .attr('stroke-width',1.5)
    // .attr("d", (d) => d3.line()
    // .x(d => x(d.date))
    // .y(d => y(d.price)));


    svg.selectAll("myLines")
      .data(data)
      .enter()
      .append("path")
      .attr("class", function (d) { return d.key.replace(/\ /g, "") })
      .attr("d", (d) => line(d.values))
      .attr("stroke", (d) => color(d.key))
      .style("stroke-width", 3.5)
      // .attr("width",width)
      .style("fill", "none")
      .style("opacity", function (d) { return d.show });
    // Add the points

    svg
      // First we need to enter in a group
      .selectAll("myDots")
      .data(data)
      .enter()
      .append('g')
      .attr("class", function (d) { return d.key.replace(/\ /g, "") })
      .style("fill", (d) => color(d.key))
      .style("opacity", function (d) { return d.show })
      // Second we need to enter in the 'values' part of this group
      .selectAll("myPoints").style("opacity", function (d) { return d.show })
      .attr("class", function (d) { return d.key.replace(/\ /g, "") })
      .data((d) => d.values)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.x))
      .attr("cy", (d) => y(d.value))
      .attr("r", 5)
      .attr("stroke", "white")
      .style("opacity", function (d) { return d.show })
      .style("display", function (d) { return enableArrays.includes(d.key) ? "block" : "none" })
      .on("mousemove", function (event, dd) {
        d3.selectAll(".tooltip").style("display", "none");
        div.style("display", "none");
        //if (shouldshowtooltip(dd.key, dd.value, data) === 1) {
        div
          .html(getTooltipHtmlForMultilineChart(dd.x, data, type)?.replace(/,/g, ""))
          .style("left", (event.pageX - (event.offsetX > 200 ? 100 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY + 10) + "px")
          .style("opacity", 1)
          .style("display", "block");
        //}

      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });


    //append legends
    //if (cardProperty.w > 6 && !isMobile && isLegendShown) {

    // **************Uncomment below given block to get legends at the top


    //  const legendHolder = svg.append('g')
    //  const legendHolder = svg.append('g')
    //   // translate the holder to the right side of the graph
    //   .attr('transform', "translate(" + (-width / 2) + "," + (-legendItems) + ")")
    //   .attr('class', 'legendHolder');
    // for(let index=0;index<(data.length/showItems);index++){
    //   let dataNew = data.slice((index*showItems),((index*showItems)+showItems))
    //   const legend = legendHolder.selectAll(".legend"+index)
    //   .data(dataNew)
    //   .enter().append("g")
    //   .attr("class", "legend"+index)
    //   .attr('transform', function (d, i) {  return "translate(" + 0 + "," + 0+(index*30) + ")"; });

    //   legend
    //   .append('circle')
    //   // .attr('cx', width - 50)
    //   .attr('cx', (d, i) => (width / 2) + (200 * i))
    //   .attr('cy', height / 20)
    //   .attr("r", 5)
    //   // .attr("width", 19)
    //   // .attr("height", 19)
    //   .style('fill', (d) => color(d.key));

    //   legend.append("text")
    //   .attr("x", (d, i) => (width / 2) + (200 * i) + 10)
    //   .attr("y", height / 20)
    //   .text((d) => {
    //     return t("header.legend_"+pageValuesByKey.find(x => x?.key == d?.key)?.value || d.key, pageValuesByKey.find(x => x?.key == d?.key)?.value || d.key);
    //   })
    //   .attr("alignment-baseline", "middle")
    // }
    //       for(let index=0;index<(data.length/showItems);index++){

    // let dataNew = data.slice((index*showItems),((index*showItems)+showItems))
    //         const legend = legendHolder.selectAll(".legend"+index)
    //         .data(dataNew)
    //         .enter().append("g")
    //         .attr("class", "legend"+index)
    //         .attr('transform', function (d, i) {  return "translate(" + 0 + "," + 0+(index*30) + ")"; });

    //       legend
    //         .append('circle')
    //         // .attr('cx', width - 50)
    //         .attr('cx', (d, i) => (width / 2) + (200 * i))
    //         .attr('cy', height / 20)
    //         .attr("r", 5)
    //         // .attr("width", 19)
    //         // .attr("height", 19)
    //         .style('fill', (d) => color(d.key));

    //       legend.append("text")
    //         .attr("x", (d, i) => (width / 2) + (200 * i) + 10)
    //         .attr("y", height / 20)
    //         .text((d) => {
    //           return t("header.legend_"+convertTranslatinString(pageValuesByKey.find(x => x?.key == d?.key)?.value || d.key, pageValuesByKey.find(x => x?.key == d?.key)?.value || d.key));
    //         })
    //         .attr("alignment-baseline", "middle")



    //       }
    //       }






    // .on("click", function(e, d){
    //   const  currentOpacity = d3.selectAll("." + d.key.replace(' ','')).style("opacity");
    //   d3.selectAll("." + d.key.replace(' ','')).transition().style("opacity", currentOpacity == 1 ? 0:1)
    //   });
    // .attr("transform", "translate(15,9)"); //align texts with boxes
    //}
    // else {
    //   const legendHolder = isLegendShown && svg.append('g')
    //     // translate the holder to the right side of the graph
    //     .attr('transform', "translate(" + (-width / 1.7) + "," + (-margin.top) + ")")
    //     .attr('class', 'legendHolder');

    //   const legend = isLegendShown && legendHolder.selectAll(".legend")
    //     .data(data)
    //     .enter().append("g")
    //     .attr("class", "legend")
    //   // .attr('transform', function (d, i) { return "translate(" + -(height / 20) * i + "," + 0 + ")"; });
    //   isLegendShown && legend
    //     .append('circle')
    //     // .attr('cx', width - 50)
    //     .attr('cx', (d, i) => (width / 2) + ((height / 2) * i))
    //     .attr('cy', height / 20)
    //     .attr("r", 5)
    //     // .attr("width", 10)
    //     // .attr("height", 19)
    //     .style('fill', (d) => color(d.key));

    //   isLegendShown && legend.append("text")
    //     .attr("x", (d, i) => (width / 2) + ((height / 2) * i) + 10)
    //     .attr("y", height / 20)
    //     .attr("font-size", 10)
    //     .text((d) => pageValuesByKey.find(x => x?.key == d?.key)?.value || d.key)
    //     .attr("alignment-baseline", "middle");
    // }
    // if (setd3) {
    //   setD3Data(d3);
    // }

    // legend.append('rect')
    //   .attr('x', width - 20)
    //   .attr('y', function (d, i) {
    //     return i * 20;
    //   })
    //   .attr('width', 10)
    //   .attr('height', 10)
    //   .style('fill', function (d) {
    //     return color(d.name);
    //   });

    // legend.append('text')
    //   .attr('x', width - 8)
    //   .attr('y', function (d, i) {
    //     return (i * 20) + 9;
    //   })
    //   .text(function (d) {
    //     return d.name;
    //   });

  };

  const apiLineChart = (data, node, cardProperty, options) => {
    let type = data.type ? data.type : "";
    const subjecttext = data.subjecttext;

    let copyData = data;
    // let data2 = data // added

    data = []; //added
    if (copyData && copyData.currentYear.values && Array.isArray(copyData.currentYear.values) && copyData.currentYear.values.length) {
      let d = {
        key: copyData.currentYear.key,
        values: copyData.currentYear.values
      }
      data.push(d)
    }

    let data2 = []; //added
    if (copyData && copyData.previousYear.values && Array.isArray(copyData.previousYear.values) && copyData.previousYear.values.length) {
      let d = {
        key: copyData.previousYear.key,
        values: copyData.previousYear.values
      }
      data2.push(d)
    }

    const margin = isMobile ? chartMarginMobile  : chartMargin;
    const width = getChartWidth(node.clientWidth, margin),
      height = getChartHeight(LINE_CHART_HEIGHT, margin);

    d3.select(node).html('');

    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const keys = data.map(s => s.key);
    const keys2 = data2.map(s => s.key); // added

    let maxData, maxData2, maxDataX, maxDataX2;

    var formatLineDate = props.widjetName === "Viewersondemand" || (type == "weekly" || type == "weeklystartingfrommonday" || type == "firstDayIsMonday" || type == "daily") ? d3.timeFormat("%d %b %Y") :
      (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b %Y"))
    var formatLineTime = d3.timeFormat("%H:%M");
    var formatLineDateForToolTip = type == "weekly" || type == "daily" || type == "weeklystartingfrommonday" ? d3.timeFormat("%d %b %Y") : d3.timeFormat("%b %Y");

    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");

    if (data !== null && data !== undefined && data.length > 0) {
      data.forEach(function (d) {
        d.values.forEach(function (d) {
          d.x = typeof d.x == 'string' ? parseDate(d.x) : d.x;
          d.value = +d.value;
        });
      });
    }

    maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));
    maxData2 = d3.max(data2.map((s) => d3.max(s.values, (i) => i.value)));

    maxDataX = 6;//d3.max(data.map((s) => d3.max(s.values, (i) => i.x.length)));
    maxDataX2 = 6;//d3.max(data.map((s) => d3.max(s.values, (i) => i.x.length)));

    let arrayDates = [];
    if (data.length && data[0].values !== undefined && data[0].values.length > 0) {
      data[0].values.forEach(function (e) {
        if (e.value > 0) {
          arrayDates.push(formatLineDate(e.x));
        }
      });
    }

    let arrayDates2 = []; //added
    if (data2.length && data2[0].values !== undefined && data2[0].values.length > 0) {
      data2[0].values.forEach(function (e) {
        if (e.value > 0) {
          arrayDates2.push(formatLineDate(parseDate(e.x)));
        }
      });
    }

    const totaltick = { "value": arrayDates, "total": (data !== undefined && data !== null && data.length > 0) ? data[0].values.length : 0 }

    const color = d3.scaleOrdinal().domain(keys).range(CHART_COLORS_SERIES);
    svg.select("#rotate")
      .append("svg")
      .attr("width", 1000)
      .attr("height", 300);

    let x;
    let x2;
    let xData = data;
    let xData2 = data2;

    var formatDate = d3.timeFormat("%b")

    const xExtent = data.length && d3.extent(data[0].values, d => d.x);
    x = d3.scaleTime()
      .domain(xExtent)
      .range([0, width]);


    const xExtent2 = data2.length && d3.extent(data2[0].values, d => parseDate(d.x));
    x2 = d3.scaleTime() //added
      .domain(xExtent2)
      .range([0, width]);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      // .attr("transform", "translate(100,100)")
      .style("font-size", "12px")
      .style("font-weight", "250")
      .style("color", "#1f3357")
      // .call(d3.axisBottom(x).tickFormat(function (d) { 
      //   return (props.widjetName === "Viewerslive" || props.widjetName === "Viewersondemand") ? 
      //   (totaltick.value.includes(formatLineDate(d)) ? 
      //   (props.widjetName === "Viewerslive" ? formatLineTime(d) : formatLineDate(d)) : "") 
      //   : formatLineDate(d)
      // }).ticks(7));

      .call(d3.axisBottom(x)
        .tickValues(
          xData && xData.length && xData[0].values.map(function (d) {
            return d.x;
          }
          ))
        .tickPadding(10)
        .tickSize(7)
        .tickFormat((d, i) => {
          if (type != "weekly" || i > 0) {
            return formatLineDate(d);
          }
          else if (type == "weekly" && i == 0) {
            return "";
          }
        }))



    if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {
      svg.selectAll("text")
        .attr('transform', "translate(0,-4)rotate(-45)")
        .style('text-anchor', 'end');

    }
    else if (isMobile && maxDataX > 2) {
      svg.selectAll("text")
        .attr('transform', "translate(0,-5)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else {
      if (type == "weekly" || type == "weeklystartingfrommonday" || type == "daily" || type == "firstDayIsMonday") {
        svg.selectAll("text")
          .attr('transform', "translate(-10,-2)rotate(-45)")
          .style('text-anchor', 'end');
      }
      else {
        svg.selectAll("text")
          .attr('transform', "translate(-10,-2)rotate(-45)")//rotate(-75)")
          .style('text-anchor', 'end');
      }
    }
    const legendHolder = svg.append('g')
      // translate the holder to the right side of the graph
      .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
      .attr('class', 'legendHolder');
      
    const legend = legendHolder.selectAll(".legend")
      .data(props.legends)
      .enter().append("g")
      .attr("class", "legend")
      .attr('transform', function (d, i) { return "translate(" + -(height / 2) * i + "," + 0 + ")"; });

    legend.append("text")
      .attr("x", (d, i) => (width / 2.5) + (height * i) + 10)
      .attr("y", height / 20)
      .text((d) => t("header.WA_Linechart_Legend_" + d.name))
      .attr("alignment-baseline", "middle")
      .style("font-weight", "bold");;

    //for year legends
    const uniqueYears = [...new Set(data.flatMap(series => series.values.map(d => new Date(d.x).getFullYear())))];
    const uniqueYearsPrevious = [...new Set(data2.flatMap(series => series.values.map(d => new Date(d.x).getFullYear())))];

    const legendData = [
      { key: data2[0].key, years: uniqueYearsPrevious },
      { key: data[0].key, years: uniqueYears },
    ];

    const legendHolder2 = svg.append('g')
      // .attr('transform', `translate(${margin.left + 10},${height + margin.top + 30})`)
      .attr('class', cardProperty.w > 6 ? 'legend-holder-line-big' : 'legend-holder-line');

    const legend2 = legendHolder2.selectAll(".legend")
      .data(legendData)
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(${i * 110}, 0)`);

    legend2
      .append('circle')
      .attr('cx', 10)
      .attr('cy', isMobile ? 17 : cardProperty.w>6 ? 10 : 12)
      .attr("r", 5)
      .style('fill', (d, i) => CHART_COLORS_SERIES[i]); // Choose colors appropriately

    legend2.append("text")
      .attr("x", 20)
      .attr("y",  isMobile ? 17 : cardProperty.w>6 ? 10 : 12)
      .text((d) => d.years.join(' - '))
      .attr("alignment-baseline", "middle");

    let maxDataCombined = Math.max(maxData, maxData2);

    let y = d3.scaleLinear()
      .domain([0, maxDataCombined]).nice() // Set the domain using the combined maximum value
      .range([height, 0]);

    let y2 = d3.scaleLinear()
      .domain([0, maxDataCombined]).nice() // Set the domain using the combined maximum value
      .range([height, 0]);

    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")

   
    if (maxDataCombined > 9) {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)

          .tickFormat(d3.format("~s"))
          .tickPadding(10)
        );
    }
    else {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)
          .ticks(maxDataCombined)
          .tickFormat(d3.format("d"))
          .tickPadding(10)
        );

    }

    // create a tooltip
    var Tooltip = d3.select("#my_dataviz")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")

    // Three function that change the tooltip when user hover / move / leave a cell
    let mouseover = function (d) {
      Tooltip
        .style("opacity", 1)
    }
    let mousemove = function (event, d) {
      Tooltip
        .html("Exact value: " + d.value)
        .style("left", (d3.pointer(event)[0] + 70) + "px")
        .style("top", (d3.pointer(event)[1]) + "px")
    }
    let mouseleave = function (d) {
      Tooltip
        .style("opacity", 0)
    }

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);



    //current year path
    svg
      .append("path")
      .datum(data.length && data[0].values ? data[0].values : [])
      .attr('fill', 'none')
      .attr('stroke', (d) => CHART_COLORS_SERIES[1])
      .attr('stroke-width', 3.5)
      .attr("d", d3.line()
        .x(function (d) {
          return x(d.x)
        })
        .y(function (d) {
          return y(d.value)
        })
        .curve(d3.curveMonotoneX)
      );


    // Add the points for current year
    svg
      .append("g")
      .selectAll("dot")
      .data(data.length && data[0].values ? data[0].values : [])
      .enter()
      .append("circle")
      .attr("cx", function (d) { return d.value > 0 ? x(d.x) : x(d.x) })
      .attr("cy", function (d) { return y(d.value) })
      .attr("r", 5)
      .attr("fill", function (d) { return d.value > 0 ? CHART_COLORS_SERIES[1] : "transparent" })
      .on("mousemove", function (event, d) {
        d3.selectAll(".tooltip").style("display", "none");
        div.style("display", "none");
        div
          .html(getTooltipHtml(formatLineDateForToolTip(d.x), d.value, false, subjecttext))
          .style("left", (event.pageX - (event.offsetX > 200 ? 100 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 40) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });

    //previous year path
    svg
      .append("path")
      .datum(data2.length && data2[0].values ? data2[0].values : [])
      .attr('fill', 'none')
      .attr('stroke', (d) => CHART_COLORS_SERIES[2])
      // .attr('stroke', (d) => color(d.key))
      .attr('stroke-width', 3.5)
      .attr("d", d3.line()
        .x(function (d) {
          return x2(parseDate(d.x))
        })
        .y(function (d) {
          return y2(d.value)
        })
        .curve(d3.curveMonotoneX)
      );

    // Add the points for previous year
    svg
      .append("g")
      .selectAll("dot")
      .data(data2.length && data2[0].values ? data2[0].values : [])
      .enter()
      .append("circle")
      .attr("cx", function (d) { return d.value > 0 ? x2(parseDate(d.x)) : x2(parseDate(d.x)) })
      .attr("cy", function (d) { return y2(d.value) })
      .attr("r", 5)
      .attr("fill", function (d) { return d.value > 0 ? CHART_COLORS_SERIES[2] : "transparent" })
      // .attr("fill", function (d) { return d.value > 0 ? color(data2[0].key) : "transparent" })
      .on("mousemove", function (event, d) {
        div.style("display", "none");
        div
          .html(getTooltipHtml(formatLineDateForToolTip(parseDate(d.x)), d.value, false, subjecttext))
          .style("left", (event.pageX - (event.offsetX > 200 ? 100 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 40) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });
  }
  const apiLineChart2 = (data, node, cardProperty, options) => {
    let type = data.type ? data.type : "";
    const subjecttext = data.subjecttext;
    let copyData = data;
    data = [];
    if (copyData && copyData.values && Array.isArray(copyData.values) && copyData.values.length) {
      let d = {
        key: copyData.key,
        values: copyData.values
      }
      data.push(d)
    }

    const margin = isMobile ? chartMarginMobileMultiLineChart : chartMargin;
    const width = getChartWidth(node.clientWidth, margin),
      height = getChartHeight(LINE_CHART_HEIGHT, margin);

    d3.select(node).html('');
    const newMarginTop = isMobile ? margin.top + 30 : margin.top; // You can adjust the value as needed for mobile
    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + newMarginTop + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + newMarginTop + ')');
    const keys = data.map(s => s.key);
    let maxData, maxDataX;

    var formatLineDate = props.widjetName === "Viewersondemand" || (type == "weekly" || type == "weeklystartingfrommonday" || type == "firstDayIsMonday" || type == "daily") ? d3.timeFormat("%d %b %Y") :
      (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b %Y"))
    var formatLineTime = d3.timeFormat("%H:%M");

    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");

    if (data !== null && data !== undefined && data.length > 0) {
      data.forEach(function (d) {
        d.values.forEach(function (d) {
          d.x = typeof d.x == 'string' ? parseDate(d.x) : d.x;
          d.value = +d.value;
        });
      });
    }

    maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));

    maxDataX = 6;//d3.max(data.map((s) => d3.max(s.values, (i) => i.x.length)));

    let arrayDates = [];

    if (data.length && data[0].values !== undefined && data[0].values.length > 0) {
      data[0].values.forEach(function (e) {
        if (e.value > 0) {
          arrayDates.push(formatLineDate(e.x));
        }
      });
    }

    const totaltick = { "value": arrayDates, "total": (data !== undefined && data !== null && data.length > 0) ? data[0].values.length : 0 }

    const color = d3.scaleOrdinal().domain(keys).range(CHART_COLORS_SERIES);

    svg.select("#rotate")
      .append("svg")
      .attr("width", 1000)
      .attr("height", 300);

    let x;
    let xData = data;

    var formatDate = d3.timeFormat("%b")

    const xExtent = data.length && d3.extent(data[0].values, d => d.x);
    x = d3.scaleTime()
      .domain(xExtent)
      .range([0, width]);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      // .attr("transform", "translate(100,100)")
      .style("font-size", "12px")
      .style("font-weight", "250")
      .style("color", "#1f3357")
      // .call(d3.axisBottom(x).tickFormat(function (d) { 
      //   return (props.widjetName === "Viewerslive" || props.widjetName === "Viewersondemand") ? 
      //   (totaltick.value.includes(formatLineDate(d)) ? 
      //   (props.widjetName === "Viewerslive" ? formatLineTime(d) : formatLineDate(d)) : "") 
      //   : formatLineDate(d)
      // }).ticks(7));

      .call(d3.axisBottom(x)
        .tickValues(
          xData && xData.length && xData[0].values.map(function (d) {
            return d.x;
          }
          ))
        .tickPadding(10)
        .tickSize(7)
        .tickFormat((d, i) => {
          if (type != "weekly" || i > 0) {
            return formatLineDate(d);
          }
          else if (type == "weekly" && i == 0) {
            return "";
          }
        }))

    if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {

      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');

    }
    else if (isMobile && maxDataX > 2) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-40)")
        .style('text-anchor', 'end');
    }
    else {
      if (type == "weekly" || type == "weeklystartingfrommonday" || type == "monthly" || type == "daily" || type == "firstDayIsMonday") {
        svg.selectAll("text")
          .attr('transform', "translate(-15,10)rotate(-45)")
          .style('text-anchor', 'end');
      }
      else {
        svg.selectAll("text")
          .attr('transform', "translate(20,1)")//rotate(-75)")
          .style('text-anchor', 'end');
      }

    }
    const legendHolder = svg.append('g')
      // translate the holder to the right side of the graph
      .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
      .attr('class', 'legendHolder');
    const legend = legendHolder.selectAll(".legend")
      .data(props.legends)
      .enter().append("g")
      .attr("class", "legend")
      .attr('transform', function (d, i) {
        if (isMobile) {
          // Adjust the legend position slightly up for mobile
          return "translate(" + -(height / 2) * i + "," + (-25) + ")";
        } else {
          return "translate(" + -(height / 2) * i + "," + 0 + ")";
        }
      });

    legend
      .append('circle')
      .attr('cx', (d, i) => (width / 2) + (height * i))
      .attr('cy', height / 20)
      .attr("r", 5)
      .style('fill', (d) => color(d.key));

    legend.append("text")
      .attr("x", (d, i) => (width / 2) + (height * i) + 10)
      .attr("y", height / 20)
      .text((d) => t("header.Linechart_Legend_" + d.name, d.name))
      .attr("alignment-baseline", "middle");

    const y = d3.scaleLinear()
      .domain([0, maxData]).nice()
      .range([height, 0]);

    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
    //.append('g').call(d3.axisLeft(y).tickSize(-width));

    // svg
    //   .style("font-size", "11px")
    //   .style("font-weight", "300")
    //   .style("color", "#1f3357")
    //   .append('g').call(d3.axisLeft(y).tickSize(-width));

    svg.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat((d) => { return d })
        .tickPadding(10)
      );
    // create a tooltip
    var Tooltip = d3.select("#my_dataviz")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")

    // Three function that change the tooltip when user hover / move / leave a cell
    let mouseover = function (d) {
      Tooltip
        .style("opacity", 1)
    }
    let mousemove = function (event, d) {
      Tooltip
        .html("Exact value: " + d.value)
        .style("left", (d3.pointer(event)[0] + 70) + "px")
        .style("top", (d3.pointer(event)[1]) + "px")
    }
    let mouseleave = function (d) {
      Tooltip
        .style("opacity", 0)
    }

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .append("path")
      .datum(data.length && data[0].values ? data[0].values : [])
      .attr('fill', 'none')
      .attr('stroke', (d) => color(d.key))
      .attr('stroke-width', 3.5)
      .attr("d", d3.line()
        .x(function (d) {
          return x(d.x)
        })
        .y(function (d) {
          return y(d.value)
        })
        .curve(d3.curveMonotoneX)
      );

    // Add the points
    svg
      .append("g")
      .selectAll("dot")
      .data(data.length && data[0].values ? data[0].values : [])
      .enter()
      .append("circle")
      .attr("cx", function (d) { return d.value > 0 ? x(d.x) : x(d.x) })
      .attr("cy", function (d) { return y(d.value) })
      .attr("r", 5)
      .attr("fill", function (d) { return d.value > 0 ? CHART_COLORS_SERIES[1] : "transparent" })
      .on("mousemove", function (event, d) {
        div.style("display", "none");
        div
          .html(getTooltipHtmlForLine(formatLineDate(d.x), d.value))
          .style("left", (event.pageX - (event.offsetX > 200 ? 120 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 60) + "px")
          .style("opacity", 1)
          .style("display", "block")
          .style("z-index", 9999999);
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      })
      .on("click", function (event, d) {
        div.style("display", "none");
        div
          .html(getTooltipHtml(formatLineDate(d.x), d.value, true, subjecttext))
          .style("left", (event.pageX - (event.offsetX > 200 ? 120 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 60) + "px")
          .style("opacity", 1)
          .style("display", "block")
          .style("z-index", 999999);
      });

  }

  const apiBarChart = (data, node, cardProperty, options) => {
    let copyData = data;
    let data2 = data


    const margin = isMobile ? chartMarginMobileBarChart : chartMargin;
    const width = getChartWidth(node.clientWidth, margin),
      height = getChartHeight(LINE_CHART_HEIGHT, margin);

    d3.select(node).html('');

    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + (margin.top + 10) + ')');

    //const keys = resultSet.seriesNames(options.pivotConfig).map((s) => s.key);

    // let e = data && data?.values.map((item, i) => Object.keys(item).length);
    // const keys = data && data?.values.length ? Object.keys(data?.values[e.indexOf(Math.max(...e))]).map((key) => key) : [];

    let e = data && data?.currentYear.values.map((item, i) => Object.keys(item).length);
    const keys = data && data?.currentYear.values.length ? Object.keys(data?.currentYear.values[e.indexOf(Math.max(...e))]).map((key) => key) : [];


    let ispercentage = 0;
    keys.splice(-3);
    const subgroup = keys;
    if (subgroup.length > 0 && subgroup.includes("Percentage")) {
      ispercentage = 1;
    }

    // const subgroupee = keysee.slice(3);
    // if (subgroupee.length > 0 && subgroupee.includes("Percentage")) {
    //   ispercentage = 1;
    // }
    let maxData, maxData2, maxDataX, maxDataX2;


    var formatLineDate = props.widjetName === "Viewersondemand" ? d3.timeFormat("%d %b %Y") : (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b"))
    var formatLineTime = d3.timeFormat("%H:%M");
    var formatDateLineToolTip = d3.timeFormat("%Y");


    // data = data ? d3.stack().keys(subgroup)(data?.values) : [];
    data = data ? d3.stack().keys(subgroup)(data?.currentYear.values) : [];
    let data3 = data2 ? d3.stack().keys(subgroup)(data2?.previousYear.values) : [];


    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");
    data && data.forEach(function (d) {
      d.forEach(function (e) {
        if (typeof e.data.x !== 'object' && e.data.x !== null) {
          e.data.x = parseDate(e.data.x) ? parseDate(e.data.x) : e.data.x;
        }
      });
    });

    maxData = data ? d3.max(data.map((s) => d3.max(s, (i) => i[1]))) : [];
    maxData2 = data3 ? d3.max(data3.map((s) => d3.max(s, (i) => i[1]))) : [];
    maxDataX = 6;// d3.max(data.map((s) => d3.max(s, (i) => i.data.x.length)));
    maxDataX2 = 6;// d3.max(data.map((s) => d3.max(s, (i) => i.data.x.length)));

    const totaltick = { "value": "", "total": (data !== undefined && data !== null && data.length > 0) ? data[0].values.length : 0 }
    const color = d3.scaleOrdinal().domain(subgroup).range(CHART_COLORS_SERIES);
    svg.select("#rotate")
      .append("svg")
      .attr("width", 1000)
      .attr("height", 300);

    let x;
    let x2;
    let xData = [];
    let xData2 = [];
    // xData = copyData ? copyData.values : [];
    xData = copyData ? copyData.currentYear.values : [];
    xData2 = data2 ? data2.previousYear.values : [];

    var formatDate = d3.timeFormat("%b")

    x = d3
      .scaleBand()
      .range([0, width])
      .domain(xData.map((c) => c.x))
      .paddingInner(0.6)
      .padding(0.7);

    x2 = d3
      .scaleBand()
      .range([0, width])
      .domain(xData2.map((c) => c.x))
      .paddingInner(0.6)
      .padding(0.7);


    const barWidth = Math.min(x.bandwidth() - 2, 100);
    // Calculate the offset for x-axis ticks
    const tickOffset = barWidth / 2;

    svg
      .append('g')
      .attr('transform', 'translate(' + (-tickOffset) + ',' + height + ')')
      // .style("font-size", "12px")
      // .style("font-weight", "300")
      // .style("color", "#1f3357")
      .attr("class", "month-ticks")
      .call(d3.axisBottom(x).tickFormat(function (d) {
        let a = formatLineDate(Date(d));
        return a != " 0NaN" && formatLineDate(new Date(d));
      })
        .ticks(totaltick.total));



    if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {

      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');

    }
    else if (isMobile && maxDataX > 2) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else {
      if (ispercentage == 0) {
        svg.selectAll("text")
          .attr('transform', "translate(10,1)")//rotate(-75)")
          .style('text-anchor', 'end');
      }
      else {
        svg.selectAll("text")
          .attr('transform', "translate(-15,10)rotate(-90)")//rotate(-75)")
          .style('text-anchor', 'end');
      }
    }

    let dataval = "";

    if (ispercentage === 0) {
      for (var i = 0; i < data.length; i++) {
        data[i].key = subgroup[i];
      }

      const legendHolder = svg.append('g')
        .attr('transform', 'translate(' + (-width / 2) + ',' + (-margin.top) + ')')
        .attr('class', 'legend-holder-custom');

      const legendHolder2 = svg.append('g')
        .attr('transform', 'translate(' + (-width / 2) + ',' + (-margin.top + height / 10) + ')')
        .attr('class', 'legend-holder-custom-2');

      const legend = legendHolder.selectAll(".legend")
        .data(data.slice(0, Math.ceil(data.length / 2))) // Display first half in the first line
        .enter().append("g")
        .attr("class", "legend-custom")
        .attr('transform', function (d, i) { return "translate(" + i * (-15) + "," + 0 + ")"; });

      const legend2 = legendHolder2.selectAll(".legend")
        .data(data.slice(Math.ceil(data.length / 2))) // Display second half in the second line
        .enter().append("g")
        .attr("class", "legend")
        .attr('transform', function (d, i) { return "translate(" + i * (-15) + "," + 0 + ")"; });

      legend
        .append('circle')
        .attr('cx', (d, i) => (width / 2 - 20) + (height * i))
        .attr('cy', height / 20)
        .attr("r", 5)
        .style('fill', (d) => color(d.key));

      legend.append("text")
        .attr("x", (d, i) => (width / 2 - 20) + (height * i) + 10)
        .attr("y", height / 20)
        .text((d) => t("header.WA_Traffic_Source_" + convertTranslatinString(d.key)))
        .attr("alignment-baseline", "middle");

      legend2
        .append('circle')
        .attr('cx', (d, i) => (width / 2 - 20) + (height * i))
        .attr('cy', height / 20)
        .attr("r", 5)
        .style('fill', (d) => color(d.key));

      legend2.append("text")
        .attr("x", (d, i) => (width / 2 - 20) + (height * i) + 10)
        .attr("y", height / 20)
        .text((d) => t("header.WA_Traffic_Source_" + convertTranslatinString(d.key)))
        .attr("alignment-baseline", "middle");


      //for year legends

      const currentYear = [];
      const previousYear = [];

      // Iterate through the outer array (data)
      for (let i = 0; i < data[0].length; i++) {
        // Access the value data[i][2].data.x and store it in the currentYear
        const valueCurrent = data[0][i].data.x;
        const valuePrevious = data3[0][i].data.x;
        const yearCurrent = new Date(valueCurrent).getFullYear();
        const yearPrevious = new Date(valuePrevious).getFullYear();
        currentYear.push(yearCurrent);
        previousYear.push(yearPrevious);
      }

      const legendData = [
        { years: [...new Set(previousYear)] },
        { years: [...new Set(currentYear)] }
      ];



      const legendHolder3 = svg.append('g')
        // .attr('transform', `translate(${margin.left + 10},${height + margin.top + 30})`)
        .attr('class', 'legend-holder');

      const legend3 = legendHolder3.selectAll(".legend")
        .data(" ")
        .enter().append("g")
        .attr("class", "legend")
      // .attr("transform", (d, i) => `translate(${i * 110}, 0)`);
      const comparisonYears = legendData.map(item => item.years.join('-')).join(t('header.and'));

      legend3.append("text")
        // .attr("x", -20)
        // .attr("y", -15)
        .text(`${t("header.Comparison_Year")} : ${comparisonYears}`)
        .attr("alignment-baseline", "middle")
        // .style("font-size", "12px")
        // .style("font-weight", "bold")
        .attr("class", "comparison-text");
    }


    let maxDataCombined = Math.max(maxData, maxData2);

    let y = d3.scaleLinear()
      .domain([0, maxDataCombined]).nice() // Set the domain using the combined maximum value
      .range([height, 0]);

    let y2 = d3.scaleLinear()
      .domain([0, maxDataCombined]).nice() // Set the domain using the combined maximum value
      .range([height, 0]);

    if (ispercentage == 1) {
      y = d3.scaleLinear()
        .domain([0, 100]).nice()
        .range([height, 0]);
    }

    if (ispercentage == 1) {
      y2 = d3.scaleLinear()
        .domain([0, 100]).nice()
        .range([height, 0]);
    }

    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")

    // svg.append("g")
    //   .attr("class", "grid")
    //   .call(d3.axisLeft(y)
    //     .tickSize(-width)
    //     .tickFormat(d3.format(".2~s"))
    //   );

    if (maxDataCombined > 9) {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid-lines")
        .call(d3.axisLeft(y)
          .tickSize(-width)

          .tickFormat(d3.format("~s"))
          .tickPadding(10)
        );
    }
    else {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)
          .ticks(maxDataCombined)
          .tickFormat(d3.format("d"))
          .tickPadding(10)
        );

    }

    // create a tooltip
    var Tooltip = d3.select("#my_dataviz")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")

    // Three function that change the tooltip when user hover / move / leave a cell
    let mouseover = function (d) {
      Tooltip
        .style("opacity", 1)

    }
    let mousemove = function (event, d) {
      Tooltip
        .html("Exact value: " + d.value)
        .style("left", (d3.pointer(event)[0] + 70) + "px")
        .style("top", (d3.pointer(event)[1]) + "px")
    }
    let mouseleave = function (d) {
      Tooltip
        .style("opacity", 0)
    }

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    svg
      .append('g')
      .attr("class", "bars")
      .selectAll('mybar')
      .data(data3)
      .enter()
      .append('g')
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => x2(d.data.x) - Math.min(x.bandwidth() - 2, 100))
      .attr('y', (d) => y2(d[1]))
      .attr('width', Math.min(x.bandwidth() - 2, 100))
      .attr('height', (d) => y2(d[0]) - y2(d[1]))
      .on("mousemove", function (event, d) {
        var a = d[1] - d[0];
        let pertext = '';
        if (ispercentage == 1) {
          pertext = " %";
        }
        d3.selectAll(".tooltip").style("display", "none");
        div.style("display", "none");
        div
          // .html(getTooltipHtmlForBar(a, true) + pertext)
          .html("<span style = 'font-weight: bold;'>" + t("header.WA_Traffic_Source_Tooltip_" + convertTranslatinString(d3.select(this.parentNode).datum().key)) + "</span> </br>"
            + formatDateLineToolTip(parseDate(d.data.x)) + ": <span style='font-weight: bold;'>" + a + "</span>"
            + pertext)
          .style("left", (event.pageX - (event.offsetX > 200 ? 100 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 65) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });


    svg
      .append('g')
      .attr("class", "bars")
      .selectAll('mybar')
      .data(data)
      .enter()
      .append('g')
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.data.x) + 2)
      .attr('y', (d) => y(d[1]))
      .attr('width', Math.min(x.bandwidth() - 2, 100))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .on("mousemove", function (event, d) {
        var a = d[1] - d[0];
        let pertext = '';
        if (ispercentage == 1) {
          pertext = " %";
        }
        div.style("display", "none");
        div
          // .html(getTooltipHtmlForBar(a, true) + pertext)
          .html("<span style = 'font-weight: bold;'>" + t("header.WA_Traffic_Source_Tooltip_" + convertTranslatinString(d3.select(this.parentNode).datum().key)) + "</span> </br>"
            + formatDateLineToolTip(d.data.x) + ": <span style='font-weight: bold;'>" + a + "</span>"
            + pertext)
          .style("left", (event.pageX - (event.offsetX > 200 ? 100 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 65) + "px")
          .style("opacity", 1)
          .style("display", "block");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      });

    if (data.length > 0 && data[0][0].data.Value !== undefined) {
      //barChartClickEvent({name:data[0][0].data.category,value:data[0][0].data.Value});
    }

  }

  const apiBarChart2 = (data, node, cardProperty, options) => {
    let copyData = data;
    const margin = isMobile ? chartMarginMobileBarChart : chartMargin;
    const width = getChartWidth(node.clientWidth, margin),
      height = getChartHeight(LINE_CHART_HEIGHT, margin);

    d3.select(node).html('');

    const svg = d3
      .select(node)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    //const keys = resultSet.seriesNames(options.pivotConfig).map((s) => s.key);

    let e = data && data?.values.map((item, i) => Object.keys(item).length);
    const keys = data && data?.values.length ? Object.keys(data?.values[e.indexOf(Math.max(...e))]).map((key) => key) : [];

    let ispercentage = 0;

    const subgroup = keys.slice(3);
    if (subgroup.length > 0 && subgroup.includes("Percentage")) {
      ispercentage = 1;
    }

    let maxData, maxDataX;

    var formatLineDate = props.widjetName === "Viewersondemand" ? d3.timeFormat("%d %b %Y") : (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b %Y"))
    var formatLineTime = d3.timeFormat("%H:%M");


    data = data ? d3.stack().keys(subgroup)(data?.values) : [];

    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");
    data && data.forEach(function (d) {
      d.forEach(function (e) {
        if (typeof e.data.x !== 'object' && e.data.x !== null) {
          e.data.x = parseDate(e.data.x) ? parseDate(e.data.x) : e.data.x;
        }
      });
    });

    maxData = data ? d3.max(data.map((s) => d3.max(s, (i) => i[1]))) : [];
    maxDataX = 6;// d3.max(data.map((s) => d3.max(s, (i) => i.data.x.length)));

    const totaltick = { "value": "", "total": (data !== undefined && data !== null && data.length > 0) ? data[0].values.length : 0 }

    const color = d3.scaleOrdinal().domain(subgroup).range(CHART_COLORS_SERIES);
    svg.select("#rotate")
      .append("svg")
      .attr("width", 1000)
      .attr("height", 300);

    let x;
    let xData = [];

    xData = copyData ? copyData.values : [];

    var formatDate = d3.timeFormat("%b")
    // xData.forEach(function (d) {
    //   d.x = parseDate(d.x);
    //   d.value = +d.value;
    //   // d.x = parseDate(d.x)
    // });


    x = d3
      .scaleBand()
      .range([0, width])
      .domain(xData.map((c) => c.x))
      // .paddingInner(0.6)
      .padding(0.7);

    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      // .attr("transform", "translate(100,100)")
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
      .call(d3.axisBottom(x).tickFormat(function (d) {
        let a = formatLineDate(d);
        return a != " 0NaN" ? formatLineDate(d) : t("header.barchart_Xaxis_" + convertTranslatinString(d), d)
      })
        .ticks(totaltick.total));

    if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {

      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');

    }
    else if (isMobile && maxDataX > 2) {
      svg.selectAll("text")
        .attr('transform', "translate(0,1)rotate(-45)")
        .style('text-anchor', 'end');
    }
    else {
      if (ispercentage == 0) {
        svg.selectAll("text")
          .attr('transform', "translate(10,1)")//rotate(-75)")
          .style('text-anchor', 'end');
      }
      else {
        svg.selectAll("text")
          .attr('transform', "translate(-15,10)rotate(-90)")//rotate(-75)")
          .style('text-anchor', 'end');
      }
    }

    let dataval = "";
    // dataval = resultSet.series().map((series) => ({
    //   key: resultSet.tableColumns().find(x => x.key == series.key) == undefined ? series.key : resultSet.tableColumns().find(x => x.key == series.key).shortTitle,
    //   values: series.series
    // }));

    if (ispercentage === 0) {
      for (var i = 0; i < data.length; i++) {
        data[i].key = subgroup[i];
      }
      const legendHolder = svg.append('g')
        // translate the holder to the right side of the graph
        .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
        .attr('class', 'legendHolder');
      const legend = legendHolder.selectAll(".legend")
        .data(data)
        .enter().append("g")
        .attr("class", "legend")
        .attr('transform', function (d, i) { return "translate(" + i * 20 + "," + 0 + ")"; });

      legend
        .append('circle')
        // .attr('cx', width - 50)
        .attr('cx', (d, i) => (width / 2) + (height * i))
        .attr('cy', height / 20)
        .attr("r", 5)
        // .attr("width", 19)
        // .attr("height", 19)
        .style('fill', (d) => color(d.key));

      legend.append("text")
        .attr("x", (d, i) => (width / 2) + (height * i) + 10)
        .attr("y", height / 20)
        .text((d) => t("header.legend_" + convertTranslatinString(d.key)))
        .attr("alignment-baseline", "middle")
      // .on("click", function(e, d){
      //   const  currentOpacity = d3.selectAll("." + d.key.replace(' ','')).style("opacity");
      //   d3.selectAll("." + d.key.replace(' ','')).transition().style("opacity", currentOpacity == 1 ? 0:1)
      //   });
      // .attr("transform", "translate(15,9)"); //align texts with boxes
    }


    let y = d3.scaleLinear()
      .domain([0, maxData]).nice()
      .range([height, 0]);

    if (ispercentage == 1) {
      y = d3.scaleLinear()
        .domain([0, 100]).nice()
        .range([height, 0]);
    }

    svg
      .append('g')
      .attr('transform', 'translate(' + -width / 100 + ',0)')
      .style("font-size", "12px")
      .style("font-weight", "300")
      .style("color", "#1f3357")
    //.append('g').call(d3.axisLeft(y).tickSize(-width));

    // svg
    //   .style("font-size", "11px")
    //   .style("font-weight", "300")
    //   .style("color", "#1f3357")
    //   .append('g').call(d3.axisLeft(y).tickSize(-width));

    // svg.append("g")
    //   .attr("class", "grid")
    //   .call(d3.axisLeft(y)
    //     .tickSize(-width)
    //     .tickFormat(d3.format(".2~s"))
    //   );

    if (maxData > 9) {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)

          .tickFormat(d3.format("~s"))
          .tickPadding(10)
        );
    }
    else {
      svg.append("g")
        // .attr("transform", 'translate(50,0)') 
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)
          .ticks(maxData)
          .tickFormat(d3.format("d"))
          .tickPadding(10)
        );

    }

    // create a tooltip
    var Tooltip = d3.select("#my_dataviz")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")

    // Three function that change the tooltip when user hover / move / leave a cell
    let mouseover = function (d) {
      Tooltip
        .html("werqwrwe")
        .style("display", "block")
        .style("opacity", 1)
        .style("z-index", "9999999");

    }
    let mousemove = function (event, d) {
      Tooltip
        .html("Exact value: " + d.value)
        .style("left", (d3.pointer(event)[0] + 70) + "px")
        .style("top", (d3.pointer(event)[1]) + "px")
    }
    let mouseleave = function (d) {
      Tooltip
        .style("opacity", 0)
    }

    let xValue = 0;
    let yValue = 0;
    let clickObject = "";
    let barName = "";
    let currentTooltipLeft = 0;
    let currentTooltipTop = 0;

    let div = d3.select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    let divValue = d3.select(".modal-body");

    if (divValue != undefined) {

      divValue.on("scroll", function (event) {
        d3.selectAll(".tooltip").style("display", "block").style("opacity", 1).each(function (e, index) {
          d3.select(this).style("display", "none").style("opacity", 0)
        })


        if (barName !== undefined) {


          let elementObjectNew = document.getElementById(barName)
          if (elementObjectNew !== null) {
            let objectValues = elementObjectNew.getBoundingClientRect()
            let tooltipDiv = document.getElementById(clickObject);
            if (tooltipDiv !== null) {
              tooltipDiv.style.display = 'block';
              tooltipDiv.style.opacity = 1;
              tooltipDiv.style.left = Math.round(objectValues.left) + 'px';
              tooltipDiv.style.top = objectValues.top - document.body.getBoundingClientRect().top + 'px';
            }
          }

        }
      });
    }





    svg
      .append('g')
      .selectAll('mybar') // Enter in the stack data = loop key per key = group per group
      .data(data)
      .enter()
      .append('g')
      .attr('fill', (d) => color(d.key))
      .selectAll('rect') // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d) => d)
      .enter()
      .append('rect')
      .attr("id", (d) => d.data.category)
      .attr('x', (d) => x(d.data.x))
      .attr('y', (d) => {
        if (d.data.Percentage && Number(d.data.Percentage) > 0 && Number(d.data.Percentage) <= 2) {
          return y(d[0]) - 3.5;
        }
        return y(d[1]);
      })
      // .attr('width', x.bandwidth())
      .attr("width", Math.min(x.bandwidth() - 2, 100))
      .attr('height', (d) => {
        let barHeight = y(d[0]) - y(d[1]);
        if (d.data.Percentage && Number(d.data.Percentage) > 0 && Number(d.data.Percentage) <= 2) {
          return 3.5;
        }
        return barHeight;
      })
      .on("mousemove", function (event, d) {
        var a = d[1] - d[0];
        let pertext = '';
        if (ispercentage == 1) {
          pertext = " %";
        }
        d3.selectAll(".tooltip").style("display", "none");
        div.style("display", "none");
        div
          .html(getTooltipHtmlForBar(a, true) + pertext)
          .style("left", (event.pageX - (event.offsetX > 200 ? 40 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 40) + "px")
          .style("opacity", 1)
          .style("display", "block")
          .style("z-index", "9999999");
      })
      .on("mouseout", function () {
        div.html(" ").style("display", "none");
      })

      .on("click", function (event, d) {
        var a = d[1] - d[0];
        let pertext = '';
        if (ispercentage == 1) {
          pertext = " %";
        }
        let charString = Math.random().toString(36).slice(2, 6);
        div.style("display", "none");
        div
          .attr("id", charString)
          .html(getTooltipHtmlForBar(a, true) + pertext)
          .style("left", (event.pageX - (event.offsetX > 200 ? 40 : 0)) + "px")
          .style("right", "auto")
          .style("top", (event.pageY - 40) + "px")
          .style("opacity", 1)
          .style("display", "block")
          .style("z-index", "9999999");
        barChartClickEvent({ name: d.data.category, value: d.data.Value });
        // Set a timeout to hide the tooltip after 3 seconds
        clickObject = charString;
        barName = d.data.category;
        xValue = event.offsetX;
        yValue = event.offsetY;


        setTimeout(function () {
          div.html(" ").style("display", "none");
        }, TOOLTIP_HIDE_TIMEOUT); //hide tooltip after few seconds
      });

    if (data.length > 0 && data[0][0].data.Value !== undefined) {
      //barChartClickEvent({name:data[0][0].data.category,value:data[0][0].data.Value});
    }

  }

  const drawChart = (data, resultSetNew, apiUrl, node, resultSet, chartType, cardProperty, options = {}) => {
    if (!isResFromAPI && resultSet.series()[0] == undefined)
      return (<div className="text-center no-data">{t("header.NoDataFound")}</div>);
    if (chartType == "line" && isResFromAPI) {
      return apiLineChart(data, node, cardProperty, options);
    }
    if (chartType == "line2" && isResFromAPI) {
      return apiLineChart2(data, node, cardProperty, options);
    }
    if (chartType == "multiline" && isResFromAPI) {
      return apiDrawMultiLineChart(data, node, cardProperty, options);
    }
    if (chartType == "bar" && isResFromAPI) {
      return apiBarChart(data, node, cardProperty, options);
    }
    if (chartType == "bar2" && isResFromAPI) {
      return apiBarChart2(data, node, cardProperty, options);
    }
    if (chartType == "pie" && isResFromAPI) {
      return apiDrawPieChart(node, data, cardProperty, options);
    }
    else if (chartType === 'pie') {
      return drawPieChart(node, resultSet, cardProperty, options);
    }
    else if (chartType === 'donet') {
      return drawDonetChart(apiUrl, node, resultSetNew, resultSet, cardProperty, options);
    }
    else if (chartType === 'multiBar') {
      return drawMultiBar(node, cardProperty);
    }
    else if (chartType === 'multiLine') {
      return drawMultiLineChart(resultSetNew, node, cardProperty, options);
    }
    else if (chartType === 'map') {
      return Mapvalue(resultSetNew, node, resultSet, cardProperty, options);
    }

    if (resultSet) {

      const margin = isMobile ? chartMarginMobile : chartMargin;
      const width = getChartWidth(node.clientWidth, margin),
        height = getChartHeight(LINE_CHART_HEIGHT, margin);

      d3.select(node).html('');

      const svg = d3
        .select(node)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      const keys = resultSet.seriesNames(options.pivotConfig).map((s) => s.key);

      let data, maxData, maxDataX;

      var formatLineDate = props.widjetName === "Viewersondemand" ? d3.timeFormat("%d %b %Y") : (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b %Y"))
      var formatLineTime = d3.timeFormat("%H:%M");

      if (chartType === 'line') {
        data = resultSet.series().map((series) => ({
          key: series.key,
          values: series.series
        }));

        if (apiUrl !== "" && resultSetNew !== undefined && resultSetNew.values.length) {
          for (let i = 0; i < resultSetNew.values.length; i++) {
            resultSetNew.values[i].x = resultSetNew.values[i].xvalue
          }
          data = [resultSetNew];
        }

        const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");

        if (data !== null && data !== undefined && data.length > 0) {
          data.forEach(function (d) {
            d.values.forEach(function (d) {
              d.x = parseDate(d.x);
              d.value = +d.value;
            });
          });
        }

        maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));
        maxDataX = 6; // d3.max(data.map((s) => d3.max(s.values, (i) => i.x.length)));

      } else {
        if (apiUrl !== "" && chartType == "bar") {
          if (resultSetNew.values.length) {
            resultSetNew.values.forEach((element) => {
              element.x = element.category;
            })
          }

          resultSet.loadResponses[0].data = resultSetNew.values;

          data = d3.stack().keys(keys)(resultSet.loadResponses[0].data);
        } else {
          data = d3.stack().keys(keys)(resultSet.chartPivot(options.pivotConfig));
        }


        const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");
        data.forEach(function (d) {
          d.forEach(function (e) {
            if (typeof e.data.x !== 'object' && e.data.x !== null) {
              e.data.x = parseDate(e.data.x);
            }
          });
        });

        maxData = d3.max(data.map((s) => d3.max(s, (i) => i[1])));
        maxDataX = 6;// d3.max(data.map((s) => d3.max(s, (i) => i.data.x.length)));
      }

      let arrayDates = [];

      if (data[0].values !== undefined && data[0].values.length > 0) {
        data[0].values.forEach(function (e) {
          if (e.value > 0) {
            arrayDates.push(formatLineDate(e.x));
          }
        });
      }

      const totaltick = { "value": arrayDates, "total": (data !== undefined && data !== null && data.length > 0) ? data[0].values.length : 0 }

      const color = d3.scaleOrdinal().domain(keys).range(CHART_COLORS_SERIES);

      svg.select("#rotate")
        .append("svg")
        .attr("width", 1000)
        .attr("height", 300);

      let x;
      let xData = []

      // for rest api
      if (apiUrl !== "" && chartType == "bar" && resultSetNew.values !== undefined && resultSetNew.values.length) {
        if (resultSetNew.values.length) {
          resultSetNew.values.forEach((element) => {
            element.x = element.category;
          })
        }
        xData = resultSetNew.values;
      } else {
        xData = resultSet.chartPivot(options.pivotConfig);
      }


      var formatDate = d3.timeFormat("%b")
      const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");
      xData.forEach(function (d) {
        if (chartType !== 'line') {
          d.x = parseDate(d.x);

        }
        d.value = +d.value;
        // d.x = parseDate(d.x)
      });
      // xData.forEach( (d) => {
      //     d.x = (new Date(d.x) !== "Invalid Date") ? moment(d.x).format("MMM") : d.x;
      // });
      if (chartType === 'line' || chartType === 'area') {

        const xExtent = d3.extent(data[0].values, d => d.x);

        x = d3.scaleTime()
          .domain(xExtent)
          .range([0, width]);
      }
      else if (chartType === 'bar') {
        x = d3
          .scaleBand()
          .range([0, width])
          .domain(xData.map((c) => c.x))
          .padding(0.7);
      }
      else {
        x = d3
          .scaleTime()
          .domain(
            d3.extent(resultSet.chartPivot(options.pivotConfig), (c) =>
              d3.isoParse(c.x)
            )
          )
          .nice()
          .range([0, width]);
      }

      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        // .attr("transform", "translate(100,100)")
        .style("font-size", "12px")
        .style("font-weight", "300")
        .style("color", "#1f3357")
        .call(d3.axisBottom(x).tickFormat(function (d) { return (props.widjetName === "Viewerslive" || props.widjetName === "Viewersondemand") ? (totaltick.value.includes(formatLineDate(d)) ? (props.widjetName === "Viewerslive" ? formatLineTime(d) : formatLineDate(d)) : "") : formatLineDate(d) }).ticks(totaltick.total));

      if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {

        svg.selectAll("text")
          .attr('transform', "translate(0,1)rotate(-45)")
          .style('text-anchor', 'end');

      }
      else if (isMobile && maxDataX > 2) {
        svg.selectAll("text")
          .attr('transform', "translate(0,1)rotate(-45)")
          .style('text-anchor', 'end');
      }
      else {
        svg.selectAll("text")
          .attr('transform', "translate(10,1)")//rotate(-75)")
          .style('text-anchor', 'end');
      }

      if (props.legends !== undefined && props.legends.length > 0 && chartType != 'bar') {
        const legendHolder = svg.append('g')
          // translate the holder to the right side of the graph
          .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
          .attr('class', 'legendHolder');
        const legend = legendHolder.selectAll(".legend")
          .data(props.legends)
          .enter().append("g")
          .attr("class", "legend")
          .attr('transform', function (d, i) { return "translate(" + -(height / 2) * i + "," + 0 + ")"; });

        legend
          .append('circle')
          // .attr('cx', width - 50)
          .attr('cx', (d, i) => (width / 2) + (height * i))
          .attr('cy', height / 20)
          .attr("r", 5)
          // .attr("width", 19)
          // .attr("height", 19)
          .style('fill', (d) => color(d.key));

        legend.append("text")
          .attr("x", (d, i) => (width / 2) + (height * i) + 10)
          .attr("y", height / 20)
          .text((d) => d.name)
          .attr("alignment-baseline", "middle");
      }
      else {
        let dataval = "";
        if (apiUrl !== "" && chartType == "bar") {
          // let barsData = {}
          //   for(let i=0; i<resultSetNew[0].values.length;i++){
          //     let objectKeys = Object.keys(resultSetNew[0].values);
          //     barsData.key=resultSetNew[0].values[0][objectKeys[i]]
          //   }

          // dataval = resultSet.series().map((series) => ({
          //   key: resultSet.tableColumns().find(x=> x.key == series.key)==undefined?series.key: resultSet.tableColumns().find(x=> x.key == series.key).shortTitle,
          //   values: series.series
          // }));
        }

        dataval = resultSet.series().map((series) => ({
          key: resultSet.tableColumns().find(x => x.key == series.key) == undefined ? series.key : resultSet.tableColumns().find(x => x.key == series.key).shortTitle,
          values: series.series
        }));


        for (var i = 0; i < data.length; i++) {
          data[i].key = dataval[i].key;
        }
        if (chartType != 'line') {
          if (cardProperty.w > 6 && !isMobile) {
            const legendHolder = svg.append('g')
              // translate the holder to the right side of the graph
              .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
              .attr('class', 'legendHolder');
            const legend = legendHolder.selectAll(".legend")
              .data(data)
              .enter().append("g")
              .attr("class", "legend")
              .attr('transform', function (d, i) { return "translate(" + 0 + "," + 0 + ")"; });

            legend
              .append('circle')
              // .attr('cx', width - 50)
              .attr('cx', (d, i) => (width / 2) + (height * i))
              .attr('cy', height / 20)
              .attr("r", 5)
              // .attr("width", 19)
              // .attr("height", 19)
              .style('fill', (d) => color(d.key));

            legend.append("text")
              .attr("x", (d, i) => (width / 2) + (height * i) + 10)
              .attr("y", height / 20)
              .text((d) => d.key)
              .attr("alignment-baseline", "middle")
            // .on("click", function(e, d){
            //   const  currentOpacity = d3.selectAll("." + d.key.replace(' ','')).style("opacity");
            //   d3.selectAll("." + d.key.replace(' ','')).transition().style("opacity", currentOpacity == 1 ? 0:1)
            //   });
            // .attr("transform", "translate(15,9)"); //align texts with boxes
          }
          else {
            const legendHolder = svg.append('g')
              // translate the holder to the right side of the graph
              .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
              .attr('class', 'legendHolder');

            const legend = legendHolder.selectAll(".legend")
              .data(data)
              .enter().append("g")
              .attr("class", "legend")
            legend
              .append('circle')
              .attr('cx', (d, i) => (width / 2) + ((height / 2) * i))
              .attr('cy', height / 20)
              .attr("r", 5)
              .style('fill', (d) => color(d.key));
            legend.append("text")
              .attr("x", (d, i) => (width / 2) + ((height / 2) * i) + 8)
              .attr("y", height / 20)
              .text((d) => d.key)
              .attr("alignment-baseline", "middle")
              .on("click", function (e, d) {
                const currentOpacity = d3.selectAll("." + d.key.replace(' ', '')).style("opacity");
                d3.selectAll("." + d.key.replace(' ', '')).transition().style("opacity", currentOpacity == 1 ? 0 : 1)
              });
          }
        }
      }
      const y = d3.scaleLinear()
        .domain([0, maxData]).nice()
        .range([height, 0]);

      svg
        .append('g')
        .attr('transform', 'translate(' + -width / 100 + ',0)')
        .style("font-size", "12px")
        .style("font-weight", "300")
        .style("color", "#1f3357")
        .append('g').call(d3.axisLeft(y).tickSize(-width));

      svg.append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y)
          .tickSize(-width)
          .tickFormat("")
        );
      // create a tooltip
      var Tooltip = d3.select("#my_dataviz")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px")

      // Three function that change the tooltip when user hover / move / leave a cell
      let mouseover = function (d) {
        Tooltip
          .style("opacity", 1)
      }
      let mousemove = function (event, d) {
        Tooltip
          .html("Exact value: " + d.value)
          .style("left", (d3.pointer(event)[0] + 70) + "px")
          .style("top", (d3.pointer(event)[1]) + "px")
      }
      let mouseleave = function (d) {
        Tooltip
          .style("opacity", 0)
      }

      let div = d3.select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);


      if (chartType === 'line') {
        if (data.length > 0) {
          //Add Line
          svg
            .append("path")
            .datum(data[0].values)
            .attr('fill', 'none')
            .attr('stroke', (d) => color(d.key))
            .attr('stroke-width', 3.5)
            .attr("d", d3.line()
              .x(function (d) { return x(d.x) })
              .y(function (d) { return y(d.value) })
              .curve(d3.curveMonotoneX)
            );

          // Add the points
          svg
            .append("g")
            .selectAll("dot")
            .data(data[0].values)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return d.value > 0 ? x(d.x) : "" })
            .attr("cy", function (d) { return y(d.value) })
            .attr("r", 5)
            .attr("fill", function (d) { return d.value > 0 ? color(data[0].key) : "transparent" })
            .on("mousemove", function (event, d) {
              div.style("display", "none");
              div
                .html(getTooltipHtml(formatLineDate(d.x), d.value, true))
                .style("left", (event.pageX - 50) + "px")
                .style("right", "auto")
                .style("top", (event.pageY - 65) + "px")
                .style("opacity", 1)
                .style("display", "block");
            })
            .on("mouseout", function () {
              div.html(" ").style("display", "none");
            });
          //     .on("mouseover", (d) => mouseover(d))
          // .on("mousemove", (event,d) => mousemove(event,d))
          // .on("mouseleave",(d) => mouseleave(d) );
        }


      } else if (chartType === 'area') {
        svg
          .selectAll('mylayers')
          .data(data)
          .enter()
          .append('path')
          .style('fill', (d) => color(d.key))
          // .attr(
          //   'd',
          //   d3
          //     .area()
          //     .x((d) => x(d3.isoParse(d.data.x)))
          //     .y0((d) => y(d[0]))
          //     .y1((d) => y(d[1]))
          // );
          .attr("d", d3.area()
            .x(function (d) { return x(d.data.x) })
            .y0(function (d) { return y(d[0]) })
            .y1(function (d) { return y(d[1]) })
          );

      }
      else if (chartType === 'scatter') {
        svg
          .append('path')
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", (d) => color(d.key))
          .attr("stroke-width", 1.5)
          .attr('d', d3
            .line()
            .x((d) => x(d3.isoParse(d.x)))
            .y((d) => y(d[1]))
          )
        // Add the points
        svg
          .append("g")
          .selectAll("dot")
          .data(data)
          .enter()
          .append("circle")
          .attr("cx", (d) => x(d3.isoParse(d.x)))
          .attr("cy", (d) => y(d[1]))
          .attr("r", 5)
          .attr("fill", (d) => color(d.key))

      }
      else {
        // For Bar chart
        svg
          .append('g')
          .selectAll('mybar') // Enter in the stack data = loop key per key = group per group
          .data(data)
          .enter()
          .append('g')
          .attr('fill', (d) => color(d.key))
          .selectAll('rect') // enter a second time = loop subgroup per subgroup to add all rectangles
          .data((d) => d)
          .enter()
          .append('rect')
          .attr('x', (d) => x(d.data.x))
          .attr('y', (d) => y(d[1]))
          // .attr('width', x.bandwidth())
          .attr("width", Math.min(x.bandwidth() - 2, 100))
          .attr('height', (d) => y(d[0]) - y(d[1]))
          .on("mousemove", function (event, d) {
            var a = d[1] - d[0];
            div.style("display", "none");
            div
              .html(getTooltipHtml(formatLineDate(d.data.x), a, true))
              .style("left", (event.pageX - 50) + "px")
              .style("right", "auto")
              .style("top", (event.pageY - 65) + "px")
              .style("opacity", 1)
              .style("display", "block");
          })
          .on("mouseout", function () {
            div.html(" ").style("display", "none");
          });

      }
    }
  };



  return !isResFromAPI ?
    (resultSet.series()[0] == undefined ?
      (<>{tableCaption !== undefined ? <h6 className="mt-4 ms-4 page-subtitle">{tableCaption}</h6> : ""}
        <div className="text-center no-data">{t("header.NoDataFound")}</div></>)
      :
      (<>{tableCaption !== undefined ? <h6 className="mt-4 ms-4 page-subtitle">{tableCaption}</h6> : ""} <div ref={(el) => el && drawChart(data, resultSetNew, apiUrl, el, resultSet, type, cardProperty, props)} /></>))
    : <>{tableCaption !== undefined ? <h6 className="mt-4 ms-4 page-subtitle">{tableCaption}</h6> : ""} <div ref={(el) => el && drawChart(data, resultSetNew, apiUrl, el, resultSet, type, cardProperty, props)} /></>;
};

ChartsComponent.propTypes = {
  type: PropTypes.string,
  resultSet: PropTypes.object
};


ChartsComponent.defaultProps = {
  type: "",
  resultSet: {}
};

export default ChartsComponent;
