import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import Mapbox from '../components/Mapbox';
import { useTranslation } from 'react-i18next';

function FeedbackAnalyticsPage() {
    const [t, i18n] = useTranslation();
    return (
        <>
        <Row className="mb-3">
            <Col lg={12} xl={4}>
                <h2 className="h2 mb-0 ps-3">{t("header.feedbackAnalyticsMessage")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                 
                </Col>
            </Row>
        {/* <Row className="mb-3">
                <Col lg={12}>
                    <h5 className="h5 ps-3">What? Feedback Analytics</h5>
                    <p className="ps-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Donec dictum metus ligula, eget blandit erat volutpat eu. 
                        Praesent quis metus sollicitudin, accumsan risus in, tristique tellus. 
                        Sed eu congue velit.
                    </p>
                </Col>
            </Row> */}
        </>
    )
}

export default FeedbackAnalyticsPage