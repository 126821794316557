import React from 'react'
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { SessionTimeoutPopupTimeinMinutes } from "../utils/common";
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';

const CountdownTimer = (props) => {
    const [t, i18n] = useTranslation();
    const { initialMinute = SessionTimeoutPopupTimeinMinutes, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    let sec=seconds < 10 ? `0${seconds}` : seconds;
    const headerTitle = JSON.stringify(minutes) +":"+sec+ t("header.countDownTitle");
   
    useEffect(() => {

        let sessionTime = localStorage.getItem("sessionTime");
        if(!sessionTime) {
            localStorage.setItem("sessionTime", new Date());
        }

        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <>
            <Helmet>
                <title>{`${headerTitle}`}</title>
            </Helmet>
            {minutes} {t("header.countDownMin")} {seconds < 10 ? `0${seconds}` : seconds} {t("header.countDownSects")}

        </>
    )
}

export default withRouter(CountdownTimer);