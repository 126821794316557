import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Col, Card, Nav, Pagination } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { TABLE_TOTAL_ROWS, DEFAULT_PAGE_NO } from "../AppConfig";
import { propTypes } from "@themesberg/react-bootstrap/lib/esm/Image";
import { useTranslation } from 'react-i18next';

const PaginationComponent = ({
    tableBodyData,
    withIcons,
    pageNo,
    pagesData,
    items,
    setItems,
    setPageNo,
    onPageNoChange,
    onItemChange,
    showPaginationSummary,
    totalRecords,
    minimumRows
}) => {
    const [t, i18n] = useTranslation();

    
    const pageSize = 5;
    const totalData = totalRecords;

    const consideredMinimumRows = minimumRows !== undefined ? minimumRows : TABLE_TOTAL_ROWS
    let pageNum = localStorage.getItem("pageNo");
    const showingStart = (pageNum - 1) * consideredMinimumRows + 1;
    const showingEnd = Math.min(pageNum * consideredMinimumRows, totalData);

    const tableRows = minimumRows !== undefined ?  minimumRows: TABLE_TOTAL_ROWS
    const currentRecord = pageNo === DEFAULT_PAGE_NO ? totalRecords : totalRecords + (tableRows * (pageNo - 1));
    const totalPages = Math.ceil(totalData / tableRows);



    const Pagesvalue = (num) => {
        items = [];
        let i = 0;
        //let n=num!=1 && num!=totalPages?num-1:(num===totalPages && totalPages>pageSize?num-(pageSize-2):totalPages<pageSize?1: num);
        let n = num != 1 && num != totalPages ? num - 1 : (num === totalPages && totalPages > pageSize ? num - (pageSize - 2) : totalPages < pageSize ? 1 : (num === totalPages) ? num - 2 : num);
        for (let number = n; number >= 1 && number <= totalPages; number++) {
            const isItemActive = pageNo === number;
            items.push(
                <Pagination.Item active={isItemActive} key={number} onClick={() => onPaginationItemClick(number)}>
                    {number}
                </Pagination.Item>
            );

            i++;
            if (i === pageSize)
                break;
        };
        return items;
    }
    const renderPaginationItem = (abc) => {
    }
    const onPaginationItemClick = (number) => {
        localStorage.setItem("pageNo",number)
        onPageNoChange && onPageNoChange(number);

    };

    const onPrevsItemClick = () => {
        localStorage.setItem("pageNo",1)
        onPageNoChange && onPageNoChange(1);
    };
    
    const onNextItemClick = () => {
        localStorage.setItem("pageNo",totalPages)
        onPageNoChange && onPageNoChange(totalPages);
    };

    function formatNumberWithComma(number) {
        return number.toLocaleString();
    }
    return (
        <>
            {

                totalData > 0 && totalRecords > 0 && (totalData > tableRows) &&
                <>
                    {/* <PageComponent totalData={totalData} pageNo={pageNo} itemsvalue={items1} onNextItemClick={onNextItemClick} onPrevsItemClick={onPrevsItemClick}></PageComponent> */}
                    {
                        showPaginationSummary ?
                            <small className="fw-bold">
                                {t("header.paginationMessage1")} <b>{showingStart} - {showingEnd}</b>{t("header.paginationMessage2")} <b> {formatNumberWithComma(totalData)}</b> {t("header.paginationMessage3")}
                            </small >
                            :
                            <small className="fw-bold">
                            </small>
                    }
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0 mt-2 mt-lg-0">

                            <Pagination.Prev onClick={onPrevsItemClick} disabled={pageNo === DEFAULT_PAGE_NO ? true : false}>
                                {withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "Previous"}
                            </Pagination.Prev>
                            {/* { pagesData.length>0 &&  pagesData.map((page,index) =>
                         
                    <Pagination.Item active={false} key={page} onClick={() => onPaginationItemClick(page)}>
                    {page}
                </Pagination.Item>
                )} */}

                            {Pagesvalue(pageNo)}
                            <Pagination.Next onClick={onNextItemClick} disabled={pageNo === totalPages ? true : false}>
                                {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                </>
            }
        </>
    );


}

PaginationComponent.propTypes = {
    tableBodyData: PropTypes.array,
    withIcons: PropTypes.bool,
    pageNo: PropTypes.number,
    items: PropTypes.array,
    pages: PropTypes.array,
    onPageNoChange: PropTypes.func,
    onItemChange: PropTypes.func,
    showPaginationSummary: PropTypes.bool,
    totalRecords: PropTypes.number

};
PaginationComponent.defaultProps = {
    tableBodyData: [],
    withIcons: true,
    pageNo: DEFAULT_PAGE_NO,
    items: [],
    pages: [],
    onPageNoChange: null,
    onItemChange: null,
    showPaginationSummary: false,
    totalRecords: 25


}

export default PaginationComponent;