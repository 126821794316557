export const getServiceCenterReports = (workordercompanymappingid,companyId, searchString, year, offset) => {
    let serviceCenterReportData = [
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/ServiceCentre/GetWOClientReportLists?CompanyId=${companyId}&workorderCompanyId=${workordercompanymappingid}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "1",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":8}",
            name: "Report History",
            summary: `{}`,
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[\"Miworkorderreport.count\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Miworkorderreport.count\"],\"timeDimensions\":[],\"order\":{},\"filters\":[{\"member\":\"Miworkordercompanymapping.workordercompanymappingid\",\"operator\":\"equals\",\"values\":[\"${workordercompanymappingid}\"]},{\"member\":\"Miworkorderreport.workorderreportname\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}],\"dimensions\":[]}}` },
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Miworkorderreport.reportid\",\"Miworkorderreport.workorderreportname\",\"Miworkorderreport.RemainingHours\", "\Miworkorderreport.totalcontractbillablehours\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Miworkorderreport.createddt\":\"desc\"},\"filters\":[{\"member\":\"Miworkordercompanymapping.workordercompanymappingid\",\"operator\":\"equals\",\"values\":[\"${workordercompanymappingid}\"]},{\"member\":\"Miworkorderreport.workorderreportname\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}],\"dimensions\":["\Miworkorderreport.reportid\",\"Miworkorderreport.workorderreportname\",\"Miworkorderreport.reportstartdate\", \"Miworkorderreport.reportenddate\",\"Miworkorderreport.reportperiod\",\"Miworkorderreport.createddt\", \"Miworkorderreport.RemainingHours\", "\Miworkorderreport.totalbillablehours\"],\"limit\": 6,\"offset\": ${offset}}}`,
            legends: [],
            enableOption: false
        },
    ];
    return serviceCenterReportData;
};

export const getServiceCenterReportDetails = (reportID, companyId, userID, detailSearchString, offset) => {
    let serviceCenterReportDetailData = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":8}",
            name: "Details",
            summary: `{}`,
            pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[\"Miworkorderreportdetail.count\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Miworkorderreportdetail.count\"],\"timeDimensions\":[],\"order\":{},\"filters\":[{\"member\":\"Miworkordercompanymapping.companyid\",\"operator\":\"equals\",\"values\":[\"${companyId}\"]},{\"member\":\"Miworkorderreport.reportid\",\"operator\":\"equals\",\"values\":[\"${reportID}\"]},{\"member\":\"Miusercompany.userid\",\"operator\":\"equals\",\"values\":[\"${userID}\"]},{\"member\":\"Miworkorderreportdetail.workordername\",\"operator\":\"contains\",\"values\":[\"${detailSearchString}\"]},{\"member\":\"Miworkorderreport.active\",\"operator\":\"equals\",\"values\":[\"1\"]}],\"dimensions\":[]}}` },
            vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Miworkorderreportdetail.workorderdate\",\"Miworkorderreportdetail.workorderid\",\"Miworkorderreportdetail.workordername\",\"Miworkorderreportdetail.workorderadjustedtime\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[\"Miworkorderreportdetail.workorderdate\",\"Miworkorderreportdetail.workorderid\",\"Miworkorderreportdetail.workordername\",\"Miworkorderreportdetail.workorderadjustedtime\"],\"timeDimensions\":[],\"order\":[[\"Miworkorderreportdetail.workorderdate\",\"asc\"],[\"Miworkorderreportdetail.workorderid\",\"asc\"]],\"filters\":[{\"member\":\"Miworkordercompanymapping.companyid\",\"operator\":\"equals\",\"values\":[\"${companyId}\"]},{\"member\":\"Miworkorderreport.reportid\",\"operator\":\"equals\",\"values\":[\"${reportID}\"]},{\"member\":\"Miusercompany.userid\",\"operator\":\"equals\",\"values\":[\"${userID}\"]},{\"member\":\"Miworkorderreportdetail.workordername\",\"operator\":\"contains\",\"values\":[\"${detailSearchString}\"]},{\"member\":\"Miworkorderreport.active\",\"operator\":\"equals\",\"values\":[\"1\"]}],\"limit\": 6,\"offset\": ${offset}}}`,
            legends: [],
            enableOption: false
        },
    ];
    return serviceCenterReportDetailData;
};
