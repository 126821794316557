import React, { Component } from 'react';

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by ErrorPage:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="generic-error-msg">
                    <h2>Oops! Something went wrong.</h2>
                    <p>We apologize for the inconvenience. Please try again later.</p>
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorPage;