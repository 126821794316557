import { useState,useImperativeHandle,forwardRef } from "react";
import { Button } from "@themesberg/react-bootstrap";
import { Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
const UserDigestAlertComponent = forwardRef((props, ref) => {
   
    useImperativeHandle(ref, () => ({
        ShowAlert(success, msg) {
            setshowAlert(true);
            setIsSuccess(success);
            setalertMessage(msg);
        }
      }))
    const [showAlert, setshowAlert] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [alertMessage, setalertMessage] = useState(true);
    const onCloseAlert = () =>{
        setshowAlert(false);
        setalertMessage("");
      }
    return (
        <Alert
            variant={isSuccess ? "success" : "danger"}
            show={showAlert}>
            <div className="d-flex justify-content-between mb-0">
            <div>
                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                {alertMessage}
            </div>
            <Button variant="close" size="xs" onClick={() => onCloseAlert()} />
            </div>
        </Alert>
      );
    });
    export default UserDigestAlertComponent;