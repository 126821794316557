import { getDataForViewersDomainsVE,getDataForOnDemandCard, getDataForOnLiveCard, getDataForViewerDomain, getDataForViewerDevice } from "../../apis/services/webcastPageService.js";

export const apiCharts = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getDataForViewersDomainsVE(cryptoKey, payload, callBackFn) },
    },
    // {
    //     id: 1,
    //     service: (payload, callBackFn) => { getDataForOnDemandCard(payload, callBackFn) },
    // },
    // {
    //     id: 2,
    //     service: (payload, callBackFn) => { getDataForOnLiveCard(payload, callBackFn) },
    // }
]
export const apiChartsForIL = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getDataForOnDemandCard(cryptoKey, payload, callBackFn) },
    },
    {
        id: 2,
        service: (cryptoKey, payload, callBackFn) => { getDataForOnLiveCard(cryptoKey, payload, callBackFn) },
    },
    {
        id: 3,
        service: (cryptoKey, payload, callBackFn) => { getDataForViewerDomain(cryptoKey, payload, callBackFn) },
    },
    {
        id: 5,
        service: (cryptoKey, payload, callBackFn) => { getDataForViewerDevice(cryptoKey, payload, callBackFn) },
    },
]

export const webCastchartsVE = (eventtype, PresentationId,companyId,clientSlug,presentationSlug) => {
    let webCastchartsVEList = [
        {
            isSummeryShown: true,
            chartType: "pie",
            isResultShownFromApi: true,
            id: 1,
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":2,\"w\":6,\"h\":8}",
            layoutone: {},
            name: "Viewer Domains",
            summary: "{}",

            // vizState: `{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"viewereventsDomains.domain\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"viewereventsDomains.TotalViewers\"],\"timeDimensions\":[],\"order\":{\"viewereventsDomains.domain\":\"asc\"},\"filters\":[{\"member\": \"viewereventsDomains.presentationId\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"dimensions\":[\"viewereventsDomains.domain\"]}}`,
            legends: {}
        },
        {
            id: "2",
            layout: "{\"x\":6,\"y\":2,\"w\":6,\"h\":8}",
            name: "Viewer Domains",
            summary: "{}",
            // vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"viewereventsDomains.domain\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"viewereventsDomains.TotalViewers\"],\"timeDimensions\":[],\"order\":{\"viewereventsDomains.domain\":\"asc\"},\"filters\":[{\"member\": \"viewereventsDomains.presentationId\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"dimensions\":[\"viewereventsDomains.domain\"]}}`,
            legends: ``,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveDomainDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${companyId}&clienttype=VE&type=table`,type:"get",count:""},
            tooltip:"",
        },
    ];

    return webCastchartsVEList;
  
};

export const webCastquestionsVE = (eventtype, PresentationId,offset,searchString, selectedPublishStatus, aprovedFor,clientSlug,presentationSlug) => {
   if(aprovedFor === "all"){
                let webCastquestionsVEWithStatusAllList = [
                        {
                            id: "1",
                            layout: "{\"x\":0,\"y\":3,\"w\":12,\"h\":13}",
                            name: "Questions",
                            summary:"{}",
                            pagination:"{}",
                            vizState:"{}",
                            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"order\":{\"Viewerevents.timeutc\":\"desc\"}}, \"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"or\":[{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"contains\",\"values\":[\"${aprovedFor}\"]},{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"notSet\"}]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\",\"operator\":\"equals\",\"values\":[\"${eventtype}\"]},{\"member\":\"Viewerevents.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}]}}`,
                            //pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"order\":{\"Viewerevents.timeutc\":\"desc\"}, \"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"or\":[{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"contains\",\"values\":[\"${aprovedFor}\"]},{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"notSet\"}]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\": \"Viewerevents.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}]}}`},
                            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Viewerevents.nameandemail\",\"Viewerevents.value\" ,\"Viewerevents.timeutc\",\"Viewerevents.questionApproved\",\"Viewerevents_view.questionApprovedfor\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Viewerevents.timeutc\":\"desc\"},\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"or\":[{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"contains\",\"values\":[\"${aprovedFor}\"]},{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"notSet\"}]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\":\"Viewerevents.presentationid\",\"operator\": \"contains\", \"values\":[\"${PresentationId}\"]}]}],\"limit\":15,\"offset\":\"${offset}"\,\"dimensions\":[\"Viewerevents.id\",\"Viewerevents.presentationid\",\"Viewerevents.nameandemail\",\"Viewerevents.value\",\"Viewerevents.timeutc\",\"Viewerevents.questionApproved\",\"Viewerevents_view.questionApprovedfor\"]}}`
                            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveQuetions?clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&skip=0&providerName=InvestisLive&searchString=${searchString}&clienttype=VE&approvedFor=${aprovedFor}`,type:"get",count:""},
                            tooltip:"header.questionscardTooltipText"
                        }
                    ];
                return webCastquestionsVEWithStatusAllList;
        }
        else if(aprovedFor === "presenter"){
            let webCastquestionsWithPresenterVEList = [
                    {
                        id: "1",
                        layout: "{\"x\":0,\"y\":3,\"w\":12,\"h\":13}",
                        name: "Questions",
                        summary:"{}",
                        pagination:"{}",
                        vizState:"{}",
                        //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"order\":{\"Viewerevents.timeutc\":\"desc\"}}, \"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"contains\",\"values\":[\"${aprovedFor}\"]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\",\"operator\":\"equals\",\"values\":[\"${eventtype}\"]},{\"member\":\"Viewerevents.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}]}}`,
                        //pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"order\":{\"Viewerevents.timeutc\":\"desc\"}, \"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"contains\",\"values\":[\"${aprovedFor}\"]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\": \"Viewerevents.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}]}}`},
                        //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Viewerevents.nameandemail\",\"Viewerevents.value\" ,\"Viewerevents.timeutc\",\"Viewerevents.questionApproved\",\"Viewerevents_view.questionApprovedfor\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Viewerevents.timeutc\":\"desc\"},\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"member\":\"Viewerevents_view.questionApprovedfor\",\"operator\":\"contains\",\"values\":[\"${aprovedFor}\"]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\":\"Viewerevents.presentationid\",\"operator\": \"contains\", \"values\":[\"${PresentationId}\"]}]}],\"limit\":15,\"offset\":\"${offset}"\,\"dimensions\":[\"Viewerevents.id\",\"Viewerevents.presentationid\",\"Viewerevents.nameandemail\",\"Viewerevents.value\",\"Viewerevents.timeutc\",\"Viewerevents.questionApproved\",\"Viewerevents_view.questionApprovedfor\"]}}`
                        apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveQuetions?clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&skip=0&providerName=InvestisLive&searchString=${searchString}&clienttype=VE&approvedFor=${aprovedFor}`,type:"get",count:""},
                        tooltip:"header.questionscardTooltipText"
                    }
                 ];
            return webCastquestionsWithPresenterVEList;
        }
        else{
            let webCastquestionsVEList = [
                {
                    id: "1",
                    layout: "{\"x\":0,\"y\":3,\"w\":12,\"h\":14}",
                    name: "Questions",
                    summary:"{}",
                    pagination:"{}",
                    vizState:"{}",
                    // summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"order\":{\"Viewerevents.timeutc\":\"desc\"}}, \"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\",\"operator\":\"equals\",\"values\":[\"${eventtype}\"]},{\"member\":\"Viewerevents.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}]}}`,
                    // pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"order\":{\"Viewerevents.timeutc\":\"desc\"}, \"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\": \"Viewerevents.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}]}}`},
                    // vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Viewerevents.nameandemail\",\"Viewerevents.value\" ,\"Viewerevents.timeutc\",\"Viewerevents.questionApproved\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Viewerevents.timeutc\":\"desc\"},\"filters\":[{\"and\":[{\"or\":[{\"member\":\"Viewerevents.value\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]},{\"member\":\"Viewerevents.nameandemail\",\"operator\":\"contains\",\"values\":[\"${searchString}\"]}]},{\"member\":\"Viewerevents.questionApproved\",\"operator\":\"contains\",\"values\":[\"${selectedPublishStatus}\"]},{\"member\":\"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\":\"Viewerevents.presentationid\",\"operator\": \"contains\", \"values\":[\"${PresentationId}\"]}]}],\"limit\":15,\"offset\":\"${offset}"\,\"dimensions\":[\"Viewerevents.id\",\"Viewerevents.presentationid\",\"Viewerevents.nameandemail\",\"Viewerevents.value\",\"Viewerevents.timeutc\",\"Viewerevents.questionApproved\",\"Viewerevents_view.questionApprovedfor\"]}}`,
                    apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveQuetions?clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&skip=0&providerName=InvestisLive&searchString=${searchString}&clienttype=VE&approvedFor=${aprovedFor}`,type:"get",count:""},
                    tooltip:"header.questionscardTooltipText"
                }
                 ];
            return webCastquestionsVEList;
        }
  
};

export const webCastPollsVE = (PresentationId) => {
    let webCastPollsVEList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":4,\"w\":12,\"h\":10}",
            name: "Polls",
            summary:`{}`,
            pagination:`{}`,
            vizState:`{}`,
            // summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"PresentationPollsQaDetails.countquestions\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"PresentationPollsQaDetails.id\",\"operator\":\"equals\",\"values\":[\"${PresentationId}"]}]}}`,
            // pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"PresentationPollsQaDetails.countquestions\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"PresentationPollsQaDetails.id\",\"operator\":\"equals\",\"values\":[\"${PresentationId}"]}]}}`},
            // vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"PresentationPollsQaDetails.question\",\"PresentationPollsQaDetails.answerData\",\"PresentationPollsQaDetails.answersValue\",\"PresentationPollsQaDetails.answersCount\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[\"PresentationPollsQaDetails.countquestions\",\"PresentationPollsQaDetails.countanswers\"],\"timeDimensions\":[],\"order\":{\"PresentationPollsQaDetails.question\":\"asc\"},\"filters\":[{\"member\": \"PresentationPollsQaDetails.id\", \"operator\": \"equals\", \"values\": [\"${PresentationId}\"]}],\"limit\": 15,\"offset\":\"${offset}\" ,\"dimensions\":[\"PresentationPollsQaDetails.question\",\"PresentationPollsQaDetails.answerData\",\"PresentationPollsQaDetails.answersValue\",\"PresentationPollsQaDetails.answersCount\"]}}`,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetPollDetail?presentationId=${PresentationId}`,type:"get",count:""},
        }
    ];
  return webCastPollsVEList;
};
export const tabsContent = () => {
    let webcastTabs = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":4,\"w\":12,\"h\":25}",
            name: "webcast",
            summary:`{}`,
            pagination:`{}`,
            vizState:`{}`,
            // summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"PresentationPollsQaDetails.countquestions\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"PresentationPollsQaDetails.id\",\"operator\":\"equals\",\"values\":[\"${PresentationId}"]}]}}`,
            // pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"PresentationPollsQaDetails.countquestions\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"PresentationPollsQaDetails.id\",\"operator\":\"equals\",\"values\":[\"${PresentationId}"]}]}}`},
            // vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"PresentationPollsQaDetails.question\",\"PresentationPollsQaDetails.answerData\",\"PresentationPollsQaDetails.answersValue\",\"PresentationPollsQaDetails.answersCount\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[\"PresentationPollsQaDetails.countquestions\",\"PresentationPollsQaDetails.countanswers\"],\"timeDimensions\":[],\"order\":{\"PresentationPollsQaDetails.question\":\"asc\"},\"filters\":[{\"member\": \"PresentationPollsQaDetails.id\", \"operator\": \"equals\", \"values\": [\"${PresentationId}\"]}],\"limit\": 15,\"offset\":\"${offset}\" ,\"dimensions\":[\"PresentationPollsQaDetails.question\",\"PresentationPollsQaDetails.answerData\",\"PresentationPollsQaDetails.answersValue\",\"PresentationPollsQaDetails.answersCount\"]}}`,
            // apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetPollDetail?presentationId=${PresentationId}`,type:"get",count:""},
        }
    ];
  return webcastTabs;
};
export const webCastMultiPollsVEList = (PresentationId,offset) => {
    let webCastMultiPollsVEList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":4,\"w\":12,\"h\":10}",
            name: "Polls",
            summary: `{}`,
            
            vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"PresentationsPolls.pollsName\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"PresentationsPolls.pollsName\":\"asc\"},\"filters\":[{\"member\": \"PresentationsPolls.presentationId\", \"operator\": \"equals\", \"values\": [\"${PresentationId}\"]}] ,\"dimensions\":[\"PresentationsPolls.pollsName\"]}}`  
         }
    ];
    return webCastMultiPollsVEList;
}

export const webCastMultiPollsQuestionVEList = (pollId,offset) => {
    let webCastMultiPollsQuestionVEList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":4,\"w\":12,\"h\":13}",
            name: "Multipoll Questions",
            summary: `{}`,
            vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"PresentationsPollsQA.question\",\"PresentationsPollsQA.answerData\",\"PresentationsPollsQA.answersValue\",\"PresentationsPollsQA.answersCount\",\"PresentationsPollsQA.questionid\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"PresentationsPollsQA.question\":\"asc\"},\"filters\":[{\"member\": \"PresentationsPollsQA.pollId\", \"operator\": \"equals\", \"values\": [\"${pollId}\"]}],\"limit\": 15,\"offset\":\"${offset}\" ,\"dimensions\":[\"PresentationsPollsQA.question\",\"PresentationsPollsQA.answersValue\",\"PresentationsPollsQA.answerData\",\"PresentationsPollsQA.answersCount\",\"PresentationsPollsQA.questionid\"]}}`   
        }
    ];
    return webCastMultiPollsQuestionVEList;
}
export const webCastViewersVE = (domain,viewer,viewertype,eventtype, PresentationId,offset,companyId,clientSlug,presentationSlug) => {
    
    let webCastStatsVEList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":12,\"h\":16}",
            name: "Webcast Viewers",
            pagination:"{}",
            summary:"{}",
            vizState:"{}",
            //pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}"]}]}}`},
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Videoanalytics.name\",\"Videoanalytics.email\",\"Videoanalytics.vieweddate\",\"Videoanalytics.connecttime\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 10},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Videoanalytics.name\":\"asc\"},\"filters\":[{\"member\": \"Videoanalytics.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"limit\": 15,\"offset\":${offset} ,\"dimensions\":[\"Videoanalytics.name\",\"Videoanalytics.email\",\"Videoanalytics.vieweddate\",\"Videoanalytics.connecttime\"]}}`,
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveFilterData?device=&domain=${domain}&viewer=${viewer}&viewerType=${viewertype}&clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${companyId}&clienttype=VE`,type:"get",count:""},
            apiUrlForDomains: {url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveEventViewersDetail?clientSlug=${clientSlug}&fetchLatest=true&generateLatest=false&isExport=false&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&companyid=${companyId}`,type:"get",count:""},
            tooltip:"header.webcastViewersTooltipText"
        }
    ];
    return webCastStatsVEList;
};

export const webCastStatsIE = (eventtype, PresentationId, currentPage,companyId,clientSlug,presentationSlug, culture) => {
    let webCastStatsIEList = [
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "line2",
            id: "2",
            // layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
            layout: "{\"x\":6,\"y\":2,\"w\":6,\"h\":8}",
            name: "Live",

            summary: "{}",
            // summary: "{\"isCarouselCard\": true,\"carousalMainSummary\": \"Sessions\", \"summaryPrimary\": \"10,020\", \"showPercentageForPrimary\" : false, \"showPositiveOrNegativeForPrimary\" : false,\"summarySecondary\": 23.4,\"showPercentageForSecoundary\" : true, \"showPositiveOrNegativeForSecoundary\" :true}",
            vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"ViewereventsPlayerevents.playereventsEventtime\"],\"y\":[\"measures\"],\"fillMissingDates\":false,\"joinDateRange\":false},\"query\":{\"measures\":[\"ViewereventsViewerdata.count\"],\"timeDimensions\":[{\"dimension\": \"ViewereventsPlayerevents.playereventsEventtime\",\"granularity\": \"minute\"}],\"order\":{},\"filters\":[{\"member\": \"Viewerevents.currentpage\", \"operator\": \"equals\",\"values\": [\"live\"]},{\"member\": \"Viewerevents.presentationid\", \"operator\": \"equals\", \"values\": [\"${PresentationId}\"]},{\"member\": \"ViewereventsPlayerevents.playereventsEventtype\",\"operator\": \"set\"}],\"dimensions\":[]}, \"widjetName\":\"Viewerslive\"}`,
            legends: [{ name: "Viewers" }],
            tooltip:"webcastLiveTooltipText",
            summaryBesideText:"Unique Viewers"
        },
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "line2",
            id: "1",
            // layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
            layout: "{\"x\":0,\"y\":1,\"w\":6,\"h\":8}",
            name: "On demand",
            summary: "{}",
            // summary: "{\"isCarouselCard\": false, \"summaryPrimary\": 23, \"showPercentageForPrimary\" : true, \"showPositiveOrNegativeForPrimary\" : false,\"summarySecondary\": \"of visitors decilined analytics cookies\",\"showPercentageForSecoundary\" : false, \"showPositiveOrNegativeForSecoundary\" :false}",
            vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"ViewereventsPlayerevents.playereventsEventtime\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":true},\"query\":{\"measures\":[\"ViewereventsViewerdata.count\"],\"timeDimensions\":[{\"dimension\": \"ViewereventsPlayerevents.playereventsEventtime\",\"granularity\": \"day\"}],\"order\":{},\"filters\":[{\"member\": \"Viewerevents.currentpage\", \"operator\": \"equals\",\"values\": [\"${currentPage}\"]},{\"member\": \"Viewerevents.presentationid\", \"operator\": \"equals\", \"values\": [\"${PresentationId}\"]},{\"member\": \"ViewereventsPlayerevents.playereventsEventtype\",\"operator\": \"set\"}],\"dimensions\":[]}, \"widjetName\":\"Viewersondemand\"}`,
            legends: [{ name: "Viewers" }],
            tooltip:"webcastOnDemandTooltipText",
            summaryBesideText:"Total viewers"
        },        
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "pie",
            id: "3",
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":3,\"w\":6,\"h\":8}",
            name: "Viewer Domains",
            summary: "{}",

            vizState: `{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"viewereventsDomains.domain\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"viewereventsDomains.TotalViewers\"],\"timeDimensions\":[],\"order\":{\"viewereventsDomains.domain\":\"asc\"},\"filters\":[{\"member\": \"viewereventsDomains.presentationId\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"dimensions\":[\"viewereventsDomains.domain\"]}}`,
            legends: ``
        },
        {
            id: "4",
            layout: "{\"x\":6,\"y\":3,\"w\":6,\"h\":8}",
            name: "Viewer Domains",
            summary: "{}",
            vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"viewereventsDomains.domain\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"viewereventsDomains.TotalViewers\"],\"timeDimensions\":[],\"order\":{\"viewereventsDomains.domain\":\"asc\"},\"filters\":[{\"member\": \"viewereventsDomains.presentationId\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"dimensions\":[\"viewereventsDomains.domain\"]}}`,
            legends: ``,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveDomainDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${companyId}&clienttype=IL&type=table`,type:"get",count:""},
        },
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "pie",
            id: "5",
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":4,\"w\":6,\"h\":8}",
            name: "Viewer Device",
            summary: "{}",
            vizState: `{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"Viewerevents.DeviceType\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Viewerevents.TotalDeviceType\"],\"timeDimensions\":[],\"order\":{\"Viewerevents.TotalDeviceType\":\"desc\"},\"filters\":[{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"dimensions\":[\"Viewerevents.DeviceType\"]}}`,
            legends: ``
        },
        {
            id: "6",
            layout: "{\"x\":6,\"y\":4,\"w\":6,\"h\":8}",
            name: "Viewer Device",
            summary: "{}",
            vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Viewerevents.DeviceType\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Viewerevents.TotalDeviceType\"],\"timeDimensions\":[],\"order\":{\"Viewerevents.TotalDeviceType\":\"desc\"},\"filters\":[{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"dimensions\":[\"Viewerevents.DeviceType\"]}}`,
            legends: ``,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveDeviceDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${companyId}&type=table&culture=${culture}`,type:"get",count:""},
        },
        
       
    ];

    return webCastStatsIEList;

};
export const WebcastViewersIL = (device,domain,viewer,viewertype,eventtype, PresentationId, currentPage,offset,companyId,clientSlug,presentationSlug,ismanualrefresh) => {
    let WebcastViewersILList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":5,\"w\":12,\"h\":16}",
            name: "Webcast Viewers",
            pagination:"{}",
            summary:"{}",
            vizState:"{}",
            //pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"ViewereventsViewerdata.Totalviewer\"],\"filters\":[{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`},
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"ViewereventsViewerdata.Totalviewer\"],\"filters\":[{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]},{\"member\": \"ViewereventsPlayerevents.playereventsEventtype\", \"operator"\: \"equals\", \"values\": [\"Play\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`,
            // summary: "{\"isCarouselCard\": false, \"summaryPrimary\": 95, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\": \"Dummy Text\",\"showPercentageForSecoundary\" :false, \"showPositiveOrNegativeForSecoundary\" :false}",
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"ViewereventsViewerdata.viewerdataMetadataName\",\"ViewereventsViewerdata.viewerdataEmail\",\"ViewereventsViewerdata.viewerdataRegistrationdate\",\"ViewereventsViewerdata.viewerdataViewdate\",\"ViewereventsPlayerevents.ctime\",\"ViewereventsPlayerevents.dtime\",\"ViewereventsPlayerevents.duration\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{},\"filters\":[{\"member\": \"Viewerevents.presentationid\",\"operator\": \"equals\", \"values\": [\"${PresentationId}\"]},{\"member\": \"ViewereventsPlayerevents.playereventsEventtype\", \"operator"\: \"equals\", \"values\": [\"Play\"]}],\"limit\": 15,\"offset\":${offset} ,\"dimensions\":[\"ViewereventsViewerdata.viewerdataMetadataName\",\"ViewereventsViewerdata.viewerdataEmail\",\"ViewereventsViewerdata.viewerdataRegistrationdate\",\"ViewereventsViewerdata.viewerdataViewdate\",\"ViewereventsPlayerevents.ctime\", \"ViewereventsPlayerevents.dtime\", \"ViewereventsPlayerevents.duration\"]}}`,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveFilterData?device=${device}&domain=${domain}&viewer=${viewer}&viewerType=${viewertype}&clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&fetchLatest=true&isExport=false&generateLatest=${ismanualrefresh !== undefined ? "true" : "false"}&companyid=${companyId}&clienttype=IL`,type:"get",count:""},
            apiUrlForDomains:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveViewersDetail?clientSlug=${clientSlug}&fetchLatest=true&generateLatest=false&isExport=false&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&companyid=${companyId}`,type:"get",count:""},
            tooltip:"header.webcastViewersTooltipText"
        }
    ];

    return WebcastViewersILList;
  
};
export const WebcastQuestionsIL = (eventtype, PresentationId, currentPage,offset,clientSlug,presentationSlug,searchString) => {
    let WebcastQuestionsILList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":6,\"w\":12,\"h\":20}",

            name: "Questions",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"filters\":[{\"member\": \"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`,
           
            pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Viewerevents.Totalquestion\"],\"filters\":[{\"member\": \"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`},
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Viewerevents.nameandemail\",\"Viewerevents.value\",\"Viewerevents.timeutc\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Presentations.name\":\"asc\"},\"filters\":[{\"member\": \"Viewerevents.eventtype\", \"operator\": \"equals\", \"values\": [\"${eventtype}\"]},{\"member\": \"Viewerevents.presentationid\", \"operator\": \"contains\", \"values\": [\"${PresentationId}\"]}],\"limit\": 15,\"offset\":${offset} ,\"dimensions\":[\"Viewerevents.nameandemail\",\"Viewerevents.value\",\"Viewerevents.timeutc\"]}}`,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetInvestisLiveQuetions?clientSlug=${clientSlug}&presentationId=${PresentationId}&presentationSlug=${presentationSlug}&skip=0&providerName=InvestisLive&searchString=${searchString}&clienttype&clienttype=IL`,type:"get",count:""},
            tooltip:"header.questionscardTooltipText"
        }
    ];

    return WebcastQuestionsILList;
  
};

export const webCastUserListVE = (eventId,offset) => {
    let webCastUserList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":5,\"w\":12,\"h\":20}",
            name: "user",
            tooltip:"header.manageUsersUsersTooltipText",
            summary:`{}`,
            vizState:`{}`,
            // summary:"4",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Users.Totalusers\"],\"filters\":[{\"member\": \"Events.id\", \"operator\": \"equals\", \"values\": [\"${eventId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`,
            //pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Users.Totalusers\"],\"filters\":[{\"member\": \"Events.id\", \"operator\": \"equals\", \"values\": [\"${eventId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`},
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Users.email\",\"Users.nameandjob\",\"Users.registrationdate\",\"Users.eventaccessstatus\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Users.nameandjob\":\"desc\"},\"filters\":[{\"member\": \"Events.id\", \"operator\": \"equals\", \"values\": [\"${eventId}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Users.email\",\"Users.nameandjob\",\"Users.registrationdate\",\"Users.eventaccessstatus\"]}}`,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetRegisteredUsers?eventId=${eventId}`,type:"get",count:""},
        },
    ];

    return webCastUserList;

};
export const webCastUserList = (clientId,slug,url) => {
    let webCastUserList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":5,\"w\":12,\"h\":20}",
            name: "",
            tooltip:"header.manageUsersUsersTooltipText",
            summary:`{}`,
            vizState:`{}`,
            // summary:"4",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Users.Totalusers\"],\"filters\":[{\"member\": \"Events.id\", \"operator\": \"equals\", \"values\": [\"${eventId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`,
            //pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Users.Totalusers\"],\"filters\":[{\"member\": \"Events.id\", \"operator\": \"equals\", \"values\": [\"${eventId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`},
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Users.email\",\"Users.nameandjob\",\"Users.registrationdate\",\"Users.eventaccessstatus\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Users.nameandjob\":\"desc\"},\"filters\":[{\"member\": \"Events.id\", \"operator\": \"equals\", \"values\": [\"${eventId}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Users.email\",\"Users.nameandjob\",\"Users.registrationdate\",\"Users.eventaccessstatus\"]}}`,
            apiUrl:{url:`${process.env.REACT_APP_API_KEY_NEW}/Analytics/GetRegisteredUsersWebcast?ClientID=${clientId}&Slug=${slug}&Url=${url}`,type:"get",count:""},
        },
    ];

    return webCastUserList;

};