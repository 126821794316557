import React, { useState, useEffect, useContext } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faChevronLeft,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Col,
    Button,
    Form,
    InputGroup,
    FormCheck,
    Image,
    Nav, Tab,
    Dropdown, ButtonGroup, Alert
} from "@themesberg/react-bootstrap";
import Loader from "../components/VoltLoader";
import AppContext from "../components/AppContext";
import DashboardItem from "../components/DashboardItem";
import { isMobile } from "react-device-detect";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import ChartRendererTable from "../components/ChartRendererTable";
import ModalComponent from "../components/VoltModal";
import Search from "../components/Search";
import ApiChartRenderer from '../components/ApiChartRenderer';
import {
    getPagesViewedLineChart, apiChartsVisitorInsightsService, getAllPopupTrafficSourceBarChart
} from "../stubs/ComponentJson/Visitoranalytics";
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import { convertBase64 } from "../utils/common";
import { useTranslation } from 'react-i18next';
import html2pdf from "html2pdf.js";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { decryptData } from "../utils/crypto";

const ReactGridLayout = WidthProvider(RGL);
const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined,
    isResultShownFromApi: i.isResultShownFromApi ? i.isResultShownFromApi : false,
    chartType: i.chartType ? i.chartType : undefined,
    extraSummary: i.extraSummary ? JSON.parse(i.extraSummary) : {},
});
const VisitorAll = ({
    selectedCategoryId,
    SearchText,
    visitors,
    // setVisitorId,
    setSearchText,
    //setIsRefresh,
    // changeVisitor,
    // offsetAll,
    // setOffsetAll,
    // pageNoAll,
    // setPageNoAll,
    setheaderFilterData,
    setTotalRecords,
    setCountryListForFilter,
    FilterParams
}) => {

    const contextData = useContext(AppContext);
    const [apiUrl, setApiUrl] = useState({});
    const [t, i18n] = useTranslation();
    const [items1, setItems1] = useState([]);
    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const [showPopup, setshowPopup] = useState(false)
    const [apiUrlPopup, setapiUrlPopup] = useState({});
    const [apiUrlPopup2, setapiUrlPopup2] = useState({});
    const [searchData, setsearchData] = useState("");
    const [headerFilterDataPopup, setheaderFilterDataPopup] = useState(null);
    const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
    const [pageObjDetails, setPageObjDetails] = useState({});
    const [trafficSource, setTrafficSource] = useState("");
    const [pageViewedPopupLineConfig, setpageViewedPopupLineConfig] = useState({});
    const [trafficSourcePopupBarConfig, setTrafficSourcePopupBarConfig] = useState({});
    const [blurLoader, setBlurLoader] = useState(false);
    const [trafficSourceTotal, setTrafficSourceTotal] = useState(0);
    const [trafficSourceText, setTrafficSourceText] = useState(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
    const [visitorTabKey, setVistorTabKey] = useState("");
    const [getTotalResults, setGetTotalResults] = useState(0);
    const [alertVisible, setAlertVisible] = useState(false);

    // if (pageNoAll === 0) {
    //     setPageNoAll(1);
    // }

    useEffect(() => {

        if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
            let ms = new Date(Date.parse(contextData.startDate));
            let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
            let ms2 = new Date(Date.parse(contextData.endDate));
            let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
            if (selectedCategoryId.sectionId !== 0) {
                setApiUrl(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetVisitorWithWatchlistBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
          &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pagesectionid=${selectedCategoryId.sectionId}&packageid=5`, type: 'get', count: 0
                    }
                )
            }
            else {
                setApiUrl(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
          &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&packageid=5${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`, type: 'get', count: 0
                    }
                )
            }
        }

    }, [contextData.startDate, contextData.endDate, selectedCategoryId.sectionId, contextData.selectedProfile])
    useEffect(() => {
        setTotalRecords(0)
        setTotalRecords(getTotalResults)
    }, [getTotalResults])

    const ModalOperation = (obj) => {
        let lineconfig = getPagesViewedLineChart();
        setpageViewedPopupLineConfig(lineconfig);

        let barconfig = getAllPopupTrafficSourceBarChart();
        setTrafficSourcePopupBarConfig(barconfig);

        let ms = new Date(Date.parse(contextData.startDate));
        let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
        let ms2 = new Date(Date.parse(contextData.endDate));
        let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()

        if (obj !== undefined && obj.data !== undefined) {
            setsearchData("");
            if (selectedCategoryId.sectionId !== 0) {
                setapiUrlPopup(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageByVisitorSection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startDate=${sdate}&endDate=${sdate2}&visitorcountryid=${obj.data.countryId}&visitorid=${obj.data.companyId}&pagesectionid=${selectedCategoryId.sectionId}&packageid=5&selectedcompanyid=${contextData.companyId}`, type: 'get', count: 0
                    })

                setapiUrlPopup2(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceVisitorSection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
              &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${obj.data.countryId}&companyid=${obj.data.companyId}&traffic=${trafficSource}&packageid=5&sectionid=${selectedCategoryId.sectionId}`, type: 'get', count: 0
                    }
                )
            }
            else {
                setapiUrlPopup(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startDate=${sdate}&endDate=${sdate2}&countryid=${obj.data.countryId}&visitorid=${obj.data.companyId}`, type: 'get', count: 0
                    })

                setapiUrlPopup2(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
              &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${obj.data.countryId}&companyid=${obj.data.companyId}&traffic=${trafficSource}`, type: 'get', count: 0
                    }
                )
            }
            setPageObjDetails(obj.data);
            setTrafficSource("Organic");
        }
        else {
            setPageObjDetails({});
            setTrafficSource("");
        }
        setshowPopup(!showPopup);
        setVistorTabKey("visitors");
        setAutoFillForCompanyPopup(false);
    }

    useEffect(() => {
        let ms = new Date(Date.parse(contextData.startDate));
        let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
        let ms2 = new Date(Date.parse(contextData.endDate));
        let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()

        if (pageObjDetails.companyId !== undefined) {
            if (selectedCategoryId.sectionId === 0) {
                if (trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") {
                    setapiUrlPopup2(
                        {
                            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
            &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${pageObjDetails.countryId}&companyid=${pageObjDetails.companyId}&traffic=${trafficSource}`, type: 'get', count: 0
                        }
                    )
                }
                else {
                    setBlurLoader(true)
                }
                request(
                    baseURL + `/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
      &startdate=${sdate}&enddate=${sdate2}&countryid=${pageObjDetails.countryId}&companyid=${pageObjDetails.companyId}&selectedcompanyid=${contextData.companyId}&getvalues=1`,
                    METHODS.GET,
                    {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    }
                )
                    .then((response) => {
                        setBlurLoader(false);
                        if (response != null) {
                            if (!response.organic) {
                                setTrafficSource("Direct")
                            }
                            if (trafficSource === "Organic") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.organic);
                            }
                            else if (trafficSource === "Direct") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.direct);
                            }
                            else if (trafficSource === "Referral") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.referral);
                            }
                            else if (trafficSource === "Email") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.email);
                            }
                            else if (trafficSource === "Social") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.social);
                            }
                            else if (trafficSource === "PaidSearch") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.paidSearch);
                            }
                        }
                    }).catch((error) => {
                        setBlurLoader(false);
                    })
            }
            else {
                if (trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") {
                    setapiUrlPopup2(
                        {
                            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceVisitorSection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
            &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${pageObjDetails.countryId}&companyid=${pageObjDetails.companyId}&traffic=${trafficSource}&packageid=5&sectionid=${selectedCategoryId.sectionId}`, type: 'get', count: 0
                        }
                    )
                }
                else {
                    setBlurLoader(true)
                }
                request(
                    baseURL + `/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
      &startdate=${sdate}&enddate=${sdate2}&countryid=${pageObjDetails.countryId}&companyid=${pageObjDetails.companyId}&selectedcompanyid=${contextData.companyId}&getvalues=1&packageid=5&sectionid=${selectedCategoryId.sectionId}`,
                    METHODS.GET,
                    {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    }
                )
                    .then((response) => {
                        setBlurLoader(false);
                        if (response != null) {
                            if (!response.organic) {
                                setTrafficSource("Direct")
                            }
                            if (trafficSource === "Organic") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.organic);
                            }
                            else if (trafficSource === "Direct") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.direct);
                            }
                            else if (trafficSource === "Referral") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.referral);
                            }
                            else if (trafficSource === "Email") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.email);
                            }
                            else if (trafficSource === "Social") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.social);
                            }
                            else if (trafficSource === "PaidSearch") {
                                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.paidSearch);
                            }
                        }
                    }).catch((error) => {
                        setBlurLoader(false);
                    })
            }
        }


    }, [trafficSource])

    // const onSearchBoxChange = (visitor) => {
    //     if (visitor === "") {
    //         setVisitorId(null);
    //     } else {
    //         setVisitorId(visitor["Visitorlist.VisitorId"]);
    //     }
    // };
    const onchangeVistiorTab = (event, d) => {
        if (searchData != "") {
            setsearchData("");
        }
        else if (showAutoFillForCompanyPopup && searchData == "") {
            setsearchData("emptytextbox");
        }
        setAutoFillForCompanyPopup(false);
        setVistorTabKey(event);
    };

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setBlurLoader(true)
        const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
        let paramsList = {
            profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            visitorid: pageObjDetails.companyId,
            countryid: pageObjDetails.countryId,
            startDate: moment(contextData.startDate).format("YYYY-MM-DD"),
            endDate: moment(contextData.endDate).format("YYYY-MM-DD"),
            visitor: pageObjDetails.companyName,
            companyName: selectedComapnyData.companyName,
            pagesectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            packageid: 5,
            companyid: contextData.companyId,
            webSiteName: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            culture: localStorage.getItem('i18nextLng')
        }

        const tempVar = await axios.get(
            `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/ExportVisitorPageViewExcelFileWithEPPlus`,
            {
                params: { encodeStr: convertBase64(JSON.stringify(paramsList)) },
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                }
            }
        );
        if (tempVar !== undefined && tempVar !== null) {
            setBlurLoader(false)
            let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
            let a = document.createElement("a");
            a.href = fetchDataModified;
            a.download = "Insights_Visitor.xlsx";
            a.click();
        }
    };

    const downloadPDF = async () => {
        setBlurLoader(true);
        let url = apiUrlPopup.url;
        let fileName = "Insights_Pages_Viewed.pdf";
        let filters = (searchData !== "" && searchData !== null) ? "pagesTitle|contains|" + searchData : "";
        let obj = {
            Title: pageObjDetails.companyName,
            SubTitle: t("header.visitorAllMessageText1") + pageObjDetails.companyName + t("header.visitorAllMessageText2") + moment(contextData.startDate).format("MMM DD, YYYY") + t("header.visitorAllMessageText3") + moment(contextData.endDate).format("MMM DD, YYYY"),
            PageName: "Pages Viewed",
            Filters: filters,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
            Count: 0,
            CountTitle: "",
            FilterContent: "",
            ClientName: "",
            TableHeaders: []
        }

        await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                }
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setBlurLoader(false);
                }
                else {
                    setBlurLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const downloadPageLevelPDF = async (pageName, fileName) => {
        setBlurLoader(true);
        const content = document.createElement("div");
        content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;
        const options = {
            filename: fileName,
            margin: [5, 2, 2, 2], //[top, right, bottom, left]
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
        };
        const combinedNode = document.createElement("div");
        combinedNode.innerHTML = `<div class="row mt-3">
            <div class="col-md-12">
                <div class="ms-2 mb-0 pdf-download-title">${pageObjDetails.companyName}</div>
            </div>
        </div>`;
        combinedNode.appendChild(content.cloneNode(true));
        combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
            btn.style.display = "none";
        });
        if (combinedNode.querySelector(".table-responsive-lg")) {
            combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
                tr.style.pageBreakInside = "avoid";
            });
        }
        combinedNode.querySelectorAll(".me-1")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll("a").forEach(link => {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
        });
        combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
        await html2pdf().set(options).from(combinedNode).save().then(() => {
            setBlurLoader(false);
        });
    }

    const loadImageAsync = (img) => {
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const covertSVGToIMG = async (content) => {
        var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

        for (let i = 0; i < dynamicSvgs.length; i++) {
            dynamicSvgs[i].querySelectorAll("line").forEach(x => {
                x.setAttribute("stroke", "#dddee2");
            });
            dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
                x.style.display = "none";
            });

            var canvas = document.createElement('canvas');
            const scaleFactor = 2;
            canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
            canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

            var context = canvas.getContext('2d');
            context.scale(scaleFactor, scaleFactor);

            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);

            var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
            var img = new window.Image();
            img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

            await loadImageAsync(img);

            const scaleWidthFactor = 1;

            context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

            var imgSrc = canvas.toDataURL('image/jpeg');
            var dynamicImg = document.createElement('img');
            dynamicImg.src = imgSrc;

            let parent = dynamicSvgs[i].parentNode;
            while (parent && parent !== content) {
                parent = parent.parentNode;
            }

            if (parent === content) {
                dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
            }
        }
        return content.innerHTML;
    };

    const visitorItem = (item) => (
        <div
            key={item.id}
            data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
        >
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={item.name}
                hideTitle={true}
            >
                {apiUrl != undefined ?
                    <ChartRendererTable
                        SearchText={SearchText}
                        apiUrl={apiUrl}
                        setheaderFilterData={setheaderFilterData}
                        totalRecords={numberOfRecords}
                        setNumberOfRecords={setNumberOfRecords}
                        // setVisitorId={setVisitorId}
                        setSearchText={setSearchText}
                        // setIsRefresh={setIsRefresh}
                        cardProperty={item.layout}
                        legends={item.legends}
                        items={items1}
                        setItems={setItems1}
                        showPagination={item.pagination}
                        summaryTooltip={t("header.VisitorsAllVisitorsCountHelpTooltipText")}
                        summaryBesideText={t("header.visitorAllBesideText")}
                        openPopupEvent={ModalOperation}
                        setGetTotalResults={setGetTotalResults}
                        Exportdata={setCountryListForFilter}
                        FilterParams={FilterParams}
                    /> : <div></div>}
            </DashboardItem>
        </div>
    );

    return <>
        <Row>
            <Col>
                <ModalComponent
                    title={pageObjDetails.companyName}
                    showCloseButton={true}
                    open={showPopup}
                    handleClose={ModalOperation}
                    className="visitor-analytics-modal"
                >
                    <Row>
                        <Col>
                            <Row className="position-relative hide-mobile"
                                hidden={visitorTabKey == "traffic" ? true : false}
                            >
                                <Col md={12}>
                                    {(
                                        <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                            <Row className="mb-4">
                                                <Col md={12} className="text-end">
                                                    <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                        <span className={pageObjDetails.pageViews > 0 ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"} onClick={() => downloadPageLevelPDF('visitorsContent', 'Insights_Pages_Viewed')}>
                                                            <svg className="icon-nav">
                                                                <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                                <use href={iconSprite + `#Download`} />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="text-end">
                                                    <div className="search-box-container modal-search-box">
                                                        <Search
                                                            setsearchData={setsearchData}
                                                            searchData={searchData}
                                                            searchWaterMark={t("header.visitorAllWatermark")}
                                                            isAutoComplete={true}
                                                            setAutoFillForCompany={setAutoFillForCompanyPopup}
                                                            showAutoFillForCompany={showAutoFillForCompanyPopup}
                                                            data={headerFilterDataPopup}
                                                            type={"client"}
                                                        ></Search>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                            <Row className="position-relative hide-mobile"
                                hidden={visitorTabKey == "visitors" ? true : false}
                            >
                                <Col md={12}>
                                    {(
                                        <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                            <Row className="mb-4">
                                                <Col md={12} className="text-end">
                                                    <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                        <span className={pageObjDetails.pageViews > 0 ? "icon-nav-box hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"} onClick={() => downloadPageLevelPDF('trafficContent', 'Insights_Traffic_Sources')}>
                                                            <svg className="icon-nav">
                                                                <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                                <use href={iconSprite + `#Download`} />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Tab.Container
                                defaultActiveKey="visitors"
                                onSelect={onchangeVistiorTab}
                            >
                                <Nav fill variant="pills" className="tab subtab">
                                    <Nav.Item>
                                        <Nav.Link eventKey="visitors" className="mb-sm-3 mb-md-0" >
                                            {t("header.VisitorsActivityPopPagesTabText")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="traffic" className="mb-sm-3 mb-md-0" >
                                            {t("header.VisitorsActivityPopTrafficTabText")}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="visitors" className="visitorsContent">
                                        {visitorTabKey == "visitors" ? (<Row>
                                            <Col md={12} className="chart-data-renderer">
                                                {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                                                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                                                </div>}
                                                {(alertVisible || blurLoader) && (<div className="fade modal-backdrop show"></div>)}
                                                <Alert
                                                    className="toast-custom"
                                                    variant="success"
                                                    show={alertVisible}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                                            {t("header.PdfDeliveredToMailMessage")}
                                                        </div>
                                                    </div>
                                                </Alert>
                                                <Row className="mt-4">
                                                    <Col md={12}>
                                                        <p>{t("header.visitorAllMessageText1")} {pageObjDetails.companyName} {t("header.visitorAllMessageText2")} {moment(contextData.startDate).format("DD MMM YYYY")} {t("header.visitorAllMessageText3")} {moment(contextData.endDate).format("DD MMM YYYY")}</p>
                                                    </Col>
                                                </Row>
                                                {pageObjDetails.pageViews > 0 ? <>
                                                    <Row className="api-chart">
                                                        <Col md={12}>
                                                            <ApiChartRenderer isLegendShown={false} selectedCategoryId={selectedCategoryId}
                                                                cardName={pageViewedPopupLineConfig.name}
                                                                services={apiChartsVisitorInsightsService} chartType={"line2"} itemId={21}
                                                                cardProperty={pageViewedPopupLineConfig.layout} countryid={pageObjDetails.countryId} visitorid={pageObjDetails.companyId} />
                                                        </Col>
                                                    </Row>
                                                    <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                                        <Col md={12} className="text-end">
                                                            <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                                <span className={pageObjDetails.pageViews > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                                    <Dropdown as={ButtonGroup}>
                                                                        <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                                            <svg className="icon-nav">
                                                                                <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                                <use href={iconSprite + `#Download`} />
                                                                            </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            {setDowloadDropdownMenu()}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col md={12}>
                                                            <ChartRendererTable
                                                                SearchText={searchData}
                                                                apiUrl={apiUrlPopup}
                                                                showCount={false}
                                                                setheaderFilterData={setheaderFilterDataPopup}
                                                                showNumberOfRows={10}
                                                                showPagination={true}
                                                            />
                                                        </Col>
                                                    </Row>

                                                </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}
                                            </Col>
                                        </Row>) : (<div></div>)}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="traffic" className="trafficContent">
                                        {visitorTabKey == "traffic" ? (<Row>
                                            <Col md={12} className="chart-data-renderer">
                                                {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                                                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                                                </div>}
                                                {blurLoader && (<div className="fade modal-backdrop show"></div>)}
                                                <Row className="mt-4">
                                                    <Col md={12}>
                                                        <p>{t("header.visitorAllTrafficMessageText1")} {pageObjDetails.companyName} {t("header.watchlistMessageText")} {moment(contextData.startDate).format("DD MMM YYYY")} {t("header.visitorAllTrafficMessageText2")} {moment(contextData.endDate).format("DD MMM YYYY")}</p>
                                                    </Col>
                                                </Row>
                                                {pageObjDetails.pageViews > 0 ? <>
                                                    <Row className="mb-0 api-chart">
                                                        <Col md={12}>
                                                            <ApiChartRenderer isLegendShown={false} selectedCategoryId={selectedCategoryId}
                                                                services={apiChartsVisitorInsightsService} chartType={"bar2"} itemId={22}
                                                                barChartClickEvent={function (d) { setTrafficSource(d.name); }}
                                                                countryid={pageObjDetails.countryId} visitorid={pageObjDetails.companyId}
                                                                cardProperty={trafficSourcePopupBarConfig.layout} cardName={"allTabTrafficeSource"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-0">
                                                        <Col md={12}>
                                                            {trafficSourceText &&
                                                                <div className="alert alert-gray-800" role="alert">
                                                                    <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {trafficSourceText}</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-0 mt-0">
                                                        <Col md={12} >
                                                            <p className="text-body fw-bold">{trafficSourceTotal} </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col md={12}>
                                                            {(trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") && <ChartRendererTable
                                                                apiUrl={apiUrlPopup2}
                                                                showCount={false}
                                                                showNumberOfRows={10}
                                                                showPagination={true}
                                                            />}
                                                        </Col>
                                                    </Row>
                                                </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}
                                            </Col>
                                        </Row>) : (<div></div>)}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </ModalComponent>
            </Col>
        </Row>
        {blurLoader && <div id='blurdiv' className="blurbackground">
            <div className={`preloader loader-center-align`}>
                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
            </div>
        </div>}
        {visitors.map(deserializeItem).map(visitorItem)}</>;
};

export default withRouter(VisitorAll);
