export const CHART_HEIGHT = 415;
export const DONUT_CHART_HEIGHT_MOBILE = 360;
export const PIE_CHART_HEIGHT = 325;
export const PIE_CHART_HEIGHT_MOBILE = 280;
export const LINE_CHART_HEIGHT = 300;
export const MULTILINE_CHART_HEIGHT = 300;
export const BAR_CHART_HEIGHT = 300;
export const CHART_SIZE = 10;
export const TOOLTIP_HIDE_TIMEOUT = 3000;

export const chartMargin = {
  top: 40,
  right: 30,
  bottom: 90,
  left: 60,
};

export const chartMarginMobile = {
  top: 40,
  right: 35,
  bottom: 85,
  left: 60,
};


export const chartMarginMobileMultiLineChart = {
  top: 10,
  right: 55,
  bottom: 85,
  left: 85,
};

export const chartMarginMobileBarChart = {
  top: 40,
  right: 55,
  bottom: 85,
  left: 50,
};

export const getChartWidth = (clientWidth, margin) => {
  const width = clientWidth - margin.left - margin.right;
  return width;
}

export const getChartHeight = (chartHeight, margin) => {
  const height = chartHeight - margin.top - margin.bottom;
  return height;
}

export const CHART_COLORS_SERIES = [
  '#7C0032',
  '#FF006F',
  '#B30049',
  '#A7AE00',
  '#0CAE86',
  '#11FFCC',
  '#747700'
];

export const doubleBarChartData = '[{"Race":"Data1","HTC":270,"Registry":449},{"Race":"Data 2","HTC":202,"Registry":327},{"Race":"Data 3","HTC":120,"Registry":214},{"Race":"Data 4","HTC":114,"Registry":193},{"Race":"Data 5","HTC":894,"Registry":155},{"Race":"Data 6","HTC":737,"Registry":135}]';

export const multiLineChartData = [
  { year: 2016, media: "Digital", spending: 72.2 },
  { year: 2017, media: "Digital", spending: 90.39 },
  { year: 2018, media: "Digital", spending: 107.3 },
  { year: 2019, media: "Digital", spending: 125.75 },
  { year: 2020, media: "Digital", spending: 142.23 },
  { year: 2021, media: "Digital", spending: 156.43 },
  { year: 2022, media: "Digital", spending: 173},
  { year: 2016, media: "TV", spending: 71.29 },
  { year: 2017, media: "TV", spending: 70.22 },
  { year: 2018, media: "TV", spending: 69.87 },
  { year: 2019, media: "TV", spending: 69.17 },
  { year: 2020, media: "TV", spending: 69.52 },
  { year: 2021, media: "TV", spending: 68.82 },
  { year: 2022, media: "TV", spending: 68.13 },
  { year: 2016, media: "Print", spending: 25.49 },
  { year: 2017, media: "Print", spending: 22.81 },
  { year: 2018, media: "Print", spending: 20.05 },
  { year: 2019, media: "Print", spending: 17.29 },
  { year: 2020, media: "Print", spending: 15.19 },
  { year: 2021, media: "Print", spending: 13.56 },
  { year: 2022, media: "Print", spending: 12.38 },
  { year: 2016, media: "Radio", spending: 14.33 },
  { year: 2017, media: "Radio", spending: 14.33 },
  { year: 2018, media: "Radio", spending: 14.41 },
  { year: 2019, media: "Radio", spending: 14.43 },
  { year: 2020, media: "Radio", spending: 14.46 },
  { year: 2021, media: "Radio", spending: 14.49 },
  { year: 2022, media: "Radio", spending: 14.52 },
  { year: 2016, media: "Out-of-home", spending: 7.6 },
  { year: 2017, media: "Out-of-home", spending: 7.75 },
  { year: 2018, media: "Out-of-home", spending: 7.87 },
  { year: 2019, media: "Out-of-home", spending: 7.95 },
  { year: 2020, media: "Out-of-home", spending: 8.03 },
  { year: 2021, media: "Out-of-home", spending: 8.11 },
  { year: 2022, media: "Out-of-home", spending: 8.19 },
  { year: 2016, media: "Directories", spending: 2.35 },
  { year: 2017, media: "Directories", spending: 1.83 },
  { year: 2018, media: "Directories", spending: 1.47 },
  { year: 2019, media: "Directories", spending: 1.19 },
  { year: 2020, media: "Directories", spending: 0.99 },
  { year: 2021, media: "Directories", spending: 0.84 },
  { year: 2022, media: "Directories", spending: 0.74 }
];

export const multiLineChartDt = [
  {
    name: "USA",
    values: [
      {date: "2000", price: "100"},
      {date: "2001", price: "110"},
      {date: "2002", price: "145"},
      {date: "2003", price: "241"},
      {date: "2004", price: "101"},
      {date: "2005", price: "90"},
      {date: "2006", price: "10"},
      {date: "2007", price: "35"},
      {date: "2008", price: "21"},
      {date: "2009", price: "201"}
    ]
  },
  {
    name: "Canada",
    values: [
      {date: "2000", price: "200"},
      {date: "2001", price: "120"},
      {date: "2002", price: "33"},
      {date: "2003", price: "21"},
      {date: "2004", price: "51"},
      {date: "2005", price: "190"},
      {date: "2006", price: "120"},
      {date: "2007", price: "85"},
      {date: "2008", price: "221"},
      {date: "2009", price: "101"}
    ]
  },
  {
    name: "Maxico",
    values: [
      {date: "2000", price: "50"},
      {date: "2001", price: "10"},
      {date: "2002", price: "5"},
      {date: "2003", price: "71"},
      {date: "2004", price: "20"},
      {date: "2005", price: "9"},
      {date: "2006", price: "220"},
      {date: "2007", price: "235"},
      {date: "2008", price: "61"},
      {date: "2009", price: "10"}
    ]
  }
];