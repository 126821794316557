import React, { useState, useEffect,useContext,useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Form, Image, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { isEmpty, BadgetsVarients, MAX_CHARACTERS_FOR_TEXT_AREA } from "../AppConfig";
import ButtonComponent from "../components/Buttons";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { withRouter } from "react-router";
import { DELETE_DASHBOARD_ITEM } from '../graphql/mutations';
import RGL, { WidthProvider } from 'react-grid-layout';
import AppContext from '../components/AppContext';
import Loader from '../components/VoltLoader';
import { object_equals } from "../AppConfig";
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const NotificationComponent = ({
  children,handleButtonClick,intellConfigId,setIntellConfigId,isFormSubmittedSucessfully,isRefresh, setIsRefresh,selectedProfile,setSelectedProfile,
}) => {
    const [t, i18n] = useTranslation();
    const contextData =useContext(AppContext);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [selectedWebsiteIdNew,setSelectedWebsiteIdNew] = useState("");
    const [selectedProfileId, setSelectedProfileId] = useState({});
    const [selectedCompany, setSelectedCompany] = useState({});
    const [visitorData, setVisitorData] = useState({});
    const [updateKey, setUpdateKey] = useState(0);
    const [autoLoad,setAutoLoad] = useState(false);
    const [profilesList,setProfilesList] = useState([]);
    const [selectedProfileIdNew,setSelectedProfileIdNew]=useState("")
    const [blurLoader, setBlurLoader] = useState(false);
    useEffect(() => {
      if(intellConfigId!=0 && (visitorData != null || (visitorData != null && visitorData.length===undefined)))
      {
        GetVisitors();
      }
   
  }, [intellConfigId,selectedCompany,isFormSubmittedSucessfully]);
  useEffect(()=>{

    if(profilesList != null && profilesList.length){
      let profilesData = profilesList.filter((e)=>{ return e.WebsiteId === selectedWebsiteIdNew["WebsiteList.WebsiteId"]}); 
      let profileDataUpdated = profilesData.map((e)=>({["WebsiteProfiles.ProfileId"]:e.ProfileId,["WebsiteProfiles.DisplayName"]:e.DisplayName,["WebsiteProfiles.IntelConfigId"]:e.IntelConfigId}));
      if(selectedWebsiteIdNew["WebsiteList.WebsiteId"]){
        renderWebsiteProfiles({resultSet:profileDataUpdated})
      }
      
    }
 
},[selectedWebsiteIdNew["WebsiteList.WebsiteId"],profilesList])
useEffect(() => {
  localStorage.setItem("websiteData",JSON.stringify([]))
  localStorage.setItem("profileData",JSON.stringify([]))
  getWebsites();
  //setSelectedProfileIdNew({})
}, [contextData.userId,contextData.companyId]);

const getWebsites = () => {
  setBlurLoader(true);
  request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
          let resultData = [];
          if(res.result !== null && res.result.length){
            resultData=res.result.map((e)=>({cmsplatformid:res.platformid,["WebsiteList.WebsiteName"]:e.WebsiteName,["WebsiteList.WebsiteId"]:e.WebsiteId,["WebsiteList.WebsiteUrl"]:e.WebsiteUrl}))
          }
        rendersite({resultSet:resultData})
      })
      .catch((err) => {
        
      })
      
      request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
          setProfilesList(res.result)
      })
      .catch((err) => {
        
      })
}
  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileId({});
      //localStorage.setItem("WebsiteProfilesIntelConfigId", "");
    }
    const websiteId = data["WebsiteList.WebsiteId"];
    //contextData.changeWebsiteId(websiteId);
    //contextData.changeSelectedWebsite(data);
    //setSelectedWebsiteIdNew(data)
    setSelectedWebsiteIdNew(data);
    
    setUpdateKey(JSON.stringify(data))
  };
    const onProfileDropdownChange = (data) => {
        const intellConfigIdValue = data["WebsiteProfiles.IntelConfigId"];
        //setIntellConfigId(intellConfigIdValue);
        //contextData.intelConfigId=intellConfigIdValue;
        //GetVisitors();
        //setVisitorData({});
         contextData.changeSelectedProfile(data)
       // contextData.changeIntelConfigId(intellConfigId);
        setSelectedProfileId(data);
        
      };
    function GetVisitors()
    {
      let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
        axios
        .get(
            `${process.env.REACT_APP_API_KEY}/Website/GetIntelligenceVisitors`,  {
              params: {
                userId: contextData.userId,
                intellConfigId:intellConfigId
              },
              headers: {
                'Authorization': `Bearer ${token}`
              }
          }, )
        .then(response => {
            setVisitorData(response.data.companyCountry);
            contextData.visitorData=response.data.companyCountry;
            setIsRefresh(true);
        })
        .catch(error => {

        });
 
    
    
    }
    const applyFilters = ()=>{
      setBlurLoader(false);
      contextData.changeWebsiteId(selectedWebsiteIdNew["WebsiteList.WebsiteId"])
      setSelectedWebsiteId(selectedWebsiteIdNew)
      setSelectedProfileId(contextData.selectedProfile);
      setIntellConfigId(selectedProfileId["WebsiteProfiles.IntelConfigId"]);
      localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));
      localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(contextData.selectedProfile));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
      GetVisitors();
      //setVisitorData({});
      //contextData.changeSelectedProfile(data)
      //contextData.changeSelectedProfile(localStorage.getItem(JSON.parse("IntelConfigId")));
      //contextData.changeSelectionsText("werwq");
      //return "Website: "+contextData.selectedWebsite["WebsiteList.WebsiteName"]+" | Website profile: "+contextData.selectedProfile["WebsiteProfiles.DisplayName"]
       //return contextData.selectedWebsite["WebsiteList.WebsiteName"]+" - "+contextData.selectedProfile["WebsiteProfiles.DisplayName"]
      }
      const cancelFilters = ()=>{
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
        }
        const compareItem = (items,compareItem)=>{
          if(items.length > 0){
            for(let i=0; i<=items.length; i++){
              if(object_equals(items[i],compareItem)){
                return true;
              }
            }
          }
        }
    //   const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    //     if (error) {
    //       return <div>{error.toString()}</div>;
    //     }
    
    //     if (!resultSet) {
    //       return <Loader show={false} />;
    //     }
    //     localStorage.setItem("websiteData",JSON.stringify([]))
    //     if( resultSet !== null && resultSet.tablePivot(pivotConfig).length && Object.keys(selectedWebsiteIdNew).length <= 0){
    //       if(localStorage.getItem("WebsiteListWebsiteId")){
    //         onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    //       }else{
    //         onWebsiteDropdownChange(resultSet.tablePivot(pivotConfig)[0])
    //         contextData.changeSelectedWebsite(resultSet.tablePivot(pivotConfig)[0]);
    //         setSelectedWebsiteIdNew(resultSet.tablePivot(pivotConfig)[0])
    //         //contextData.siteData = resultSet.tablePivot(pivotConfig);  
    //       }
                  
          
    //     }
    //     if( resultSet !== null && resultSet.tablePivot(pivotConfig).length && resultSet.tablePivot(pivotConfig).length){
    //       localStorage.setItem("websiteData",JSON.stringify(resultSet.tablePivot(pivotConfig)))
    //       //contextData.siteData = resultSet.tablePivot(pivotConfig);
    //       setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
    //     }
        
    //     return (
    //       <>
    //         {/* <DropdownWithIconComponent
    //           dropdownData={resultSet.tablePivot(pivotConfig)}
    //           arrayValue="WebsiteList.WebsiteName"
    //           selectedDropdown={selectedWebsiteId}
    //           onChange={onWebsiteDropdownChange}
    //           showLabel={false}
    //           showIconInDropdownItem={false}
    //           customClassName="full-width text-overflow"
    //         /> */}
    //       </>
    //     );
    
    //   };
    //   const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
    //     if (error) {
    //       return <div>{error.toString()}</div>;
    //     }
    
    //     if (!resultSet) {
    //       return <Loader show={false} />;
    //     }
    //     localStorage.setItem("profileData",JSON.stringify([]))
    //     if(resultSet !== null && resultSet.tablePivot(pivotConfig).length > 0 && selectedProfileId !== null && Object.keys(selectedProfileId).length <= 0){
    //       setTimeout(()=>{
    //         if(compareItem(resultSet.tablePivot(pivotConfig),JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))){
    //           onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
    //           setAutoLoad(true)  
    //         }else{
    //           onProfileDropdownChange(resultSet.tablePivot(pivotConfig)[0])
    //         //setIntellConfigId(resultSet.tablePivot(pivotConfig)[0]["WebsiteProfiles.IntelConfigId"]);
    //         //contextData.intelConfigId=resultSet.tablePivot(pivotConfig)[0]["WebsiteProfiles.IntelConfigId"];
    //         //setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0])

    //         setAutoLoad(true)  
    //         //setUpdateKey(Math.random())
    //         }
            
            
    //             },1000)
    //       // onProfileDropdownChange(resultSet.tablePivot(pivotConfig)[0])
    //       // setIntellConfigId(resultSet.tablePivot(pivotConfig)[0]["WebsiteProfiles.IntelConfigId"]);
    //       // contextData.intelConfigId=resultSet.tablePivot(pivotConfig)[0]["WebsiteProfiles.IntelConfigId"];
    //       // setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0])
    //       // applyFilters();
    //     }
    //     if(resultSet !== null && resultSet.tablePivot(pivotConfig).length){
    //       localStorage.setItem("profileData",JSON.stringify(resultSet.tablePivot(pivotConfig)))
    //       setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
    //       //setAutoLoad(true)
    //     }else{
    //       setAutoLoad(true)
    //     }
        
    //     //  setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0]);
    //    // setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0]);
    //     return (
    //       // <DropdownComponent
    //       //   dropdownData={resultSet.tablePivot(pivotConfig)}
    //       //   arrayKey="WebsiteProfiles.AccountId"
    //       //   arrayValue="WebsiteProfiles.DisplayName"
    //       //   dropdownValue={profileId}
    //       //   onChange={setProfileId}
    //       //   showLabel={false}
    //       // />
    
    //       // <DropdownWithIconComponent
    //       //   dropdownData={resultSet.tablePivot(pivotConfig)}
    //       //   arrayValue="WebsiteProfiles.DisplayName"
    //       //   selectedDropdown={Object.keys(selectedProfileId).length > 0 ? selectedProfileId : resultSet.tablePivot(pivotConfig)[0]}
            
    //       //   onChange={onProfileDropdownChange}
    //       //   showLabel={false}
    //       //   showIconInDropdownItem={false}
    //       //   customClassName="full-width text-overflow"
    //       // />
    // <></>
    //     );
    
    //   };
    const rendersite = ({ resultSet, error, pivotConfig, request }) => {

      // if (error) {
      //   return <div>{error.toString()}</div>;
      // }
    
      // if (!resultSet) {
      //   return <Loader show={false} />;
      // }
    
      localStorage.setItem("websiteData", JSON.stringify([]))
      localStorage.setItem("websiteData", JSON.stringify(resultSet))
      if (resultSet !== null && resultSet.length) {
        setUpdateKey(JSON.stringify(resultSet))
    
        if (resultSet !== null && resultSet.length && selectedWebsiteIdNew !== undefined && Object.keys(selectedWebsiteIdNew).length <= 0) {
          if (localStorage.getItem("WebsiteListWebsiteId")) {
            onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
          } else {
            onWebsiteDropdownChange(resultSet[0])
          }
    
        }
        
      }
    
    
      return (
    
        <>
    
    
        </>
      );
    
    };
    
      
    //   const rendersite = (resultSet) => {
    
    //     // if(resultSet !==null || resultSet !== undefined){
    //     // }
        
      
        
    //     // if (error) {
    //     //   return <div>{error.toString()}</div>;
    //     // }
    
    //     // if (!resultSet) {
    //     //   return <Loader show={false} />;
    //     // }
        
    //     localStorage.setItem("websiteData",JSON.stringify([]))
    //     localStorage.setItem("websiteData",JSON.stringify(resultSet))
    //     if(resultSet !== null && resultSet.length>0){
    //       //setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
    //     //contextData.websiteData = resultSet.tablePivot(pivotConfig)
    //     if(selectedWebsiteIdNew!==undefined && Object.keys(selectedWebsiteIdNew).length<=0){
    //       if(localStorage.getItem('WebsiteListWebsiteId')){
    //         onWebsiteDropdownChange(JSON.parse(localStorage.getItem('WebsiteListWebsiteId')))
    //       }else{
    //         onWebsiteDropdownChange(resultSet[0])
    //       }
    //     }
     
        
    //     }
        
    //     //setWebsiteData(resultSet.tablePivot(pivotConfig))
    
    //     return (
    //       // <DropdownComponent
    //       //   dropdownData={resultSet.tablePivot(pivotConfig)}
    //       //   arrayKey="WebsiteList.WebsiteId"
    //       //   arrayValue="WebsiteList.WebsiteName"
    //       //   dropdownValue={websiteId}
    //       //   onChange={setWebsiteId}
    //       //   showLabel={false}
    //       // />
    //       <>
    //         {/* <DropdownWithIconComponent
    //           dropdownData={resultSet.tablePivot(pivotConfig)}
    //           arrayValue="WebsiteList.WebsiteName"
    //           selectedDropdown={selectedWebsiteId}
    //           onChange={onWebsiteDropdownChange}
    //           showLabel={false}
    //           showIconInDropdownItem={false}
    //           customClassName="full-width text-overflow"
    //         /> */}
    
    //       </>
    //     );
    
    //   };
    
      const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
      
        // if (error) {
        //   return <div>{error.toString()}</div>;
        // }
    
        // if (!resultSet) {
        //   return <Loader show={false} />;
        // }
        localStorage.setItem("profileData",JSON.stringify([]))
        if(resultSet !== undefined && resultSet.length && Object.keys(selectedProfileIdNew).length<=0){
          //setTimeout(()=>{
            if(compareItem(resultSet,JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"))) === true){
            
             onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
             //setSelectedProfileIdNew(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
             setAutoLoad(true)
          
            }else{
    
              onProfileDropdownChange(resultSet[0])
              //contextData.changeSelectedProfile(resultSet.tablePivot(pivotConfig)[0]);
              setSelectedProfileIdNew(resultSet[0]);
              setAutoLoad(true)
            }
     
         //},3000)
         
        }
        
        if(resultSet !== undefined && resultSet.length){
          localStorage.setItem("profileData",JSON.stringify(resultSet))
          setUpdateKey(JSON.stringify(resultSet))
          // if(Object.keys(selectedProfileIdNew).length){
          //   setAutoLoad(true)
          // }
          
          
        }else if(resultSet !== undefined  && resultSet.length <=0){
          setUpdateKey(JSON.stringify(resultSet))
          setAutoLoad(true)
        }else{
          
        }
        return (<></>);
    
      };
    // const rendersite = ({ resultSet, error, pivotConfig, request }) => {
  
    //   if (error) {
    //     return <div>{error.toString()}</div>;
    //   }
  
    //   if (!resultSet) {
    //     return <Loader show={false} />;
    //   }
  
    //   localStorage.setItem("websiteData", JSON.stringify([]))
    //   if (resultSet !== null && resultSet.tablePivot(pivotConfig).length) {
    //     setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
  
    //     if (resultSet !== null && resultSet.tablePivot(pivotConfig).length && selectedWebsiteIdNew !== undefined && Object.keys(selectedWebsiteIdNew).length <= 0) {
    //       if (localStorage.getItem("WebsiteListWebsiteId")) {
    //         onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    //       } else {
    //         onWebsiteDropdownChange(resultSet.tablePivot(pivotConfig)[0])
    //       }
  
    //     }
    //     localStorage.setItem("websiteData", JSON.stringify(resultSet.tablePivot(pivotConfig)))
    //   }
  
  
    //   return (
  
    //     <>
  
  
    //     </>
    //   );
  
    // };
    const cubejsApi = cubejs(
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
        { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
      );
const commonHeader = useMemo(()=><CommonHeader cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
  {name:'Dropdown1 - website',data:JSON.parse(localStorage.getItem("websiteData")),selectedItem:selectedWebsiteIdNew,isVisible:true,label:t("header.filterLabelWebsite"),callback:onWebsiteDropdownChange,displayText:"WebsiteList.WebsiteName"},
  {name:'Dropdown2 - website profile',data:JSON.parse(localStorage.getItem("profileData")),selectedItem:selectedProfileId,isVisible:true,label:t("header.filterLabelProfile"),callback:onProfileDropdownChange,displayText:"WebsiteProfiles.DisplayName"},
   ]} title={t("header.WONotificationsTitle")} showDate={false} applyFilters={applyFilters} />,[selectedWebsiteIdNew,selectedProfileId,updateKey,autoLoad]);
   
// const profileQuery = useMemo(()=><QueryRenderer
// query={{
//   "dimensions": [
//     "WebsiteProfiles.ProfileId",
//     "WebsiteProfiles.DisplayName",
//     "WebsiteProfiles.IntelConfigId"
//   ],
//   "timeDimensions": [],
//   "order": {
//     "WebsiteProfiles.DisplayName": "asc"
//   },
//   "filters": [
//     {
//       "member": "WebsiteProfiles.WebsiteId",
//       "operator": "equals",
//       "values": [`${selectedWebsiteId["WebsiteList.WebsiteId"]}`]
//     },
//     {
//       "member": "WebsiteProfiles.CompanyId",
//       "operator": "equals",
//       "values": [`${contextData.companyId}`]
//     },
//   ],
// }}
// cubejsApi={cubejsApi}
// resetResultSetOnChange={true}
// render={(props) => renderWebsiteProfiles({
//   ...props,
//   chartType: 'dropdown',
//   pivotConfig: {
//     "x": [
//       "WebsiteProfiles.ProfileId",
//       "WebsiteProfiles.DisplayName",
//       "WebsiteProfiles.IntelConfigId"

//     ],
//     "y": ["WebsiteProfiles.DisplayName"],
//     "fillMissingDates": true,
//     "joinDateRange": false
//   }
// })}
// />,[selectedWebsiteId]);
    return (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
        <Row>
            <Col xs={11} sm={6} xl={8}>
                <h4 className="h2 mb-3 ps-3">{t("header.WONotificationsTitle")}</h4>
            </Col>
            <Col xs={1} className="text-end">
            <a className="mt-2 d-inline-block" onClick={(e) =>handleButtonClick(e)}>
               <FontAwesomeIcon
                icon={faPlusCircle} className="fa-lg" /> </a>
            </Col>
            <Col xs={12} sm={12} xl={12} className="mb-4 notification-card">
            <div className="notification-header-dropdown">
            {/* <QueryRenderer
                  query={{
                    "dimensions": [
                      "WebsiteList.WebsiteId",
                      "WebsiteList.WebsiteName",
                      "WebsiteList.WebsiteUrl"
                    ],
                    "timeDimensions": [],
                    "order": {
                      "WebsiteList.WebsiteId": "asc"
                    },
                    "filters": [
                      {
                        "member": "WebsiteList.CompanyId",
                        "operator": "equals",
                        "values": [`${contextData.companyId}`]
                      },
                      {
                        "member": "WebsiteList.UserId",
                        "operator": "equals",
                        "values": [`${contextData.userId}`]
                      }
                    ],
                  }}
                  cubejsApi={cubejsApi}
                  resetResultSetOnChange={false}
                  render={(props) => rendersite({
                    ...props,
                    chartType: 'dropdown',
                    pivotConfig: {
                      "x": [
                        "WebsiteList.WebsiteId",
                        "WebsiteList.WebsiteName",
                        "WebsiteList.WebsiteUrl"

                      ],
                      "y": ["WebsiteList.WebsiteName"],
                      "fillMissingDates": true,
                      "joinDateRange": false
                    }
                  })}
                /> */}
                {/* {profileQuery} */}
                {commonHeader}
                 {/* {(selectedWebsiteIdNew["WebsiteList.WebsiteId"]!=undefined) && 
                <QueryRenderer
// query={{
//   "dimensions": [
//     "WebsiteProfiles.ProfileId",
//     "WebsiteProfiles.DisplayName",
//     "WebsiteProfiles.IntelConfigId"
//   ],
//   "timeDimensions": [],
//   "order": {
//     "WebsiteProfiles.DisplayName": "asc"
//   },
//   "filters": [
//     {
//       "member": "WebsiteProfiles.WebsiteId",
//       "operator": "equals",
//       "values": [`${selectedWebsiteIdNew["WebsiteList.WebsiteId"]}`]
//     },
//     {
//       "member": "WebsiteProfiles.CompanyId",
//       "operator": "equals",
//       "values": [`${contextData.companyId}`]
//     },
//   ],
// }}
// cubejsApi={cubejsApi}
// resetResultSetOnChange={true}
// render={(props) => renderWebsiteProfiles({
//   ...props,
//   chartType: 'dropdown',
//   pivotConfig: {
//     "x": [
//       "WebsiteProfiles.ProfileId",
//       "WebsiteProfiles.DisplayName",
//       "WebsiteProfiles.IntelConfigId"

//     ],
//     "y": ["WebsiteProfiles.DisplayName"],
//     "fillMissingDates": true,
//     "joinDateRange": false
//   }
// })}
// />} */}
            </div>
        </Col>
        </Row>
        
        
        <Row>
            <Col>
            <ReactGridLayout cols={12} rowHeight={50}
        isDraggable={false} isResizable={false}
        // onLayoutChange={onLayoutChange}
      >
        
        {/* <CommonHeader dataSource={[
         {name:'Dropdown1 - website',data:JSON.parse(localStorage.getItem("websiteData")),selectedItem:contextData.selectedWebsite,isVisible:true,label:'Webiste',callback:onProfileDropdownChange,displayText:"WebsiteProfiles.DisplayName"},
         {name:'Dropdown2 - website profile',data:JSON.parse(localStorage.getItem("profileData")),selectedItem:contextData.selectedProfile,isVisible:true,label:'Webiste profile',callback:onProfileDropdownChange,displayText:"WebsiteProfiles.DisplayName"},
          ]} title={"Notifications"} showDate={false} applyFilters={applyFilters} /> */}
        { visitorData !=undefined && visitorData.length!=undefined &&
        children}
      </ReactGridLayout>
      </Col>
        </Row>

</>
    );

};

NotificationComponent.propTypes = {
    handleButtonClick: PropTypes.func

};

NotificationComponent.defaultProps = {
    handleButtonClick: null

};

export default NotificationComponent;
