import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Loader from '../components/VoltLoader';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import FormComponent from '../components/Forms';
import { hobbiesList } from '../stubs/forms';
import {
    isEmpty, isLessThanMinLength,
    isGreaterThanMaxLength, isValueValid,
    EMAIL_PATTERN, ALPHABETS_NUMBER_PATTERN, PASSWORD_PATTERN,
    AlertVarients, TIMEOUT_FOR_ALERTS, fileUploadExtensions, FILE_UPLOADER_MAX_SIZE
} from "../AppConfig";
import AlertComponent from '../components/Alerts';

const FormsPage = ({ history, location }) => {
    const [maxCharacters, setMaxCharacters] = useState(0);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        address: '',
        address1: '',
        gender: '',
        dob: '',
        upload: {},
        hobbies: [],
        confirm: false,
        toggle: false,
    });
    const [formError, setFormError] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        address: '',
        address1: '',
        gender: '',
        dob: '',
        upload: '',
        hobbies: ''
    });
    const [hobbiesData, setHobbiesData] = useState([]);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [alertVarient, setAlertVarient] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const myRef = createRef();


    useEffect(() => {
        if (hobbiesList.length > 0) {
            let hobbData = [];
            hobbiesList.forEach((dta, index) => {
                let hobbieItm = { ...dta };
                // hobbieItm.isChecked = false;
                hobbData.push(hobbieItm);
            });
            setHobbiesData(hobbData);
        }

    }, [hobbiesList]);

    const validateFormData = (name, value) => {
        let errorObj = formError;
        switch (name) {
            case "username":
                errorObj.username = "";
                if (isEmpty(value)) {
                    errorObj.username = "Please enter username";
                }
                else if (isLessThanMinLength(value, 2)) {
                    errorObj.username = "Username should be more than 2 characters";
                }
                else if (isGreaterThanMaxLength(value, 10)) {
                    errorObj.username = "Username should be less than 10 characters";
                }
                else if (!isValueValid(value, ALPHABETS_NUMBER_PATTERN)) {
                    errorObj.username = "Username should be Alpabets and number without space";
                }
                break;
            case "email":
                errorObj.email = "";
                if (isEmpty(value)) {
                    errorObj.email = "Please enter Email";
                }
                else if (isLessThanMinLength(value, 2)) {
                    errorObj.email = "Username should be more than 2 characters";
                }
                else if (isGreaterThanMaxLength(value, 50)) {
                    errorObj.username = "Username should be less than 50 characters";
                }
                else if (!isValueValid(value, EMAIL_PATTERN)) {
                    errorObj.email = "Email is invalid";
                }
                break;
            case "password":
                errorObj.password = "";
                if (isEmpty(value)) {
                    errorObj.password = "Please enter Password";
                }
                else if (!isValueValid(value, PASSWORD_PATTERN)) {
                    errorObj.password = "Please choose a password with minimum 8 and maximum 15 characters, containing at least one upper case letter, one lower case letter, one number and one special character [ !@#$%^&*() ]";
                }

                break;
            case "confirmPassword":
                errorObj.confirmPassword = "";
                if (isEmpty(value)) {
                    errorObj.confirmPassword = "Please enter Retype Password";
                }
                else if (value != formData.password) {
                    errorObj.confirmPassword = "Password and Retype Password should be same";
                }
                break;
            case "address":
                errorObj.address = "";
                if (isEmpty(value)) {
                    errorObj.address = "Please enter address";
                }
                else if (isLessThanMinLength(value, 10)) {
                    errorObj.address = "Address should be more than 10 characters";
                }
                break;
            case "address1":
                errorObj.address1 = "";
                if (isEmpty(value)) {
                    errorObj.address1 = "Please enter address";
                }
                else if (isLessThanMinLength(value, 10)) {
                    errorObj.address1 = "Address should be more than 10 characters";
                }
                break;
            case "gender":
                errorObj.gender = "";
                if (isEmpty(value)) {
                    errorObj.gender = "Please select gender";
                }
                break;

            case "dob":
                errorObj.dob = "";
                if (isEmpty(value)) {
                    errorObj.dob = "Please select Date of birth";
                }
                break;

            case "hobbies":
                errorObj.hobbies = "";
                if (Array.isArray(value) && value.length === 0) {
                    errorObj.hobbies = "Please select Hobbies";
                }
                break;
            case "upload":
                errorObj.upload = "";
                let fileExtensionIndex = -1;
                if (value && Object.keys(value).length > 0) {
                    const fileExt = value.name.split(".")[1];
                    fileExtensionIndex = fileUploadExtensions.indexOf(fileExt);
                }

                if (value && Object.keys(value).length === 0) {
                    errorObj.upload = "Please select file to upload";
                }
                else if (fileExtensionIndex == -1) {
                    errorObj.upload = "File uploaded is invalid.Please select PDF,Jpeg,Jpg or Png files";
                }
                else if(value.size > FILE_UPLOADER_MAX_SIZE){
                    errorObj.upload = "File size should be less than or equal to 2 mb";
                }
                break;
        }
        setFormError(errorObj);
    }

    const isFormFieldsValid = () => {
        const errorObj = formError;
        let isFieldValid = true;

        for (let fieldName in errorObj) {
            validateFormData(fieldName, formData[fieldName]);
            if (errorObj[fieldName].length > 0) {
                isFieldValid = false;
            }
        }

        return isFieldValid;

    };

    const scrollToTop = () => {
        // myRef.current.scrollTo(0, 0);
        // myRef.current.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }

    const onButtonClose = () => {
        setAlertVarient("");
        setAlertMessage("");
    };

    const onSubmitButonClick = (e) => {
        e.preventDefault();
        setIsFormSubmit(true);

        if (isFormFieldsValid()) {
            scrollToTop();
            const message = "Form successfully submitted";
            const variant = AlertVarients["success"];
            setAlertVarient(variant);
            setAlertMessage(message);

            setTimeout(() => {
                onButtonClose();
            }, [TIMEOUT_FOR_ALERTS]);
        }
        else {
            scrollToTop();
            const message = "Form has error";
            const variant = AlertVarients["error"];
            setAlertVarient(variant);
            setAlertMessage(message);
            setTimeout(() => {
                onButtonClose();
            }, [TIMEOUT_FOR_ALERTS]);
        }
    };

    return (
        <>
            {
                (!isEmpty(alertVarient) && !isEmpty(alertMessage)) &&
                <AlertComponent variantName={alertVarient}
                    message={alertMessage} showCloseButton={true}
                    onButtonClose={onButtonClose}
                />
            }
            <div ref={myRef}>
                <FormComponent
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    validateFormData={validateFormData}
                    isFormSubmit={isFormSubmit}
                    hobbiesData={hobbiesData}
                    onSubmit={onSubmitButonClick}
                    maxCharacters={maxCharacters}
                    setMaxCharacters={setMaxCharacters}
                />
            </div>
        </>

    );

};

FormsPage.propTypes = {
    hobbiesList: PropTypes.array,


};
FormsPage.defaultProps = {
    hobbiesList: []


}

export default withRouter(FormsPage);