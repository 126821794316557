export const eventCalender = (companyId, userId, websiteId, languagecode, year, categoryId, offset) => {
    let eventCalender = [
        {
            isLegendShown: false,
            isResultShownFromApi: true,
            chartType: "table",
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/EventCalendar/GetEventListByClient?CompanyId=${companyId}&UserId=${userId}&WebsiteId=${websiteId}&LanguageCode=${languagecode}&year=${year}&categoryId=${categoryId}`, type: 'get', count: 0 },
            id: "1",
            // layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
            layout: "{\"x\":0,\"y\":24,\"w\":12,\"h\":26}",
            name: "View and manage the events available on the events calendar. View existing events according to the language. Filter events based on the year and the event category. Edit existing events and attachments from the actions column.",
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"EventsList.TotalEvents\"],\"filters\":[{\"member\": \"EventsList.Clientid\", \"operator\": \"equals\", \"values\": [\"${clientid}\"]},{\"member\": \"EventsList.Languagecode\", \"operator\": \"equals\", \"values\": [\"${languagecode}\"]}],\"timeDimensions\":[{\"dimension\": \"EventsList.Startdate\"}],\"order\":{},\"dimensions\":[]}}` },
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"EventsList.TotalEvents\"],\"filters\":[{\"member\": \"EventsList.Clientid\", \"operator\": \"equals\", \"values\": [\"${clientid}\"]},{\"member\": \"EventsList.Languagecode\", \"operator\": \"equals\", \"values\": [\"${languagecode}\"]}],\"timeDimensions\":[{\"dimension\": \"EventsList.Startdate\"}],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"EventsList.Startdate\",\"EventsList.Enddate\",\"EventsList.Utcoffset\",\"EventsList.Descriptions\",\"EventsList.Eventstatus\",\"EventsList.Categories\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"EventsList.Descriptions\":\"asc\"},\"filters\":[{\"member\": \"EventsList.Title\", \"operator\": \"set\"},{\"member\": \"EventsList.Clientid\", \"operator\": \"equals\", \"values\": [\"${clientid}\"]},{\"member\": \"EventsList.Languagecode\", \"operator\": \"equals\", \"values\": [\"${languagecode}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"EventsList.Startdate\",\"EventsList.Enddate\",\"EventsList.Utcoffset\",\"EventsList.Descriptions\",\"EventsList.Eventstatus\",\"EventsList.Categories\"]}}`
        }
    ];

    return eventCalender;
};