import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import ButtonComponent from "../../components/Buttons";
function SocialMediaMarketingPages() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12} xl={12}>
                <h2 className="h2 mb-0">{t("header.SocialMediaMarketingTitle")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                       
                    <p className="mb-4"> {t("header.SocialMediaMarketingContent2")}</p>
                    <Row>
                        <Col md={6}>
                        <h5 className="h5">
                            {t("header.SocialMediaMarketingContent1")}
                        </h5>

                        <p className="mt-2 mb-1"> {t("header.SocialMediaMarketingContent3")} </p>
                        <p className="mb-3"> {t("header.SocialMediaMarketingContent4")} </p>
                        
                        </Col>
                        <Col md={6}>
                        <h5 className="h5">
                             {t("header.SocialMediaMarketingContent6")}
                            </h5>
                        <p className="mt-2 mb-1"> {t("header.SocialMediaMarketingContent7")} </p>
                        <p className="mb-3"> {t("header.SocialMediaMarketingContent8")} </p>

                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                        <ButtonComponent
                                    isIcon={false}
                                    isPrimary={true}
                                    btnText={t("header.SocialMediaMarketingContent5")}
                                    dissable={true}
                                />
                        </Col>
                        <Col md={6}>
                        <ButtonComponent
                                    isIcon={false}
                                    isPrimary={true}
                                    btnText={t("header.SocialMediaMarketingContent9")}
                                    dissable={true}
                                />
                        </Col>
                    </Row>
                        
                        
                        <p className="mt-4 mb-0">
                            <a href="#" className="link-primary">{t("header.SocialMediaMarketingContent10")}</a> {t("header.SocialMediaMarketingContent11")}  {t("header.SocialMediaMarketingContent12")} {t("header.SocialMediaMarketingContent13")}
                        </p>
                       
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SocialMediaMarketingPages