import React, { useState, useEffect, useContext, useMemo } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Row, Col, Form, InputGroup, Button } from '@themesberg/react-bootstrap';
import Loader from '../components/VoltLoader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { object_equals } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import AppContext from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const Useranalytics = ({ setPageNo, setOffset, children, handleBackButtonClick,
  selectedProfileId, setSelectedProfileId, selectedWebsiteId, setSelectedWebsiteId,
  startDate, endDate, setSelectedSectionId }) => {
  const contextData = useContext(AppContext);
  // const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  // const [datePickerValue, setDatePickerValue] = useState("");
  // const [selectedDatePicker, setSelectedDatePicker] = useState({});
  // const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
  // const [companyIdValue, setcompanyIdValue] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  // const [updateKey, setUpdateKey] = useState("");
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState({})
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState({});
  // const [toggle, setToggle] = useState(false);
  const [t, i18n] = useTranslation();
  const [profilesList, setProfilesList] = useState([]);
  const [websiteList, setWebsiteList] = useState([]);
  // const [blurLoader, setBlurLoader] = useState(false);
  // const [tabdatahide, setTabDataHide] = useState(0);

  // useEffect(() => {
  //   if (profilesList != null && profilesList.length) {
  //     let profilesData = profilesList.filter((e) => { return e.WebsiteId === selectedWebsiteIdNew["WebsiteList.WebsiteId"] });
  //     let profileDataUpdated = profilesData.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
  //     renderWebsiteProfiles({ resultSet: profileDataUpdated })
  //   }
  // }, [selectedWebsiteIdNew["WebsiteList.WebsiteId"], profilesList]);

  // useEffect(() => {
  //   localStorage.setItem("websiteData", JSON.stringify([]))
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   getWebsites();
  // }, [contextData.userId, contextData.companyId]);

  // const getWebsites = () => {
  //   request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
  //     METHODS.GET,
  //     {},
  //     {
  //       Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
  //     })
  //     .then((res) => {
  //       let resultData = [];
  //       if (res.result !== null && res.result.length) {
  //         resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
  //       }
  //       rendersite({ resultSet: resultData })
  //     })
  //     .catch((err) => {
  //     })

  //   request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
  //     METHODS.GET,
  //     {},
  //     {
  //       Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
  //     })
  //     .then((res) => {
  //       setProfilesList(res.result)
  //     })
  //     .catch((err) => {
  //     })
  // }

  // useEffect(() => {
  //   if (contextData.userId > 0 && contextData.companyId > 0) {
  //     setcompanyIdValue(contextData.companyId);
  //     if (localStorage.getItem("IsPageRefresh") == "1") {
  //       if (localStorage.getItem("WebsiteListWebsiteId")) {
  //         setSelectedWebsiteIdNew(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
  //       }
  //       if (localStorage.getItem("WebsiteProfilesIntelConfigId") && localStorage.getItem("WebsiteProfilesIntelConfigId") != "")
  //         setSelectedProfileIdNew(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
  //       if (localStorage.getItem("SelectedDatePicker")) {
  //         setSelectedDatePicker(JSON.parse(localStorage.getItem("SelectedDatePicker")));
  //       }
  //     }
  //   }
  // }, [contextData.userId, contextData.companyId]);

  // const applyFilters = () => {
  //   if (selectedWebsiteIdNew["WebsiteList.WebsiteId"]) {
  //     const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
  //     contextData.changeWebsiteId(websiteId);
  //     contextData.changeSelectedWebsite(selectedWebsiteIdNew);
  //     setSelectedWebsiteId(selectedWebsiteIdNew);
  //     const intellConfigId = selectedProfileIdNew["WebsiteProfiles.IntelConfigId"];
  //     contextData.changeIntelConfigId(intellConfigId);
  //     contextData.changeSelectedProfile(selectedProfileIdNew);
  //     setSelectedProfileId(selectedProfileIdNew);
  //     localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));
  //     localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));
  //   }
  // }

  // const cancelFilters = () => {
  //   onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
  //   onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  // }

  // const compareItem = (items, compareItem) => {
  //   if (items.length > 0) {
  //     for (let i = 0; i <= items.length; i++) {
  //       if (object_equals(items[i], compareItem)) {
  //         return true;
  //       }
  //     }
  //   }
  // }

  // const onWebsiteDropdownChange = (data) => {
  //   if (!object_equals(selectedWebsiteIdNew, data)) {
  //     setSelectedProfileIdNew({});
  //   }
  //   setSelectedWebsiteIdNew(data);
  //   setUpdateKey(Math.random())
  // };

  // const onProfileDropdownChange = (data) => {
  //   setSelectedProfileIdNew(data)
  //   setPageNo(0);
  //   setOffset(0);
  //   setUpdateKey(Math.random())
  // };

  // const onDatePickerDropdownChange = (data) => {
  //   setDatePickerCompareValue("");
  //   if (data && Object.keys(data).length > 0) {
  //     if (data.key.length > 0) {
  //       const datePickerSplit = data.key.split("-");
  //       contextData.changeStartDate(moment(datePickerSplit[0]));
  //       contextData.changeEndDate(moment(datePickerSplit[1]));
  //       if (data.compareOn || data.showDatePicker) {
  //         const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
  //         const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
  //         const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
  //         setDatePickerValue(`${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`);
  //         setDatePickerCompareValue(compareOnValue);
  //       }
  //     }
  //     if (data.showDatePicker)
  //       setShowCustomDatePicker(true);
  //     else
  //       setShowCustomDatePicker(false);
  //   }
  //   setSelectedDatePicker(data)
  //   localStorage.setItem("SelectedDatePicker", JSON.stringify(data));
  // };

  // const validStartDate = (current) => {
  //   let currentDay = moment(endDate).format("MM/DD/YYYY");
  //   return current.isBefore(currentDay);
  // };

  // const validEndDate = (current) => {
  //   let yesterday = moment().subtract(1, 'day');
  //   return current.isBefore(yesterday);
  // };

  // const onClickOutsideListener = () => {
  //   setShowCustomDatePicker(false);
  //   document.removeEventListener("click", onClickOutsideListener)
  // }

  // const rendersite = ({ resultSet, error, pivotConfig, request }) => {
  //   localStorage.setItem("websiteData", JSON.stringify([]))
  //   if (resultSet !== null && resultSet.length) {
  //     localStorage.setItem("websiteData", JSON.stringify(resultSet))
  //     if (resultSet !== null && resultSet.length && selectedWebsiteIdNew !== undefined && Object.keys(selectedWebsiteIdNew).length <= 0) {
  //       if (resultSet !== null && resultSet.length && compareItem(resultSet, JSON.parse(localStorage.getItem("WebsiteListWebsiteId"))) === true) {
  //         onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
  //       } else {
  //         onWebsiteDropdownChange(resultSet[0])
  //       }
  //     }
  //     setUpdateKey(JSON.stringify(resultSet))
  //   }
  //   return (
  //     <></>
  //   );
  // };

  // const renderWebsiteProfiles = async ({ resultSet, error, pivotConfig, request }) => {
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   if (resultSet !== undefined && resultSet.length) {
  //     //localStorage.setItem("profileData", JSON.stringify(resultSet))
  //     if (compareItem(resultSet, JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"))) === true) {
  //       onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  //       setAutoLoad(true)
  //     } else {
  //       onProfileDropdownChange(resultSet[0])
  //       setAutoLoad(true)
  //     }
  //   }

  //   if (resultSet !== undefined && resultSet.length) {
  //     localStorage.setItem("profileData", JSON.stringify(resultSet))
  //     setUpdateKey(JSON.stringify(resultSet))
  //   } else if (resultSet !== undefined && resultSet.length <= 0) {
  //     setUpdateKey(JSON.stringify(resultSet))
  //     setAutoLoad(true)
  //   }  
  // };
  //==============================

  useEffect(() => {
    if (contextData.companyId > 0) {

      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));

        renderWebsite(websiteList, selectedWebsite)

      }
      else if (!localStorage.getItem("websiteData")) {
        getWebsites2();
      }
    }
  }, [])

  const renderWebsite = (resultSet, selectedWebsiteValue) => {

    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);

    renderProfileList2(selectedWebsiteValue, 0);

  };

  const renderProfileList2 = (selectedWebsiteValue, isTempUpdate) => {

    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

        setProfilesList(profileData)

        let selectedProfile = profileData[0];


        if (isTempUpdate === 0) {

          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };

          localStorage.setItem("profileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);

      }
      else {


        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
      }

    }
    setAutoLoad(true)
  }
  const getWebsites2 = () => {

    //setBlurLoader(true);

    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {

          request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {

              if (res.result != null && res.result.length > 0) {

                let result = removeDuplicateObjects(res.result, 'IntelConfigId')
                localStorage.setItem("AllprofileData", JSON.stringify(result))

                let selectedWebsiteProfiles = result.filter((e) => { return e.WebsiteId === resultData[0]["WebsiteList.WebsiteId"] });

                if (selectedWebsiteProfiles.length > 0) {

                }
                else {

                }
              }
              else {
                localStorage.setItem("profileData", JSON.stringify([]))
              }
              localStorage.setItem("websiteData", JSON.stringify(resultData))
              renderWebsite(resultData, resultData[0])
              //setBlurLoader(false);
              if (res.result.length == 0) {
                setAutoLoad(true);
              }

            })
            .catch((err) => {
              //setBlurLoader(false);
            })
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
        }

      })
      .catch((err) => {
        //setBlurLoader(false);
      })

  }
  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }
  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList2(data, 1)
  };
  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)

    //setUpdateKey(Math.random())
  };
  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }
  const applyFilters = () => {

    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    // if (JSON.stringify(selectedProfileIdNew).length == 2) {
    //   setTabDataHide(1);
    // }
    // else {
    //   setTabDataHide(-1);
    // }

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);
    //setSelectedWebsiteId(selectedWebsiteIdNew)
    contextData.changeSelectedProfile(selectedProfileIdNew);
    //setSelectedProfileId(selectedProfileIdNew);

    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]))
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }


  const commonHeaderChild = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
  ]} title={t("header.userAnalyticsTitle")} showDate={true} applyFilters={applyFilters} />, [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])


  return (
    <>
      <Row>
        <Col className="text-start" sm={12} xl={12}>
          {commonHeaderChild}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-start" lg={12} xl={4}>
        </Col>
        <Col xs={12} xl={8}>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button variant="light" onClick={(e) => handleBackButtonClick(e)} bsPrefix="text" className="ms-3 link-icn-btn">
            <FontAwesomeIcon className="me-1" icon={faChevronLeft} /> {t("header.backButton")}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2 mb-2" id="userAnalyticsContent">
        <Col lg={12}>
          {contextData.selectedProfile["WebsiteProfiles.ProfileId"] && children ?
            <ReactGridLayout rowHeight={65}
              isDraggable={false} isResizable={false}
            >
              {children}
            </ReactGridLayout>
            : <div></div>
          }
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Useranalytics);
