import { Row, Col, Card, Tooltip, OverlayTrigger, Nav, Tab, Accordion, Button } from '@themesberg/react-bootstrap';
import CommonMessageComponent from '../CommonMessageComponent.js';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useMemo } from "react";
import request from '../../apis/request.js';
import { baseURL, METHODS } from '../../apis/utilities/constant';
import { DataStatusMessage, TF_ToolName } from "../../utils/constant";
import DataChartRenderer from '../../components/DataChartRenderer.js';
import { TnfTableHeaders } from '../../utils/constant';
import DataTableRenderer from '../../components/DataTableRenderer.js';
import moment from 'moment';
import { decryptData } from '../../utils/crypto.js';

const ASXAnnouncement = React.memo(({ profileID, startDate, endDate, intelConfigID, cryptoKey }) => {
    const [t, i18n] = useTranslation();

    const [summary, setSummary] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [lineChart, setLineChart] = useState({
        transactionStatus: {
            PageViewStatus: DataStatusMessage.Loading,
            TotalUsersStatus: DataStatusMessage.Loading,
            EventCountStatus: DataStatusMessage.Loading,
            EventTotalUsersStatus: DataStatusMessage.Loading,
            EventCountPerUserStatus: DataStatusMessage.Loading
        }
    });

    const [mapData, setMapData] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableDataMap, setTableDataMap] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [customEventCard1, setCustomEventCard1] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [customEventCard2, setCustomEventCard2] = useState({ transactionStatus: DataStatusMessage.Loading });


    const [tab, setTab] = useState(1);

    useEffect(() => {
        if (summary.transactionStatus !== DataStatusMessage.Loading) {
            setTab(1)
            setSummary({ transactionStatus: DataStatusMessage.Loading });
            setLineChart({
                transactionStatus: {
                    PageViewStatus: DataStatusMessage.Loading,
                    TotalUsersStatus: DataStatusMessage.Loading,
                    EventCountStatus: DataStatusMessage.Loading,
                    EventTotalUsersStatus: DataStatusMessage.Loading,
                    EventCountPerUserStatus: DataStatusMessage.Loading
                }
            });
            setMapData({ transactionStatus: DataStatusMessage.Loading });
            setTableDataMap({ transactionStatus: DataStatusMessage.Loading });
            setCustomEventCard1({ transactionStatus: DataStatusMessage.Loading })
            setCustomEventCard2({ transactionStatus: DataStatusMessage.Loading })
        }

        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");


        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfComponents?ToolId=${TF_ToolName.ASXAnnouncement}&StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=eventCount,screenPageViews,totalUsers,userEngagementDuration&Dimensions=country,date,eventName,pagePath&intelConfigId=${intelConfigID}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response && response.transactionStatus !== DataStatusMessage.ErrorOccured) {
                setSummary({ result: response.result.pageSummary, transactionStatus: response.transactionStatus.pageSummaryStatus })
                setLineChart({
                    result: {
                        PageView: response.result.PageView,
                        TotalUsers: response.result.TotalUsers,
                        EventCount: response.result.EventCount,
                        EventTotalUsers: response.result.EventTotalUsers,
                        EventCountPerUser: response.result.EventCountPerUser
                    },
                    transactionStatus: {
                        PageViewStatus: response.transactionStatus.PageViewStatus,
                        TotalUsersStatus: response.transactionStatus.TotalUsersStatus,
                        EventCountStatus: response.transactionStatus.EventCountStatus,
                        EventTotalUsersStatus: response.transactionStatus.EventTotalUsersStatus,
                        EventCountPerUserStatus: response.transactionStatus.EventCountPerUserStatus
                    }
                })
                setMapData({ result: response.result.mapDetails, transactionStatus: response.transactionStatus.mapDetailsStatus })
                setTableDataMap({
                    header: TnfTableHeaders.MapUserTableForTools, data: response.transactionStatus.mapDetailsStatus === DataStatusMessage.DataAvailable ?
                        response.result.mapDetails : [], transactionStatus: response.transactionStatus.mapDetailsStatus
                });

                setCustomEventCard1({
                    header: TnfTableHeaders.CustomEventDateSelector,
                    data: response.transactionStatus.tableComponent1Status === DataStatusMessage.DataAvailable ? response.result.tableComponent1 : [],
                    headerSummary: response.result.tableComponent1HeaderSummary,
                    transactionStatus: response.transactionStatus.tableComponent1Status
                })

                setCustomEventCard2({
                    header: TnfTableHeaders.CustomEventSearchOptions,
                    data: response.transactionStatus.tableComponent2Status === DataStatusMessage.DataAvailable ? response.result.tableComponent2 : [],
                    headerSummary: response.result.tableComponent2HeaderSummary,
                    transactionStatus: response.transactionStatus.tableComponent2Status
                })
            } else {
                setSummary({ transactionStatus: DataStatusMessage.ErrorOccured });
                setLineChart({
                    transactionStatus: {
                        PageViewStatus: response ? response.transactionStatus.PageViewStatus : DataStatusMessage.ErrorOccured,
                        TotalUsersStatus: response ? response.transactionStatus.TotalUsersStatus : DataStatusMessage.ErrorOccured,
                        EventCountStatus: response ? response.transactionStatus.EventCountStatus : DataStatusMessage.ErrorOccured,
                        EventTotalUsersStatus: response ? response.transactionStatus.EventTotalUsersStatus : DataStatusMessage.ErrorOccured,
                        EventCountPerUserStatus: response ? response.transactionStatus.EventCountPerUserStatus : DataStatusMessage.ErrorOccured
                    }
                })
                setMapData({ transactionStatus: DataStatusMessage.ErrorOccured });
                setTableDataMap({ transactionStatus: DataStatusMessage.ErrorOccured });
                setCustomEventCard1({ transactionStatus: DataStatusMessage.ErrorOccured })
                setCustomEventCard2({ transactionStatus: DataStatusMessage.ErrorOccured })
            }


        }).catch(
            (error) => {
                setSummary({ transactionStatus: DataStatusMessage.ErrorOccured });
                setLineChart({
                    transactionStatus: {
                        PageViewStatus: DataStatusMessage.ErrorOccured,
                        TotalUsersStatus: DataStatusMessage.ErrorOccured,
                        EventCountStatus: DataStatusMessage.ErrorOccured,
                        EventTotalUsersStatus: DataStatusMessage.ErrorOccured,
                        EventCountPerUserStatus: DataStatusMessage.ErrorOccured
                    }
                })
                setMapData({ transactionStatus: DataStatusMessage.ErrorOccured });
                setTableDataMap({ transactionStatus: DataStatusMessage.ErrorOccured });
                setCustomEventCard1({ transactionStatus: DataStatusMessage.ErrorOccured })
                setCustomEventCard2({ transactionStatus: DataStatusMessage.ErrorOccured })
            }
        );


    }, [profileID, startDate, endDate])

    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        // <Tooltip>{t("header.VisitorAnalyticsTooltip")}</Tooltip>
                        <Tooltip>
                            <div
                            // dangerouslySetInnerHTML={{
                            //   __html: t("header.VisitorAnalyticsTooltip", {
                            //     interpolation: { escapeValue: false },
                            //   }),
                            // }}
                            >{tooltipKey}</div>
                        </Tooltip>
                    }
                >

                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }
    
    return (
        <>

            <div className={summary.transactionStatus !== DataStatusMessage.DataAvailable ?
                "d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100" : "spc-equation-box d-inline-block w-100 card-spacing-bottom"}>
                <Card border="light" className="bg-white shadow-sm ms-3">
                    <Card.Header className="ps-3 pt-3">
                        {t("header.Summary")}{createTooltip(t("header.SPC_Summary_Tooltip"), "right")}
                    </Card.Header>
                    <Card.Body className=' pb-0'>
                        {/* <CommonMessageComponent transactionStatus={2}></CommonMessageComponent> */}
                        {
                            summary.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <CommonMessageComponent transactionStatus={summary.transactionStatus}></CommonMessageComponent>
                        }
                        {summary.transactionStatus === DataStatusMessage.DataAvailable &&
                            <Row className="tools-overview-key-insight-container card-spacing-bottom">
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result.ScreenPageViews}>
                                                    {summary.result.ScreenPageViews}
                                                </div>
                                                <div class="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.summary_views")}
                                                    {createTooltip(t("header.summary_views_tooltip"), "right")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result.TotalUsers}>
                                                    {summary.result.TotalUsers}
                                                </div>
                                                <div class="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.summary_users")}
                                                    {createTooltip(t("header.summary_users_tooltip"), "left")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result.ScreenPageViewsPerUser}>
                                                    {summary.result.ScreenPageViewsPerUser}
                                                </div>
                                                <div class="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.summary_views_per_user")}
                                                    {createTooltip(t("header.summary_views_per_user_tooltip"), "right")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result.UserEngagementDurationConverted ? summary.result.UserEngagementDurationConverted : "0s"}>
                                                    {summary.result.UserEngagementDurationConverted ? summary.result.UserEngagementDurationConverted : "0s"}
                                                </div>
                                                <div class="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.summary_time_per_user")}
                                                    {createTooltip(t("header.summary_time_per_user_tooltip"), "left")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        }

                    </Card.Body>
                </Card>
            </div >

            <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100">
                <Card border="light" className="bg-white shadow-sm ms-3 p-3">
                    <Card.Body>
                        <Row className="tools-overview-subtab">
                            <Col md={12}>
                                {
                                    summary.transactionStatus === DataStatusMessage.DataAvailable &&
                                    <Tab.Container defaultActiveKey="tab1" >
                                        <Nav fill variant="pills" className="flex-column flex-sm-row tab subtab ms-0 me-0 mt-1">
                                            <Nav.Item className="pe-0">
                                                <Nav.Link eventKey="tab1" onClick={() => { setTab(1) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                        {t("header.linechart_summary_views")}
                                                        {createTooltip(t("header.linechart_summary_views_tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count"> {summary.result.ScreenPageViews}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab2" onClick={() => { setTab(2) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                        {t("header.linechart_summary_users")}
                                                        {createTooltip(t("header.linechart_summary_users_tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result.TotalUsers}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab3" onClick={() => { setTab(3) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                        {t("header.linechart_summary_events")}
                                                        {createTooltip(t("header.linechart_summary_events_tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result.EventCount}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab4" onClick={() => { setTab(4) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                        {t("header.linechart_summary_event_users")}
                                                        {createTooltip(t("header.linechart_summary_event_users_tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result.EventTotalUsers}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab5" onClick={() => { setTab(5) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                        {t("header.linechart_summary_events_per_user")}
                                                        {createTooltip(t("header.linechart_summary_events_per_user_tooltip", "left"))}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result.EventCountPerUser}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab1" className="py-2">
                                                {tab === 1 && lineChart.transactionStatus.PageViewStatus === DataStatusMessage.DataAvailable &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result?.PageView
                                                        }
                                                        chartType="line" />
                                                }
                                                {lineChart.transactionStatus.PageViewStatus !== DataStatusMessage.DataAvailable &&
                                                    <CommonMessageComponent transactionStatus={lineChart.transactionStatus.PageViewStatus}></CommonMessageComponent>
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab2" className="py-2">
                                                {tab === 2 && lineChart.transactionStatus.TotalUsersStatus === DataStatusMessage.DataAvailable &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result?.TotalUsers
                                                        }
                                                        chartType="line" />
                                                }
                                                {lineChart.transactionStatus.TotalUsersStatus !== DataStatusMessage.DataAvailable &&
                                                    <CommonMessageComponent transactionStatus={lineChart.transactionStatus.TotalUsersStatus}></CommonMessageComponent>
                                                }

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab3" className="py-2">
                                                {tab === 3 && lineChart.transactionStatus.EventCountStatus === DataStatusMessage.DataAvailable &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result?.EventCount
                                                        }
                                                        chartType="line" />}
                                                {lineChart.transactionStatus.EventCountStatus !== DataStatusMessage.DataAvailable &&
                                                    <CommonMessageComponent transactionStatus={lineChart.transactionStatus.EventCountStatus}></CommonMessageComponent>
                                                }

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab4" className="py-2">
                                                {tab === 4 && lineChart.transactionStatus.EventTotalUsersStatus === DataStatusMessage.DataAvailable &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result?.EventTotalUsers
                                                        }
                                                        chartType="line" />}
                                                {lineChart.transactionStatus.EventTotalUsersStatus !== DataStatusMessage.DataAvailable &&
                                                    <CommonMessageComponent transactionStatus={lineChart.transactionStatus.EventTotalUsersStatus}></CommonMessageComponent>
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab5" className="py-2">
                                                {tab === 5 && lineChart.transactionStatus.EventCountPerUserStatus === DataStatusMessage.DataAvailable &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result?.EventCountPerUser
                                                        }
                                                        chartType="line" />}
                                                {lineChart.transactionStatus.EventCountPerUserStatus !== DataStatusMessage.DataAvailable &&
                                                    <CommonMessageComponent transactionStatus={lineChart.transactionStatus.EventCountPerUserStatus}></CommonMessageComponent>
                                                }
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                }

                                {
                                    (summary.transactionStatus !== DataStatusMessage.DataAvailable) &&
                                    <CommonMessageComponent transactionStatus={summary.transactionStatus}></CommonMessageComponent>
                                }

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>

            <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100">
                <Card border="light" className="bg-white shadow-sm ms-3">
                    <Card.Header className="pt-3 ps-3">
                        {t("header.cardheading_views_by_country")}{createTooltip(t("header.cardheading_views_by_country_tooltip"), "right")}
                    </Card.Header>
                    <Card.Body>
                        {
                            mapData.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    <CommonMessageComponent transactionStatus={mapData.transactionStatus}></CommonMessageComponent>
                                </Col>
                            </Row>
                        }
                        {
                            tableDataMap.transactionStatus === DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    <DataChartRenderer
                                        data={
                                            mapData.result
                                        }
                                        chartType="map" />
                                </Col>
                                <Col md={12} className='mt-2'>
                                    {mapData.transactionStatus === DataStatusMessage.DataAvailable &&
                                        <DataTableRenderer
                                            header={tableDataMap.header}
                                            dataSet={tableDataMap.data}
                                            transactionStatus={tableDataMap.transactionStatus}
                                            customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                        ></DataTableRenderer>
                                    }
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                </Card>
            </div>

            <Row className="half-width-card-container">
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100 md-ps-1">
                        <Card border="light" className="bg-white shadow-sm  ms-3">
                            <Card.Header className="pt-3 ps-3">
                                {t("header.custom_events_events_by_users")}{createTooltip(t("header.custom_events_events_by_users_tooltip"), "right")}
                            </Card.Header>
                            <Card.Body>
                                {
                                    customEventCard1.transactionStatus !== DataStatusMessage.DataAvailable &&
                                    <CommonMessageComponent transactionStatus={customEventCard1.transactionStatus}></CommonMessageComponent>
                                }
                                {
                                    customEventCard1.transactionStatus === DataStatusMessage.DataAvailable &&
                                    <DataTableRenderer
                                        header={customEventCard1.header}
                                        dataSet={customEventCard1.data}
                                        transactionStatus={customEventCard1.transactionStatus}
                                        headerSummaryData={customEventCard1.headerSummary}
                                        customAttributes={{ displayRowsPerPage: 7, showHeaderSummary: true, showPagination: true }}
                                    ></DataTableRenderer>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                {customEventCard2.transactionStatus !== DataStatusMessage.NA || customEventCard2.transactionStatus === DataStatusMessage.DataAvailable && <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100">
                        <Card border="light" className="bg-white shadow-sm">
                            <Card.Header className="pt-3 ps-3">
                                {t("header.custom_events_events_by_users")}{createTooltip(t("header.custom_events_events_by_users_tooltip"), "right")}
                                {/* {t("header.Spc_Advanced_Search_Options")}{createTooltip(t("header.Spc_Advanced_Search_Options_Tooltip"), "right")} */}
                            </Card.Header>
                            <Card.Body>
                                {
                                    customEventCard2.transactionStatus !== DataStatusMessage.DataAvailable &&
                                    <CommonMessageComponent transactionStatus={customEventCard2.transactionStatus}></CommonMessageComponent>
                                }
                                {
                                    customEventCard2.transactionStatus === DataStatusMessage.DataAvailable &&
                                    <DataTableRenderer
                                        header={customEventCard2.header}
                                        dataSet={customEventCard2.data}
                                        transactionStatus={customEventCard2.transactionStatus}
                                        headerSummaryData={customEventCard2.headerSummary}
                                        customAttributes={{ displayRowsPerPage: 7, showHeaderSummary: true, showPagination: true }}
                                    ></DataTableRenderer>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </Col>}

            </Row>

        </>
    )
});
export default ASXAnnouncement;
