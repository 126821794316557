import { Row, Col, Button } from "@themesberg/react-bootstrap";
import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router";
import AppContext from '../components/AppContext';
import { QueryRenderer } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import Loader from '../components/VoltLoader';
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import ModalComponent from "../components/VoltModal";
import ButtonsComponent from "./Buttons";
import { useTranslation } from 'react-i18next';
import { decryptData } from "../utils/crypto";

const YourInvestisTeam = (props) => {

  let {
    setShowBlurLoader
  } = props;

  const contextData = useContext(AppContext);
  const [t, i18n] = useTranslation();
  const [myInvestisTeamData, setMyInvestisTeamData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreModal, setViewMoreModal] = useState(false);

  const getMyInvestisData = () => {
    let companyId = contextData.companyId;
    let userId = contextData.userId;
    setShowBlurLoader && setShowBlurLoader(true);
    if (companyId > 0) {
      setMyInvestisTeamData([]);
      request(baseURL + `/ServiceCentre/GetInvestisTeamByCompany?CompanyId=${companyId}&userId=${userId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((res) => {
          setMyInvestisTeamData(res?.data);
          setShowBlurLoader && setShowBlurLoader(false);
        })
        .catch((err) => {
          setMyInvestisTeamData([]);
          setShowBlurLoader && setShowBlurLoader(false);
        })
    }

  }

  useEffect(() => {
    getMyInvestisData();
  }, [contextData.companyId, contextData.userId]);

  // const memberList = [
  //   {
  //     name: "Betty Bravo",
  //     designation: " Digital Communication Manager",
  //     email: "betty.bravo@investisdigital.comm",
  //     phoneno: "+47 333 78 90",
  //   },
  //   {
  //     name: "Jack Johnson",
  //     designation: "Account Developer",
  //     email: "jack.johnson@investisdigital.com",
  //     phoneno: "+47 333 78 901",
  //   },
  //   {
  //     name: "Max",
  //     designation: "Account Developer",
  //     email: "max.daniel@investisdigital.com",
  //     phoneno: "+47 333 78 901",
  //   },
  // ];

  const renderYourInvestisTeam = ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Loader show={false} />;
    }
    const memberList = resultSet.tablePivot(pivotConfig)
    return (
      <>
        {memberList.map(function (item) {
          return (
            <></>
          );
        })}
      </>
    );
  };
  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );

  return (
    <>
      <ModalComponent
        open={viewMoreModal}
        title="Your IDX team"
        handleClose={() => { setViewMoreModal(false) }}
        showCloseButton={true}>
        <Row className="mb-2">
          {
            myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.map((item, index) => (
              <>

                <Col xs={12} sm={6} Col={6}>
                  <p className="fw-bold mb-2 text-capitalize">{item["InvestisDigitalTeam.Name"]}</p>
                  <p className="investis-team">
                    {item["InvestisDigitalTeam.Role"]}
                    <br />
                    <a href={"mailto:" + item["InvestisDigitalTeam.EmailLink"]}>{item["InvestisDigitalTeam.Email"]}</a>
                    <br />
                    <a href="tel:{item.phoneno}" className="phone fw-semi-bold">
                      {item["InvestisDigitalTeam.Phone"]}
                    </a>
                  </p>
                </Col>

              </>

            ))
          }
        </Row>
        <Row className="mt-4 mb-2">
          <Col>
            <ButtonsComponent
              isIcon={false}
              isPrimary={false}
              btnText={t("header.yourInvestisCloseButtonText")}
              onClick={() => { setViewMoreModal(false) }}
              btn_class="mb-0" />
          </Col>
        </Row>
      </ModalComponent>
      <Row>
        <Col xs={12}>
          <div className="card-custom-title mb-0 fw-bold">
            {t("header.HeaderMyAccountTitle")}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        {
          myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.map((item, index) => (
            <>
              {index < 4 &&
                <Col xs={12} sm={6} Col={6}>
                  <p className="fw-bold mb-2 text-capitalize">{item["InvestisDigitalTeam.Name"]}</p>
                  <p className="investis-team">
                    {item["InvestisDigitalTeam.Role"]}
                    <br />
                    <a href={"mailto:" + item["InvestisDigitalTeam.Email"] + "?subject=Query for Service Centre&body="}>{item["InvestisDigitalTeam.Email"]}</a>
                    <br />
                    <a href="tel:{item.phoneno}" className="phone fw-semi-bold">
                      {item["InvestisDigitalTeam.Phone"]}
                    </a>
                  </p>
                </Col>}

            </>

          ))
        }

        {
          viewMore && myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.map((item, index) => (
            <>
              {index >= 4 &&
                <Col xs={12} sm={6} Col={6}>
                  <p className="fw-bold mb-2">{item["InvestisDigitalTeam.Name"]}</p>
                  <p className="investis-team">
                    {item["InvestisDigitalTeam.Role"]}
                    <br />
                    <a href={"mailto:" + item["InvestisDigitalTeam.Email"] + "?subject=Query for Service Centre&body="}>{item["InvestisDigitalTeam.Email"]}</a>
                    <br />
                    <a href="tel:{item.phoneno}" className="phone fw-semi-bold">
                      {item["InvestisDigitalTeam.Phone"]}
                    </a>
                  </p>
                </Col>}

            </>

          ))
        }
        {
          myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.length > 4 ?
            <Row>
              <Col className="text-center">
                <Button onClick={() => { setViewMore(!viewMore) }} variant="primary" className="me-2">
                  {viewMore ? t("header.viewLessLabel") : t("header.loadMoreLabel")}
                </Button>
              </Col>
            </Row> :
            <></>
        }
      </Row>

    </>
  );
};
export default withRouter(YourInvestisTeam);
