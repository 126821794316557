import { withRouter } from "react-router";
import RGL, { WidthProvider } from 'react-grid-layout';
import { Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import DashboardItem from "../components/DashboardItem";
import ChartRenderer from "../components/ChartRenderer";
import { isMobile } from "react-device-detect";
import ChartRendererTable from "./ChartRendererTable";
import ButtonsComponent from "./Buttons";
import { useTranslation } from 'react-i18next';

const ReactGridLayout = WidthProvider(RGL);
const UserWebcastDigestComponent = (props) => {
  const [t, i18n] = useTranslation();

  const [allData, setAllData] = useState([])
  const [inputValue, setInputValue] = useState(""); // Initial value is empty
  const [initialValue, setInitialValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);


  const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    //  summary: JSON.parse(i.summary) || {},
    //vizState: JSON.parse(i.vizState)
  });
  const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 8,
    minW: 4,
    minH: 8,
  });
  const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
  });

  useEffect(()=>{
    setIsButtonDisabled(true);
    if (allData.additionalEmails) {
      setInitialValue(allData.additionalEmails); // Set the initial value from Exportdata
      setInputValue(allData.additionalEmails);   // Set the input box value
    }
  },[allData])

  const handleInputChange = (e) => {
    
    const newValue = e.target.value;

    // Enable the button if user starts typing beyond the initial value
    if (newValue !== initialValue) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }

    setInputValue(newValue); // Update input value as user types
    props.setRecipients(newValue);
  };
  const dashboardItem = (item) => (
    <div
      key={item.id}
      // className='model-table'
      data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
    >
      <DashboardItem key={item.id} itemId={item.id} title={item.name}>
        <ChartRendererTable
          apiUrl={item.apiUrl}
          onItemChange={props.onItemChange}
          showCount={false}
          Exportdata={setAllData}
        />

        <Row className="mt-3">
          <Col>
            <span className="ms-3 text-bold">Add Additional Email</span>
          </Col>
        </Row>
        <Row className="mt-2 mb-3">
          <Col md={6}>
            <Form.Control className="recipient-textarea-DS ms-3 me-3" as="textarea" rows="1" maxLength="" name="recipients" value={inputValue} placeholder={"Add comma-separated email address"} onChange={(e) => handleInputChange(e)} />
          </Col>
        </Row>

        <Row>
          <Col>
            <ButtonsComponent
              onClick={(e) => props.handleSubmitButtonClick(e)}
              isIcon={false}
              dissable={props.webcastTableData && props.webcastTableData.length > 0 || !isButtonDisabled ? false : true}
              isPrimary={true}
              btn_class="ms-3"
              btnText={t("header.SettingsMDSWebcastButtonSave")}
            />
          </Col>
        </Row>

      </DashboardItem>
    </div>
  );
  return <>
    {props.userWebCastData && props.userWebCastData.length > 0 &&
      // <ReactGridLayout cols={12} rowHeight={20} isDraggable={false} isResizable={false}>

      // </ReactGridLayout>
      <>{props.userWebCastData.map(deserializeItem).map(dashboardItem)}</>
    }</>;
  ;
};
export default withRouter(UserWebcastDigestComponent);
