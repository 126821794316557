import React  from 'react';
import {Row,Col} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";
import ModalComponent from "../components/VoltModal";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Search from "../components/Search";

const ServiceCenterReportDetail = ({
  children,
  showModal,
  setShowModal,
  reportTitle,
  clientName,
  remainingServiceHours, 
  createddt,
  reportPeriod,
  billableHours,
  detailSearchData,
  setDetailSearchString
}) => {
  const [t, i18n] = useTranslation();
  const onModalClose = () => {
    setShowModal && setShowModal(false);
  };
  const onCancelButtonClick = (e) => {
    e.preventDefault();
    onModalClose();
  };
  
  return (
    <ModalComponent
      open={showModal}
      title={reportTitle}
      handleClose={onModalClose}
      showCloseButton={true}>
         <Row className="mb-1">
          <Col sm={8}>
            <div className="fw-bold">{clientName}</div>
          </Col>
          <Col sm={4} className="service-period">
            <div>{t("header.serviceCenterReportDetailsLabel5")+" "}{reportPeriod}</div>
          </Col>
        </Row>
        {/* <Row className="mb-1">
          <Col sm={12}>
            <div>Remaining service hours: <span className="fw-bold">{remainingServiceHours.toFixed(2)}</span> hours (as on {moment(createddt).format("MMM")} {moment(createddt).format("DD")}, {moment(createddt).format("YYYY")} {moment(createddt).format("hh:mm A")})</div>
          </Col>
        </Row> */}
        <Row className="mb-3 service-filter">
          <Col md={7}>
            <div className="d-block">{t("header.serviceCenterReportDetailsLabel1")+" "} <span className="fw-bold">{remainingServiceHours.toFixed(2)}</span> {" "+t("header.serviceCenterReportDetailsLabel2")+" "}( {moment(createddt).format("MMM")} {moment(createddt).format("DD")}, {moment(createddt).format("YYYY")} {moment(createddt).format("hh:mm A")})</div>
            <div>{t("header.serviceCenterReportDetailsLabel3")+" "}<span className="fw-bold">{billableHours.toFixed(2)}</span> {t("header.serviceCenterReportDetailsLabel4")}</div>
          </Col>
          <Col md={5}>
            <Search searchData={detailSearchData} type={'server'}></Search>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
             {children}
          </Col>
        </Row>
    </ModalComponent>
  );
};

ServiceCenterReportDetail.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setSelectedCompany: PropTypes.func,
  showAutoFillForCompany: PropTypes.bool,
  setAutoFillForCompany: PropTypes.func,
  formError: PropTypes.object,
  isFormSubmit: PropTypes.bool,
  validateFormData: PropTypes.func,
  setFormError: PropTypes.func,
  setIsFormSubmit: PropTypes.func,
};

ServiceCenterReportDetail.defaultProps = {
  showModal: true,
  setShowModal: null,
  setSelectedCompany: null,
  showAutoFillForCompany: false,
  setAutoFillForCompany: null,
  formError: {},
  isFormSubmit: false,
  validateFormData: null,
  setFormError: null,
  setIsFormSubmit: null,
};
export default ServiceCenterReportDetail;