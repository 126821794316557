import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from "react-i18next";

function SocialMediaPages() {
    const [t] = useTranslation();
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12} xl={4}>
                <h2 className="h2 mb-0">{t("header.socialMediaTitle")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <p>
                        {t("header.socialMediaDescription")}
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SocialMediaPages