import { Row, Col, Button, Image } from "@themesberg/react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router";
import { getStringValue } from "../utils/common";
import { header_languages } from "../AppConfig";
import YourInvestisTeam from "../components/YourInvestisTeam";
import ButtonComponent from "../components/Buttons";
import MyAccountModal from "./MyAccountModal";
import AppContext from '../components/AppContext';
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import { useTranslation } from "react-i18next";
import { Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import cubejs from '@cubejs-client/core';
import { getMenusAndSubMenuDetails } from "../utils/common";
import { menuItemsDetais } from "../utils/sidebar";
import WebSocketTransport from '@cubejs-client/ws-transport';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { encryptData, decryptData } from "../utils/crypto";

const API_URL = `${process.env.REACT_APP_CUBESERVERURL}`;

const jwt = require('jsonwebtoken');
const CUBE_API_SECRET = 'secret';

const MyAccount = (props) => {
  const contextData = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);
  const [t] = useTranslation();

  const [uData, setuData] = useState({
    fName: "",
    lName: "",
    uName: "",
    uEmail: "",
    uLanguage: {},
    currentPassword: "",
    uPassword: "",
    uConfirmPassword: ""
  });

  const [selectedComapny, setSelectedCompany] = useState({});

  useEffect(() => {
    getUdata();
  }, []);

  const getUdata = () => {
    let udta = {
      fName: "",
      lName: "",
      uName: "",
      uEmail: "",
      uLanguage: {},
      currentPassword: "",
      uPassword: "",
      uConfirmPassword: ""
    }
    let fName = decryptData(localStorage.getItem("firstName"), contextData.cryptoKey);
    udta.fName = fName;

    let lName = decryptData(localStorage.getItem("lastName"), contextData.cryptoKey);
    udta.lName = lName;

    let uEmail = decryptData(localStorage.getItem("email"), contextData.cryptoKey);
    udta.uEmail = uEmail;

    let uName = fName + (!lName ? "" : " " + lName)
    udta.uName = uName;

    let uCompanies = JSON.parse(localStorage.getItem("companies"));
    setUserCompanies(uCompanies);

    const selectedComapnyData = uCompanies.find(dta => dta.companyId === contextData.companyId);
    if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0) {
      setSelectedCompany(selectedComapnyData);
    }

    let language;
    if (localStorage.getItem("userlangPref")) {
      language = header_languages.find(
        (dta) => dta.id === localStorage.getItem("userlangPref").toString()
      );
      udta.uLanguage = language;
    }
    setuData(udta);
  };

  const onCloseAlert = () => {
    setshowSuccess(false);
  }

  const onComapnyDropdownChange = (data) => {
    const companyId = data.companyId;
    contextData.changeCompanyId(companyId);
    getMenuData(companyId);
    getWebCastData(companyId);
    setSelectedCompany(data);
    localStorage.setItem("companyId", encryptData(companyId, contextData.cryptoKey));
    //Clear All previous items in local storage
    localStorage.setItem("IsPageRefresh", "0");  // set  page refresh indicator to 0 on side bar click event
    localStorage.removeItem("EventId");
    localStorage.removeItem("PresentationId");
    localStorage.removeItem("ManageusersEventId");
    localStorage.removeItem("WebsiteProfilesIntelConfigId");
    localStorage.removeItem("WebsiteListWebsiteId");
    localStorage.removeItem("SelectedDatePicker");
    localStorage.removeItem("EventCalendarWebsiteId");
    localStorage.removeItem("EventCalendarProfileId");
    localStorage.removeItem("AlertStatsWebsiteId");
    localStorage.removeItem("ArchiveAlertWebsiteId");
    localStorage.removeItem("ManualAlertWebsiteId");
    localStorage.removeItem("ManagesubscriptionWebsiteId");
    localStorage.removeItem("AlertSummaryWebsiteId");
  }

  const getMenuData = (companyId) => {
    if (companyId > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_KEY}/UserV2/GetMainMenuByClientUser`,
          {
            params: {
              userId: contextData.userId,
              companyid: companyId
            },
            headers: {
              'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
            },
          },
        )
        .then(response => {
          if (response && response.data && response.data.resultData.menuDetails) {
            let menuDta = getMenusAndSubMenuDetails(response.data.resultData.menuDetails);
            contextData.setMenu(menuDta);

            const cubejsToken = jwt.sign({ token: "" }, CUBE_API_SECRET, {

            });

            const cubejsApi = cubejs({
              transport: new WebSocketTransport({
                authorization: cubejsToken,
                apiUrl: API_URL.replace('http', 'ws'),
              }),
            });

            if (Array.isArray(menuDta) && menuDta.length > 0) {
              let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];

              if (Array.isArray(defaultMenuData.subMenu) && defaultMenuData.subMenu.length > 1) {
                let defaultSubMenuData = defaultMenuData.subMenu.filter(dta => dta.isActive === true)[0];
                const subMenuDetails = menuItemsDetais.find(dta => dta.title === defaultSubMenuData.title);
                if (subMenuDetails && Object.keys(subMenuDetails).length > 0)
                  props.history.push(`${subMenuDetails.link}`);


              }
              else if (defaultMenuData) {
                const menuDta = menuItemsDetais.find(dta => dta.title === defaultMenuData.title);
                if (menuDta && Object.keys(menuDta).length > 0)
                  props.history.push(`${menuDta.link}`);
              }
            }
          }
          else {

          }

        })
        .catch(error => {
          console.log("error", error);
          // this.props.history.push("/");
        });
    }
  }

  const getWebCastData = (companyId) => {
    if (companyId > 0) {
      let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
      axios
        .get(
          `${process.env.REACT_APP_API_KEY}/WebcastV2/GetWebcastConfiguration`,
          {
            params: {
              companyId: companyId
            },
            headers: {
              'Authorization': `Bearer ${token}`
            }
          },
        )
        .then(response => {
          if (response && response.data) {
            contextData.changeHasEvents(response.data.hasEvent);
            contextData.changeWebcastType(response.data.webcastType);
            contextData.changeWebcastClientId(response.data.webcastClientId);
          }

        })
        .catch(error => {
          console.log("login error", error);
          // this.props.history.push("/");
        });
    }
  }

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <div className="ps-3">
        <Row className="mb-3">
          <Col lg={12}>
            <h2 className="h2 mb-0">{t("header.AccountSDetailsMyAccount")}</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Alert
              variant="success"
              show={showSuccess}>
              <div className="d-flex justify-content-between mb-0">
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  {t("header.AccountDetailsSaveMessage")}
                </div>
                <Button variant="close" size="xs" onClick={() => onCloseAlert("success")} />
              </div>
            </Alert>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={12}>
            <div className="bg-white shadow-soft border rounded border-light p-4 w-100 my-account-container">
              <Row>
                <Col xs={12}>
                  <div className="card-custom-title mb-0">{t("header.AccountSDetails")}</div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} sm={2} className="fw-semi-bold">
                  {t("header.AccountSDetailsName")}
                </Col>
                <Col xs={12} sm={10}>
                  {getStringValue(uData.uName)}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} sm={2} className="fw-semi-bold">
                  {t("header.AccounEmail")}
                </Col>
                <Col xs={12} sm={10}>
                  {uData.uEmail}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} sm={2} className="fw-semi-bold">
                  {t("header.AccounLanguage")}
                </Col>
                <Col xs={12} sm={10}>
                  {uData.uLanguage.title}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} className="fw-semi-bold">
                  <ButtonComponent
                    isIcon={false}
                    isPrimary={false}
                    btnText={t("header.AccountSDetailsEditButtonText")}
                    btn_size="sm"
                    btn_class="border-0 p-0 no-bg fw-semi-bold"
                    onClick={() => setShowModal(true)}
                  />
                </Col>
                {showModal &&
                  <MyAccountModal
                    setBlurLoader={setBlurLoader}
                    setuData={setuData}
                    userData={uData}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setshowSuccess={setshowSuccess}
                  >
                  </MyAccountModal>
                }
              </Row>
              <Row className="mt-4 d-md-none">
                <Col xs={12}>
                  {/* <div className="card-custom-title mb-0">{t("header.AccounLanguage")}</div> */}
                  <div className="card-custom-title mb-0">{t("header.selectClientLabel")}</div>
                </Col>
              </Row>
              <Row className="mt-2 d-md-none">
                <Col xs={12}>
                  {
                    (userCompanies && Array.isArray(userCompanies) && userCompanies.length > 0) &&
                    <div className="me-3 w-100">
                      <DropdownWithIconsComponent
                        dropdownData={userCompanies}
                        arrayValue="companyName"
                        selectedDropdown={selectedComapny}
                        onChange={onComapnyDropdownChange}
                        showLabel={false}
                        showIconInDropdownItem={false}
                        isPrimaryImage={false}
                        customClassName="text-overflow full-width"
                      />
                    </div>
                  }
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default withRouter(MyAccount);
