import React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { withRouter } from "react-router";

const ReactGridLayout = WidthProvider(RGL);
const ServiceCenter = ({ children }) => {
  return (
    <div className="ps-3 pe-1">
        <ReactGridLayout cols={12} rowHeight={80} isDraggable={false} isResizable={false}>
          {children}
        </ReactGridLayout>
    </div>
  );
};
export default withRouter(ServiceCenter);