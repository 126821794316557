export const dahbordCardItems = [
    {
        id: "10",
        layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
        name: "Cookies",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 25, \"showPercentageForPrimary\" : true, \"showPositiveOrNegativeForPrimary\" : true,\"summarySecondary\": \"Sample Text\",\"showPercentageForSecoundary\" : false, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}",
    },
    {
        id: "11",
        layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
        name: "Bar Chart",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 55, \"showPercentageForPrimary\" : true, \"showPositiveOrNegativeForPrimary\" : true,\"summarySecondary\":35.09,\"showPercentageForSecoundary\" : true, \"showPositiveOrNegativeForSecoundary\" : true}",
        vizState: "{\"chartType\":\"bar\",\"pivotConfig\":{\"x\":[\"TrafficSource.percentagediff\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.percentagediff\"]}}"
    },
    {
        id: "13",
        layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
        name: "Line chart",
        summary: "{\"layoutType\": \"plain\",\"carousalMainSummary\": \"Carousel Sumary\" ,\"summaryPrimary\":  85, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" : true,\"summarySecondary\":20.18,\"showPercentageForSecoundary\" :true, \"showPositiveOrNegativeForSecoundary\" :true}",
        vizState: "{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}"
    },
    {
        id: "1",
        layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":10}",
        name: "Table",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 95, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\": \"Dummy Text\",\"showPercentageForSecoundary\" :false, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}"
    },
    {
        id: "18",
        layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
        name: "Area Chart",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 45, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\":15.09,\"showPercentageForSecoundary\" :true, \"showPositiveOrNegativeForSecoundary\" :true}",
        vizState: "{\"chartType\":\"area\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}"
    },
    {
        id: "20",
        layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
        name: "Pie Chart",
        summary: "{\"layoutType\": \"plain\",\"carousalMainSummary\": \"Carousel Dummy Sumary\" ,\"summaryPrimary\": 65, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\":10.16,\"showPercentageForSecoundary\" :true, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"pie\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}"
    },
    {
        id: "22",
        layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
        name: "Pie Chart 1",
        summary: "{\"layoutType\": \"plain\",\"carousalMainSummary\": \"Carousel Dummy Sumary\" ,\"summaryPrimary\": 65, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\":10.16,\"showPercentageForSecoundary\" :true, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"pie\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}"
    },
    {
        id: "24",
        layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
        name: "Multiple Bar Chart",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 55, \"showPercentageForPrimary\" : true, \"showPositiveOrNegativeForPrimary\" : true,\"summarySecondary\":35.09,\"showPercentageForSecoundary\" : true, \"showPositiveOrNegativeForSecoundary\" : true}",
        vizState: "{\"chartType\":\"multiBar\",\"pivotConfig\":{\"x\":[\"TrafficSource.percentagediff\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.percentagediff\"]}}"
    },
    // {
    //     id: "23",
    //     layout:  "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
    //     name: "Multi Line chart",
    //     summary: "{\"layoutType\": \"plain\",\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}"
    // },
     {
        id: "25",
        layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
        name: "Cookies 1",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 25, \"showPercentageForPrimary\" : true, \"showPositiveOrNegativeForPrimary\" : true,\"summarySecondary\": \"Sample Text\",\"showPercentageForSecoundary\" : false, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"TrafficSource.title\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"TrafficSource.count\"],\"timeDimensions\":[],\"order\":{\"TrafficSource.count\":\"desc\"},\"dimensions\":[\"TrafficSource.title\"]}}",
    },
    {
        id: "26",
        layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":11}",
        name: "Table 1",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 95, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\": \"Dummy Text\",\"showPercentageForSecoundary\" :false, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Alertsummary.Month\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"dimensions\":[\"Alertsummary.Month\"],\"timeDimensions\":[{\"dimension\":\"Alertsummary.ProcessedTime\"}],\"order\":[[\"Alertsummary.Month\",\"asc\"]],\"limit\":5,\"measures\":[\"Alertsummary.SMS\",\"Alertsummary.HTMLEmail\",\"Alertsummary.TextEmail\",\"Alertsummary.Total\"]}}"
    },
    {
        id: "27",
        layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":10}",
        name: "Table 2",
        summary: "{\"layoutType\": \"plain\", \"summaryPrimary\": 95, \"showPercentageForPrimary\" :true, \"showPositiveOrNegativeForPrimary\" :true,\"summarySecondary\": \"Dummy Text\",\"showPercentageForSecoundary\" :false, \"showPositiveOrNegativeForSecoundary\" :false}",
        vizState: "{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"EventsList.Title\",\"EventsList.Languagecode\",\"EventsList.Location\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"EventsList.Title\":\"desc\"},\"limit\":5,\"dimensions\":[\"EventsList.Title\",\"EventsList.Languagecode\",\"EventsList.Location\"]},\"filters\":[{\"member\": \"EventsList.Clientid\", \"operator\": \"equals\",\"values\":[\"29\"]},{\"member\":\"EventsList.Desscription\", \"operator\": \"set\"}]}"
    }
   
];