import SECFilings from '../../components/TnfAnalytics/SECFilings'


export const SECFilingsPage = () => {
    return (
        <>
            <SECFilings />
        </>
    )
}
