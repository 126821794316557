export const EMAIL_PATTERN = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
export const ALPHABETS_NUMBER_PATTERN = "^([A-Za-z0-9])+$";
export const ALPHABETS_WITH_SPACE_PATTERN = "/^[a-zA-Z ]*$/";
export const ALPHABETS_NUMBER_AND_UNDERSCORE_PATTERN = "^[A-Za-z0-9_]+$";
export const POSITIVE_NUMER_PATTERN = "^[1-9]\d*$";
export const PASSWORD_PATTERN = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()]).{8,15}$";
export const ERROR_TIMEOUT = 2500;
export const NULL_VALUE_MESSAGE_STRING = "N/A";
export const EMPTY_VALUE_MESSAGE_STRING = "";
export const NULL_VALUE_MESSAGE_DROPDOWN = "Select";
export const TYPE_ICON = "icon";
export const NO_TABLE_DATA_MESSAGE = "No Data Found";
export const TABLE_TOTAL_ROWS = 15;
export const DEFAULT_PAGE_NO = 1;
export const TIMEOUT_FOR_ALERTS = 10000;
export const DEFAULT_TIMEOUT = 5000;
export const MAX_CHARACTERS_FOR_TEXT_AREA = "500";
export const customDurationIds = [3];
export const FILE_UPLOADER_MAX_SIZE = 2097152; // 2MB  
// export const FILE_UPLOADER_MAX_SIZE = 2048 ; // 2KB 

export const HiddenMenus = [2, 7];

export const fileUploadExtensions = ["pdf", "jpeg", "jpg", "png"];

export class OPERATION_TYPE {
  static EDIT = "edit";
  static CANCEL = "cancel";
  static SEND = "send";
}

export const AlertVarients = {
  "success": "success",
  "error": "danger",
  "warning": "warning",
  "information": "info"
}

export const BadgetsVarients = {
  "success": "success",
  "error": "danger",
  "warning": "warning",
  "light": "light"
}

export const DEFAULT_WEBSITE_DASHBORD = {
  "WebsiteList.WebsiteId": 12007,
  "WebsiteList.WebsiteName": "premium"
};

export const DEFAULT_PROFILE_DASHBORD = {
  "WebsiteProfiles.AccountId": "133834349",
  "WebsiteProfiles.DisplayName": "Connect.ID Intelligence Website"
};
export const CARD_MOBILE_LAYOUT = {
  "x": 0,
  "y": 16,
  "w": 12,
  "h": 8
};


export const LANGUAGE_LIST = {
  "eng_uk": "English UK",
  "eng_us": "English US",
  "deut": "Deutsch",
  "suo_kie": "Suomen kieli"
};

export const Date_Range_List = {
  "30_days": "Last 30 Days",
  "90_days": "Last 90 Days",
  "180_days": "Last 180 Days",
  "custom_range": "Custom Range"
};

export const optionalSummary = [
  {
    id: 1,
    title: "Total",
    values: [
      {
        key: "TrafficSource.count",
        value: 120000
      },
      {
        key: "Alertsummary.HTMLEmail",
        value: 1111
      },
      {
        key: "Alertsummary.TextEmail",
        value: 2222
      },
      {
        key: "Alertsummary.SMS",
        value: 3000
      },
      {
        key: "Alertsummary.Total",
        value: 2000
      }
    ],
    keys: ["TrafficSource.count", "Alertsummary.SMS", "Alertsummary.Total", "Alertsummary.HTMLEmail", "Alertsummary.TextEmail"]
  }
  // ,
  // {
  //   id: 2,
  //   title: "Sum",
  //   values: [
  //     {
  //       key: "Alertsummary.Total",
  //       value: 8000
  //     }
  //   ],
  //   keys: ["Alertsummary.Total"]

  // }
];

export const hideMetricsList = ["visitorCompanies", "allTabTrafficeSource", "watchlistTabTrafficeSource", "allPagesTabTrafficeSource", "landingPagesTabTrafficeSource", "alertAnalyticsMulitline", "PageviewsandUsers"];

export const tbHeaderWithSorting = ["TrafficSource.title", "Alertsummary.Month",
  "Alertsummary.Total", "EventsList.Title", "EventsList.Languagecode", "Visitor_Pageviews.pageviewcount", "Visitors.companycountry", "Googleanalyticsdata.pagetitlepath", "Googleanalyticsdata.avgtimeonpage", "Googleanalyticsdata.pageviews", "Alertanalytics.AlertDetails", "Alertanalytics.OpenCountval", "Alertanalytics.ClickCount", "Subscribers.Email", "Subscribers.LanguageCode", "Subscribers.UpdateDt", "EventsList.Startdate", "EventsList.Enddate", "Videoanalytics.name", "Videoanalytics.email", "Videoanalytics.vieweddate", "Videoanalytics.connecttime", "Users.email", "Users.nameandjob", "Users.registrationdate", "Users.eventaccessstatus", "Midocuments.Uploadedby", "Midocuments.uploadeddt", "Viewerevents.nameandemail", "Viewerevents.value", "Viewerevents.timeutc", "ViewereventsViewerdata.viewerdataMetadataName", "ViewereventsViewerdata.viewerdataEmail", "ViewereventsViewerdata.viewerdataRegistrationdate", "ViewereventsViewerdata.viewerdataViewdate", "ViewereventsPlayerevents.ctime", "ViewereventsPlayerevents.dtime", "ViewereventsPlayerevents.duration"];

export const tbHeaderReplace = ["measures", "value"];

export const tbRowWithImage = [""];

export const tbRowWithToggle = ["Alertsummary.HTMLEmails", "Notifications.Active", "Watchlist.active", "Watchlist.watchlistactive", "notifications.Active"];
export const tbRowWithMultipleAction = ["ManualAlert.Actions", "EventTable.Actions", "DocumentTable.Actions", "Midocuments.Actions", "pagesId", "Watchlist.pagesId", "AlertysTable.Action"];
export const tbRowWithBadge = ["EventsList.Eventstatus", "Notifications.Ismailsent", "NotificationsHistory.Activehistory", "notifications.Ismailsent"];

export const tbRowWithCheckBox = ["WebcastDigestTable.Status", "WebsiteDigestTable.Weekly", "WebsiteDigestTable.Monthly", "WebsiteDigestTable.Yearly", "WebsiteDigestTable.Biweekly", "AppDigestTable.Status", "Alertsummary.TextEmails", "watched", "Watchlist.delete", "Visitors.iswatchlist", "Miuserwebsitedigest.weekly", "Miuserwebsitedigest.monthly", "Miuserwebsitedigest.yearly", "Userwebcastdigest.Finalstatus", "WebsitesforAppdigest.monthly", "Midocuments.Documentcheck"];
export const tbRowWithDateAndTimeWidthGMT = ["QuestionTime",];

export const tbRowWithDateAndTime = ["DocumentTable.UploadedDateTime", "CustomalertsCategories.PublishDateTime", "Archivealerts1.ProcessedTime",
  "Users.registrationdate", "AlertysTable.Updated", "Videoanalytics.vieweddate", "Viewerevents.timeutc", "Alertcorporate.Date", "EventsList.Startdate", "EventsList.Enddate", "ViewereventsViewerdata.viewerdataRegistrationdate", "Miworkorderreport.createddt", "AlertysTable.Date", "processedDateTime",
];
export const tbRowWithDateOnly = ["Subscribers.UpdateDt", "SubscribersCas2.UpdateDt", "ViewereventsViewerdata.viewerdataViewdate", "Users.registrationdate", "Videoanalytics.vieweddate", "ViewereventsViewerdata.viewerdataRegistrationdate", "Notifications.Createdt", "NotificationsHistory.Updatedt", "Miworkorderreportdetail.workorderdate", "registrationDate", "updatedDate"];
export const tbRowWithTimeOnly = ["ViewereventsPlayerevents.playereventsEventtime", "Videoanalytics.connecttime", "ViewereventsPlayerevents.ctime", "ViewereventsPlayerevents.dtime"];
export const tbRowWithMonthOnly = ["Alertsummary.ProcessedTime.month"];


export const tbRowWithPercentage = ["Alertcorporate.OpenRate", "Alertcorporate.CountClickThroughRate", "ContentInsights.BounceRate"];

export const tbRowWithDecimalNumber = ["Googleanalyticsdata.avgtimeonpage"];
export const tbRowWithDecimalNumberPercentage = ["Googleanalyticsdata.bouncerate"];
export const tblRowWithReplaceData = ["Visitor_Pageviews.directpageviewcount", "Visitor_Pageviews.referralpageviewcount",
  "Visitor_Pageviews.organicpageviewcount", "Visitor_Pageviews.socialpageviewcount", "Visitor_Pageviews.paidpageviewcount"];

export const tbRowWithIntegerNumber = ["Additionalinsights.countryclickcount", "Additionalinsights.countryopencount"];
export const tbRowWithLargeDatawithToolTipLongText = ["pagesTitle"];
export const tbRowWithLargeData = ["EventTable.Category", "EventTable.Events", "DocumentTable.DocTitle", "Googleanalyticsdata.pagetitlepath", "companyDetailsVA", "Watchlist.cmp_name", "Videoanalytics.name", "ViewereventsViewerdata.Name", "ViewereventsViewerdata.viewerdataMetadataName",
  "Viewerevents.nameandemail", "Users.nameandjob", "Alertcorporate.AlertDetails", "Archivealerts1.AlertDetails", "EventsList.Event", "Visitors.companycountry", "email", "name", "ContentInsights.Page", "messageSubject", "AlertysTable.ConsentProvided", "Email"
  , "pageCompanyCountryTitle", "companyCountryName"];
export const tbRowWithDifferentDatabase = ["Notifications.VisitorandCountry"];
export const tbRowWithLink = ["ReportHistory.ReportName", "notifications.Edit", "notifications.History", "Watchlist.delete", "Subscribers.Edit", "Subscribers.Delete", "Miworkorderreport.workorderreportname", "AlertysTable.Actions"];
export const tbRowWithCheckBoxSubscribe = ["Miuserwebsitedigest.weekly", "Miuserwebsitedigest.monthly", "Miuserwebsitedigest.yearly", "Userwebcastdigest.Finalstatus", "WebsitesforAppdigest.monthly"]

export const EMAIL_REGEX = /^([\w-']+(?:\.[\w-']+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,66}(?:\.[a-z]{2})?)$/i;
export const VALIDATE_URL_REGEX = /^(http:\/\/|https:\/\/){1}?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
export const ValidatePasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/;

export const EDITOR_API_KEY = "cusdapnwwhuz7xvb5putwhkp3f9735bnyfl244l8vii01iby";

export const tbRowWithMultiText = [
  {
    key: "Alertsummary.SMSs",
    value: ["Alertsummary.HTMLEmails", "Alertsummary.TextEmails"]
  },
  {
    key: "EventsList.Event",
    value: ["EventsList.Title", "EventsList.Description"]
  },


];
export const tbColumnHide = ["ManualAlert.MessageId", "EventTable.TBCEvent", "EventTable.TimeZoneOffSet", "EventTable.Attachments", "EventTable.EventId", "DocumentTable.DocumentName", "DocumentTable.DocumentPath", "DocumentTable.DocumentType", "DocumentTable.Description", "DocumentTable.UploadedDate", "DocumentTable.UploadedById", "DocumentTable.IsDocumentOwner", "AppDigestTable.WebsiteId", "WebsiteDigestTable.UserWebsiteId", "ReportHistory.ReportId", "Miuserwebsites.userwebsiteid", "Flurryanalytic.id",
  "Watchlist.Watchlistid", "time.day", "Watchlist.vistorcomp", "Watchlist.Visitorid",
  "Watchlist.Visitorcountryid", "Watchlist.Profileid", "Alertsummary.ProcessedTime",
  "Miworkorderreport.reportstartdate", "Miworkorderreport.reportenddate", "Miworkorderreport.reportid",
  "Miworkorderreport.totalbillablehours", "Miworkorderreport.RemainingHours", "PresentationPollsQaDetails.answersValue", "PresentationPollsQaDetails.answersCount",
  "PresentationsPollsQA.answersValue", "PresentationsPollsQA.answersCount", "PresentationsPollsQA.questionid",
  "Visitors.VisitorId", "Visitors_country.Id", "PresentationPollsQaDetails.countquestions", "PresentationPollsQaDetails.countanswers", "Viewerevents_view.questionApprovedfor",
  "Viewerevents.presentationid", "Viewerevents.id", "AlertysTable.SubscriberId"];


export const dispalyTableRow = (data) => {

  const displayData = (data || data === 0) ? data : "-";//(!isNaN(data) ? 0 : NULL_VALUE_MESSAGE_STRING);
  return displayData;

};

export const checkArrayElementsAreSame = (array1, array2) => {
  let objectsAreSame = false;
  array1.forEach((arr1) => array2.forEach((arr2) => {
    if (arr1 === arr2) {
      objectsAreSame = true;
    }

  }));
  // for(let propertyName in x) {
  //    if(x[propertyName] !== y[propertyName]) {
  //       objectsAreSame = false;
  //       break;
  //    }
  // }
  return objectsAreSame;
}


export function object_equals(x, y) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof (x[p]) !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!object_equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y)
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p))
      return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export const getValue = (obj, expression) => {
  try {
    return expression.split(".").reduce((o, x, i) => {
      if (i === 0) {
        return o;
      }
      return typeof o === "undefined" || o === null ? undefined : o[x];
    }, obj);
  } catch (e) {
    console.error("Error: " + e);
    return undefined;

  }
};

export const isEmpty = value => {
  return (
    !value || value === null ||
    (typeof value === "string" && value.trim() === "")
  );
};

export const isNumeric = value => {
  if (!value) return true;
  const reg = new RegExp("^[0-9]+$");
  return reg.test(value);
};

export const isValueValid = (value, regExp) => {
  const reg = new RegExp(regExp);
  return reg.test(value);
};

export const isValueMatch = (value, matchValue) => {
  return value == matchValue;
};

export const isLessThanMinLength = (value, length) => {
  return value.length <= length;
};

export const isGreaterThanMaxLength = (value, length) => {
  return value.length >= length;
};

export const checkValueExistsInObject = (obj, value) => {
  let isExist = false;
  Object.keys(obj).forEach(function (key) {
    if (obj[key] === value) {
      isExist = true;
    }
  });

  return isExist;
}
//Uncomment these objects to make it visible in dropdown on login page, Also check i18n.js file.
export const header_languages = [
  { id: 'en-GB', title: 'English (UK)' },
  { id: 'en-US', title: 'English (US)' },
  // { id: 'de-DE', title: 'Deutsch' }, 
  // { id: 'fi-FI', title: 'Suomen kieli' },
  // { id: 'sv-SE', title: 'Svenska' },
  // { id: 'es-ES', title: 'Espa\u00f1ol' },
];
export const logout_options = [

  { id: 'settings', title: 'Settings' },
  { id: 'logout', title: 'Log out' },
];
export const channelOverviewDetails = ["Website", "Social Media"];
export const dropdownWithDefaultValue = ["WebsiteList.WebsiteName", "WebsiteProfiles.DisplayName", "Languages.languagename", "Events.title", "Presentations.name", "Reports.Reporttitle", "WebsiteProfiles.websiteandprofile", "Profilesection.displayname", "year", "Miworkordercompanymapping.workordercompanyname"];

export const summaryDetails = [
  {
    title: "Audience trends",
    primarySummary: "",
    secondarySummary: "",
    layoutType: "carosal",
  },
  {
    title: "Cookies",
    primarySummary: "",
    secondarySummary: "of visitors decilined analytics cookies",
    layoutType: "carosal",
  },
  {
    title: "User",
    primarySummary: "",
    secondarySummary: "",
    layoutType: "carosal",
  },
  {
    title: "Top 5 countries",
    primarySummary: "",
    secondarySummary: "",
    layoutType: "plain",
  },
  {
    title: "Content Insight",
    primarySummary: "",
    secondarySummary: "Pages",
    layoutType: "plain",
  },
  {
    title: "Content Insights",
    primarySummary: "",
    secondarySummary: "page views",
    layoutType: "plain",
  },
  {
    title: "Search",
    primarySummary: "",
    secondarySummary: "Total queries",
    layoutType: "plain",
  },
  {
    title: "Traffic source",
    primarySummary: "",
    secondarySummary: "",
    layoutType: "carosal",
  },
  {
    title: "Questions",
    primarySummary: "",
    secondarySummary: "Questions",
    layoutType: "plain",
  },
  {
    title: "Polls",
    primarySummary: "",
    secondarySummary: "Polls",
    layoutType: "plain",
  },
  {
    title: "Webcast Viewers",
    primarySummary: "",
    secondarySummary: "Total Viewers",
    layoutType: "plain",
  },
  {
    title: "Users",
    primarySummary: "",
    secondarySummary: "users",
    layoutType: "plain",
  },
  {
    title: "Live",
    primarySummary: "",
    secondarySummary: "viewers",
    layoutType: "plain",
  },
  {
    title: "On demand",
    primarySummary: "",
    secondarySummary: "viewers",
    layoutType: "plain",
  },
  {
    title: "Alerts",
    primarySummary: "",
    secondarySummary: "alerts",
    layoutType: "plain",
  },
  {
    title: "Notifications",
    primarySummary: "",
    secondarySummary: "notifications",
    layoutType: "plain",
  }
  , {
    title: "List of all the email alerts sent. You can download the detailed list of subscribers in Excel friendly format.",
    primarySummary: "",
    secondarySummary: "alerts",
    layoutType: "plain",
  },
  {
    title: "View and manage the events available on the events calendar. View existing events according to the language. Filter events based on the year and the event category. Edit existing events and attachments from the actions column.",
    primarySummary: "",
    secondarySummary: "events",
    layoutType: "plain",
  },
  {
    title: "Visitor Insights",
    primarySummary: "",
    secondarySummary: " Companies",
    layoutType: "plain",
  },
  {
    title: "Watchlist",
    primarySummary: "",
    secondarySummary: " Watchlist",
    layoutType: "plain",
  },
  {
    title: "Documents",
    primarySummary: "",
    secondarySummary: " documents",
    layoutType: "plain",
  },
  {
    title: "Visitors",
    primarySummary: "",
    secondarySummary: " visitors",
    layoutType: "plain",
  }
  // ,
  // {
  //   title:"The manual alerts listed below need to be approved before they are sent to your subscribers. If you don’t want to send the manual alert, select Cancel.",
  //   primarySummary: "",
  //   secondarySummary: "alerts",
  //   layoutType: "plain",
  // },


];

export const primarySummaryPositiveOnTitle = ["Top 5 countries"];
export const primarySummaryPercentageOnTitle = ["Top 5 countries", "Cookies"];

export const secoundarySummaryPositiveOnTitle = ["Audience trends", "Traffic source", "User"];
export const secoundarySummaryPercentageOnTitle = ["Audience trends", "Traffic source", "User"];


export const summaryFromCubeDetails = ["Cookies", "Watchlist", "Visitor Insights", "Audience trends", "User", "Notifications", "Documents", "Top 5 countries", "Content Insights", "Content Insight", "Search", "Traffic source", "Questions", "Polls", "Webcast Viewers", "Users", "Live", "On demand", "Alerts", "List of all the email alerts sent. You can download the detailed list of subscribers in Excel friendly format.", "View and manage the events available on the events calendar. View existing events according to the language. Filter events based on the year and the event category. Edit existing events and attachments from the actions column.", "Visitors"];

export const CAROSAL_LAYOUT_TYPE = "carosal";

export const ChannelID = {
  Alerts: 8,
  Website: 1,
  Webcasting: 5,
  Settings: 0,
  TnfAnalytics: 41,
  Tools: 6
}

export const formatCount = (count) => {
  if (count >= 1000000000) {
    return (count / 1000000000).toFixed(2) + "B";
  } else if (count >= 1000000) {
    return (count / 1000000).toFixed(2) + "M";
  } else if (count >= 1000) {
    return (count / 1000).toFixed(2) + "K";
  }
  return count.toString();
}