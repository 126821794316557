import request from "../request"
import { baseURL, METHODS } from "../utilities/constant";
import { decryptData } from "../../utils/crypto";

export function GetCategoryWisePageViewsPieChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetCategoryWisePageViews?type=piechart&profileid=${payload.profileId}&startDate=${payload.startDate}&endDate=${payload.endDate}&sectionId=${payload.selectedCategoryId}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {

            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetCountryWisePageViewsPieChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetCountryWisePageViews?type=piechart&profileid=${payload.profileId}&startDate=${payload.startDate}&endDate=${payload.endDate}&sectionId=${payload.selectedCategoryId}&packageid=5&culture=${payload.culture}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {

            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetTrafficSourceByProfileIdPieChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetTrafficSourceByProfileId?type=piechart&profileid=${payload.profileId}&startDate=${payload.startDate}&endDate=${payload.endDate}&sectionId=${payload.selectedCategoryId}&companyid=${payload.companyId}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {

            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetVisitorInsightsMultilineChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=${payload.profileId}&sectionid=${payload.selectedCategoryId}&startDate=${payload.startDate}&endDate=${payload.endDate}&packageid=5`,
        //  baseURL + `/Intelligence/GetIntelligencePageViewSeriesByProfileId?profileId=${payload.profileId}&sectionid=${payload.selectedCategoryId}&startDate=${payload.startDate}&endDate=${payload.endDate}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetPagesTrafficSourceBarChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetTrafficSourceByPage?profileid=${payload.profileId}&startdate=${payload.startDate}&enddate=${payload.endDate}&pageid=${payload.pageId}&companyid=${payload.companyId}&packageid=5`,
        //  baseURL + `/Intelligence/GetIntelligencePageViewSeriesByProfileId?profileId=${payload.profileId}&sectionid=${payload.selectedCategoryId}&startDate=${payload.startDate}&endDate=${payload.endDate}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetPagesTrafficSourceLineChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetPageViewVisitsWithPRByPage?profileid=${payload.profileId}&startdate=${payload.startDate}&enddate=${payload.endDate}&pageid=${payload.pageId}&companyid=${payload.companyId}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetPagesEntranceTrafficSourceBarChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetTrafficSourceByEntrancePage?profileid=${payload.profileId}&startdate=${payload.startDate}&enddate=${payload.endDate}&pageid=${payload.pageId}&companyid=${payload.companyId}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetPagesViewedLineChart(cryptoKey, payload, callBackFn) {  
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=${payload.profileId}&startdate=${payload.startDate}&enddate=${payload.endDate}&visitorid=${payload.visitorid}&countryid=${payload.countryid}&sectionid=${payload.selectedCategoryId}&packageid=5&selectedcompanyid=${payload.companyId}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function GetAllPopupTrafficSourceBarChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${payload.profileId}&startdate=${payload.startDate}&enddate=${payload.endDate}&companyid=${payload.visitorid}&countryid=${payload.countryid}&selectedcompanyid=${payload.companyId}&sectionid=${payload.selectedCategoryId}&packageid=5`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}