import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";
import ToolsOverview from '../../components/TnfAnalytics/ToolsOverview'
import { Row, Col, Image, Button, Card } from '@themesberg/react-bootstrap';
import CommonHeader from '../../components/CommonHeader';
import AppContext from '../../components/AppContext';
import { object_equals } from '../../AppConfig';
import request, { requestPDF } from '../../apis/request';
import { METHODS, baseURL } from '../../apis/utilities/constant';
import { decryptData } from '../../utils/crypto';
import LoaderImage from "../../assets/img/LoaderGraphic.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AiIcon from "../../assets/img/AI-icon.svg";
import ModalComponent from "../../components/VoltModal";
import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm'


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
// import AiIcon from "../../assets/img/AI-icon.svg";
// import ModalComponent from "../../components/VoltModal";


export const ToolsOverviewPage = (props) => {

  const [t] = useTranslation();
  const contextData = useContext(AppContext);

  const [websiteList, setWebsiteList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);
  //const [profileId, setProfileId] = useState(0);

  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState(websiteList[0]);
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState({})
  const [autoLoad, setAutoLoad] = useState(false);

  const [AITabClick, setAITabClick] = useState(false)
  const [peersClickData, setPeersClickData] = useState("")
  const [peersNewsList, setPeersNewsList] = useState("")
  const [peersNewsClickData, setPeersNewsClickData] = useState("")
  const [peersNewsDetails, setPeersNewsDetails] = useState("")
  const [loading, setLoading] = useState({
    isNewsListLoading: false,
    isNewsSummaryLoading: false,
    isViewOriginalClicked: false
  })

  useEffect(() => {
    if (contextData.companyId > 0) {

      setAutoLoad(false);
      if (localStorage.getItem("TnfWebsiteData") && JSON.parse(localStorage.getItem("TnfWebsiteData")).length > 0
        && localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("TnfWebsiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId"));
        renderWebsite(websiteList, selectedWebsite)

      } else if (!localStorage.getItem("TnfWebsiteData") || !localStorage.getItem("TnfAllProfileData")
        || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0 || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {

        if (!localStorage.getItem("TnfWebsiteData") || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0) {
          getWebsites();
        }
        else if (!localStorage.getItem("TnfAllProfileData") || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {
          getProfiles(JSON.parse(localStorage.getItem("TnfWebsiteData")));
        }
      }

    }
  }, [contextData.companyId])

  const getWebsites = () => {



    request(baseURL + `/TnfAnalytics/GetTnFChannelWebsiteByClientUser?CompanyId=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {
          localStorage.setItem("TnfWebsiteData", JSON.stringify(resultData))
          getProfiles(resultData)
        }
        else {
          localStorage.setItem("TnfWebsiteData", JSON.stringify([]))
          localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
        }

      })
      .catch((err) => {

      })

  }

  const getProfiles = (resultData) => {
    // setBlurLoader(true);

    request(baseURL + `/TnfAnalytics/GetTnFWebsites?CompanyId=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        if (res.result != null && res.result.length > 0) {
          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("TnfAllProfileData", JSON.stringify(result))

          // let selectedWebsiteProfiles = result.filter((e) => { return e.WebsiteId === resultData[0]["WebsiteList.WebsiteId"] });
        } else {

          localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
        }


        if (!localStorage.getItem("TnfWebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
        }
        // localStorage.setItem("TnfWebsiteData", JSON.stringify(resultData))
        // renderWebsite(resultData, resultData[0])
        // setBlurLoader(false);
        if (res.result.length == 0) {
          setAutoLoad(true);
        }
      })
      .catch((err) => {
        // setBlurLoader(false);
      })

  };

  const renderWebsite = (resultSet, selectedWebsiteValue) => {
    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList(selectedWebsiteValue, 0);
  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {
    if (localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

      let profileList = JSON.parse(localStorage.getItem("TnfAllProfileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

      if (selectedWebsiteProfiles.length > 0) {
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

        setProfilesList(profileData)

        let selectedProfile = profileData[0];


        if (isTempUpdate === 0) {
          //   if (localStorage.getItem("TnfWebsiteProfilesIntelConfigId")) {
          let storedProfile = JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };
          //  }
          localStorage.setItem("TnfProfileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);
      }
      else {
        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("TnfProfileData", JSON.stringify([]))
        }
      }
    }
    setAutoLoad(true)
  }

  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }

  const onWebsiteDropdownChange = (data) => {
    // if (!object_equals(selectedWebsiteIdNew, data)) {
    //   setSelectedProfileIdNew({});
    // }
    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)
  };

  const applyFilters = () => {
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);


    if (JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {
      contextData.changeSelectedProfile(selectedProfileIdNew);
    }


    // if (selectedProfileIdNew) {
    //   setProfileId(selectedProfileIdNew["WebsiteProfiles.ProfileId"]);
    // }
    if (profilesList.length > 0) {
      localStorage.setItem("TnfProfileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("TnfProfileData", JSON.stringify([]))
    }
    localStorage.setItem("TnfWebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("TnfWebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId")))
  }


  const commonHeaderChild = useMemo(() =>
    <>
      <CommonHeader channel="website" cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
        { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
        { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
      ]}
        title={t("header.Tools_Overview")}
        showDate={true}
        applyFilters={applyFilters}
      />
    </>,
    [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])

  const peersList = [
    {
      peers: "Sainsbury",
      type: "RNS",
      clientId: "162",
      list_type: "rns"
    }
  ];
  // const newsList = [
  //   {
  //     title: "Title 1",
  //     summary: "Short summery of news",
  //     shortSumary: "Short summery of news - max 2 lines"
  //   },
  //   {
  //     title: "Title 2",
  //     summary: "Short summery of news",
  //     shortSumary: "Short summery of news - max 2 lines"
  //   },
  //   {
  //     title: "Title 3",
  //     summary: "Short summery of news",
  //     shortSumary: "Short summery of news - max 2 lines"
  //   },
  // ]

  const handleAITabClose = () => {
    setAITabClick(false);
    setPeersClickData("");
    setPeersNewsClickData("");
    setPeersNewsList("")
    setPeersNewsDetails("")
  }

  const handlePeersListClick = (data) => {
    // setLoading(true)
    setLoading((prev) => ({ ...prev, isNewsListLoading: true }));
    request('https://idx-otp-ai.azurewebsites.net/api/summarize',
      METHODS.POST,
      {
        "type": "list",
        "clientId": data.clientId,
        "list_type": data.list_type
      }
    )
      .then((response) => {
        if (response) {
          setPeersClickData(data)
          setPeersNewsList(response)
        }
        // setLoading(false)
        setLoading((prev) => ({ ...prev, isNewsListLoading: false }));
      })

  }

  const handlePeersNewsListClick = (data) => {
    // setLoading(true)
    setLoading((prev) => ({ ...prev, isNewsSummaryLoading: true }));
    request('https://idx-otp-ai.azurewebsites.net/api/summarize',
      METHODS.POST,
      {
        "type": "summary",
        "url": data.DocumentLinks.PDF
      }
    )
      .then((response) => {
        if (response) {
          setPeersNewsClickData(data)
          setPeersNewsDetails(response)
        }
        // setLoading(false)
        setLoading((prev) => ({ ...prev, isNewsSummaryLoading: false }));

      })

  }

  const handleOriginalClick = (data) => {
    setLoading((prev) => ({ ...prev, isViewOriginalClicked: true }));

    const url = data.DocumentLinks.PDF
    let fileName = `${data.Title}.pdf`;
    requestPDF(`${process.env.REACT_APP_API_KEY_NEW}/TnfAnalytics/FetchPdf?url=${url}`,
      METHODS.GET, {},
      { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}` }
    )
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          setLoading((prev) => ({ ...prev, isViewOriginalClicked: false }));

        }
        else {
          setLoading((prev) => ({ ...prev, isViewOriginalClicked: false }));
        }
      })
      .catch((err) => {
        setLoading((prev) => ({ ...prev, isViewOriginalClicked: false }));
        console.error('Error generating PDF:', err);
      })

  }
  // const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

  return (
    <>
      <Row className="align-items-center">
        <Col>
          {/* common header */}
          {commonHeaderChild}
        </Col>
      </Row>
      {contextData.selectedProfile["WebsiteProfiles.ProfileId"] &&
        <ToolsOverview
          profileID={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          startDate={contextData.startDate}
          endDate={contextData.endDate}
          intelConfigID={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
          cryptoKey={contextData.cryptoKey}
        />
      }
      {!contextData.selectedProfile["WebsiteProfiles.ProfileId"] && <h1 className='text-center'>connect.ID Intelligence</h1>}



      {/* AI Asistant tool */}
      {
      // selectedWebsiteIdNew && selectedWebsiteIdNew["WebsiteList.WebsiteId"] == 34721 &&
      //   <div class="ai-asist-tooltip">
      //     <span class="ai-asist-trigger" onClick={() => setAITabClick(true)}>
      //       {/* <FontAwesomeIcon icon={faHeadphones} className="" /> */}
      //       <Image className="" src={AiIcon} height={40} />

      //     </span>
      //     <div class="tooltiptext">Try our Peers AI Tool</div>
      //   </div>
      }


      <ModalComponent
        title={!peersNewsList && !loading ? "Explore your Peers activity!" : ""}
        subtitle={""}
        showCloseButton={true}
        open={AITabClick}
        handleClose={() => { handleAITabClose() }}
        className="visitor-analytics-modal tnf-insights-modal ps-0 pe-0">
        <>
          {loading.isNewsListLoading || loading.isNewsSummaryLoading || loading.isViewOriginalClicked ?
            <>
              <div className={`preloader loader-center-align alert-analytics ${loading ? "" : "show fff"}`}>
                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
              </div>
              {
                loading.isNewsListLoading && <div className='AI-Loading'>Generating your personalized news feed...</div> ||
                loading.isNewsSummaryLoading && <div className='AI-Loading'>Please wait as we fetch the latest insights powered by AI</div> ||
                loading.isViewOriginalClicked && <div className='AI-Loading'>Fetching the original document... Your file will be ready shortly.</div>
              }
              {/* <div className='AI-Loading'>Generating your personalized news feed... Please wait as we fetch the latest insights powered by AI.</div> */}
            </>
            :
            <>
              {!peersNewsList &&
                <table className="table table-flush table-striped table-hover mb-3">
                  <thead>
                    <tr>
                      <th>Peers</th>
                      <th>Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {peersList && peersList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.peers}</td>
                        <td>{item.type}</td>
                        <td className="arrow-link">
                          <Card.Link className="ms-0 me-2" onClick={() => handlePeersListClick(item)}>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Card.Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
              {/* Explore your Peers activity! ends here */}

              {peersNewsList && !peersNewsDetails &&
                <div class="ai-asistant-landing mt-4">
                  <div className="modal-header">
                    <h2 className="modal-title">
                      <Card.Link className="me-2" onClick={() => setPeersNewsList("")}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Card.Link>
                      {peersClickData.peers}
                      <span className="ai-modal-subtitle">
                        {peersClickData.type}
                      </span>
                    </h2>
                  </div>
                  <table class="table table-flush table-striped table-hover mb-3">
                    <tbody>
                      {peersNewsList && peersNewsList.NewsSummaries.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span class="table-title-text">{item.Title}</span>
                            <span class="table-summery1"> {item.ShortDescription} </span>
                            {/* <span class="table-summery2">{item.shortSumary}</span> */}
                          </td>
                          <td className="arrow-link">
                            <Card.Link className="ms-0 me-2" onClick={() => handlePeersNewsListClick(item)}>
                              <FontAwesomeIcon icon={faArrowRight} />
                            </Card.Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
              {/* Detail table ends here */}

              {peersNewsDetails &&
                <div class="ai-asistant-detail">
                  <div class="page-heading modal-header">

                    <h2 className="modal-title">
                      <Card.Link className="me-2" onClick={() => setPeersNewsDetails("")}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Card.Link>
                      {peersClickData.peers}

                      <span className="ai-modal-subtitle">
                        {peersClickData.type}
                        {/* <i>|</i>
                        {peersNewsClickData.Title} */}
                      </span>
                    </h2>

                    <span class="original-button">
                      <Card.Link onClick={() => handleOriginalClick(peersNewsClickData)}>
                        Original
                        <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                      </Card.Link>
                    </span>
                  </div>
                  <div class="container">
                    <div class="left-content">
                      <ReactMarkdown children={peersNewsDetails.summary} />,
                    </div>

                    {/* <div class="right-content">
                <div class="right-container-details">
                  <div class="date">20 August 2024</div>
                  <table>
                    <tr>
                      <td>Sentiment</td>
                      <td class="emoji">&#128529;</td>
                      <td>Neutral</td>
                    </tr>
                  </table>
                </div>
              </div> */}

                  </div>
                </div>
              }
              {/* News details ends here */}
            </>
          }
        </>



      </ModalComponent>
    </>
  )
}
