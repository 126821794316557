import { Row, Col, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
function AlertsMarketingPage() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
            {/* <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <h2 className="h2 mb-0">{t("header.AlertMarketingTitle")}</h2>
                </Col>
            </Row> */}
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100 marketing-content">

                        <h2>{t("header.AlertsMarketingTitle")}</h2>

                        <h5>{t("header.AlertsMarketingContent1")}</h5>

                        <h5>{t("header.AlertsMarketingContent2")}</h5>
                        <p className='paragraph'>{t("header.AlertsMarketingContent3")}</p>

                        <h5>{t("header.ToolsMarketingContent4")}</h5>
                        <p className='paragraph'>{t("header.AlertsMarketingContent5")}</p>

                        <h5>{t("header.AlertsMarketingContent6")}</h5>
                        <p className='paragraph'>{t("header.AlertsMarketingContent7")}</p>

                        <h5>{t("header.AlertsMarketingContent8")}</h5>
                        <p className='paragraph'>{t("header.AlertsMarketingContent9")}</p>

                        <h5>{t("header.AlertsMarketingContent10")}</h5>
                        <p className='paragraph'>{t("header.AlertsMarketingContent11")}</p>

                        <>
                            {/* <p>{t("header.AlertMarketingContent1")}</p>
                            <h6 className="h6 mt-4">
                                {t("header.AlertMarketingContent2")}
                            </h6>

                            <p>{t("header.MarketingQuestion")} {t("header.AlertMarketingContent3")}</p>
                            <h6 className="h6 mt-4"> {t("header.AlertMarketingContent4")}
                            </h6>

                            <p>{t("header.MarketingQuestion")} {t("header.AlertMarketingContent5")}</p>
                        
                            <h6 className="h6"> </h6>
                            <p className="mb-0 mt-3">
                            <a href="#" className="link-primary">{t("header.AlertMarketingContent6")}  </a>   {t("header.AlertMarketingContent7")}
                            </p> */}
                        </>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AlertsMarketingPage