import { Row, Col, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
function WebcastMarketingPages() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
            {/* <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <h2 className="h2 mb-0">{t("header.WebcastMarketingTitle")}</h2>
                </Col>
            </Row> */}
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">

                        <h2>{t("header.WebcastMarketingTitle")}</h2>

                        <h5>{t("header.WebcastMarketingContent1")}</h5>

                        <h5>{t("header.WebcastMarketingContent2")}</h5>
                        <p className='paragraph'>{t("header.WebcastMarketingContent3")}</p>

                        <h5>{t("header.WebcastMarketingContent4")}</h5>
                        <p className='paragraph'>{t("header.WebcastMarketingContent5")}</p>

                        <h5>{t("header.WebcastMarketingContent6")}</h5>
                        <p className='paragraph'>{t("header.WebcastMarketingContent7")}</p>

                        <h5>{t("header.WebcastMarketingContent8")}</h5>
                        <p className='paragraph'>{t("header.WebcastMarketingContent9")}</p>

                        <h5>{t("header.WebcastMarketingContent10")}</h5>
                        <p className='paragraph'>{t("header.WebcastMarketingContent11")}</p>


                        <>
                            {/* <p>{t("header.WebcastMarketingContent1")}</p>
                            <h6 className="h6 mt-4"> {t("header.WebcastMarketingContent2")}
                            </h6>

                            <p> {t("header.MarketingQuestion")} {t("header.WebcastMarketingContent3")}</p>
                            <h6 className="h6 mt-4"> 
                            {t("header.WebcastMarketingContent4")}</h6>
                            
                            <p> {t("header.MarketingQuestion")} {t("header.WebcastMarketingContent5")}
                            </p>

                        
                            <p className="mb-0">
                                <a href="#" className="link-primary">{t("header.WebcastMarketingContent6")}</a> {t("header.WebcastMarketingContent7")}
                            </p> */}
                        </>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default WebcastMarketingPages