import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Image, Row, Nav, Tab, Dropdown, ButtonGroup, Alert, Button } from '@themesberg/react-bootstrap'
import { useTranslation } from 'react-i18next';
import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { DATE_FORMAT, DATE_FORMATNEW, TnfTableHeaders } from '../../utils/constant';
import { baseURL, METHODS } from '../../apis/utilities/constant.js';
import request, { requestPDF } from '../../apis/request.js';
import Search from "../../components/Search";
import { decryptData } from '../../utils/crypto.js';
import TnfVisitorAll from './TnfVisitorAll.js';
import TnfWatchlist from './TnfWatchlist.js';
import TnfAllPages from './TnfAllPages.js';
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from ".././Autocomplete";
import ButtonComponent from "../../components/Buttons";
import { ERROR_TIMEOUT } from "../../AppConfig";
import Filters from '../Filters.js';
import moment from 'moment';
import { convertBase64 } from '../../utils/common.js';

export const InsightsTables = React.memo(({ cryptoKey, ProfileId, StartDate, EndDate, IntelConfigId, CompanyId, UserId, ToolId, WebsiteData, ProfileData, CompanyName, ToolName }) => {
  const [tabSection, setTabSection] = useState(1); //Visitors = 1, Pages = 2
  const [activeTab, setActiveTab] = useState(1); // Visitors-All = 1, Visitors-Watchlist = 2 & Pages-All-Pages = 3
  const [searchData, setSearchData] = useState("");
  const [searchDataList, setSearchDataList] = useState([]);
  const [showAutoFillForSearch, setShowAutoFillForSearch] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [isPDFDownloadEnable, setIsPDFDownloadEnable] = useState(false);
  const [showAddWatchlist, setShowAddWatchlist] = useState(false);
  const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);
  const [autocompleteSelection, setAutocompleteSelection] = useState({ id: 0 });
  const [listMultiselect, setListMultiselect] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [disableAddWatchlistButton, setDisableAddWatchlistButton] = useState(true);
  const [dataWatchlist, setDataWatchlist] = useState({});
  const [checkCompanyName, setCheckCompanyName] = useState(false);
  const [autoTickData, setAutoTickData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [refreshWatchlist, setRefreshWatchlist] = useState(true);
  const [watchlistData, setWatchlistData] = useState(null);

  const [filterCountryList, setFilterCountryList] = useState([]);
  const [filterTempSelectedCountryList, setFilterTempSelectedCountryList] = useState({ countryId: 0, countryName: "All" });
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [filterCompleteCategoryList, setFilterCompleteCategoryList] = useState([]);
  const [filterTempSelectedCategoryList, setFilterTempSelectedCategoryList] = useState({ categoryId: 0, categoryName: "All" });
  const [filterSubCategoryList, setFilterSubCategoryList] = useState([]);
  const [filterTempSelectedSubCategoryList, setFilterTempSelectedSubCategoryList] = useState({ categoryId: 0, categoryName: "All" });
  const [selectedFilterItem, setselectedFilterItem] = useState({});
  const [toggle, setToggle] = useState(true);

  const [t] = useTranslation();

  useEffect(() => {
    setSearchData("");
    setShowAutoFillForSearch(false);
    setIsPDFDownloadEnable(false);
    setselectedFilterItem({});
    setFilterCountryList([])
    setFilterSubCategoryList([])
    setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" });
    setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
    setFilterTempSelectedSubCategoryList({ categoryId: 0, categoryName: "All" });
    visitorCategoryForDrowdown();
    setToggle(!toggle);
  }, [activeTab]);

  useEffect(() => {
    setSelectedOption([]);
    setListMultiselect([]);
    setDisableAddWatchlistButton(true);
  }, [checkCompanyName]);

  useEffect(() => {
    if (autocompleteSelection.id > 0) {
      request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceCountryByWatchedVisitor?companyid=` + autocompleteSelection.id, METHODS.GET, {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" })
        .then((response) => {
          if (dataWatchlist.some(item => item.CompanyId === autocompleteSelection.id) && response.length > 0) {
            const selectedcompanywatchlistdata = dataWatchlist.filter(item => item.CompanyId === autocompleteSelection.id);
            const commonRecords = findCommonRecords(selectedcompanywatchlistdata, response);
            setSelectedOption(commonRecords);
          }
          setListMultiselect(response);
        })
        .catch(() => {
        })
    }
    else {
      setListMultiselect([])
      setSelectedOption([])
    }
  }, [autocompleteSelection]);

  useEffect(() => {
    if (showAddWatchlist == true) {
      document.addEventListener('click', onClickOutsideListenerFilters);
    }
  }, [showAddWatchlist]);

  useEffect(() => {
    if (watchlistData) {
      setCountryListForFilter(watchlistData)
    }
    else {
      setFilterCountryList([]);
      setDataWatchlist([]);
      setAutoTickData([]);
    }
  }, [watchlistData]);

  const setDowloadDropdownMenu = () => {
    let ddList = ["Excel", "PDF"];
    return ddList.map(item => {
      return (
        <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
          {item}
        </Dropdown.Item>
      );
    })
  }

  const downloadGrid = (item) => {
    if (item === "Excel") {
      downloadExcel()
    }
    else {
      downloadPDF();
    }
  }

  const downloadExcel = async () => {
    setBlurLoader(true);
    let type = "All visitors";
    let url;
    let config;
    let filename = "";
    if (activeTab === 1) {
      type = "All visitors";
      url = "/VisitorAnalytics/ExportVisitorExcelFileWithEPPlus"
      filename = 'Insights_All_Visitors.xlsx';
      config = convertBase64(JSON.stringify({
        type: type,
        websiteName: WebsiteData["WebsiteList.WebsiteName"],
        pagesectionid: ToolName.toolID,
        pageSectionName: ToolName.title,
        packageid: 5,
        startDate: moment(StartDate).format(DATE_FORMAT),
        endDate: moment(EndDate).format(DATE_FORMAT),
        profileId: ProfileId,
        profileName: ProfileData["WebsiteProfiles.DisplayName"],
        intellConfigId: IntelConfigId,
        userid: UserId,
        companyid: CompanyId,
        CompanyName: CompanyName,
        culture: localStorage.getItem('i18nextLng'),
        isTF: 1,
        toolid: ToolId
      }))
    } else if (activeTab === 2) {
      type = "watched visitors";
      url = "/VisitorAnalytics/ExportVisitorExcelFileWithEPPlus"
      filename = 'Insights_Watched_visitors.xlsx';
      config = convertBase64(JSON.stringify({
        type: type,
        websiteName: WebsiteData["WebsiteList.WebsiteName"],
        pagesectionid: ToolName.toolID,
        pageSectionName: ToolName.title,
        packageid: 5,
        startDate: moment(StartDate).format(DATE_FORMAT),
        endDate: moment(EndDate).format(DATE_FORMAT),
        profileId: ProfileId,
        profileName: ProfileData["WebsiteProfiles.DisplayName"],
        intellConfigId: IntelConfigId,
        userid: UserId,
        companyid: CompanyId,
        CompanyName: CompanyName,
        culture: localStorage.getItem('i18nextLng'),
        isTF: 1,
        toolid: ToolId
      }))
    }
    else if (activeTab === 3) {
      type = "All Tools";
      url = "/VisitorAnalytics/ExportPageExcelFileWithEPPLus"
      filename = 'Insights_All_Tools.xlsx';
      config = convertBase64(JSON.stringify({
        type: type,
        websitename: WebsiteData["WebsiteList.WebsiteName"],
        sectionid: ToolName.toolID,
        pageSectionName: ToolName.title,
        startdate: moment(StartDate).format(DATE_FORMATNEW),
        enddate: moment(EndDate).format(DATE_FORMATNEW),
        profileid: ProfileId,
        profileName: ProfileData["WebsiteProfiles.DisplayName"],
        pagetype: 1,
        companyName: CompanyName,
        culture: localStorage.getItem('i18nextLng'),
        intelconfigid: IntelConfigId,
        userid: UserId,
        isTF: 1,
        toolid: ToolId
      }))
    }

    request(`${process.env.REACT_APP_API_KEY}${url}?encodeStr=${config}`, METHODS.GET, {},
      { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}` })
      .then((response) => {
        if (response) {
          let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
          let a = document.createElement("a");
          a.href = fetchDataModified;
          a.download = filename
          a.click();
        }
        setBlurLoader(false);
      })
      .catch(() => {
        setBlurLoader(false);
      })
  }

  const downloadPDF = async () => {
    setBlurLoader(true);
    let clientName = decryptData(localStorage.getItem("companyName"), cryptoKey);
    let url = "";
    let pageName = "Visitor Insights";
    let countTitle = "";
    let fileName = "";
    let filters = "";
    let tableHeaders = [];
    const selectedStartDate = moment(StartDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(EndDate).format("YYYY-MM-DD");
    if (activeTab === 1) {
      countTitle = "Total All Visitors: <span class='total-record-count'>{{count}}</span>";
      filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
      url = `${process.env.REACT_APP_API_KEY_NEW}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${ProfileId}&intellConfigId=${IntelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${CompanyId}&isTF=1&toolid=${ToolId}${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`;
      fileName = "Insights_All_Visitors.pdf";
      tableHeaders = TnfTableHeaders.VisitorAllTable;
    }
    else if (activeTab === 2) {
      countTitle = "Total Watchlist: <span class='total-record-count'>{{count}}</span>";
      filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
      url = `${process.env.REACT_APP_API_KEY_NEW}/WebsiteAnalytics/GetWatchedVisitorByWebsite?endDate=${selectedEndDate}&startDate=${selectedStartDate}&profileId=${ProfileId}&intellConfigId=${IntelConfigId}&companyid=${CompanyId}&isTF=1&toolid=${ToolId}${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`;
      fileName = "Insights_Watchlist.pdf";
      tableHeaders = TnfTableHeaders.WatchlistTable;
    }
    else if (activeTab === 3) {
      countTitle = "<span class='total-record-count'>{{count}}</span> Total Tools";
      filters = (searchData !== "" && searchData !== null) ? "filterybykey|contains|" + searchData : "";
      url = `${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetPagesDataByProfileId?profileid=${ProfileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${CompanyId}&isTF=1&toolid=${ToolId}&sortexpression=PageViews&sortdirection=true&intelconfigid=${IntelConfigId}`;
      fileName = "Insights_All_Tools.pdf";
      tableHeaders = TnfTableHeaders.AllPagesTable;
    }

    let obj = {
      Title: document.getElementById("websiteWebsiteProfile").innerHTML,
      SubTitle: document.getElementById("dateRange").innerHTML,
      PageName: pageName,
      CountTitle: countTitle,
      Filters: filters,
      ClientName: clientName,
      Url: url,
      Token: decryptData(localStorage.getItem("token"), cryptoKey),
      FileName: fileName,
      Email: decryptData(localStorage.getItem("email"), cryptoKey),
      Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
      Count: 0,
      FilterContent: "",
      TableHeaders: tableHeaders
    }

    requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
      METHODS.POST,
      obj,
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
      })
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          else {
            setAlertVisible(true);
            setTimeout(() => {
              setAlertVisible(false);
            }, 3000);
          }
          setBlurLoader(false);
        }
        else {
          setBlurLoader(false);
          console.error('Error generating PDF.');
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating PDF:', err);
      })
  };

  const onClickOutsideListenerFilters = () => {
    setSelectedOption([]);
    setListMultiselect([]);
    setDisableAddWatchlistButton(true);
    setAutocompleteSelection({ id: 0 });
    setShowAddWatchlist(false)
    document.removeEventListener("click", onClickOutsideListenerFilters)
  }

  const addWatchlistCompany = event => {
    event.preventDefault();
    if (autocompleteSelection.id > 0) {
      let acountries = [];
      let rcountries = [];
      if (dataWatchlist && dataWatchlist.length > 0) {
        if (dataWatchlist.some(item => item.CompanyId === autocompleteSelection.id)) {
          const selectedcompanywatchlistdata = dataWatchlist.filter(item => item.CompanyId === autocompleteSelection.id);
          for (const item1 of selectedOption) {
            if (!selectedcompanywatchlistdata.some(item => item.CountryId === item1.value)) {
              acountries.push({ CountryId: item1.value, CountryName: item1.label })
            }
          }
          for (const item2 of selectedcompanywatchlistdata) {
            if (!selectedOption.some(item => item.value === item2.CountryId)) {
              rcountries.push({ CountryId: item2.CountryId, CountryName: item2.countryName })
            }
          }
        }
        else {
          acountries = selectedOption.map(val => ({ CountryId: val.value, CountryName: val.label }));
        }
      }
      else {
        acountries = selectedOption.map(val => ({ CountryId: val.value, CountryName: val.label }));
      }
      let postobj = {
        CompanyId: autocompleteSelection.id, ProfileId: ProfileId
        , AddedCountries: acountries, RemovedCountries: rcountries, selectedcompanyid: CompanyId, packageid: 5,
        isTF: 1
      }
      setBlurLoader(true);
      setShowAddWatchlist(false);
      setRefreshWatchlist(false);
      request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/SaveWatchlistForUserMain`, METHODS.POST,
        postobj, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" })
        .then((response) => {
          let msg = "";
          if (acountries.length > 0 && rcountries.length > 0) {
            msg = "Visitor updated in your watchlist successfully";
            setRefreshWatchlist(true);
          }
          else if (acountries.length > 0) {
            msg = t("header.Message_Visitor_added_to_your_watchlist_successfully");
            setRefreshWatchlist(true);
          }
          else if (rcountries.length > 0) {
            msg = t("header.Message_Visitor_removed_from_your_watchlist_successfully");
          }

          setTimeout(() => {
            setSuccessMessage(msg);
          }, 500);

          setTimeout(() => {
            setSuccessMessage(undefined);
          }, ERROR_TIMEOUT);

          setSelectedOption([]);
          setListMultiselect([]);
          setDisableAddWatchlistButton(true);
          setAutocompleteSelection({ id: 0 });
          setBlurLoader(false);
          setRefreshWatchlist(true);
          localStorage.setItem("refreshCache", 1);
        })
        .catch(() => {
          setSelectedOption([]);
          setListMultiselect([]);
          setDisableAddWatchlistButton(true);
          setAutocompleteSelection({ id: 0 });
          setBlurLoader(false);
        })
    }
  }

  const onCloseSuccessMessage = () => {
    setSuccessMessage(undefined)
  };

  const sendErrorReport = () => {
    window.open(
      "mailto:" +
      "connect.id.admin@idx.inc" +
      "?subject=Tools Insights - Report an error" +
      "&body=" +
      ""
    );
  };

  const autoFillSelection = (obj) => {
    setAutocompleteSelection(obj);
    setListMultiselect([])
  }

  const setMultiselectOption = (data) => {
    let dbutton = true;
    if (dataWatchlist.some(item => item.CompanyId === autocompleteSelection.id)) {
      const selectedcompanywatchlistdata = dataWatchlist.filter(item => item.CompanyId === autocompleteSelection.id);
      let checked = false;
      for (const item1 of data) {
        if (!selectedcompanywatchlistdata.some(item => item.CountryId === item1.value)) {
          checked = true;
        }
      }
      for (const item2 of selectedcompanywatchlistdata) {
        if (!data.some(item => item.value === item2.CountryId)) {
          checked = true;
        }
      }
      if (checked == true) {
        dbutton = false;
      }
      else {
        dbutton = true;
      }
    }
    else if (data.length > 0) {
      dbutton = false;
    }
    else {
      dbutton = true;
    }
    setDisableAddWatchlistButton(dbutton);
    setSelectedOption(data)
  }

  const findCommonRecords = (array1, array2) => {
    const commonRecords = [];
    for (const item1 of array1) {
      const commonItem = array2.find(item2 => item2.value === item1.CountryId);
      if (commonItem) {
        commonRecords.push(commonItem);
      }
    }
    return commonRecords;
  }

  //#region Filter Dropdown
  const setCountryListForFilter = (data) => {
    if (data && data.data?.length > 0) {
      var list = [];
      list = data.country;
      if (list.length > 0) {
        list.splice(0, 0, { countryId: 0, countryName: "All" });
      }
      const uniqueCountries = [...new Map(list.map(item => [item.countryId, item])).values()];
      setFilterCountryList(uniqueCountries);
      setDataWatchlist(data.data);
      let autoticklist = []
      data.data.map(function (item) {
        autoticklist.push(item.companyName)
      });
      setAutoTickData(autoticklist);
    }
  }

  function onCountryDropDownChange(data) {
    if (data) {
      setFilterTempSelectedCountryList(data)
    }
  }

  const visitorCategoryForDrowdown = () => {
    request(baseURL + `/VisitorAnalytics/GetCategory`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.resultData !== null && res.resultData.length) {
          setFilterCompleteCategoryList(res.resultData);
          resultData = res.resultData.filter((item) => item.parentCategoryId == 0);
          resultData.splice(0, 0, { categoryId: 0, categoryName: "All" });
        }
        setFilterCategoryList(resultData);
      })
      .catch((err) => {

      })
  }

  function onCategoryDropdownChange(data) {
    if (data) {

      let filteredRecords = filterCompleteCategoryList.filter((item) => item.parentCategoryId == data.categoryId);

      if (filteredRecords.length > 0 && data.categoryId > 0) {
        filteredRecords.splice(0, 0, { categoryId: 0, categoryName: "All" });
        setFilterSubCategoryList(filteredRecords)
        setFilterTempSelectedSubCategoryList({ categoryId: 0, categoryName: "All" })
      }
      else {
        setFilterSubCategoryList([])
      }

      setFilterTempSelectedCategoryList(data)
    }
  }

  function onSubCategoryDropDownChange(data) {
    if (data) {
      setFilterTempSelectedSubCategoryList(data)
    }
  }

  function onIconClick() {
    setShowAutoFillForSearch(false);
  }

  function cancelfilter() {
    var filterfs = {};
    setselectedFilterItem(filterfs);
    setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" });
    setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
    setFilterSubCategoryList([]);
  }

  function applyfilter() {
    if (activeTab === 1) {
      var filterfs = {};
      if (filterTempSelectedCountryList.countryId > 0) {
        filterfs["countryId"] = filterTempSelectedCountryList.countryId;
      }
      else {
        filterfs["countryId"] = 0;
      }

      if (filterSubCategoryList.length == 0) {
        if (filterTempSelectedCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        else {
          filterfs["categoryId"] = 0;
        }

        setselectedFilterItem(filterfs);
      }
      else {
        if (filterTempSelectedSubCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedSubCategoryList.categoryId;
        }
        else {
          filterfs["parentCategoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        setselectedFilterItem(filterfs);
      }

    }
    else if (activeTab === 2) {
      var filterfs = {};
      if (filterTempSelectedCountryList.countryId > 0) {
        filterfs["CountryId"] = filterTempSelectedCountryList.countryId;
      }
      else {
        filterfs["CountryId"] = 0;
      }
      if (filterSubCategoryList.length == 0) {
        if (filterTempSelectedCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        else {
          filterfs["categoryId"] = 0;
        }
        setselectedFilterItem(filterfs);
      }
      else {
        if (filterTempSelectedSubCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedSubCategoryList.categoryId;
        }
        else {
          filterfs["parentCategoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        setselectedFilterItem(filterfs);
      }
    }

  }
  //#endregion

  const createFilter = useMemo(
    () => (
      <>
        <Filters
          onIconClick={onIconClick}
          iconText={t("header.Icon_Tooltip_Filter_Visitors")}
          applyFilters={applyfilter}
          cancelFilter={cancelfilter}
          showHeader={false}
          btnOneText={t("header.VisitorsIconFilterVisitorsButtonFilterText")}
          btnTwoText={t("header.VisitorsIconFilterVisitorsButtonClearFilterText")}
          dataSource={[
            {
              name: "Country",
              data: [...new Set(filterCountryList)],
              selectedItem: filterTempSelectedCountryList,
              isVisible: true,
              label: t("header.VisitorsIconFilterVisitorsLabelCountryText"),
              callback: onCountryDropDownChange,
              displayText: "countryName",
            },
            {
              name: "Category",
              data: filterCategoryList,
              isVisible: true,
              label: t("header.VisitorsIconFilterVisitorsLabelCategoryText"),
              selectedItem: filterTempSelectedCategoryList,
              callback: onCategoryDropdownChange,
              displayText: "categoryName"
            },
            {
              name: "Subcategory",
              data: filterSubCategoryList,
              isVisible: filterSubCategoryList.length > 0 ? true : false,
              label: t("header.VisitorsIconFilterVisitorsLabelSubCategoryText"),
              selectedItem: filterTempSelectedSubCategoryList,
              callback: onSubCategoryDropDownChange,
              displayText: "categoryName"
            }
          ]}
        ></Filters>
      </>
    ),
    [filterCountryList, filterTempSelectedCountryList, filterCategoryList, filterTempSelectedCategoryList, filterSubCategoryList, filterTempSelectedSubCategoryList]
  );


  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}

      <Alert
        className="toast-custom"
        variant="success"
        show={successMessage ? true : false}
        onClose={() => onCloseSuccessMessage()}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {successMessage}
          </div>
          <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
        </div>
      </Alert>
      {(alertVisible || successMessage) && (<div className="fade modal-backdrop show"></div>)}
      <Alert
        className="toast-custom"
        variant="success"
        show={alertVisible}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {t("header.PdfDeliveredToMailMessage")}
          </div>
        </div>
      </Alert>
      <div className="d-inline-block p-3 pe-0 w-100 ">
        <div className="shadow-soft border rounded border-light w-100 nested-card-container">
          <Row className="mt-3 mb-2">
            <Col md={12}>
              <div className="tools-dashboard-card-loader">
                <Card border="light" className="bg-white shadow-sm">
                  <Card.Body>
                    <Tab.Container defaultActiveKey="Visitors" >
                      <Nav fill variant="pills" className="flex-column main-tab flex-sm-row tab border-bottom-0 ms-1 mt-3 mb-2 justify-content-center">
                        <Nav.Item className="pe-0">
                          <Nav.Link eventKey="Visitors" onClick={() => { setTabSection(1); setActiveTab(1) }} className="mb-sm-3 mb-md-0">
                            <span className="d-inline-block w-100 tools-tab-label-text">
                              {t("header.PagesDetailsPopVisitorsTabText")}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Pages" onClick={() => { setTabSection(2); setActiveTab(3) }} className="mb-sm-3 mb-md-0">
                            <span className="d-inline-block w-100 tools-tab-label-text">
                              {t("header.InsightsTabTools")}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="mt-4">
                        <Tab.Pane eventKey="Visitors" className="py-2" >
                          {tabSection === 1 &&
                            <Row>
                              <Row className={localStorage.getItem("isDemoClient") ? "custom-disable mb-4" : "mb-4"}>
                                <Col xs={6}>
                                </Col>
                                <Col xs={6} className="text-end icon-nav-list svg-icon-nav-list">
                                  <div className="pe-3 d-inline-block pull-right">
                                    {
                                      filterCountryList.length > 0 ?
                                        createFilter
                                        :
                                        <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                                          <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                            <span className="icon-nav-box  ms-0 disabled"  >
                                              <svg className="icon-nav">
                                                <title>{t("header.Icon_Tooltip_Filter")}</title>
                                                <use href={iconSprite + `#Filter`} />
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                    }
                                    {
                                      activeTab === 2 &&
                                      <div className="d-inline-block icon-nav icon-nav-filter ms-2 mt-0">
                                        {activeTab === 2 &&
                                          <>
                                            <div className="d-inline-block icon-nav icon-nav-filter me-2">
                                              <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                                <span className="icon-nav-box  ms-0" onClick={() => {
                                                  setAutoFillForCompany(false);
                                                  setShowAddWatchlist(!showAddWatchlist);
                                                  setListMultiselect([]);
                                                  setSelectedOption([]);
                                                }}>
                                                  <FontAwesomeIcon title={t("header.Icon_Tooltip_AddCompaniesToWatchlist")} icon={faPlusCircle} size="lg" />
                                                </span>
                                              </div>
                                            </div>
                                          </>}
                                        {
                                          showAddWatchlist == true &&
                                          <Row className="align-items-center mt-2 mb-3">
                                            <Col>
                                              <div className="date-range-container pt-4 mt-2 me-3"
                                                onMouseLeave={() => {
                                                  document.addEventListener("click", onClickOutsideListenerFilters)
                                                }}
                                                onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListenerFilters) }}
                                              >
                                                <Autocomplete
                                                  apiurl={`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/SearchVisitorsByName`}
                                                  placeholder={t("header.Add_Watchlist_Company_Placeholder")}
                                                  OnValueSelection={autoFillSelection}
                                                  autoTickEnabled={true}
                                                  autoTickData={autoTickData}
                                                  setCheckCompanyName={setCheckCompanyName}
                                                >
                                                </Autocomplete>

                                                <MultiSelect
                                                  className="mt-3 text-start dropdown-items"
                                                  disabled={listMultiselect.length > 0 && checkCompanyName ? false : true}
                                                  options={listMultiselect}
                                                  value={checkCompanyName ? selectedOption : []}
                                                  onChange={setMultiselectOption}
                                                  labelledBy="Select"
                                                  disableSearch={true}
                                                />

                                                <div className="d-block mt-4 text-start">
                                                  <ButtonComponent
                                                    dissable={disableAddWatchlistButton}
                                                    onClick={(e) => addWatchlistCompany(e)}
                                                    isIcon={false}
                                                    isPrimary={true}
                                                    btnText={t("header.VisitorsIconAddCompToWatchButtonAddCompanyText")}
                                                    btn_class="btn-md mb-3 filter-btn"
                                                  />
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        }
                                      </div>
                                    }

                                    <span className={isPDFDownloadEnable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                      <Dropdown as={ButtonGroup}>
                                        <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                          <svg className="icon-nav">
                                            <title>{t("header.Icon_Tooltip_Download")}</title>
                                            <use href={iconSprite + `#Download`} />
                                          </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {setDowloadDropdownMenu()}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </span>
                                    <span className="icon-nav-box hide-mobile">
                                      <svg className="icon-nav" onClick={() => sendErrorReport()}>
                                        <title>{t("header.Icon_Tooltip_Report_Error")}</title>
                                        <use href={iconSprite + `#WarningTriangle`} />
                                      </svg>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="visitor-search-download-box position-relative">
                                <Col xs={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"} >
                                  <div className="search-box-container">
                                    <Search
                                      setsearchData={setSearchData}
                                      searchData={searchData}
                                      searchWaterMark={activeTab === 1 ? t("header.visitorAllWatermark") : t("header.searchwatchlistPlaceHolder")}
                                      isAutoComplete={true}
                                      setAutoFillForCompany={setShowAutoFillForSearch}
                                      showAutoFillForCompany={showAutoFillForSearch}
                                      data={searchDataList}
                                      type={"client"}
                                      emptyTextBox={toggle}
                                    ></Search>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Tab.Container defaultActiveKey="All" >
                                    <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                                      <Nav.Item className="pe-0">
                                        <Nav.Link eventKey="All" onClick={() => { setActiveTab(1) }} className="mb-sm-3 mb-md-0">
                                          <span className="d-inline-block w-100 tools-tab-label-text">
                                            {t("header.VisitorsAllVisitorsTabText")}
                                          </span>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="Watchlist" onClick={() => { setActiveTab(2) }} className="mb-sm-3 mb-md-0">
                                          <span className="d-inline-block w-100 tools-tab-label-text">
                                            {t("header.VisitorsWatchlistTabText")}
                                          </span>
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                      <Tab.Pane eventKey="All" className="mt-4" >
                                        {activeTab === 1 &&
                                          <TnfVisitorAll
                                            Exportdata={setCountryListForFilter}
                                            FilterParams={selectedFilterItem}
                                            UserId={UserId}
                                            searchData={searchData}
                                            cryptoKey={cryptoKey}
                                            setSearchDataList={setSearchDataList}
                                            ProfileId={ProfileId}
                                            StartDate={StartDate}
                                            EndDate={EndDate}
                                            IntelConfigId={IntelConfigId}
                                            CompanyId={CompanyId}
                                            ToolId={ToolId}
                                            setDownloadEnable={setIsPDFDownloadEnable}
                                            ProfileData={ProfileData}
                                            CompanyName={CompanyName}
                                            WebsiteData={WebsiteData}
                                            ToolName={ToolName}
                                          />}
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="Watchlist" className="mt-4">
                                        {activeTab === 2 &&
                                          <TnfWatchlist
                                            Exportdata={setCountryListForFilter}
                                            FilterParams={selectedFilterItem}
                                            refreshWatchlist={refreshWatchlist}
                                            setRefreshWatchlist={setRefreshWatchlist}
                                            UserId={UserId}
                                            ProfileId={ProfileId}
                                            StartDate={StartDate}
                                            EndDate={EndDate}
                                            IntelConfigId={IntelConfigId}
                                            CompanyId={CompanyId}
                                            ToolId={ToolId}
                                            searchData={searchData}
                                            cryptoKey={cryptoKey}
                                            setWatchlistData={setWatchlistData}
                                            setSearchDataList={setSearchDataList}
                                            setDownloadEnable={setIsPDFDownloadEnable}
                                            ProfileData={ProfileData}
                                            CompanyName={CompanyName}
                                            WebsiteData={WebsiteData}
                                            ToolName={ToolName}
                                          />}
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </Tab.Container>
                                </Col>
                              </Row>
                            </Row>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="Pages" className="py-2">
                          {tabSection === 2 && <>
                            <Row className={localStorage.getItem("isDemoClient") ? "custom-disable mb-4" : "mb-4"}>
                              <Col md={12} className="text-end">
                                <div className="icon-nav-list svg-icon-nav-list float-end mb-2 me-3 pe-1">
                                  <span className={isPDFDownloadEnable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                    <Dropdown as={ButtonGroup}>
                                      <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                        <svg className="icon-nav">
                                          <title>{t("header.Icon_Tooltip_Download")}</title>
                                          <use href={iconSprite + `#Download`} />
                                        </svg>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row className="visitor-search-download-box position-relative">
                              <Col xs={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                <div className="search-box-container">
                                  <Search
                                    setsearchData={setSearchData}
                                    searchData={searchData}
                                    isAutoComplete={false}
                                    type={"client"}
                                  ></Search>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Tab.Container defaultActiveKey="AllPages" >
                                  <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                                    <Nav.Item className="pe-0">
                                      <Nav.Link eventKey="AllPages" className="mb-sm-3 mb-md-0">
                                        <span className="d-inline-block w-100 tools-tab-label-text">
                                          {t("header.ToolsAllToolsTabText")}
                                        </span>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="AllPages" onClick={() => { setActiveTab(3) }} className="mt-4" >
                                      {activeTab === 3 && <TnfAllPages
                                        UserId={UserId}
                                        ProfileId={ProfileId}
                                        StartDate={StartDate}
                                        EndDate={EndDate}
                                        IntelConfigId={IntelConfigId}
                                        CompanyId={CompanyId}
                                        ToolId={ToolId}
                                        searchData={searchData} cryptoKey={cryptoKey}
                                        setDownloadEnable={setIsPDFDownloadEnable}
                                        ProfileData={ProfileData}
                                        CompanyName={CompanyName}
                                        WebsiteData={WebsiteData}
                                        ToolName={ToolName}
                                      />
                                      }
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </Col>
                            </Row>
                          </>}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
});
