import React, { useState, useEffect, useContext, useMemo } from 'react';
import { withRouter } from "react-router";
import { Row, Col, Image, Dropdown, ButtonGroup, Alert } from '@themesberg/react-bootstrap';
import AppContext from '../components/AppContext';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import WebcastViewersFilters from "../components/WebcastviewersFilters"
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import { convertBase64 } from "../utils/common";
import { useTranslation } from 'react-i18next';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    summary: JSON.parse(i.summary) || {},
    vizState: JSON.parse(i.vizState)
});

const WebcastViewers = ({ webcastViewers, changeViewerData, hasEvents, setWebcastViewersFiltersData, showLoader, onRefresh, modifySummary, setStatsForVE }) => {
    const [offsetViewer, setOffsetViewer] = useState(0);
    const [pageNoViewer, setPageNoViewer] = useState(0);
    const [items1, setItems1] = useState([]);
    const [searchData, setSearchData] = useState("");
    const contextData = useContext(AppContext);
    const [apiResponse, setApiResponse] = useState({});
    const [apiResult, setApiResult] = useState({});
    const [apiResultBackup, setApiResultBackup] = useState({})
    const [url, setUrl] = useState("");
    const [domainsUrl, setDomainsUrl] = useState("")
    const [urlForLastUpdate, setUrlForLastUpdate] = useState("")
    const [updateString, setUpdateString] = useState("")
    const [domainsList, setDomainsList] = useState([{ value: "", label: "All" }]);
    const [dropdown1, setDropdown1] = useState({ value: "", label: "All" });
    const [dropdown2, setDropdown2] = useState({ value: "", label: "All" });
    const [dropdown3, setDropdown3] = useState({ value: "unique", label: "Unique" });
    const [dropdown4, setDropdown4] = useState({ value: "", label: "All" });
    const [callDomains, setCallDomains] = useState(true);
    const [blurLoader, setBlurLoader] = useState(false);
    const [getTotalResults, setGetTotalResults] = useState(0);
    const [isAutoRefresh, setIsAutoRefresh] = useState(false);
    const [manualRefresh, setManualRefresh] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [lastDate, setLastDate] = useState("");
    const [t, i18n] = useTranslation();
    const [alertVisible, setAlertVisible] = useState(false);

    if (pageNoViewer === 0) {
        setPageNoViewer(1);
    }

    const onPageNoChangeViewer = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffsetViewer(num);
        setPageNoViewer(page);
        changeViewerData();
    }

    useEffect(() => {
        setSearchData("")
        setCallDomains(true)
        setDropdown1({ value: "", label: "All" });
        setDropdown2({ value: "", label: "All" });
        setDropdown3({ value: "unique", label: "Unique" });
        setDropdown4({ value: "", label: "All" });
        setDomainsList([{ value: "", label: "All" }]);
        //setWebcastViewersFiltersData({ domain: "", viewer: "unique", viewertype: "", devicetype: "" })
        setApiResultBackup({})
    }, [contextData.presentation])

    useEffect(() => {
        if (webcastViewers[0]?.apiUrl.url) {
            setUrl(webcastViewers[0].apiUrl.url)
            loadContent(webcastViewers[0].apiUrl.url)
            setUpdateString(Math.random().toString())
        }
        if (webcastViewers[0]?.apiUrlForDomains.url && callDomains) {
            setCallDomains(false)
            setDomainsUrl(webcastViewers[0].apiUrlForDomains.url)
            setUrlForLastUpdate(webcastViewers[0].apiUrl);
            loadDomains(webcastViewers[0].apiUrlForDomains.url)
        }
    }, [webcastViewers])

    useEffect(() => {
        setApiResult({})
        setUpdateString(Math.random().toString())
        let searchResult = userSearch({ ...apiResultBackup }, searchData)
        localStorage.setItem("pageNo", 1)
        setTimeout(() => { setApiResult(searchResult); }, 500)
    }, [searchData, apiResultBackup])

    const BringData = (data) => {
        data?.completedAt ? setLastDate(data.completedAt) : setLastDate("");
    }

    const loadContent = (url) => {
        localStorage.setItem("pageNo", 1)
        request(url, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
            .then((response) => {
                if (response) {
                    BringData(response)
                    //modifySummary(response)
                }
                setApiResultBackup(response)
            })
            .catch(() => {
            })
    }

    useEffect(() => {
        if (manualRefresh === true) {
            setManualRefresh(false)
            loadContent(url)
            setBlurLoader(false)//here
        }
    }, [manualRefresh])

    const loadDomains = (url) => {
        localStorage.setItem("pageNo", 1)
        request(url, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
            .then((response) => {
                if (response.domains !== undefined) {
                    let domains = [];
                    domains.push({ value: "", label: "All" })
                    if (response.domains.length) {
                        for (let item of response.domains) {
                            domains.push({ value: item, label: item })
                        }
                    }
                    setDomainsList(domains)
                }
                setStatsForVE(response)
            })
            .catch(() => {

            })
    }

    const userSearch = (result, searchText) => {
        if (searchText !== undefined && searchText !== "") {
            let resultObject = result;
            if (result !== null) {
                if (resultObject.data !== null && resultObject.data?.length > 0) {
                    let filterResult = resultObject?.data?.filter(item => (item?.name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1 || item?.email?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1));
                    resultObject.data = filterResult;
                }
                return resultObject;
            }
        } else {
            return result
        }
    }

    const filterData = (result, value) => {
    }

    const applyStatusFiltersNew = () => {
        setWebcastViewersFiltersData({ domain: dropdown2.value, viewer: dropdown3.value, viewertype: dropdown1.value, devicetype: dropdown4.value })
    }

    const applyStatusFilters = (result) => {
    };

    const cancelStatusFilter = () => {
        setDropdown1({ value: "", label: "All" });
        setDropdown2({ value: "", label: "All" });
        setDropdown3({ value: "unique", label: "Unique" });
        setDropdown4({ value: "", label: "All" });
        setWebcastViewersFiltersData({ domain: "", viewer: "unique", viewertype: "", devicetype: "" })
    };

    var webcastTypeEnum = {
        LIVE: "InvestisLive",
        CHORUS: "ChorusCall",
        METRO: "Metro"
    };

    const deviceType = {
        name: "Dropdown1 - Device Type",
        data: [{ value: "", label: "All" }, { value: "desktop", label: "Desktop" }, { value: "tablet", label: "Tablet" }, { value: "mobile", label: "Mobile" }],
        selectedItem: dropdown4,
        isVisible: true,
        label: t("header.WebcastFilterLabelDevice"),
        callback: setDropdown4,
        displayText: "label",
    };

    const createFilter = useMemo(
        () => (
            <>
                <WebcastViewersFilters
                    applyFilters={applyStatusFiltersNew}
                    cancelFilter={cancelStatusFilter}
                    dataSource={[
                        {
                            name: "Dropdown1 - Viewer Type",
                            data: [{ value: "", label: "All" }, { value: "live", label: "Live Viewers" }, { value: "ondemand", label: "On Demand Viewers" }, { value: "registeredbutnotviewed", label: "Registered But Not Viewed" }],
                            selectedItem: dropdown1,
                            isVisible: true,
                            label: t("header.WebcastFilterLabelViewerType"),
                            callback: setDropdown1,
                            displayText: "label",
                        },
                        hasEvents ? "" : deviceType,
                        {
                            name: "Dropdown2 - Domains",
                            data: domainsList,
                            selectedItem: dropdown2,
                            isVisible: true,
                            label: t("header.WebcastFilterLabelDomain"),
                            callback: setDropdown2,
                            displayText: "label",
                        },
                        {
                            name: "Dropdown3 - Viewers",
                            data: [{ value: "", label: "All" }, { value: "unique", label: "Unique" }],
                            selectedItem: dropdown3,
                            isVisible: true,
                            label: t("header.WebcastPDFFilterLabelViewers"),
                            callback: setDropdown3,
                            displayText: "label",
                        }
                    ]}
                ></WebcastViewersFilters>
            </>
        ),
        [dropdown1, dropdown2, dropdown3, dropdown4, domainsList]
    );

    //lastUpdated date and time format
    const lastUpdateDateAndTime = (lastDate) => {
        const dateString = lastDate
        // Convert the input string to a JavaScript Date object
        const date = new Date(dateString);
        const formattedDate = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })} ${date.getFullYear()}, ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')} GMT`;
        return formattedDate;
    }

    const tableData = useMemo(() => (<ChartRendererTable
        pageNo={localStorage.getItem("pageNo") != undefined ? parseInt(localStorage.getItem("pageNo")) : 1}
        className="webcast-viewers-data"
        summaryTooltip="header.webcastViewersTooltipText"
        showPagination={true}
        resultSet={apiResult}
        showNumberOfRows={10}
        setPageNo={1}
        summaryBesideText={t("header.webcastViewerMessage")}
        searchText={updateString}
        setGetTotalResults={(value) => setGetTotalResults(value)}
    />), [apiResult, updateString])

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        showLoader(true)
        let providerName = ""
        if (contextData.webcastType === 1) {
            providerName = webcastTypeEnum.LIVE;
        }
        else if (contextData.webcastType === 2) {
            providerName = webcastTypeEnum.CHORUS;
        }
        else {
            providerName = webcastTypeEnum.METRO;
        }
        const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
        let paramsList = {
            presentationName: contextData.presentation["Presentations.name"],
            clientSlug: contextData.presentation["Presentations.clientslug"],
            presentationId: contextData.presentationId,
            presentationSlug: contextData.presentation["Presentations.slug"],
            viewer: dropdown3.value,
            domain: dropdown2.value,
            viewertype: dropdown1.value,
            device: dropdown4.value,
            clientType: contextData.hasEvents ? "VE" : "IL",
            fetchLatest: true,
            isExport: false,
            generateLatest: false,
            companyid: contextData.companyId,
            companyName: selectedComapnyData.companyName,
            culture: localStorage.getItem('i18nextLng'),

        }

        const tempVar = await axios.get(
            `${process.env.REACT_APP_API_KEY}/Website/ExportInvestisLiveDetailsUsingEPPlus`,
            {
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                },
                params: { encodeStr: convertBase64(JSON.stringify(paramsList)) }
            }
        );
        if (tempVar !== undefined && tempVar !== null) {
            showLoader(false)
            let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
            let a = document.createElement("a");
            a.href = fetchDataModified;
            a.download = 'Webcast_Statistics_' + contextData.presentation["Presentations.name"] + '.xlsx';
            a.click();
        }
    };

    const downloadPDF = async () => {
        showLoader(true);
        let fileName = "Webcast_Statistics.pdf";
        let filters = (searchData !== "" && searchData !== null) ? "name|contains|" + searchData + "||email|contains|" + searchData : "";
        let filterContent = getFilterContent();
        let obj = {
            Title: "Webcast statistics for " + JSON.parse(localStorage.getItem("PresentationId"))["Presentations.name"],
            SubTitle: "Client: " + decryptData(localStorage.getItem("companyName"), contextData.cryptoKey),
            PageName: "Webcast Viewers",
            CountTitle: "<span class='total-record-count'>{{count}}</span> Total Viewers",
            Filters: filters,
            FilterContent: filterContent,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
            Count: 0,
            ClientName: "",
            TableHeaders: []
        }

        await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                }
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    showLoader(false);
                }
                else {
                    showLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                showLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const getFilterContent = () => {
        let filters = "";
        const drop1 = dropdown1.value === "live" ? "Live Viewers" : dropdown1.value === "ondemand" ? "On Demand Viewers" : dropdown1.value === "registeredbutnotviewed" ? "Registered But Not Viewed" : dropdown1.value;
        const drop3 = dropdown3.value === "unique" ? "Unique" : "";
        if (dropdown1.value !== "" || dropdown2.value !== "" || dropdown3.value !== "" || dropdown4.value !== "") {
            if (dropdown1.value !== "") {
                filters += "(Viewer Type : " + drop1 + ") "
            }
            if (dropdown2.value !== "") {
                filters = filters === "" ? "" : (filters + "And ");
                filters += "(Domain : " + dropdown2.value + ") "
            }
            if (dropdown3.value !== "") {
                filters = filters === "" ? "" : (filters + "And ");
                filters += "(Viewer : " + drop3 + ") "
            }
            if (dropdown4.value !== "") {
                filters = filters === "" ? "" : (filters + "And ");
                filters += "(Device Type : " + dropdown4.value + ")"
            }
            filters = `Filter : ` + filters;
        }
        return filters;
    }

    const webcastViewerItem = (item) => (
        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            <Row className="webcast-questions-card-nav mb-5">
                <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                    <div className="d-inline-block mt-2 me-3 webcast-table-nav float-end">
                        <div className="icon-nav-list svg-icon-nav-list">
                            <div className={getTotalResults > 0 ? "icon-nav-box hide-mobile webcast-download-nav" : "icon-nav-box hide-mobile disabled"}>
                                {createFilter}
                            </div>
                            <span className={getTotalResults > 0 ? "icon-nav-box hide-mobile webcast-download-nav" : "icon-nav-box hide-mobile disabled"}>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                        <svg className="icon-nav">
                                            <title>{t("header.Icon_Tooltip_Download")}</title>
                                            <use href={iconSprite + `#Download`} />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                            <span className={getTotalResults > 0 ? "icon-nav-box webcast-sync-icon" : " icon-nav-box webcast-sync-icon disabled"} onClick={(e) => { onRefresh() }}>
                                <svg className="icon-nav">
                                    <title>{t("header.Icon_Tooltip_Refresh")}</title>
                                    <use href={iconSprite + `#Sync`} />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="d-inline-block webcast-question-search-row webcast-viewers-search">
                        <div className="d-inline-block float-end webcast-question-search-container">
                            <Search setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'server'}></Search>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* Below span is for IL clients */}
            {lastDate !== "" ?
                <span className="fw-bold last-update" id="lastUpdate">{t("header.lastUpdated")} : {lastUpdateDateAndTime(lastDate)}</span>
                :
                ""
            }
            {tableData}
        </div >
    );

    return (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {alertVisible && (<div className="fade modal-backdrop show"></div>)}
            <Alert
                className="toast-custom"
                variant="success"
                show={alertVisible}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {t("header.PdfDeliveredToMailMessage")}
                    </div>
                </div>
            </Alert>
            {webcastViewers.map(deserializeItem).map(webcastViewerItem)}
        </>
    );
};

export default withRouter(WebcastViewers);
