import React, { useState, useContext } from "react";
import AppContext from '../components/AppContext';
import PropTypes from "prop-types";
import { Col, Row, Form } from "@themesberg/react-bootstrap";
import ModalComponent from "../components/VoltModal";
import ButtonComponent from "../components/Buttons";
import { useTranslation } from "react-i18next";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import { header_languages, DEFAULT_TIMEOUT, isEmpty, ValidatePasswordRegex } from "../AppConfig";
import { Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamation, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Header from '../components/Header';
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { encryptData, decryptData } from "../utils/crypto";

const MyAccountModal = ({
  setuData,
  showModal,
  setShowModal,
  setSelectedCompany,
  setAutoFillForCompany,
  userData,
  setshowSuccess,
  setBlurLoader
}) => {

  let isFieldValid = true, isPasswordValid = false, isFormSuccessfullySubmitted = false;
  const [errorSummaryMessage, seterrorSummaryMessage] = useState("");

  const validPassword = ValidatePasswordRegex

  const contextData = useContext(AppContext);

  const [firstnamedvalidationmessage, setFirstNamedValidationMessage] =
    useState("");

  const [lastnamedvalidationmessage, setLastNamedValidationMessage] =
    useState("");

  const [currentPasswordMessage, setCurrentPasswordMessage] =
    useState("");

  const [newpasswordvalidationmessage, setNewpasswordvalidationmessage] =
    useState("");

  const [
    confirmpasswordvalidationmessage,
    setConfirmpasswordvalidationmessage,
  ] = useState("");

  const [showLoader, setShowLoader] = useState(false);

  const [formFields, setFormFields] = useState(userData);

  const [showPwdSection, setShowPwdSection] = useState(false);

  const [t, i18n] = useTranslation();

  const onModalClose = () => {
    setShowPwdSection(false);
    setShowModal && setShowModal(false);
    setAutoFillForCompany && setAutoFillForCompany(false);
    setSelectedCompany && setSelectedCompany({});
    setFormFields(userData);
  };

  const onCancelButtonClick = (e) => {
    e.preventDefault();
    onModalClose();
  };

  const onFormElementsChange = (event) => {
    const { name, value } = event.target;
    
    const forms = { ...formFields };
    forms[name] = value;
    setFormFields && setFormFields(forms);
    isFieldValid = true;
    validateFormData(name, value);
  };

  const onLanguageDropdownChange = (data) => {
    const forms = { ...formFields };
    forms.uLanguage = data;
    setFormFields && setFormFields(forms);
  };

  const checkPasswordValidation = () => {
    setNewpasswordvalidationmessage("");
    setConfirmpasswordvalidationmessage("");
    isPasswordValid = false;
    if (showPwdSection) {
      if (!formFields.uPassword) {
        setNewpasswordvalidationmessage(t("header.AccounNewPwdRequiredMessage"));
      } else if (!validPassword.test(formFields.uPassword)) {
        setNewpasswordvalidationmessage(t("header.AccounNewPwdCriteriaRequiredMessage"));
      } else if (!formFields.uConfirmPassword) {
        setConfirmpasswordvalidationmessage(t("header.AccountNewPwdRequiredMessage"));
      } else if (formFields.uPassword !== formFields.uConfirmPassword) {
        setConfirmpasswordvalidationmessage(
          t("header.AccountConfirmPwdDostNotMatchMessage")
        );
      } else {
        isPasswordValid = true;
      }
    } else {
      isPasswordValid = true;
    }
  };

  const isFormFieldsValid = () => {
    isFieldValid = true;
    for (let fieldName in formFields) {
      validateFormData(fieldName, formFields[fieldName]);
    }
    return isFieldValid;
  };

  const validateFormData = (name, value) => {
    switch (name) {
      case "fName":
        if (isEmpty(value)) {
          setFirstNamedValidationMessage(
            t("header.AccounUserFirstNameMessage")
          );
          isFieldValid = false;
        } else {
          setFirstNamedValidationMessage("");
        }
        break;
      case "lName":
        if (isEmpty(value)) {
          setLastNamedValidationMessage(
            "header.AccounUserLastNameMessage"
          );
          isFieldValid = false;
        } else {
          setLastNamedValidationMessage("");
        }
        break;
      case "currentPassword":
        if (showPwdSection) {
          if (isEmpty(value)) {
            setCurrentPasswordMessage(
              "header.AccountCurrentPasswordRequired"
            );
            isFieldValid = false;
          }
          else {
            setCurrentPasswordMessage("");
          }
        }
        break;
      case "uPassword":
        if (showPwdSection) {
          if (isEmpty(value)) {
            setNewpasswordvalidationmessage(
              "header.AccountNewPasswordRequired"
            );
            isFieldValid = false;
          }
          else if (!validPassword.test(value)) {
            setNewpasswordvalidationmessage(t("header.AccounNewPwdCriteriaRequiredMessage"));
            isFieldValid = false;
          }
          else {
            setNewpasswordvalidationmessage("");
          }
        }
        break;
      case "uConfirmPassword":
        if (showPwdSection) {
          if (isEmpty(value)) {
            setConfirmpasswordvalidationmessage(
              "header.AccountNewConfirmPwdRequiredMessage"
            );
            isFieldValid = false;
          }
          else if (formFields.uPassword !== value) {
            setConfirmpasswordvalidationmessage(
              t("header.AccountConfirmPwdDostNotMatchMessage")
            );
            isFieldValid = false;
          }
          else {
            setConfirmpasswordvalidationmessage("");
          }
        }
        break;
      default:
        break;
    }
  };

  const handleSubmitButtonClick = (e) => {
    e.preventDefault();
    if (isFormFieldsValid()) {
      setBlurLoader(true);
      const myAccountData = {
        FirstName: formFields.fName,
        LastName: formFields.lName,
        CurrentPassword: formFields.currentPassword,
        NewPassword: formFields.uPassword,
        ConfirmPassword: formFields.uConfirmPassword,
        LanguagePreference: formFields.uLanguage.id,
        Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
      };

      seterrorSummaryMessage("");
      //checkPasswordValidation();

      request(baseURL + `/User/UpdateUserInformation?userId=${contextData.userId}`,
        METHODS.POST,
        myAccountData,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          if (response) {
            setBlurLoader(false);
            if (!response.status) {
              isFormSuccessfullySubmitted = false;
              if (response.message == "samePass") {
                setNewpasswordvalidationmessage(t("header.LoginPasswordSameWarning"));
                seterrorSummaryMessage(t("header.LoginPasswordSameWarning"));
              }
              else if (response.message == "invalPass") {
                setCurrentPasswordMessage(t("header.SettingsErrorInvalidCurrentPassword"));
                seterrorSummaryMessage(t("header.SettingsErrorInvalidCurrentPassword"));
              }
              else {
                setNewpasswordvalidationmessage(t("header.AccounChangePasswordFailedMessage"));
                seterrorSummaryMessage(t("header.AccounChangePasswordFailedMessage"));
              }
            }
            else {
              isFormSuccessfullySubmitted = true;
            }
            if (isFormSuccessfullySubmitted) {

              if (showPwdSection) {
                localStorage.clear();         // Clear All Entries from Local Stoarge
                sessionStorage.clear();       // Clear All Entries from Session Stoarge
                window.location.reload();
              }

              setShowModal(false);
              let uName = formFields.fName + (formFields.lName == null ? "" : " " + formFields.lName)
              formFields.uName = uName;
              contextData.changeFirstName(formFields.fName);
              contextData.changeLastName(formFields.fName);
              contextData.changeLanguagePref(formFields.uLanguage);
              localStorage.setItem("firstName", encryptData(formFields.fName, contextData.cryptoKey));
              localStorage.setItem("lastName", encryptData(formFields.lName, contextData.cryptoKey));
              localStorage.setItem("userName", encryptData(formFields.uName, contextData.cryptoKey));
              localStorage.setItem("langPref", formFields.uLanguage.id);
              localStorage.setItem("userlangPref", formFields.uLanguage.id);
              formFields.uPassword = "";
              formFields.uConfirmPassword = "";
              setuData(formFields);
              setshowSuccess(true);
            }
            else {
              isFormSuccessfullySubmitted = false;
            }
          } else {
            isPasswordValid = false;
            isFormSuccessfullySubmitted = false;
          }
          setBlurLoader(false);
        })
        .catch((error) => {
          setBlurLoader(false);
          isFormSuccessfullySubmitted = false;
          seterrorSummaryMessage(t("header.DigestSubscriptionFailMessage"));

          setShowLoader(false);
        })
    }
  };

  const hasSpecialCharacters = (input) => {
    const specialChars = /[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return !specialChars.test(input);
  };

  return (
    <ModalComponent
      open={showModal}
      title={t("header.WOAccountEditTitle")}
      handleClose={onModalClose}
      showCloseButton={true}
    >
      <Form.Group className="mb-3">
        <Row>
          <Alert
            variant="danger"
            show={!isFormSuccessfullySubmitted && errorSummaryMessage}
          >
            <div className="d-flex justify-content-between">
              <div>
                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                {errorSummaryMessage}
              </div>
            </div>
          </Alert>
        </Row>
        <Row>
          <Col sm={3}>
            <Form.Label className="form-label-spacing">
              {t("header.AccounFirstName")}<span className="mandatory">*</span>
            </Form.Label>
          </Col>
          <Col sm={9}>
            <Form.Control
              type="text"
              name="fName"
              maxlength="200"
              isInvalid={firstnamedvalidationmessage}
              value={formFields.fName}
              autocomplete="off"
              onChange={(e) => { hasSpecialCharacters(e.target.value) && onFormElementsChange(e)}}
              placeholder={t("header.AccounFirstName")}
            />
            {firstnamedvalidationmessage && (
              <div className="invalid-feedback d-block">
                {t("header.AccounUserFirstNameMessage")}
              </div>
            )}
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
          <Col sm={3}>
            <Form.Label className="form-label-spacing">
              {t("header.AccounLastName")}<span className="mandatory">*</span>
            </Form.Label>
          </Col>
          <Col sm={9}>
            <Form.Control
              type="text"
              name="lName"
              maxlength="200"
              isInvalid={lastnamedvalidationmessage}
              autocomplete="off"
              value={formFields.lName}
              onChange={(e) => { hasSpecialCharacters(e.target.value) && onFormElementsChange(e)}}
              placeholder={t("header.AccounLastName")}
            />
            {lastnamedvalidationmessage && (
              <div className="invalid-feedback d-block">
                {t("header.AccounUserLastNameMessage")}
              </div>
            )}
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
          <Col sm={3}>
            <Form.Label className="form-label-spacing">{t("header.AccounEmail")}</Form.Label>
          </Col>
          <Col sm={9}>
            <Form.Control
              type="text"
              name="uEmail"
              value={formFields.uEmail}
              disabled="true"
              placeholder={t("header.AccounEmail")}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
          <Col sm={3}>
            <Form.Label className="form-label-spacing">
              {t("header.AccounLanguage")}<span className="mandatory">*</span>
            </Form.Label>
          </Col>
          <Col sm={9}>
            <DropdownWithIconsComponent
              dropdownData={header_languages}
              arrayValue="title"
              //primaryIcon="globe"
              //primaryIconType="icon"
              name="uLanguage"
              //primaryIconCustom={true}
              selectedDropdown={formFields.uLanguage}
              onChange={onLanguageDropdownChange}
              showLabel={false}
              showIconInDropdownItem={false}
              //isPrimaryImage={true}
              customClassName="full-width"
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3">
        {
          <Row className="mt-2">

            <Col sm={12} className="text-end">
              <ButtonComponent
                isIcon={false}
                isPrimary={false}
                btnText={t("header.AccounChangePassword")}
                btn_size="sm"
                btn_class="border-0 p-0 no-bg"
                onClick={() => setShowPwdSection(!showPwdSection)}
              />
            </Col>
          </Row>

        }
      </Form.Group>
      <Form.Group>
        <Row>
          <Col sm={12} className="text-start">
            {showPwdSection && <Alert
              variant="warning"
              show={showPwdSection}>
              <div className="d-flex justify-content-between mb-0">
                <div>
                  <FontAwesomeIcon icon={faExclamation} className="me-2" />
                  <span>{t("header.myAccountModalMessage")}</span>
                </div>
              </div>
            </Alert>}
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        {showPwdSection && (
          <Row>
            <Col sm={3}>
              <Form.Label className="form-label-spacing">
                {t("header.AccounCurrentPassword")}<span className="mandatory">*</span>
              </Form.Label>
            </Col>
            <Col sm={9}>
              <Form.Control
                type="password"
                name="currentPassword"
                maxlength="15"
                value={formFields.currentPassword}
                isInvalid={currentPasswordMessage}
                autocomplete="off"
                onChange={(e) => onFormElementsChange(e)}
                placeholder={t("header.AccounCurrentPassword")}
              />
              {currentPasswordMessage && (
                <Form.Control.Feedback type="invalid">
                  {t(currentPasswordMessage)}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        {showPwdSection && (
          <Row>
            <Col sm={3}>
              <Form.Label className="form-label-spacing">
                {t("header.AccounNewPassword")}<span className="mandatory">*</span>
              </Form.Label>
            </Col>
            <Col sm={9}>
              <Form.Control
                type="password"
                name="uPassword"
                maxlength="15"
                value={formFields.uPassword}
                isInvalid={newpasswordvalidationmessage}
                autocomplete="off"
                onChange={(e) => onFormElementsChange(e)}
                placeholder={t("header.AccounNewPassword")}
              />
              {newpasswordvalidationmessage && (
                <Form.Control.Feedback type="invalid">
                  {t(newpasswordvalidationmessage)}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
        )}
      </Form.Group>
      <Form.Group className="mb-5">
        {showPwdSection && (
          <Row>
            <Col sm={3}>
              <Form.Label className="form-label-spacing">
                {t("header.AccounConfirmNewPassword")}
                <span className="mandatory">*</span>
              </Form.Label>
            </Col>
            <Col sm={9}>
              <Form.Control
                type="password"
                name="uConfirmPassword"
                value={formFields.uConfirmPassword}
                isInvalid={confirmpasswordvalidationmessage}
                autocomplete="off"
                maxlength="15"
                onChange={(e) => onFormElementsChange(e)}
                placeholder={t("header.AccounConfirmNewPassword")}
              />
              {confirmpasswordvalidationmessage && (
                <Form.Control.Feedback type="invalid">
                  {t(confirmpasswordvalidationmessage)}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
        )}
      </Form.Group>
      <Row>
        {showPwdSection && <Col sm={7}>
          <p className="note-msg fw-bolder">
            {t("header.AccountPasswordPolicy")}
          </p>
        </Col>}
        <Col sm={showPwdSection ? 5 : 12} className="text-end">
          <ButtonComponent
            onClick={(e) => handleSubmitButtonClick(e)}
            isIcon={false}
            isPrimary={true}
            btn_class="me-3"
            btnText={t("header.AccountSave")}
          />
          <ButtonComponent
            onClick={(e) => onCancelButtonClick(e)}
            isIcon={false}
            isPrimary={false}
            btn_class="mb-0"
            btnText={t("header.AccountCancel")}
          />
        </Col>
      </Row>
    </ModalComponent>
  );
};

MyAccountModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setSelectedCompany: PropTypes.func,
  showAutoFillForCompany: PropTypes.bool,
  setAutoFillForCompany: PropTypes.func,
  formError: PropTypes.object,
  isFormSubmit: PropTypes.bool,
  validateFormData: PropTypes.func,
  setFormError: PropTypes.func,
  setIsFormSubmit: PropTypes.func,
};

MyAccountModal.defaultProps = {
  showModal: true,
  setShowModal: null,
  setSelectedCompany: null,
  showAutoFillForCompany: false,
  setAutoFillForCompany: null,
  formError: {},
  isFormSubmit: false,
  validateFormData: null,
  setFormError: null,
  setIsFormSubmit: null,
};
export default MyAccountModal;
