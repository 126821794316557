import { Row, Col, Button, Image, OverlayTrigger, Tooltip } from "@themesberg/react-bootstrap";
import React, { useState, useContext, useEffect, useRef } from "react";
import AppContext from "../components/AppContext";
import UserWebsiteDigestComponent from "../components/UserWebsiteDigestComponent";
import UserWebcastDigestComponent from "../components/UserWebcastDigestComponent";
import DigestSubscribtionBttonComponet from "../components/DigestSubscribtionBttonComponet";
import UserDigestAlertComponent from "../components/UserDigestAlertComponent";
import UserAppDigestComponent from "../components/UserAppDigestComponent";
import { userWebsiteDigestList } from "../stubs/ComponentJson/WesiteDigest";
import { userWebCastDigestList } from "../stubs/ComponentJson/WebcastDigest";
import { userAppDigestList } from "../stubs/ComponentJson/UserAppDigest";
import { useTranslation } from "react-i18next";
import { Nav, Tab, Alert } from '@themesberg/react-bootstrap';
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from "../utils/crypto";
import UserTnfDigestComponent from "../components/UserTnfDigestComponent";
import { userTnfDigestList } from "../stubs/ComponentJson/UserTnfDigest";
import { EMAIL_REGEX } from "../AppConfig";

const DigestSubscriptionsPage = () => {

  const appchild = useRef();
  const alertchild = useRef();
  const contextData = useContext(AppContext);
  const [isRefresh, setIsRefresh] = useState(false);

  const [websiteTableData, setWebsiteTableData] = useState([]);
  const [webcastTableData, setWebcastTableData] = useState([]);
  const [appTableData, setAppTableData] = useState([]);
  const [tnfTableData, setTnfTableData] = useState([]);


  const [userWebDigestData, setuserWebDigestData] = useState([]);
  const [userWebCastData, setuserWebCastData] = useState([]);
  const [userAppDigestData, setuserAppDigestData] = useState([]);
  const [userTnfDigestData, setuserTnfDigestData] = useState([]);
  const [activeTab, setActiveTab] = useState("Website");
  const [blurLoader, setBlurLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [menuItem, setMenuItem] = useState(undefined);

  const [hasWebsiteAccess, setHasWebsiteAccess] = useState(false);
  const [hasAppAccess, setHasAppAccess] = useState(false);
  const [hasTnfAccess, setHasTnfAccess] = useState(false);

  const [websiteRecipients, setWebsiteRecipients] = useState("");
  const [webcastRecipients, setWebcastRecipients] = useState("");
  const [IRRecipients, setIRRecipients] = useState("")

  const [t] = useTranslation();

  const wesiteDigestItems = useRef({});
  const webcastsDigestItems = useRef({});
  const appDigestItems = useRef({});
  const tnfDigestItems = useRef({});
  const [webcastClientByCompanyId, setWebcastClientByCompanyId] = useState(false);

  const [invalidEmailError, setInvalidEmailError] = useState("")
  //Check company has access of webcast client if not then show empty box
  useEffect(() => {
    //getWebCastClientByCompany();
    getMenuByCompanyAndUser();
  }, [contextData.companyId])

  const getMenuByCompanyAndUser = () => {
    request(baseURL + `/UserV2/GetMainMenuByClientUser?userId=${contextData.userId}&companyid=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }
    )
      .then((response) => {
        setBlurLoader(false);
        setMenuItem(response?.resultData?.menuDetails)
        let websiteAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title == "Website");
        let appAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title == "App");
        let WebcastingAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title == "Webcasting");

        //for tnf
        let tnfAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title === "IR Tools Analytics")

        setWebcastClientByCompanyId(WebcastingAccess.length > 0 && WebcastingAccess[0].isActive);
        setHasWebsiteAccess(websiteAccess.length > 0 && websiteAccess[0].isActive);
        setHasAppAccess(appAccess.length > 0 && appAccess[0].isActive);

        //for tnf
        setHasTnfAccess(tnfAccess.length > 0 && tnfAccess[0].isActive)

        setActiveTab((websiteAccess.length > 0 && websiteAccess[0].isActive) ? "Website" : (WebcastingAccess.length > 0 && WebcastingAccess[0].isActive) ? "Webcast" : (appAccess.length > 0 && appAccess[0].isActive) ? "App" : "");
      })
      .catch((error) => {
        setBlurLoader(false);
        setWebcastClientByCompanyId(false);
      })
  }

  const getWebCastClientByCompany = () => {
    request(baseURL + `/Common/GetWebcastClientByCompanyId?CompanyId=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }
    )
      .then((response) => {
        setBlurLoader(false);
        setWebcastClientByCompanyId(response?.message ? false : true);
      })
      .catch((error) => {
        setBlurLoader(false);
        setWebcastClientByCompanyId(false);
      })
  }

  useEffect(() => {
    setIsRefresh(true);

    setWebsiteTableData([]);
    setWebcastTableData([]);
    setAppTableData([]);
    setTnfTableData([])
  }, [activeTab])

  useEffect(() => {

    let wdta = activeTab == "Website" ? userWebsiteDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
    setuserWebDigestData(wdta);

    let wbdta = activeTab == "Webcast" ? userWebCastDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
    setuserWebCastData(wbdta);

    let dta = activeTab == "App" ? userAppDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
    setuserAppDigestData(dta);

    let tnfData = activeTab == "TNF" ? userTnfDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
    setuserTnfDigestData(tnfData)
  }, []);

  useEffect(() => {
    if (isRefresh) {
      let wdta = activeTab == "Website" ? userWebsiteDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
      setuserWebDigestData(wdta);

      let wbdta = activeTab == "Webcast" ? userWebCastDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
      setuserWebCastData(wbdta);

      let dta = activeTab == "App" ? userAppDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
      setuserAppDigestData(dta);

      let tnfData = activeTab == "TNF" ? userTnfDigestList(decryptData(localStorage.getItem("userId"), contextData.cryptoKey), decryptData(localStorage.getItem("companyId"), contextData.cryptoKey)) : undefined;
      setuserTnfDigestData(tnfData);

      setIsRefresh(false);
    }

  }, [isRefresh]);

  const onCancelButtonClick = (e) => {
    setIsRefresh(!isRefresh);
    // setisChanged(false);
    wesiteDigestItems.current = {};
    webcastsDigestItems.current = {};
    appDigestItems.current = {};
  };

  const onWebsiteItemChange = (updatedData) => {
    setWebsiteTableData(updatedData);
  };

  const onTnfItemChange = (updatedData) => {
    setTnfTableData(updatedData)
  }

  const onWebsiteHandleSubmit = () => {
    setInvalidEmailError("")
    var regexEmail = EMAIL_REGEX;
    let hasInvalidEmail;

    //validate emails if provided
    if (websiteRecipients) {
      let individualRecipient = websiteRecipients.split(",");
      hasInvalidEmail = individualRecipient.some((item, index) => {
        if (item && !regexEmail.test(item.trim()) || "") {
          setIsSuccess(false);
          setIsAlertShown(true);
          setInvalidEmailError("This email " + individualRecipient[index] + " is not valid one.")
          return true;
        }
        return false;
      })
    } else {
      setWebsiteRecipients("")
    }

    if (hasInvalidEmail) {
      return;
    }

    let tableData = websiteTableData;
    let reqObj = [];

    tableData && Array.isArray(tableData) && tableData.length > 0 && tableData.map((item) => {
      let obj = {
        userWebsiteId: item["WebsiteDigestTable.UserWebsiteId"],
        websiteName: item["WebsiteDigestTable.WebsiteName"],
        weekly: item["WebsiteDigestTable.Weekly"],
        monthly: item["WebsiteDigestTable.Monthly"],
        yearly: item["WebsiteDigestTable.Yearly"]
      }

      reqObj.push(obj);
    })

    if (tableData && Array.isArray(tableData) && tableData.length > 0 || websiteRecipients == "" || websiteRecipients !== "") {
      setBlurLoader(true);
      request(baseURL + `/User/SaveUserWebsiteDigestByUserId?CompanyId=${contextData.companyId}&recipient=${websiteRecipients}`,
        METHODS.POST,
        reqObj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setWebsiteTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const onTnfHandleSubmit = () => {
    setInvalidEmailError("")
    var regexEmail = EMAIL_REGEX;
    let hasInvalidEmail;

    //validate emails if provided
    if (IRRecipients) {
      let individualRecipient = IRRecipients.split(",");
      hasInvalidEmail = individualRecipient.some((item, index) => {
        if (item && !regexEmail.test(item.trim()) || "") {
          setIsSuccess(false);
          setIsAlertShown(true);
          setInvalidEmailError("This email " + individualRecipient[index] + " is not valid one.")
          return true;
        }
        return false;
      })
    } else {
      setIRRecipients("")
    }

    if (hasInvalidEmail) {
      return;
    }


    let tableData = tnfTableData;
    let reqObj = [];

    tableData && Array.isArray(tableData) && tableData.length > 0 && tableData.map((item) => {
      let obj = {
        userWebsiteId: item["WebsiteDigestTable.UserWebsiteId"],
        websiteName: item["WebsiteDigestTable.WebsiteName"],
        weekly: item["WebsiteDigestTable.Weekly"],
        biweekly: item["WebsiteDigestTable.Biweekly"],
        monthly: item["WebsiteDigestTable.Monthly"],
        yearly: item["WebsiteDigestTable.Yearly"]
      }

      reqObj.push(obj);
    })

    if (tableData && Array.isArray(tableData) && tableData.length > 0 || IRRecipients == "" || IRRecipients !== "") {
      setBlurLoader(true);
      request(baseURL + `/UserV2/SaveUserTnfDigestByUserId?CompanyId=${contextData.companyId}&Recipient=${IRRecipients}`,
        METHODS.POST,
        reqObj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setTnfTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const onWebcastItemChange = (updatedData) => {
    setWebcastTableData(updatedData);
  };

  const onAppItemChange = (updatedData) => {
    setAppTableData(updatedData);
  };

  const onWebcastHandleSubmit = () => {
    setInvalidEmailError("")
    var regexEmail = EMAIL_REGEX;
    let hasInvalidEmail;

    //validate emails if provided
    if (webcastRecipients) {
      let individualRecipient = webcastRecipients.split(",");
      hasInvalidEmail = individualRecipient.some((item, index) => {
        if (item && !regexEmail.test(item.trim()) || "") {
          setIsSuccess(false);
          setIsAlertShown(true);
          setInvalidEmailError("This email " + individualRecipient[index] + " is not valid one.")
          return true;
        }
        return false;
      })
    } else {
      setWebcastRecipients("")
    }

    if (hasInvalidEmail) {
      return;
    }

    let tableData = webcastTableData;
    let reqObj = [];

    if (tableData && Array.isArray(tableData) && tableData.length > 0) {
      let obj = {
        isAdhocActive: tableData[0]["WebcastDigestTable.Status"],
        isQuarterActive: tableData[1]["WebcastDigestTable.Status"]
      };
      reqObj.push(obj)
    }

    if (tableData && Array.isArray(tableData) && tableData.length > 0 || webcastRecipients == "" || webcastRecipients !== "") {

      setBlurLoader(true);
      request(baseURL + `/User/SaveUserWebcastDigestByUserId?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&recipient=${webcastRecipients}`,
        METHODS.POST,
        reqObj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setWebcastTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }

  }

  const onAppHandleSubmit = () => {
    let tableData = appTableData;
    let reqObj = [];

    tableData && Array.isArray(tableData) && tableData.length > 0 && tableData.map((item) => {
      let obj = {
        websiteId: item["AppDigestTable.WebsiteId"],
        websiteName: item["AppDigestTable.WebsiteName"],
        status: item["AppDigestTable.Status"]
      }
      reqObj.push(obj);
    })
    if (tableData && Array.isArray(tableData) && tableData.length > 0) {
      setBlurLoader(true);
      request(baseURL + `/User/SaveUserAppDigestByUserId?userid=${contextData.userId}`,
        METHODS.POST,
        { "userAppDigest": reqObj },
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setAppTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const wesiteStateChange = (stProp) => {
    wesiteDigestItems.current = stProp;
  }

  const TnfStateChange = (stProp) => {
    tnfDigestItems.current = stProp;
  }

  const webcastStateChange = (stProp) => {
    webcastsDigestItems.current = stProp;
  }

  const appStateChange = (stProp) => {
    appDigestItems.current = stProp;
  }

  const closeAlert = () => {
    setIsAlertShown(false);
  }

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <div className="ps-3">

        <Alert
          variant={isSuccess ? "success" : "danger"}
          show={isAlertShown}
        >
          <div className="d-flex justify-content-between mb-0">
            <div>
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              {invalidEmailError ? invalidEmailError : t("header.DigestSubscriptionSuccessMessage")}
            </div>
            <Button variant="close" onClick={() => { closeAlert() }} size="xs" />
          </div>
        </Alert>


        <Row className="mb-3">
          <Col lg={12}>
            <h2 className="h2 mb-0">{t("header.DigestSubscriptionTitle")}</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <UserDigestAlertComponent ref={alertchild} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {
              !hasWebsiteAccess && !webcastClientByCompanyId && !hasAppAccess && !hasTnfAccess ?
                <Alert
                  variant={"warning"}
                  show={true}>
                  <div className="d-flex justify-content-between mb-0">
                    <div>
                      <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                      {t("header.NoDataFound")}
                    </div>
                  </div>
                </Alert>
                :
                <Tab.Container activeKey={activeTab} defaultActiveKey="Website">
                  <Nav fill variant="pills" className="flex-column flex-sm-row tab webcast-subtab">
                    {hasWebsiteAccess && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("Website") }} eventKey="Website" className="mb-sm-3 mb-md-0">
                        {t("header.DigestSubscriptionWebsites")}
                      </Nav.Link>
                    </Nav.Item>}
                    {webcastClientByCompanyId && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("Webcast") }} eventKey="Webcast" className="mb-sm-3 mb-md-0">
                        {t("header.DigestSubscriptionWebcasts")}
                      </Nav.Link>
                    </Nav.Item>}
                    {/* {hasAppAccess && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("App") }} eventKey="App" className="mb-sm-3 mb-md-0"> */}
                    {/* {t("header.DigestSubscriptionApps")} <OverlayTrigger
                          placement="right"
                          trigger={['hover', 'focus']}
                          overlay={
                            <Tooltip>{t("header.digestSubscriptionsMessage")}</Tooltip>
                          }>
                          <Button variant="" className="btn-help border-0 p-0 btn">
                            <i class="far fa-question-circle lg"></i>
                          </Button>

                        </OverlayTrigger> */}
                    {/* </Nav.Link>

                    </Nav.Item>} */}
                    {hasTnfAccess && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("TNF") }} eventKey="TNF" className="mb-sm-3 mb-md-0">
                        {t("header.DigestSubscriptionIRTools")}
                      </Nav.Link>
                    </Nav.Item>}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane hidden={activeTab == "Website" ? false : true} eventKey="Website" className="py-2">
                      <Row className="mb-3 websitepane">
                        <Col lg={12}>
                          <UserWebsiteDigestComponent
                            websiteTableData={websiteTableData}
                            isRefresh={isRefresh}
                            setIsRefresh={setIsRefresh}
                            userWebDigestData={userWebDigestData}
                            onItemChange={onWebsiteItemChange}
                            setItems={wesiteStateChange}
                            handleSubmitButtonClick={onWebsiteHandleSubmit}
                            setRecipients={setWebsiteRecipients}
                            recipient={websiteRecipients}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col sm={12} className="text-end">
                          {/* <DigestSubscribtionBttonComponet handleSubmitButtonClick={onWebsiteHandleSubmit} onCancelButtonClick={onCancelButtonClick} /> */}
                        </Col>
                      </Row>
                    </Tab.Pane>

                    {webcastClientByCompanyId &&
                      <Tab.Pane hidden={activeTab == "Webcast" ? false : true} eventKey="Webcast" className="py-2">
                        <Row className="mb-3 webcastpane">
                          <Col lg={12}>
                            <UserWebcastDigestComponent
                              webcastTableData={webcastTableData}
                              isRefresh={isRefresh}
                              setIsRefresh={setIsRefresh}
                              userWebCastData={userWebCastData}
                              onItemChange={onWebcastItemChange}
                              setItems={webcastStateChange}
                              handleSubmitButtonClick={onWebcastHandleSubmit}
                              setRecipients={setWebcastRecipients}
                              recipient={webcastRecipients}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col sm={12} className="text-end">
                            {/* <DigestSubscribtionBttonComponet handleSubmitButtonClick={onWebcastHandleSubmit} onCancelButtonClick={onCancelButtonClick} /> */}
                          </Col>
                        </Row>
                      </Tab.Pane>}

                    {hasTnfAccess &&
                      <Tab.Pane hidden={activeTab == "TNF" ? false : true} eventKey="TNF" className="py-2">
                        <Row className="mb-3 websitepane iranalytics-pane">
                          <Col lg={12}>
                            <UserTnfDigestComponent
                              tnfTableData={tnfTableData}
                              isRefresh={isRefresh}
                              setIsRefresh={setIsRefresh}
                              userTnfDigestData={userTnfDigestData}
                              onItemChange={onTnfItemChange}
                              setItems={TnfStateChange}
                              handleSubmitButtonClick={onTnfHandleSubmit}
                              setRecipients={setIRRecipients}
                              recipient={IRRecipients}
                            />
                          </Col>
                        </Row>
                      </Tab.Pane>
                    }

                    {/* <Tab.Pane hidden={activeTab == "App" ? false : true} eventKey="App" className="py-2">
                      <Row className="mb-3 apppane">
                        <Col lg={12}>
                          <UserAppDigestComponent
                            appTableData={appTableData}
                            isRefresh={isRefresh}
                            setIsRefresh={setIsRefresh}
                            userAppDigestData={userAppDigestData}
                            onItemChange={onAppItemChange}
                            setItems={appStateChange}
                            handleSubmitButtonClick={onAppHandleSubmit} onCan
                          /> */}
                    {/* <DigestSubscribtionBttonComponet handleSubmitButtonClick={onAppHandleSubmit} onCancelButtonClick={onCancelButtonClick} /> */}

                    {/* </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col sm={12} className="text-end">
                        </Col>
                      </Row>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
            }

          </Col>
        </Row>
      </div>
    </>
  );
};

export default DigestSubscriptionsPage;
