import React, { Component, useContext } from "react";
import { withRouter } from 'react-router-dom';
import HeaderDropdowns from "../components/HeaderDropdowns";

const UserSettingsPage = ({ history, location }) => {

    return (
        <HeaderDropdowns />
    );

};

export default withRouter(UserSettingsPage);