import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import AppContext from '../components/AppContext';
import { isMobile } from "react-device-detect";
import { Nav, Card, Navbar, Container, Image, Form, Button, Dropdown } from '@themesberg/react-bootstrap';
import { getStringValue } from "../utils/common";
import Profile3 from "../assets/img/profile-picture-3.jpg";
import NoImageFound from "../assets/img/no-user-image.jpg";
import ConnectIDLogo from "../assets/img/ConnectIDIntelligence.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import HeaderDropdowns from "../components/HeaderDropdowns";
import { useTranslation } from 'react-i18next';
import IdleTimerContainer from './IdleTimerContainer';
import { decryptData } from '../utils/crypto';

const Header = (props) => {
  const contextData = useContext(AppContext);
  const [userName, setUserName] = useState("");

  const [showSideBar, setShowSideBar] = useState(false);
  const [t, i18n] = useTranslation();
  const showHamburger = isMobile ? true : false;

  useEffect(() => {
    getUserName();
  }, []);

  const getUserName = () => {
    let userNm = contextData.firstName ? contextData.firstName : decryptData(localStorage.getItem("firstName"), contextData.cryptoKey);
    setUserName(userNm);
  };


  const toggleContracted = () => props.toggleContracted && props.toggleContracted();



  return (

    <Navbar expanded
      className=""
      id="mainnav">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">


          <Navbar.Brand as={HashLink} to="/website" className="me-lg-3 d-flex align-items-center" id="logo">
            <Image src={ConnectIDLogo} className="logo-avtar large-avatar" />
          </Navbar.Brand>

          {/* <div className="d-flex align-items-center">
            {showHamburger &&
              <Button id="sidebar-toggle" variant="icon-only" size="lg" className="sidebar-toggle btn-circle d-none d-md-inline-block me-3" onClick={toggleContracted}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
            }
          </div> */}
          <Nav className="align-items-center">
            <div className="media d-flex align-items-center">

              <div className="media-body me-3 align-items-center d-none d-lg-block w-100">
                <span className="mb-0 font-small fw-bold">{t("header.WelcomeBackText")}, {getStringValue(userName)}</span>
              </div>
            </div>
            {/* <div className="media d-flex align-items-center">
              <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                <Image src={userProfileImage ? userProfileImage : NoImageFound} className="user-avatar md-avatar rounded-circle" />
              </div>
            </div> */}
            <HeaderDropdowns setValid={props.setValid} />


          </Nav>

          {/* <Navbar.Toggle aria-controls="navbar-default-primary" /> */}

        </div>
        <IdleTimerContainer></IdleTimerContainer> {/* IdleTimerContainer is for Session time out Popup*/}
      </Container>
    </Navbar>

  );
};

export default withRouter(Header);
