import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  ButtonGroup,
  Card,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { GET_DASHBOARD_ITEMS } from "../graphql/queries";
import { withRouter } from "react-router";
import { DELETE_DASHBOARD_ITEM } from "../graphql/mutations";
import CardComponent from "../components/CardWithMetrices";
import ModalComponent from "../components/VoltModal";
import { cardMenu } from "../stubs/dasbordCardMenu";
import { useTranslation } from 'react-i18next';
import {hideCardMenu} from "../AppConfig"

const DashboardItem = ({
  pageName,
  summaryTooltip,
  itemId,
  children,
  title,
  summaryPrimary,
  summarySecondary,
  showSummary,
  history,
  // location,
  enableOption,
  summary,
  chartType,
  hideTitle,
  tooltipText,
  setIsPageLoaderShown,
  recordsCount,
  // numberOfRecords,
  customTitle,
  loadedCardIdiesList

}) => {
  const [t, i18n] = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showMenu,setShowMenu] = useState(true)

  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS,
      },
    ],
  });
  useEffect(()=>{
    setShowMenu(localStorage.getItem("loaderId"+itemId));
  },[localStorage.getItem("loaderId"+itemId)])
  // useEffect(()=>{
  //   setShowMenu(localStorage.getItem("loaderId"+itemId))
  // },[localStorage.getItem("loaderId"+itemId)])
  const onModalClose = () => {
    setShowModal(false);
  };

  const onModalConfirm = () => {
    removeDashboardItem({
      variables: {
        id: itemId,
      },
    });
  };

  // const onEditItemClick = (itemId) => {
  //   history.push(`/explore?itemId=${itemId}`);
  // }
  const onDetauilViewClick = (itemId) => {
    //  history.push(`/explore?itemId=${itemId}`);
    localStorage.setItem("IsPageRefresh", "0"); // set  page refresh indicator to 0 on side bar click event
    if (itemId === "3") {
      history.push(`/website/visitoranalytics-page`);
    }
    else if (itemId === "4") {
      history.push(`/website/visitoranalytics-page/watchlist`);
    } else if (itemId === "6") {
      history.push(`/website/search-page`);
    }
    else if (itemId === "1") {
      history.push(`/website/cookie-analytics-page`);
    } 
    else if (itemId === "7") {
      history.push(`/website/useranalytics-page`);
    }
    else if (itemId === "8") {
      history.push(`/website/page-analytics-page`);
    }
  };

  const onCardMenuClick = (name, itemId) => {
    
    switch (name) {
      case "Detailed_view":
        onDetauilViewClick(itemId);
        // onEditItemClick(itemId);
        break;
      default:
        break;
      // case "delete":
      //   setShowModal(true);
      //   break;
    }
  };

  return (
    <>
      {showModal && (
        <ModalComponent
          open={showModal}
          title={t("header.dashboardItemsTitle")}
          handleClose={onModalClose}
        >
          <Button
            variant="outline-dark"
            className="m-1 text-gray ms-auto"
            onClick={onModalClose}
          >
            {t("header.dashboardItemsNo")}
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outline-danger"
            className="m-1 text-gray ms-auto"
            onClick={() => onModalConfirm(itemId)}
          >
           {t("header.dashboardItemsYes")}
          </Button>
        </ModalComponent>
      )}
      <CardComponent
        recordsCount={recordsCount}
        setIsPageLoaderShown={setIsPageLoaderShown}
        itemId={itemId}
        title={title}
        customTitle={customTitle}
        summaryPrimary={summaryPrimary}
        summarySecondary={summarySecondary}
        showSummary={showSummary}
        onModalClose={onModalClose}
        onCardMenuClick={onCardMenuClick}
        showMenuIems={loadedCardIdiesList  !== undefined && loadedCardIdiesList .indexOf(parseInt(itemId)) !== -1 ? true:false}
        cardMenu={cardMenu}
        enableOption={enableOption}
        summary={summary}
        chartType={chartType}
        hideTitle={hideTitle}
        tooltipText={tooltipText}
        tooltip={summaryTooltip}
        pageName = {pageName}
        
      >
        {children}
      </CardComponent>
    </>
  );
};

export default withRouter(DashboardItem);
