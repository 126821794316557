
import React, { useState, useEffect } from 'react'; 
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Row, Col } from '@themesberg/react-bootstrap'; 
import { useTranslation } from 'react-i18next'; 
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant'; 
import { decryptData } from '../utils/crypto';
import DashboardItem from '../components/DashboardItem';
import moment from "moment-timezone";
import { apiCharts, getWebsite } from "../stubs/ComponentJson/website";
import ApiChartRenderer from '../components/ApiChartRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import SummaryRenderer from '../components/SummaryRenderer';



const Website = React.memo(({ profileID, startDate, endDate, intelConfigID, companyID, websiteID, cryptoKey }) => {


  const [t, i18n] = useTranslation();

  const [loadedCardIdies, setLoaderCardIdies] = useState([])
  const [websiteData, setWebsiteData] = useState([]);
  const [getTotalResults, setGetTotalResults] = React.useState(0);

  useEffect(() => {

    setLoaderCardIdies([])

    const selectedstartdate = moment(startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(endDate).format("YYYY-MM-DD");

    const culture = localStorage.getItem("i18nextLng");
    let dta = null;
    let viExists = false;

    if (profileID) {
      dta = ((profileID
        && intelConfigID
        && selectedstartdate !== "Invalid date"
        && selectedenddate !== "Invalid date"))
        ?
        getWebsite(profileID, "direct", selectedstartdate, selectedenddate, intelConfigID, companyID, culture)
        : null;

      //section id and ai consent are static for website channel

      request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${companyID}&sectionid=` + 1 + `&websiteid=${websiteID}&aiconsent=1`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {

          if (res && res.length) {
            let menuData = res.find(item => item.id === 1)?.subSections.find(item => item.id === 2);

            if (menuData?.actions.length) {

              let submenudata = menuData.actions.find(item => item.id === 11 && item.isEnabled === true);

              if (submenudata) {

                viExists = true;

              }
            }
          }

          if (!viExists) {
            dta = dta.filter(item => item.id !== "3" && item.id !== "4")
          }

          setWebsiteData(dta);
        })
        .catch((err) => {

        })



    }

  }, [profileID, startDate, endDate]);

  const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    layoutone: i.layoutone ? JSON.parse(i.layoutone) : {},
    layouttwo: i.layouttwo ? JSON.parse(i.layouttwo) : {},
    vizStateSecond: i.vizStateSecond ? JSON.parse(i.vizStateSecond) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    isResultShownFromApi: i.isResultShownFromApi ? true : false,
    chartType: i.chartType ? i.chartType : undefined,
    isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false
  });

  const dashboardItem = (item) => {
    return (
      <Col md={item.id == "7" || item.id == "8" ? 12 : 6} className='col-spacing-bottom'>

        {(item.isResultShownFromApi && item.chartType) ?

          <DashboardItem
            recordsCount={getTotalResults}
            key={item.id}
            itemId={item.id}
            title={item.name}
            loadedCardIdiesList={[... new Set(loadedCardIdies)]}
            enableOption={item.enableOption}
            pageName={"WebsiteInsight"}
            summaryTooltip={showTooltips(item)}>
            <Row>
              <Col className={item.id === "7" ? "user-section" : ""} sm={(item.vizStateSecond !== undefined && Object.keys(item.vizStateSecond).length > 0) ? 6 : 12}>
                {
                  (item.name !== "Search" || item.name !== "Content Insight") &&
                  <ApiChartRenderer
                    summaryBesideText={item.name === "Cookies" ? t("header.coockiesCardSummary") : ""}
                    cardDetails={item.name}
                    summaryTooltip={showTooltips(item)}
                    isSummeryShown={item.isSummeryShown}
                    services={apiCharts}
                    chartType={item.chartType}
                    itemId={item.id}
                    loadedCardId={(e) => updateCardsArray(e)}
                    vizState={item.vizState}
                    cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout}
                    legends={item.legends}
                  />
                }
              </Col>
              {(item.vizStateSecond !== undefined && Object.keys(item.vizStateSecond).length > 0) &&
                <Col className={item.id === "7" ? "user-section" : ""} sm={6}>
                  {
                    item.apiUrl !== undefined && item.name === "User" &&
                    <>
                      <h6>{t("header.websiteAnalyticsUserCardHeading")}</h6>
                      <ChartRendererTable
                        setGetTotalResults={(e) => updateCardsArray(e)}
                        summaryTooltip={showTooltips(item)}
                        showPagination={false}
                        apiUrl={item.apiUrl}
                        item={item}
                        showNumberOfRows={5}
                        showCount={false}
                      />
                    </>
                  }
                </Col>
              }
            </Row>
          </DashboardItem>
          :
          <DashboardItem
            key={item.id}
            itemId={item.id}
            title={item.name}
            loadedCardIdiesList={[... new Set(loadedCardIdies)]}
            enableOption={item.enableOption}
            pageName={"WebsiteInsight"}
            summaryTooltip={showTooltips(item)}>
            <Row>
              <Col className={item.id === "7" ? "user-section" : ""} sm={(item.vizStateSecond !== undefined && Object.keys(item.vizStateSecond).length > 0) ? 6 : 12}>
                {Object.keys(item.summary).length > 0 &&
                  <SummaryRenderer
                    summaryTooltip={showTooltips(item)}
                    vizState={item.summary}
                    title={item.name}
                  />
                }
                {
                  item.apiUrl !== undefined && (item.name === "Search" || item.name === "Content Insights" || item.name === "Watchlist" || item.name === "Visitor Insights") &&
                  <ChartRendererTable
                    item={item}
                    loadedCardId={(e) => updateCardsArray(e)}
                    summaryTooltip={showTooltips(item)}
                    showPagination={false}
                    apiUrl={item.apiUrl}
                    showNumberOfRows={5}
                    summaryBesideText={item.name === "Content Insights" ? t("header.InsightsChartTooltipPageViews") : item.name === "Search" ? t("header.websiteAnalyticsTotalqueries") : (item.summarylabel != undefined ? t("header.summary_" + item.summarylabel) : "")}
                    showCount={true}
                    showTotalCount={(item.name === "Watchlist" || item.name === "Visitor Insights" || item.name === "Content Insights") ? true : false}
                  />
                }
              </Col>
            </Row>
          </DashboardItem>
        }
      </Col>
    );
  };

  const showTooltips = (item) => {
    if (item.name == 'Cookies') {
      return "header.cookiesTooltiptext";
    } else if (item.name == 'Visitor Insights') {
      //  return 'header.visitorInsightsTooltiptext';
      return 'header.visitorInsightTooltip';
    } else if (item.name == 'Audience trends') {
      return 'header.audienceTrendsTooltiptext';
    } else if (item.name == 'Watchlist') {
      return 'header.watchlistTooltiptext';
      //return "Watch specific company visits to your website."
    } else if (item.name == 'Traffic source') {
      return 'header.trafficsourceTooltiptext';
    } else if (item.name == 'Search') {
      return 'header.searchTooltiptext';
    } else if (item.name == 'User') {
      return 'header.userTooltiptext';
    } else if (item.name == 'Content Insights') {
      return 'header.contentInsightsTooltiptext';
    } else {
      return "";
    }
  }
  const updateCardsArray = (item) => {
    setLoaderCardIdies((e) => [...e, item])
  }

  return (
    <> 
      <div id="websitestatistics" className="d-inline-block p-3 pe-0 website-analytics w-100" >
        <Row className='width-adjust'> {websiteData && websiteData.map(deserializeItem).map(dashboardItem)}
        </Row>
      </div>

    </>
  );
});

export default Website;
