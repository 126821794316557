import React, { useState, useContext, useMemo, useEffect } from 'react';
import { withRouter } from "react-router";
import { Row, Col, Form, FormCheck, Image, Dropdown, ButtonGroup, Alert } from '@themesberg/react-bootstrap';
import { isMobile } from "react-device-detect";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import Filters from "../components/Filters";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import AppContext from '../components/AppContext';
import Search from "../components/Search";
import ChartRendererTable from '../components/ChartRendererTable';
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { convertBase64 } from "../utils/common";
import { useTranslation } from 'react-i18next';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  // summary: JSON.parse(i.summary) || {},
  // vizState: JSON.parse(i.vizState)
});

const WebcastQuestions = ({ selectedPublishStatus, isRefresh, setIsRefresh, setSearchString, selectedpresentation, presentationId, hasEvents, setSelectedPublishStatus, webcastQuestions, changeQuestionData, showLoader }) => {
  const [t, i18n] = useTranslation();
  const [offsetQuestion, setOffsetQuestion] = useState(0);
  const [pageNoQuestion, setPageNoQuestion] = useState(0);
  const [itemsQuestion, setItemsQuetion] = useState([]);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [selectedTempPublishStatus, setSelectedTempPublishStatus] = useState({ id: 1, status: "All" });
  const [qustionData] = useState([{ id: 1, status: "All" }, { id: 2, status: "Pending" }, { id: 3, status: "Approved for All" }, { id: 4, status: "Approved for Presenter" }]);
  const contextData = useContext(AppContext);
  const [isAutoRefresh, setIsAutoRefresh] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [searchTextString, setSearchTextString] = useState("");
  const [searchData, setSearchData] = useState("");
  const [questionsDataObject, setQuetionsDataObject] = useState({});
  const [apiResponse, setApiResponse] = useState({});
  const [apiResponseMaster, setApiResonseMaster] = useState({});
  const [apiResult, setApiResult] = useState({})
  const [url, setUrl] = useState("");
  const [manualRefresh, setManualRefresh] = useState(false)
  const [selectedFilterItem, setSelectedFilterItem] = useState({});
  const [blurLoader, setBlurLoader] = useState(false);
  const [getTotalResults, setGetTotalResults] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);

  if (pageNoQuestion === 0) {
    setPageNoQuestion(1);
  }

  const onPageNoChangeQuestion = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setOffsetQuestion(num);
    setPageNoQuestion(page);
    changeQuestionData();
  }

  useEffect(() => {
    setIsSubscribe(true);
    setIsAutoRefresh(true);
  }, []);

  useEffect(() => {
    setTimeout(() => { setAutoRefresh(!autoRefresh) }, 5000);
    if ((isAutoRefresh && autoRefresh) || (manualRefresh === true)) {
      setManualRefresh(false)
      loadContent(url)
    }
  }, [autoRefresh, manualRefresh])

  const userSearch = (result, searchText) => {
    if (searchText !== undefined && searchText !== "") {
      let resultObject = result;
      if (result !== null) {
        if (resultObject.data !== null && resultObject.data?.length > 0) {
          let filterResult = resultObject?.data?.filter(item => (item?.Email?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1 || item?.Question?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1));
          resultObject.data = filterResult;
        }
        return resultObject;
      }

    } else {
      return result
    }
  }

  const onFormElementsChange = (event) => {
    const { checked } = event.target;
    setIsSubscribe(checked);
    setIsAutoRefresh(checked);
  };

  useEffect(() => {
    if (webcastQuestions[0].apiUrl.url) {
      setUrl(webcastQuestions[0].apiUrl.url)
    }
  }, [webcastQuestions])

  const loadContent = (url) => {
    request(url, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
      .then((response) => {
        if (JSON.stringify(apiResponse) !== JSON.stringify(response)) {
          setApiResult(response)
        }
      })
      .catch(() => {
      })
  }

  const filterData = (result, value) => {
    let resultObject = [];
    if (result !== undefined && result.data !== undefined) {
      resultObject = result.data.filter((e) => { if (e.QuestionApprovedFor === value) { return e } });
    }
    result.data = resultObject;
    return result;
  }

  const applyStatusFiltersNew = () => {
    setSelectedFilterItem(selectedTempPublishStatus)
  }

  const applyStatusFilters = (result) => {
    if (selectedFilterItem.id === 1) {
      return result;
    } else if (selectedFilterItem.id === 2) {
      return filterData(result, "none")
    } else if (selectedFilterItem.id === 3) {
      return filterData(result, "all")
    } else if (selectedFilterItem.id === 4) {
      return filterData(result, "presenter")
    } else {
      return result;
    }
  };

  const cancelStatusFilter = () => {
    setSelectedTempPublishStatus(selectedFilterItem);
  };

  const onPublishStatusChange = (data) => {
    if (data !== undefined && data !== null) {
      setSelectedTempPublishStatus(data);
    }
  };

  const createFilter = useMemo(
    () => (
      <>
        <Filters
          iconText={t("header.Icon_Tooltip_Filter")}
          applyFilters={applyStatusFiltersNew}
          cancelFilter={cancelStatusFilter}
          dataSource={[
            {
              name: "Dropdown1 - Publish Status",
              data: qustionData,
              selectedItem: selectedTempPublishStatus,
              isVisible: true,
              label: t("header.webcastQuestionsPublishStatus"),
              callback: onPublishStatusChange,
              displayText: "status",
            }
          ]}
        ></Filters>
      </>
    ),
    [qustionData, selectedTempPublishStatus]
  );

  var webcastTypeEnum = {
    LIVE: "InvestisLive",
    CHORUS: "ChorusCall",
    METRO: "Metro"
  };

  const compareObject = (currentData, compareItem) => {
    if (JSON.stringify(currentData) === JSON.stringify(compareItem)) {
      return true;
    } else {
      return false;
    }
  }

  const searchDataNew = (resultSet, value) => {
    setTimeout(() => {
      let filterResult = applyStatusFilters(resultSet);
      setTimeout(() => {
        let searchResult = userSearch(filterResult, value);
        if (compareObject(apiResponse, searchResult) !== true) {
          setApiResponse({})
          setApiResponse(searchResult);
        }
      }, 100)
    }, 1000)
  };

  const Refresh = () => {
    setIsRefresh(!isRefresh);
  }

  useEffect(() => {
    localStorage.setItem("pageNo", 1)
    if (searchData === "") {
      setSearchData("")
    }
    searchDataNew({ ...apiResult }, searchData !== "" ? searchData : "")
  }, [searchData, selectedFilterItem])

  useEffect(() => {
    searchDataNew({ ...apiResult }, searchData !== "" ? searchData : "")
  }, [apiResult])

  useEffect(() => {
    searchDataNew("")
  }, [contextData.presentation])

  const tableData = useMemo(() => (<ChartRendererTable
    pageNo={localStorage.getItem("pageNo") != undefined ? parseInt(localStorage.getItem("pageNo")) : 1}
    summaryTooltip={"header.questionscardTooltipText"}
    showPagination={true}
    showNumberOfRows={10}
    summaryBesideText={t("header.WebcastTotalQuestionsText")}
    resultSet={apiResponse}
    setPageNo={1}
    setIsRefresh={setIsRefresh}
    searchText={searchData + JSON.stringify(selectedFilterItem)}
    setGetTotalResults={(value) => setGetTotalResults(value)}
  />), [apiResponse])

  const setDowloadDropdownMenu = () => {
    let ddList = ["Excel", "PDF"];
    return ddList.map(item => {
      return (
        <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
          {item}
        </Dropdown.Item>
      );
    })
  }

  const downloadGrid = (item) => {
    if (item === "Excel") {
      downloadExcel()
    }
    else {
      downloadPDF();
    }
  }

  const downloadExcel = async () => {
    showLoader(true);
    let providerName = ""
    if (contextData.webcastType === 1) {
      providerName = webcastTypeEnum.LIVE;
    }
    else if (contextData.webcastType === 2) {
      providerName = webcastTypeEnum.CHORUS;
    }
    else {
      providerName = webcastTypeEnum.METRO;
    }
    const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
    let paramsList = {
      presentationName: selectedpresentation["Presentations.name"],
      clientSlug: selectedpresentation["Presentations.clientslug"],
      presentationId: presentationId,
      presentationSlug: selectedpresentation["Presentations.slug"],
      skip: 0,
      providerName: providerName,
      companyName: selectedComapnyData.companyName,
      culture: localStorage.getItem('i18nextLng')
    }

    const tempVar = await axios.get(
      `${process.env.REACT_APP_API_KEY}/Website/ExportInvestisLiveQuestionsUsingEPPlus`,
      {
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
          "content-type": "application/json"
        },
        params: {
          encodeStr: convertBase64(JSON.stringify(paramsList))

        }
      }
    );
    if (tempVar !== undefined && tempVar !== null) {
      showLoader(false);
      let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
      let a = document.createElement("a");
      a.href = fetchDataModified;
      a.download = 'Webcast_Statistics_' + contextData.presentation["Presentations.name"] + '.xlsx';
      a.click();
    }
  };

  const downloadPDF = async () => {
    showLoader(true);
    const filterStatus = selectedTempPublishStatus.status == "All" ? "" : selectedTempPublishStatus.status == "Approved for All" ? "all" : selectedTempPublishStatus.status == "Approved for Presenter" ? "presenter" : "none";
    let filters = "";

    if (filterStatus !== "") {
      filters = `QuestionApprovedFor|contains|${filterStatus}`
    }
    if (searchData !== "" && searchData !== null) {
      filters += `&&Email|contains|${searchData}||Question|contains|${searchData}`
    }

    let fileName = "Webcast_Statistics.pdf";
    // let filters = (searchData !== "" && searchData !== null)  ? "Email|contains|" + searchData + "||Question|contains|" + searchData + "||QuestionApprovedFor|contains|"+ filterStatus : "";
    let obj = {
      Title: "Webcast statistics for " + JSON.parse(localStorage.getItem("PresentationId"))["Presentations.name"],
      SubTitle: "Client: " + decryptData(localStorage.getItem("companyName"), contextData.cryptoKey),
      PageName: "Webcast Questions",
      CountTitle: "<span class='total-record-count'>{{count}}</span> Total Questions",
      Filters: filters,
      ClientName: decryptData(localStorage.getItem("companyName"), contextData.cryptoKey),
      Url: url,
      Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
      FileName: fileName,
      Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
      Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
      Count: 0,
      FilterContent: "",
      TableHeaders: []
    }

    await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      })
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          else {
            setAlertVisible(true);
            setTimeout(() => {
              setAlertVisible(false);
            }, 3000);
          }
          showLoader(false);
        }
        else {
          showLoader(false);
          console.error('Error generating PDF.');
        }
      })
      .catch((err) => {
        showLoader(false);
        console.error('Error generating PDF:', err);
      })
  };

  const webcastQuestionItem = (item) => (
    <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <Row className="webcast-questions-card-nav mb-4">
        <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
          <div className="d-inline-block me-4 webcast-table-nav float-end">
            <div className="icon-nav-list svg-icon-nav-list">
              <div className={getTotalResults > 0 ? "icon-nav-box hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                {hasEvents ? createFilter : ""}
              </div>
              <span className={getTotalResults > 0 ? "icon-nav-box hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                <Dropdown title={t("header.iconTooltiop_download", "Download")} as={ButtonGroup}>
                  <Dropdown.Toggle split className="pt-0 card-dropdown-btn">
                    <svg className="icon-nav">
                      <title>{t("header.Icon_Tooltip_Download")}</title>
                      <use href={iconSprite + `#Download`} />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {setDowloadDropdownMenu()}
                  </Dropdown.Menu>
                </Dropdown>
              </span>
              <span className={isAutoRefresh === true ? "icon-nav-box webcast-sync-icon disabled" : "icon-nav-box"} onClick={(e) => setManualRefresh(true)}>
                <svg className="icon-nav">
                  <title>{t("header.Icon_Tooltip_Refresh")}</title>
                  <use href={iconSprite + `#Sync`} />
                </svg>
              </span>
            </div>
          </div>
          <div className="d-inline-block webcast-question-search-row">
            <div className="d-inline-block mt-2 pe-3 text-end float-end">
              <Form.Group className="d-inline-block float-end">
                <Form.Label className="float-start me-2">{t("header.WebcastInvestisLiveQuestionAutoRefreshText", "Auto-Refresh")} :</Form.Label>
                <FormCheck type="switch" className="float-start">
                  <FormCheck.Input checked={isAutoRefresh}
                    type="checkbox" id="billingSwitch" className="me-2" name="toggle"
                    onChange={(e) => onFormElementsChange(e)}
                  />
                </FormCheck>
              </Form.Group>
            </div>
            <div className="d-inline-block float-end webcast-question-search-container">
              <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
            </div>
          </div>
        </Col>
      </Row>
      {
        (item.name == "Questions") &&
        <Row className="webcast-table-data">
          <Col>
            {tableData}
          </Col>
        </Row>
      }
    </div>
  );

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {alertVisible && (<div className="fade modal-backdrop show"></div>)}
      <Alert
        className="toast-custom"
        variant="success"
        show={alertVisible}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {t("header.PdfDeliveredToMailMessage")}
          </div>
        </div>
      </Alert>
      {webcastQuestions !== undefined && webcastQuestions.length && webcastQuestions.map(deserializeItem).map(webcastQuestionItem)}
    </>
  );
};

export default withRouter(WebcastQuestions);
