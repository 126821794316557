import request from "../request"
import { baseURL, CHART_TYPE, METHODS } from "../utilities/constant";
import { decryptData } from "../../utils/crypto";

export function getDataForOnDemandCard(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"), cryptoKey);
    request(
        baseURL + `/Analytics/GetInvestisLiveOnDemandViewerSeries?clientSlug=${payload.clientSlug}&presentationId=${payload.presentationId}&presentationSlug=${payload.presentationSlug}&providerName=InvestisLive&companyid=${payload.companyId}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            if (payload.presentationId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function getDataForOnLiveCard(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"), cryptoKey);
    request(
        baseURL + `/Analytics/GetInvestisLiveLiveViewerSeries?clientSlug=${payload.clientSlug}&presentationId=${payload.presentationId}&presentationSlug=${payload.presentationSlug}&providerName=InvestisLive&companyid=${payload.companyId}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            if (payload.presentationId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function getDataForViewerDomain(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"), cryptoKey);
    request(
        baseURL + `/Analytics/GetInvestisLiveDomainDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=${payload.clientSlug}&presentationId=${payload.presentationId}&presentationSlug=${payload.presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${payload.companyId}&clienttype=IL&type=${CHART_TYPE.piechart}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            if (payload.presentationId)
                callBackFn && callBackFn(response?.result, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function getDataForViewerDevice(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"), cryptoKey);
    request(
        baseURL + `/Analytics/GetInvestisLiveDeviceDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=${payload.clientSlug}&presentationId=${payload.presentationId}&presentationSlug=${payload.presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${payload.companyId}&clienttype=IL&type=${CHART_TYPE.piechart}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            if (payload.presentationId)
                callBackFn && callBackFn(response?.result, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function getDataForViewersDomainsVE(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"), cryptoKey);
    request(
        baseURL + `/Analytics/GetInvestisLiveDomainDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=${payload.clientSlug}&presentationId=${payload.presentationId}&presentationSlug=${payload.presentationSlug}&fetchLatest=true&isExport=false&generateLatest=false&companyid=${payload.companyId}&clienttype=VE&type=${CHART_TYPE.piechart}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            if (payload.presentationId)
                callBackFn && callBackFn(response?.result, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}
