import { Row, Col, Modal, Form, Button, Alert, Card } from "@themesberg/react-bootstrap";
import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router";
import AppContext from '../components/AppContext';
import DashboardItem from "../components/DashboardItem";
import ChartRenderer from "../components/ChartRenderer";
import { userMyDocumentsList } from "../stubs/ComponentJson/MyDocuments";
import { isMobile } from "react-device-detect";
import SummaryRenderer from '../components/SummaryRenderer';
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO, TABLE_TOTAL_ROWS } from "../AppConfig";
import ChartRendererTable from "./ChartRendererTable";
import { useDeferredValue } from "react";
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import ButtonsComponent from "./Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DELETE_DASHBOARD_ITEM } from "../graphql/mutations";
import { useTranslation } from 'react-i18next';
import { decryptData } from "../utils/crypto";

const MyDocuments = (props) => {

    const contextData = useContext(AppContext);
    const [isMultiDeleteDisabled, setIsMultiDeleteDisabled] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isRefresh, setIsRefresh] = useState("");
    const [items, setItems] = useState([]);
    const [userMyDocumentsData, setUserMyDocumentsData] = useState([]);
    const [t, i18n] = useTranslation();
    const [deleteItemData, setDeleteItemData] = useState(undefined);
    const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
    const [isEditDocumentModalShown, setIsEditDocumentModalShown] = useState(false);
    const [isDeleteAllClick, setIsDeleteAllClick] = useState(false);

    const [documentTitle, setDocumentTitle] = useState(undefined);
    const [documentDesc, setDocumentDesc] = useState(undefined);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState(undefined);

    const [allDocumentChecked, setAllDocumentChecked] = useState(false);
    const [checkedDocument, setCheckDocument] = useState([]);
    const [tableDataForDelete, setTableDataForDelete] = useState([]);

    const [documentTitleErr, setDocumentTitleErr] = useState(undefined);
    const [documentDescErr, setDocumentDescErr] = useState(undefined);
    const [getTotalResults, setGetTotalResults] = useState(0);


    useEffect(() => {
        if (pageNo === 0) {
            setPageNo(1);
            setIsRefresh(true);
        }
        let dta = userMyDocumentsList(decryptData(localStorage.getItem("companyId"), contextData.cryptoKey), contextData.userId, offset);
        setUserMyDocumentsData(dta);
    }, []);



    useEffect(() => {
        if (props.isTableRefresh) {
            if (pageNo === 0) {
                setPageNo(1);
                setIsRefresh(true);
            }
            let dta = userMyDocumentsList(decryptData(localStorage.getItem("companyId"), contextData.cryptoKey), contextData.userId, offset);
            setUserMyDocumentsData(dta);
            props.setIsTableRefresh(false);
            setTableDataForDelete([]);
            setCheckDocument([]);
            setAllDocumentChecked(false);
        }
    }, [props.isTableRefresh])

    const deserializeItem = (i) => ({
        ...i,
        layout: JSON.parse(i.layout) || {},
        //summary: JSON.parse(i.summary) || {},
        //vizState: JSON.parse(i.vizState)
    });

    const defaultLayout = (i) => ({
        x: i.layout.x || 0,
        y: i.layout.y || 0,
        w: i.layout.w || 4,
        h: i.layout.h || 8,
        minW: 4,
        minH: 8,
    });

    const defaultLayoutMobile = (i) => ({
        x: i.layout.x || 0,
        y: i.layout.y || 0,
        w: 12,
        // w: i.layout.w || 4,
        h: i.layout.h + 2 || 12,
        minW: 4,
        minH: 8,
    });

    const onDeleteDocument = (e, row, rowIndex) => {
        e.preventDefault();
        if (row["DocumentTable.IsDocumentOwner"]) {
            setDeleteItemData(row);
            setIsConfirmModalShown(true);
        }

    }

    const onDeleteAllDocument = (e) => {
        e.preventDefault();
        if (checkedDocument.length > 0) {
            setIsDeleteAllClick(true);
            setIsConfirmModalShown(true);
        }
    }

    const handleClose = () => {
        setIsDeleteAllClick(false);
        setDeleteItemData(undefined);
        setIsConfirmModalShown(false);
        setIsEditDocumentModalShown(false);
    }

    const onConfirm = () => {
        let obj = [];
        props.setBlurLoader(true);
        if (isDeleteAllClick) {
            obj = [];
            tableDataForDelete && Array.isArray(tableDataForDelete) && tableDataForDelete.map((item, index) => {
                if (checkedDocument.includes(item["DocumentTable.DocumentID"])) {
                    obj.push(
                        {
                            "docId": item["DocumentTable.DocumentID"],
                            "docPath": item["DocumentTable.DocumentPath"]
                        }
                    )
                }
            })
        }
        else {
            obj = [
                {
                    "docId": deleteItemData["DocumentTable.DocumentID"],
                    "docPath": deleteItemData["DocumentTable.DocumentPath"]
                }
            ]

        }

        setIsConfirmModalShown(false);
        request(baseURL + `/Common/DeleteDocumentByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        )
            .then((response) => {
                props.setBlurLoader(false);
                // if (response.result[deleteItemData["DocumentTable.DocumentID"]]) {

                // }
                setAllDocumentChecked(false);
                setIsDeleteAllClick(false);
                setShowSuccessAlert(true);
                setSuccessMessage(t("header.MyDocumentsMessageDeleteAllSuccess"));
                props.setIsTableRefresh(true);
            })
            .catch((error) => {
                props.setBlurLoader(false);
            })
    }

    const onPageNoChange = (number) => {
        if (number === -1) {
            setIsRefresh(false);
        }
        else {
            const num = (number - 1) * TABLE_TOTAL_ROWS;
            const page = number;
            setOffset(num);
            setPageNo(page);
        }
    }

    const onDocumentEdit = (e, row, rowIndex) => {
        e.preventDefault();
        if (row["DocumentTable.IsDocumentOwner"]) {
            setDeleteItemData(row);
            setIsEditDocumentModalShown(true);
            setDocumentDescErr(undefined);
            setDocumentTitleErr(undefined);
            let title = row["DocumentTable.DocTitle"]?.split("|")[0];
            setDocumentTitle(title?.trim());
            setDocumentDesc(row["DocumentTable.Description"]);
        }

    }

    const onDocumentDownload = async (e, row, rowIndex) => {
        // window.open('https://localhost:44311/Website/DownloadFile/?docName=test&docPath=test.txt')
        // window.open("C:/Vaibhav/repos/ci_webapi/data/?docName=test.txt&docPath=b681dafbdd3248b4959b51857be60fbd.txt")
        // window.open(
        //     process.env.REACT_APP_API_KEY +
        //     "/Website/DownloadFile/?docName=" +
        //     tempVar.data.fileName +
        //     "&docPath=" +
        //     tempVar.data.filePath
        // );
    }

    const onEditConfirm = () => {

        if (validateEditDocument()) {
            props.setBlurLoader(true);
            let obj =
            {
                "docId": deleteItemData["DocumentTable.DocumentID"],
                "docTitle": documentTitle,
                "docDescription": documentDesc,
                "companyId": contextData.companyId,
                "userId": contextData.userId
            }


            request(baseURL + `/Common/UpdateDocumentByClientUser`,
                METHODS.POST,
                obj,
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                }
            )
                .then((response) => {
                    props.setBlurLoader(false);
                    setIsEditDocumentModalShown(false);
                    if (response.result) {
                        setShowSuccessAlert(true);
                        setSuccessMessage(t("header.MyDocumentsMessageUpdateSuccess"));
                        props.setIsTableRefresh(true);
                    }
                })
                .catch((error) => {
                    props.setBlurLoader(false);
                    setIsEditDocumentModalShown(false);
                })
        }
    }

    const dashboardItem = (item) => (
        <div
            key={item.id}
            data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
        >
            <DashboardItem key={item.id} itemId={item.id} title={item.name}>
                <ChartRendererTable
                    setIsMultiDeleteDisabled={setIsMultiDeleteDisabled}
                    setTableDataForDelete={setTableDataForDelete}
                    setCheckDocument={setCheckDocument}
                    checkedDocument={checkedDocument}
                    allDocumentChecked={allDocumentChecked}
                    setAllDocumentChecked={setAllDocumentChecked}
                    onDocumentDownload={onDocumentDownload}
                    onDocumentEdit={onDocumentEdit}
                    onDeleteDocument={onDeleteDocument}
                    minimumRows={10}
                    summaryBesideText={t("header.myDocumentsBesideText")}
                    showPagination={true}
                    apiUrl={item.apiUrl}
                    showNumberOfRows={10}
                />
            </DashboardItem>
        </div>
    );

    const onCloseAlert = () => {
        setShowSuccessAlert(false);
    }

    const validateEditDocument = () => {

        if (!documentTitle) {
            setDocumentTitleErr(t("header.MyDocumentsDocTitleRequired"));
            return false;
        }
        if (documentTitle) {
            let filteredData = documentTitle.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            if (documentTitle != filteredData) {
                setDocumentTitleErr(t("header.myDocumentsInvalidDocumentTitle"));
                return false;
            }
            else {
                setDocumentTitleErr(undefined);
                //return true;
            }
        }

        if (documentDesc) {
            let filteredData = documentDesc.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            if (documentDesc != filteredData) {
                setDocumentDescErr(t("header.myDocumentsInvalidDocumentDescription"));
                return false;
            }
            else {
                setDocumentDescErr(undefined);
                //return true;
            }
        }

        return true;
    }

    const onChangeDocumentDesc = (e) => {
        const {
            name,
            value
        } = e.target;

        if (value) {
            let filteredData = value.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            if (value != filteredData) {
                setDocumentDescErr(t("header.myDocumentsInvalidDocumentDescription"));
            }
            else {
                setDocumentDescErr(undefined);
            }
        }

        setDocumentDesc(value);
    }

    const onChangeDocumentTitle = (e) => {
        const {
            name,
            value
        } = e.target;
        if (!value) {
            setDocumentTitleErr(t("header.MyDocumentsDocTitleRequired"));
        }
        if (value) {
            let filteredData = value.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            if (value != filteredData) {
                setDocumentTitleErr(t("header.myDocumentsInvalidDocumentTitle"));
            }
            else {
                setDocumentTitleErr(undefined);
            }
        }

        setDocumentTitle(value);
    }

    const onKeyUpDocumentTitle = (e) => {
        const { value } = e.target;
        const updatedValue = value.replace('.', '');
        setDocumentTitle(updatedValue);
    }

    return (
        <>
            {/* Edit Document Model */}
            <Modal as={Modal.Dialog} size="lg" centered show={isEditDocumentModalShown} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="h5"><h5>{t("header.MyDocumentsEditDocHeader")}</h5></Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Row className="mt-3">
                        <Col md={3}>
                            <Form.Label>
                                {t("header.MyDocumentsEditDocTitle")}<span className="mandatory">*</span>
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control onChange={(e) => { onChangeDocumentTitle(e) }} onKeyUp={(e) => onKeyUpDocumentTitle(e)} value={documentTitle} isInvalid={documentTitleErr ? true : false} placeholder={t("header.myDocumentsDocumentTitle")} type="text" maxlength="180" />
                            <small>{t("header.myDocumentsMaxCharacters")}</small>
                            <Form.Control.Feedback type="invalid">{documentTitleErr}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={3}>
                            <Form.Label>
                                {t("header.MyDocumentsEditDocDesc")}
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control isInvalid={documentDescErr ? true : false} onChange={(e) => { onChangeDocumentDesc(e) }} value={documentDesc} as="textarea" />
                            <Form.Control.Feedback type="invalid">{documentDescErr}</Form.Control.Feedback>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonsComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.MyDocumentsDeleteConfirmationOK")}
                        onClick={onEditConfirm}
                        btn_class="me-3" />
                    <ButtonsComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.MyDocumentsDeleteConfirmationCancel")}
                        onClick={handleClose}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>

            {/* Confirmation Model */}
            <Modal as={Modal.Dialog} size="xs" centered show={isConfirmModalShown} onHide={handleClose}>
                <Modal.Body>
                    {t("header.MyDocumentsMessageDeleteConfirmation")}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonsComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.MyDocumentsDeleteConfirmationOK")}
                        onClick={onConfirm}
                        btn_class="me-3" />
                    <ButtonsComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.MyDocumentsDeleteConfirmationCancel")}
                        onClick={handleClose}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>
            <Alert
                variant="success"
                show={showSuccessAlert}
                onClose={() => onCloseAlert("success")}
            >

                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {successMessage}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseAlert("success")} />
                </div>
            </Alert>
            <div className="my-document-listing">
                <div className="text-end">
                    <Card.Link onClick={(e) => { onDeleteAllDocument(e) }} className={isMultiDeleteDisabled ? "mb-sm-3 mb-md-0 px-2" : "mb-sm-3 mb-md-0 px-2 is-disabled"} title="Delete">
                        <FontAwesomeIcon icon={faTrash} />
                    </Card.Link>
                </div>

                {userMyDocumentsData && Array.isArray(userMyDocumentsData) && userMyDocumentsData.map(deserializeItem).map(dashboardItem)}</div>
        </>
    );
};

export default withRouter(MyDocuments);
