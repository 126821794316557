
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import '../i18n';

const VoltLoader = (props) => {
  const [t, i18n] = useTranslation();
  const [seconds, setSeconds] = React.useState(30);
  const { show } = props;
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  });

  if (show) {
    return seconds != 0 ? (
      <div className={`preloader loader-center-align  ${show ? "" : "show"} ${props.className ? props.className : ""}`}>
        <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={seconds} />
        {/* {seconds} */}
      </div>
    )
      :
      (
        <div className="no-data w-100">{t("header.voltLoaderMessageText")}</div>
      );
  }
  else {
    return (

      <></>


    );
  }



};

export default VoltLoader; 
