import Loader from "../components/VoltLoader";
import { useTranslation } from 'react-i18next';
export const CommonMessageComponent = (props) => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Loader show={props.transactionStatus === 0 ? true : false} />
            {props.transactionStatus === 2 && <div className="text-center no-data">{t("header.NoDataFound")}</div>}
            {props.transactionStatus === 3 && <div className="text-center no-data"> {t("header.SomethingWentWrong")}</div>}
        </>
    )
}
export default CommonMessageComponent;