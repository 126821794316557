import React, { useState, useEffect } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import PropTypes from "prop-types";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormCheck,
  Button,
  Popover,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import ModalComponent from "../components/VoltModal";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import AccordianCompoenent from "../components/AccordionComponent";
import ButtonsComponent from "../components/Buttons";
import { isEmpty, customDurationIds } from "../AppConfig";
import { getThresholdData } from "../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  visitorandcountry,
  notificationListItems,
} from "../stubs/ComponentJson/Notifications";
import { DATE_FORMAT } from "../utils/constant";

const NotificationModal = ({
  handleButtonClick,
  showModal,
  setShowModal,
  selectedCompany,
  setSelectedCompany,
  showAutoFillForCompany,
  setAutoFillForCompany,
  formFields,
  setFormFields,
  formError,
  isFormSubmit,
  validateFormData,
  setFormError,
  setIsFormSubmit,
  isFormSubmittedSucessfully,
  showError,
  setShowError,
  isEdit,
  company,
  countryvalue,
  aiRuleId,
  updateWatchlistNotificationToggle,
}) => {
  const [t, i18n] = useTranslation();
  const cubejsApi = cubejs(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4",
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );

  useEffect(() => {
    if (isFormSubmittedSucessfully) {
      onModalClose();
    }
  }, [isFormSubmittedSucessfully]);

  const onModalClose = () => {
    setShowModal && setShowModal(false);
    setAutoFillForCompany && setAutoFillForCompany(false);
    setIsFormSubmit && setIsFormSubmit(false);
    setSelectedCompany && setSelectedCompany({});
    resetFormData();
    if (
      updateWatchlistNotificationToggle !== undefined &&
      !isFormSubmittedSucessfully
    ) {
      updateWatchlistNotificationToggle();
    }
  };
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const disableEndDt = (current) => {
    if (
      new Date(current).setHours(0, 0, 0, 0) ==
      new Date(formFields.customDurationStart).setHours(0, 0, 0, 0)
    )
      return true;
    else if (current.isAfter(formFields.customDurationStart)) return true;
    else return false;
  };
  const resetFormData = () => {
    const formDta = {
      companyName: "",
      country: {},
      notificationName: "",
      duration: "",
      threshold: {},
      customThreshold: "",
      customDurationStart: "",
      customDurationEnd: "",
      isCustomDuration: false,
    };
    const errorDta = {
      companyName: "",
      country: "",
      notificationName: "",
      duration: "",
      threshold: "",
      customThreshold: "",
      customDurationStart: "",
      customDurationEnd: "",
      selectedCompany: "",
    };
    setFormFields && setFormFields(formDta);
    setFormError && setFormError(errorDta);
  };

  const onCancelButtonClick = (e) => {
    e.preventDefault();
    onModalClose();
  };

  const onFormElementsChange = (event) => {
    const { name, value, id, keys } = event.target;
    const forms = { ...formFields };
    let formVlue;
    if (name === "duration") {
      setShowError(false);
      const customDurationId = parseInt(id);
      const customDurationIndex = customDurationIds.indexOf(customDurationId);
      formVlue = id;
      forms.isCustomDuration = customDurationIndex !== -1 ? true : false;
    } else formVlue = value;

    forms[name] = formVlue;
    setFormFields && setFormFields(forms);
    validateFormData && validateFormData(name, formVlue);
  };

  const onTextChanged = (e) => {
    const { value, name } = e.target;
    // let suggestions = [];
    // if (value.length > 1) {
    //     const regex = new RegExp(`^${value}`, 'i');
    //     suggestions = items.sort().filter(v => regex.test(v));
    // }
    const showAutoFill = value.length > 2 ? true : false;
    const forms = { ...formFields };
    forms[name] = value;
    //Noter: For clearing the country on text change
    forms.notificationName = "";
    forms.country = {};
    setFormFields && setFormFields(forms);
    validateFormData && validateFormData(name, value);
    setSelectedCompany({});
    setAutoFillForCompany(showAutoFill);
    // setSuggestions(suggestions);
  };

  const suggestionSelected = (value) => {
    const forms = { ...formFields };
    forms["companyName"] = value["Visitorlist.Visitor"];
    forms["notificationName"] = value["Visitorlist.Visitor"];
    setFormFields && setFormFields(forms);
    validateFormData &&
      validateFormData("companyName", value["Visitorlist.Visitor"]);
    validateFormData &&
      validateFormData("notificationName", value["Visitorlist.Visitor"]);
    validateFormData && validateFormData("selectedCompany", value);
    setSelectedCompany(value);
    setAutoFillForCompany(false);
    // setSuggestions([]);
  };

  const onDropdownChangeForCountry = (data) => {
    const forms = { ...formFields };
    forms["country"] = data;
    setFormFields && setFormFields(forms);
    validateFormData && validateFormData("country", data);
  };

  const onDropdownChangeForThreshold = (data) => {
    const forms = { ...formFields };
    forms["threshold"] = data;
    setFormFields && setFormFields(forms);
    validateFormData && validateFormData("threshold", data);
  };

  const getThresholdData = () => {
    let thresholdList = [1, 10, 50];
    let thresholdData = [];
    if (thresholdList.length > 0) {
      thresholdList.forEach((data) => {
        let dta = {};
        dta.key = data;
        dta.label = `${data} ${t("header.WONotificationSetupOrMore")}`;
        thresholdData.push(dta);
      });
      const customData = {
        key: "custom",
        label: `${t("header.WONotificationSetupCustom")}`,
      };
      thresholdData.push(customData);
    }
    return thresholdData;
  };
  const onCustomStartDatePickerChange = (date) => {
    const dta = moment(date).format("MM/DD/YYYY");
    const forms = { ...formFields };
    forms.customDurationStart = dta;
    setFormFields && setFormFields(forms);
    validateFormData && validateFormData("customDurationStart", dta);
  };

  const onCustomEndDatePickerChange = (date) => {
    const dta = moment(date).format("MM/DD/YYYY");
    const forms = { ...formFields };
    forms.customDurationEnd = dta;
    setFormFields && setFormFields(forms);
    validateFormData && validateFormData("customDurationEnd", dta);
  };

  const renderSuggestionsList = ({
    resultSet,
    error,
    pivotConfig,
    request,
  }) => {
    if (error) {
      return null;
    }

    if (!resultSet) {
      const search = t("header.WONotificationSetupDDSearching");
      return (
        <div className="auto-dropdown">
          <ul className="list-unstyled auto-dropdown-menu">
            <li className="auto-dropdown-menu-item mt-1 mb-1">{search}</li>
          </ul>
        </div>
      );
    } else {
      const result = [];
      var test = [];
      resultSet.tablePivot(pivotConfig).map((item) => {
        if (result.indexOf(item["Visitorlist.Visitor"]) == -1) {
          result.push(item["Visitorlist.Visitor"]);
          test.push(item);
        }
      });
    }

    return (
      resultSet.tablePivot(pivotConfig) &&
      resultSet.tablePivot(pivotConfig).length > 0 && (
        <div className="auto-dropdown">
          <ul className="list-unstyled auto-dropdown-menu">
            {test.map((item) => (
              <li
                className="auto-dropdown-menu-item mt-1 mb-1"
                onClick={() => suggestionSelected(item)}
              >
                {item["Visitorlist.Visitor"]}
              </li>
            ))}
          </ul>
        </div>
      )
    );
  };

  const renderDurations = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return null;
    }

    if (!resultSet) {
      return null;
    }

    return (
      <Col md={6}>
        {resultSet.tablePivot(pivotConfig) &&
          resultSet.tablePivot(pivotConfig).length > 0 &&
          resultSet.tablePivot(pivotConfig).map((item) =>
            formFields.duration == item["Duration.Id"] ? (
              <FormCheck
                type="radio"
                value={formFields.duration}
                label={item["Duration.Description"]}
                keys={item["Duration.Description"]}
                defaultChecked={true}
                name="duration"
                id={item["Duration.Id"]}
                onChange={(e) => onFormElementsChange(e)}
              />
            ) : (
              <FormCheck
                type="radio"
                value={formFields.duration}
                label={item["Duration.Description"]}
                keys={item["Duration.Description"]}
                // defaultChecked={true}
                name="duration"
                id={item["Duration.Id"]}
                onChange={(e) => onFormElementsChange(e)}
              />
            )
          )}

        {isFormSubmit && !isEmpty(formError.duration) && (
          <span className="alert-msg-danger w-100 single-validation-error">
            {formError.duration}
          </span>
        )}
      </Col>
    );
  };

  const renderCountry = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return null;
    }

    if (!resultSet) {
      return (
        <DropdownWithIconsComponent
          dropdownData={[
            {
              "Visitors_country.Id": -1,
              "Visitorlist.CountryName": "Searching",
            },
          ]}
          arrayValue="Visitorlist.CountryName"
          primaryIconCustom={false}
          selectedDropdown={formFields.country}
          onChange={onDropdownChangeForCountry}
          showLabel={false}
          showIconInDropdownItem={false}
          isPrimaryImage={false}
          customClassName="full-width"
        />
      );
    }
    return resultSet.tablePivot(pivotConfig).length > 0 ? (
      <DropdownWithIconsComponent
        dropdownData={[
          { "Visitors_country.Id": 0, "Visitorlist.CountryName": "All" },
          ...resultSet.tablePivot(pivotConfig),
        ]}
        arrayValue="Visitorlist.CountryName"
        primaryIconCustom={false}
        selectedDropdown={formFields.country}
        onChange={onDropdownChangeForCountry}
        showLabel={false}
        showIconInDropdownItem={false}
        isPrimaryImage={false}
        customClassName="full-width"
      />
    ) : (
      <Form.Control
        type="text"
        name="companyName"
        value={
          formFields &&
          formFields.companyName &&
          formFields.companyName.length > 2
            ? `${t("header.NoCountryFoundMessage")}`
            : ""
        }
        autoComplete="off"
        disabled
      />
    );
    // <DropdownWithIconsComponent
    //     dropdownData={[{ "Visitors_country.Id": 0, "Visitors.CountryName": "No Country Available" }]}
    //     arrayValue="Visitors.CountryName"
    //     primaryIconCustom={false}
    //     selectedDropdown={formFields.country}
    //     onChange={onDropdownChangeForCountry}
    //     showLabel={false}
    //     showIconInDropdownItem={false}
    //     isPrimaryImage={false}
    //     customClassName="full-width"
    //     isDropdownDisabled={true}
    // />
  };

  const companyUrlClick = (event, url) => {
    event.preventDefault();
    window.open(url, "_blank");
  };

  const renderComapanyLink = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return null;
    }

    if (!resultSet) {
      return null;
    }

    return (
      <>
        {
          resultSet.tablePivot(pivotConfig) &&
          resultSet.tablePivot(pivotConfig).length > 0 ? (
            <AccordianCompoenent
              defaultKey="panel-1"
              data={[
                {
                  id: 1,
                  eventKey: "panel-1",
                  title: `${resultSet.tablePivot(pivotConfig).length} ${
                    resultSet.tablePivot(pivotConfig).length === 1
                      ? "Url"
                      : "URLs"
                  } found`,
                  description: resultSet.tablePivot(pivotConfig),
                  displayName: "VisitorDomain.CompanyUrl",
                  buttonClick: companyUrlClick,
                },
              ]}
            />
          ) : (
            <Form.Control
              type="text"
              name="companyName"
              value={
                formFields &&
                formFields.companyName &&
                formFields.companyName.length > 2
                  ? `${t("header.NoURLFoundMessage")}`
                  : ""
              }
              autoComplete="off"
              disabled
            />
          )
          // <span> No Url Found</span>
        }
        {/* {resultSet.tablePivot(pivotConfig) && resultSet.tablePivot(pivotConfig).length > 0 ?
                    resultSet.tablePivot(pivotConfig).length === 1 ?
                        resultSet.tablePivot(pivotConfig).map((item) => (

                            <a className="text-italic font-small" onClick={(e) => companyUrlClick(e, item["VisitorDomain.CompanyUrl"])}>{item["VisitorDomain.CompanyUrl"]}</a>

                        )
                        )
                        :
                        (resultSet.tablePivot(pivotConfig).length > 1 &&
                            <OverlayTrigger
                                placement="right"
                                trigger="click"
                                overlay={
                                    <Popover>
                                        <Popover.Content>
                                            <ul className="list-unstyled auto-dropdown-menu">
                                                {resultSet.tablePivot(pivotConfig).map((item) =>

                                                    <li className="auto-dropdown-menu-item mt-1 mb-1" onClick={(e) => companyUrlClick(e, item["VisitorDomain.CompanyUrl"])}>
                                                        {item["VisitorDomain.CompanyUrl"]}
                                                    </li>
                                                )
                                                }
                                            </ul>
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <Button variant="white" size="sm" className="m-2">More</Button>
                            </OverlayTrigger>
                        )
                    :
                    <span> No Url Found</span>

                } */}
      </>
    );
  };

  return (
    <ModalComponent
      open={showModal}
      title={t("header.WONotificationSetupTitle")}
      handleClose={onModalClose}
      showCloseButton={true}
    >
      <Row className="mb-3">
        <Col md={12}>
          <p className="body-text">{t("header.WONotificationnotifiedText")}</p>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>
            {t("header.WONotificationSetupCName")}
            <span className="mandatory">*</span>
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            {!isEdit && (
              <div className="col-md-12 input">
                {isFormSubmit ? (
                  !isEdit &&
                  (!isEmpty(formError.companyName) ||
                    !isEmpty(formError.selectedCompany)) ? (
                    <Form.Control
                      isInvalid
                      type="text"
                      name="companyName"
                      placeholder={t(
                        "header.WONotificationSetupSearchPlaceholder"
                      )}
                      value={formFields.companyName}
                      onChange={(e) => onTextChanged(e)}
                      autoComplete="off"
                    />
                  ) : (
                    <Form.Control
                      isValid
                      type="text"
                      name="companyName"
                      placeholder={t(
                        "header.WONotificationSetupSearchPlaceholder"
                      )}
                      value={formFields.companyName}
                      onChange={(e) => onTextChanged(e)}
                      autoComplete="off"
                    />
                  )
                ) : (
                  <Form.Control
                    type="text"
                    name="companyName"
                    placeholder={t(
                      "header.WONotificationSetupSearchPlaceholder"
                    )}
                    value={formFields.companyName}
                    onChange={(e) => onTextChanged(e)}
                    autoComplete="off"
                  />
                )}
              </div>
            )}
            {!isEdit && showAutoFillForCompany && (
              <div className="col-md-12 justify-content-md-center">
                {/* <QueryRenderer
                  query={{
                    dimensions: [
                      "Visitorlist.Visitor",
                      // "VisitorDomain.CompanyUrl",
                      "Visitorlist.CountryName",
                      "Visitorlist.VisitorId",
                    ],
                    timeDimensions: [],
                    order: {
                      "Visitorlist.Visitor": "asc",
                    },
                    filters: [
                      {
                        member: "Visitorlist.Visitor",
                        operator: "contains",
                        values: [`${formFields.companyName}`],
                      },
                    ],
                  }}
                  cubejsApi={cubejsApi}
                  resetResultSetOnChange={false}
                  render={(props) =>
                    renderSuggestionsList({
                      ...props,
                      chartType: "table",
                      pivotConfig: {
                        x: [
                          "Visitorlist.Visitor",
                          // "VisitorDomain.CompanyUrl",
                          "Visitorlist.CountryName",
                          "Visitorlist.VisitorId",
                        ],
                        y: [],
                        fillMissingDates: true,
                        joinDateRange: false,
                      },
                    })
                  }
                /> */}
                {/* {renderSuggestions()} */}
              </div>
            )}

            {((!isEdit && isFormSubmit && !isEmpty(formError.companyName)) ||
              !isEmpty(formError.selectedCompany)) && (
              <Row>
                <Col md={4}>
                  {!isEdit &&
                    isFormSubmit &&
                    !isEmpty(formError.companyName) && (
                      <span className="alert-msg-danger">
                        {" "}
                        {formError.companyName}{" "}
                      </span>
                    )}
                </Col>
                <Col md={4}>
                  {!isEdit &&
                    isFormSubmit &&
                    isEmpty(formError.companyName) &&
                    !isEmpty(formError.selectedCompany) && (
                      <span className="alert-msg-danger">
                        {" "}
                        {formError.selectedCompany}{" "}
                      </span>
                    )}
                </Col>
              </Row>
            )}

            {isEdit && <Form.Label>{formFields.companyName}</Form.Label>}
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>{t("header.WONotificationSetupCountry")} </Form.Label>
        </Col>
        <Col md={8}>
          {/* {!isEdit && (
            <QueryRenderer
              query={{
                dimensions: ["Visitorlist.CountryName", "Visitors_country.Id"],
                timeDimensions: [],
                order: {
                  "Visitorlist.CountryName": "asc",
                },
                filters: [
                  {
                    member: "Visitorlist.Visitor",
                    operator: "equals",
                    values: [`${selectedCompany["Visitorlist.Visitor"]}`],
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) =>
                renderCountry({
                  ...props,
                  chartType: "table",
                  pivotConfig: {
                    x: ["Visitorlist.CountryName", "Visitors_country.Id"],
                    y: [],
                    fillMissingDates: true,
                    joinDateRange: false,
                  },
                })
              }
            />
          )} */}
          {isEdit && <Form.Label>{localStorage.getItem("country")}</Form.Label>}
          {isFormSubmit && !isEmpty(formError.country) && (
            <span className="alert-msg-danger">{formError.country} </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>{t("header.WONotificationSetupCompURL")}</Form.Label>
        </Col>
        <Col md={8}>
          {/* {!isEdit && (
            <QueryRenderer
              query={{
                dimensions: ["VisitorDomain.CompanyUrl"],
                timeDimensions: [],
                order: {
                  "VisitorDomain.CompanyUrl": "asc",
                },
                filters: [
                  {
                    member: "Visitorlist.Visitor",
                    operator: "equals",
                    values: [`${formFields.companyName}`],
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) =>
                renderComapanyLink({
                  ...props,
                  chartType: "table",
                  pivotConfig: {
                    x: ["VisitorDomain.CompanyUrl"],
                    y: [],
                    fillMissingDates: true,
                    joinDateRange: false,
                    limit: null,
                  },
                })
              }
            />
          )} */}
          {/* {isEdit && (
            <QueryRenderer
              query={{
                dimensions: ["VisitorDomain.CompanyUrl"],
                timeDimensions: [],
                order: {
                  "VisitorDomain.CompanyUrl": "asc",
                },
                filters: [
                  {
                    member: "Visitorlist.Visitor",
                    operator: "equals",
                    values: [`${formFields.companyName}`],
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) =>
                renderComapanyLink({
                  ...props,
                  chartType: "table",
                  pivotConfig: {
                    x: ["VisitorDomain.CompanyUrl"],
                    y: [],
                    fillMissingDates: true,
                    joinDateRange: false,
                    limit: null,
                  },
                })
              }
            />
          )} */}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>
            {t("header.WONotificationSetupName")}
            <span className="mandatory">*</span>
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            {isFormSubmit ? (
              !isEmpty(formError.notificationName) ? (
                <Form.Control
                  isInvalid
                  type="text"
                  name="notificationName"
                  value={formFields.notificationName}
                  onChange={(e) => onFormElementsChange(e)}
                  disabled={
                    !isEdit &&
                    selectedCompany &&
                    Object.keys(selectedCompany).length === 0
                      ? true
                      : false
                  }
                  autoComplete="off"
                />
              ) : (
                <Form.Control
                  isValid
                  type="text"
                  name="notificationName"
                  value={formFields.notificationName}
                  onChange={(e) => onFormElementsChange(e)}
                  disabled={
                    !isEdit &&
                    selectedCompany &&
                    Object.keys(selectedCompany).length === 0
                      ? true
                      : false
                  }
                  autoComplete="off"
                />
              )
            ) : (
              <Form.Control
                type="text"
                name="notificationName"
                value={formFields.notificationName}
                onChange={(e) => onFormElementsChange(e)}
                disabled={
                  !isEdit &&
                  selectedCompany &&
                  Object.keys(selectedCompany).length === 0
                    ? true
                    : false
                }
                autoComplete="off"
              />
            )}
            {isFormSubmit && !isEmpty(formError.notificationName) && (
              <Form.Control.Feedback type="invalid">
                {formError.notificationName}{" "}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>
            {t("header.WONotificationSetupPVThreshold")}
            <span className="mandatory">*</span>
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            <Row>
              <Col md={6}>
                <DropdownWithIconsComponent
                  dropdownData={getThresholdData()}
                  arrayValue="label"
                  primaryIconCustom={false}
                  selectedDropdown={formFields.threshold}
                  onChange={onDropdownChangeForThreshold}
                  showLabel={false}
                  showIconInDropdownItem={false}
                  isPrimaryImage={false}
                  customClassName="full-width"
                />
              </Col>
              {formFields.threshold &&
                Object.keys(formFields.threshold).length > 0 &&
                formFields.threshold.key === "custom" && (
                  <Col md={6} className="mb-3">
                    {isFormSubmit ? (
                      !isEmpty(formError.customThreshold) ? (
                        <Form.Control
                          isInvalid
                          type="number"
                          maxLength="8"
                          name="customThreshold"
                          value={formFields.customThreshold}
                          onChange={(e) => onFormElementsChange(e)}
                          autoComplete="off"
                        />
                      ) : (
                        <Form.Control
                          isValid
                          type="number"
                          maxLength="8"
                          name="customThreshold"
                          value={formFields.customThreshold}
                          onChange={(e) => onFormElementsChange(e)}
                          autoComplete="off"
                        />
                      )
                    ) : (
                      <Form.Control
                        type="number"
                        maxLength="8"
                        name="customThreshold"
                        value={formFields.customThreshold}
                        onChange={(e) => onFormElementsChange(e)}
                        autoComplete="off"
                      />
                    )}
                    {isFormSubmit && !isEmpty(formError.customThreshold) && (
                      <span className="alert-msg-danger">
                        {formError.customThreshold}{" "}
                      </span>
                    )}
                  </Col>
                )}
            </Row>
            <Row>
              <Col md={6}>
                {isFormSubmit && !isEmpty(formError.threshold) && (
                  <span className="alert-msg-danger">
                    {formError.threshold}{" "}
                  </span>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md={12}>
          <Form.Label>
            {t("header.WONotificationThreshhold")}
            {/* <p className="text-italic fw-normal font-small mb-0">
                            Note: depending on the time of the visit, the processing of the notification can take up to 48 hours
                        </p> */}
            <span className="mandatory">*</span>
          </Form.Label>
        </Col>
      </Row>
      <Row className="mb-2">
        <Form.Group>
          {/* <QueryRenderer
            query={{
              dimensions: [
                "Duration.Description",
                "Duration.Id",
                "Duration.Displayorder",
              ],
              timeDimensions: [],
              order: {
                "Duration.Displayorder": "asc",
              },
              filters: [],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderDurations({
                ...props,
                chartType: "table",
                pivotConfig: {
                  x: [
                    "Duration.Description",
                    "Duration.Id",
                    "Duration.Displayorder",
                  ],
                  y: [],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          /> */}

          {formFields.isCustomDuration && (
            <Row>
              <Col md={4}></Col>
              <Col md={4} style={{ paddingRight: "0px" }}>
                <Form.Group className="mb-3">
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={false}
                    onChange={onCustomStartDatePickerChange}
                    isValidDate={disablePastDt}
                    // isValidDate={currDate => currDate.isAfter(formFields.customDurationEnd)}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        {isFormSubmit && showError ? (
                          !isEmpty(formError.customDurationStart) ? (
                            <>
                              <InputGroup.Text className="is-invalid">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                autocomplete="off"
                                name="customDurationStart"
                                value={
                                  formFields.customDurationStart
                                    ? moment(
                                        formFields.customDurationStart
                                      ).format(DATE_FORMAT)
                                    : ""
                                }
                                placeholder={t("header.startDatePlaceHolder")}
                                onFocus={openCalendar}
                                isInvalid
                                onChange={() => {}}
                              />
                            </>
                          ) : (
                            <>
                              <InputGroup.Text className="is-valid">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                autocomplete="off"
                                name="customDurationStart"
                                value={
                                  formFields.customDurationStart
                                    ? moment(
                                        formFields.customDurationStart
                                      ).format(DATE_FORMAT)
                                    : ""
                                }
                                placeholder={t("header.startDatePlaceHolder")}
                                onFocus={openCalendar}
                                isValid
                                onChange={() => {}}
                              />
                            </>
                          )
                        ) : (
                          <>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              autocomplete="off"
                              name="customDurationStart"
                              value={
                                formFields.customDurationStart
                                  ? moment(
                                      formFields.customDurationStart
                                    ).format(DATE_FORMAT)
                                  : ""
                              }
                              placeholder={t("header.startDatePlaceHolder")}
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </>
                        )}
                      </InputGroup>
                    )}
                  />
                  {isFormSubmit &&
                    !isEmpty(formError.customDurationStart) &&
                    showError && (
                      <small className="alert-msg-danger">
                        {formError.customDurationStart}
                      </small>
                    )}
                </Form.Group>
              </Col>

              <Col md={4} style={{ paddingRight: "0px" }}>
                <Form.Group className="mb-3">
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={false}
                    // onChange={setBirthday}
                    onChange={onCustomEndDatePickerChange}
                    isValidDate={disableEndDt}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        {isFormSubmit && showError ? (
                          !isEmpty(formError.customDurationEnd) ? (
                            <>
                              <InputGroup.Text className="is-invalid">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                autocomplete="off"
                                name="customDurationEnd"
                                value={
                                  formFields.customDurationEnd
                                    ? moment(
                                        formFields.customDurationEnd
                                      ).format(DATE_FORMAT)
                                    : ""
                                }
                                placeholder={t("header.endDatePlaceHolder")}
                                onFocus={openCalendar}
                                isInvalid
                                onChange={() => {}}
                              />
                            </>
                          ) : (
                            <>
                              <InputGroup.Text className="is-valid">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                autocomplete="off"
                                name="customDurationEnd"
                                value={
                                  formFields.customDurationEnd
                                    ? moment(
                                        formFields.customDurationEnd
                                      ).format(DATE_FORMAT)
                                    : ""
                                }
                                placeholder={t("header.endDatePlaceHolder")}
                                onFocus={openCalendar}
                                isValid
                                onChange={() => {}}
                              />
                            </>
                          )
                        ) : (
                          <>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              autocomplete="off"
                              name="customDurationEnd"
                              value={
                                formFields.customDurationEnd
                                  ? moment(formFields.customDurationEnd).format(
                                      DATE_FORMAT
                                    )
                                  : ""
                              }
                              placeholder={t("header.endDatePlaceHolder")}
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </>
                        )}
                      </InputGroup>
                    )}
                  />
                  {isFormSubmit &&
                    !isEmpty(formError.customDurationEnd) &&
                    showError && (
                      <small className="alert-msg-danger">
                        {formError.customDurationEnd}
                      </small>
                    )}
                </Form.Group>
              </Col>
            </Row>
          )}
        </Form.Group>
      </Row>
      <Row>
        <Col md={7}>
          <p className="fw-normal note-msg mt-1 mb-0">
            {t("header.WONotificationProcessing")}
          </p>
        </Col>
        <Col md={2}></Col>
        <Col md={3}>
          <ButtonsComponent
            onClick={(e) => handleButtonClick(e)}
            isIcon={false}
            isPrimary={true}
            btn_class="me-3"
            btnText={t("header.WONotificationSetupSave")}
          />

          <ButtonsComponent
            onClick={(e) => onCancelButtonClick(e)}
            isIcon={false}
            isPrimary={false}
            btn_class="mb-0"
            btnText={t("header.WONotificationSetupCancel")}
          />
        </Col>
      </Row>
    </ModalComponent>
  );
};

NotificationModal.propTypes = {
  handleButtonClick: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selectedCompany: PropTypes.array,
  setSelectedCompany: PropTypes.func,
  showAutoFillForCompany: PropTypes.bool,
  setAutoFillForCompany: PropTypes.func,
  formFields: PropTypes.object,
  setFormFields: PropTypes.func,
  formError: PropTypes.object,
  isFormSubmit: PropTypes.bool,
  validateFormData: PropTypes.func,
  setFormError: PropTypes.func,
  setIsFormSubmit: PropTypes.func,
};

NotificationModal.defaultProps = {
  handleButtonClick: null,
  showModal: true,
  setShowModal: null,
  selectedCompany: {},
  setSelectedCompany: null,
  showAutoFillForCompany: false,
  setAutoFillForCompany: null,
  formFields: {},
  setFormFields: null,
  formError: {},
  isFormSubmit: false,
  validateFormData: null,
  setFormError: null,
  setIsFormSubmit: null,
};

export default NotificationModal;
