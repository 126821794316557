import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, Dropdown } from '@themesberg/react-bootstrap';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import PdfComponent from './PdfComponent';
import { useTranslation } from "react-i18next";

const CardMenu = ({
    itemId,
    cardMenu,
    onCardMenuClick,
    title,
    setIsPageLoaderShown
}) => {
    const [t, i18n] = useTranslation();


    const dashboardItemDropdownMenu = () => {
        if (cardMenu.length > 0) {



            return cardMenu.map(data => {
                if (data.title === "Download") {
                    return (
                        <Dropdown.Item
                            className='dropdown-item-download'
                            onClick={() => {
                                onCardMenuClick && onCardMenuClick(data.title, itemId);
                            }}
                        >
                            <PdfComponent title={title} setIsPageLoaderShown={setIsPageLoaderShown} iscard={true}></PdfComponent>
                        </Dropdown.Item>

                    );
                }
                else {
                    return (
                        data.disabledMenu.includes(parseInt(itemId)) === false &&
                        <Dropdown.Item onClick={() => { onCardMenuClick && onCardMenuClick(data.title, itemId); }}>
                            <span>{t("header.cardMenu_" + data.title)}</span>
                            {/* {data.disabledMenu.includes(parseInt(itemId)) === false ? <span>{t("header.cardMenu_" + data.title)}</span> : <span class="is-disabled ">{t("header.cardMenu_" + data.title)}</span>} */}
                        </Dropdown.Item >
                    );
                }
            });

        }
    }


    return (
        <Dropdown as={ButtonGroup} className="card-dropdown-container">
            <Dropdown.Toggle split className="card-dropdown-btn">
                <FontAwesomeIcon icon={faEllipsisV} className="dropdown-arrow" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {dashboardItemDropdownMenu()}
            </Dropdown.Menu>
        </Dropdown>
    );

};

CardMenu.propTypes = {
    itemId: PropTypes.number,
    cardMenu: PropTypes.object,
    onCardMenuClick: PropTypes.func,
};

CardMenu.defaultProps = {
    itemId: 0,
    cardMenu: {},
    setShowModal: null
};

export default CardMenu;