export const userAppDigestList=(userId,companyId)=>{
let appDigestList=[
    {
        apiUrl: {url: `${process.env.REACT_APP_API_KEY}/User/GetUserAppDigestByUserId?CompanyId=${companyId}&UserId=${userId}`, type: 'get', count: 0},
        id:"1",
        layout:"{\"x\":0,\"y\":1,\"w\":16,\"h\":12}",
        name:"",
        summary:{},
        pagination:{show:false,query:`{}`},
        //vizState:`{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Flurryanalytic.appname\",\"WebsitesforAppdigest.monthly\",\"Userappdigest.flurryanalyticid\"],\"y\": [],\"fillMissingDates\": true,\"joinDateRange\": false},\"query\":[{\"dimensions\":[\"Flurryanalytic.appname\",\"WebsitesforAppdigest.monthly\",\"Flurryanalytic.id\"],\"timeDimensions\":[],\"order\":[[\"Flurryanalytic.appname\",\"asc\"]],\"filters\":[{\"member\":\"WebsitesforAppdigest.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}\"]},{\"member\":\"WebsitesforAppdigest.CompanyId\",\"operator\":\"equals\",\"values\":[\"${companyId}\"]}]},{\"measures\":[],\"timeDimensions\":[],\"order\":{"Flurryanalytic.appname\":\"asc\"},\"dimensions\":[\"Flurryanalytic.appname\", \"Userappdigest.active\", \"Userappdigest.flurryanalyticid\"],\"filters\":[{\"member\":\"Userappdigest.userid\",\"operator\":\"equals\",\"values\":[\"${userId}"]},{\"member\":\"Miwebsites.companyid\",\"operator\":\"equals\",\"values\":[\"${companyId}"]}]}], \"widjetName\":\"Userappdigest\"}`
      //  vizState:`{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Flurryanalytic.appname\",\"Userappdigest.active\"],\"y\": [],\"fillMissingDates\": true,\"joinDateRange\": false},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{"Flurryanalytic.appname\":\"asc\"},\"dimensions\":[\"Flurryanalytic.appname\", \"Userappdigest.active\", \"Userappdigest.flurryanalyticid\"],\"filters\":[{\"member\":\"Userappdigest.userid\",\"operator\":\"equals\",\"values\":[\"${userId}"]},{\"member\":\"Miwebsites.companyid\",\"operator\":\"equals\",\"values\":[\"${companyId}"]}]}, \"widjetName\":\"Userappdigest\"}`
     
    }
];
return appDigestList;};