
const minHeight = 8;
const minHeight_mobile = 10;

const minWidth = 6;
const minWidth_mobile = 12;

export const defaultLayout = (i) => ({
    x: i?.layout.x || 0,
    y: i?.layout.y || 0,
    w: i?.layout.w || minWidth,
    h: i?.layout.h || minHeight,
    minW: minWidth,
    minH: minHeight,
    // w: i.layout.w || 4,
    // h: i.layout.h || 8,
    // minW: 4,
    // minH: 0,
});

export const defaultLayoutMobile = (i) => ({
    x: i?.layout.x || 0,
    y: i?.layout.y || 0,
    w: minWidth_mobile || i?.layout.w,
    h:  minHeight_mobile || i?.layout.h,
    minW: minWidth_mobile,
    minH: minHeight_mobile
    // w: 12,
    // h: i.layout.h + 2 || 12,
    // minW: 4,
    // minH: 8,
});
