import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Alert,Button } from '@themesberg/react-bootstrap';
import { isEmpty } from "../AppConfig";
import ButtonsComponent  from "./Buttons";

const AlertsComponent = ({
    message,
    variantName,
    showCloseButton,
    onButtonClose
}) => {
 


    return (
        <>
            {
                !isEmpty(variantName) &&
                <Alert variant={variantName} 
                onClose={() =>  onButtonClose && onButtonClose(variantName)} >
                    {!isEmpty(message) &&
                       <div className="d-flex justify-content-between">
                    <div>
                    {message}
                     </div>
                     {
                         showCloseButton &&
                         <ButtonsComponent isClose={true} isPrimary ={false} onClick={onButtonClose}  />
                    //    <Button variant="close" size="xs" onClick={() => onButtonClose && onButtonClose(variantName)} />
                     }
                       </div>
                    }
                </Alert>
            }
        </>
    );

};

AlertsComponent.propTypes = {
    message: PropTypes.string,
    variantName: PropTypes.string,
    onButtonClose: PropTypes.func,
    showCloseButton: PropTypes.bool

};
AlertsComponent.defaultProps = {
    message: "",
    variantName: "",
    onButtonClose: null,
    showCloseButton: false

}

export default  AlertsComponent;