import DataChartRenderer from '../../components/DataChartRenderer.js';
import DashboardItem from '../../components/DashboardItem';
import { Row, Col, Card, OverlayTrigger, Tooltip, Button } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useMemo } from "react";
import { baseURL, METHODS } from '../../apis/utilities/constant';
import request from '../../apis/request';
import CommonMessageComponent from '../../components/CommonMessageComponent.js';
import { DataStatusMessage } from "../../utils/constant";
import DataTableRenderer from '../../components/DataTableRenderer.js';
import { TnfTableHeaders } from '../../utils/constant';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { decryptData } from '../../utils/crypto.js';


const ToolsOverview = React.memo(({ profileID, startDate, endDate, intelConfigID, cryptoKey }) => {

    const [multiline, setMultiline] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [piechart, setPiechart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableDataPie, setTableDataPie] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [mapData, setMapData] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableDataMap, setTableDataMap] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tableDataDetail, setTableDataDetail] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [t, i18n] = useTranslation();

    const culture = localStorage.getItem("i18nextLng");


    useEffect(() => {
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        if (multiline.transactionStatus !== DataStatusMessage.Loading) {
            setMultiline({ transactionStatus: DataStatusMessage.Loading });
            setPiechart({ transactionStatus: DataStatusMessage.Loading });
            setMapData({ transactionStatus: DataStatusMessage.Loading });
            setTableDataDetail({ transactionStatus: DataStatusMessage.Loading });
            setTableDataPie({ transactionStatus: DataStatusMessage.Loading })
            setTableDataMap({ transactionStatus: DataStatusMessage.Loading })
        }
        //For Multilinechart
        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=totalUsers&Dimensions=date,pagePath,pageTitle&Component=multilinechart&intelConfigId=${intelConfigID}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response.transactionStatus == DataStatusMessage.DataAvailable) {
                setMultiline(response);
            }
            else {
                setMultiline({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
            }
        }).catch(
            (error) => {
                setMultiline({ transactionStatus: DataStatusMessage.ErrorOccured })
            }
        );

        //For PieChart
        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=screenPageViews&Dimensions=pageTitle,pagePath&Component=piechart&intelConfigId=${intelConfigID}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            let dataObj = [];
            if (response.transactionStatus === DataStatusMessage.DataAvailable) {

                response.result.map((obj) => {
                    dataObj.push({ Pagetitle: obj.title, Pageviews: obj.TotalOpenCount })
                });

                setPiechart(response);
            }
            else {
                setPiechart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
            }

            setTableDataPie({
                header: TnfTableHeaders.ToolsOverviewPieChartTable, data: dataObj, transactionStatus: response.transactionStatus
            });

        }).catch(
            (error) => {
                setPiechart({ transactionStatus: DataStatusMessage.ErrorOccured });
                setTableDataPie({
                    header: TnfTableHeaders.ToolsOverviewPieChartTable, data: [], transactionStatus: DataStatusMessage.ErrorOccured
                });
            }

        );

        //For Map
        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=eventCount&Dimensions=country,eventName,pagePath&Component=map&Culture=${culture}&intelConfigId=${intelConfigID}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response.transactionStatus === DataStatusMessage.DataAvailable) {
                setMapData(response);
            }
            else {
                setMapData({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
            }
            setTableDataMap({
                header: TnfTableHeaders.MapUserTable, data: response.transactionStatus === DataStatusMessage.DataAvailable ?
                    response.result : [], transactionStatus: response.transactionStatus
            });

        }).catch(
            (error) => {
                setMapData({ transactionStatus: DataStatusMessage.ErrorOccured })
                setTableDataMap({ header: TnfTableHeaders.MapUserTable, data: [], transactionStatus: DataStatusMessage.ErrorOccured });
            }
        );

        // For detail table info
        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=eventCount,screenPageViews,sessionsPerUser,totalUsers,userEngagementDuration&Dimensions=eventName,pagePath,pageTitle&Component=table&intelConfigId=${intelConfigID}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {

            if (response.transactionStatus === DataStatusMessage.DataAvailable) {
                setTableDataDetail({
                    header: TnfTableHeaders.ToolsOverviewDetailTable, data: response.transactionStatus === DataStatusMessage.DataAvailable ?
                        response.result.data : [], headerSummary: response.result.headerSummary, transactionStatus: response.transactionStatus
                });
            }
            else {
                setTableDataDetail({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured });
            }


        }).catch(
            (error) => {
                setTableDataDetail({
                    header: TnfTableHeaders.ToolsOverviewDetailTable, data: [],
                    transactionStatus: DataStatusMessage.ErrorOccured
                });
            }

        );


    }, [profileID, startDate, endDate])


    const multilineChartComponent = useMemo(
        () => (
            <DataChartRenderer
                data={
                    multiline.result
                }
                cardProperty={{ "x": 0, "y": 20, "w": 10, "h": 8 }}
                chartType="multiline"
                customAttributes={{ isDefaultMultilineValuesEnabled: true }} />

        ),
        [multiline]
    );


    const pieChartComponent = useMemo(
        () => (
            <DataChartRenderer
                data={
                    piechart.result
                }
                cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />

        ),
        [piechart]
    );

    const pieChartTableComponent = useMemo(
        () => (

            <DataTableRenderer
                header={tableDataPie.header}
                dataSet={tableDataPie.data}
                transactionStatus={tableDataPie.transactionStatus}
                customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
            ></DataTableRenderer>
        ),
        [tableDataPie]
    );

    const mapTableComponent = useMemo(
        () => (
            <DataTableRenderer
                header={tableDataMap.header}
                dataSet={tableDataMap.data}
                transactionStatus={tableDataMap.transactionStatus}
                customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
            ></DataTableRenderer>
        ),
        [tableDataMap]
    );

    const mapComponent = useMemo(
        () => (
            <DataChartRenderer
                data={
                    mapData.result
                }
                chartType="map" />

        ),
        [mapData]
    );

    const detailTableComponent = useMemo(
        () => (

            <DataTableRenderer
                header={tableDataDetail.header}
                dataSet={tableDataDetail.data}
                transactionStatus={tableDataDetail.transactionStatus}
                headerSummaryData={tableDataDetail.headerSummary}
                customAttributes={{ displayRowsPerPage: 10, showHeaderSummary: true, showPagination: true }}
            ></DataTableRenderer>
        ),
        [tableDataDetail]
    );
    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        // <Tooltip>{t("header.VisitorAnalyticsTooltip")}</Tooltip>
                        <Tooltip>
                            <div
                            // dangerouslySetInnerHTML={{
                            //   __html: t("header.VisitorAnalyticsTooltip", {
                            //     interpolation: { escapeValue: false },
                            //   }),
                            // }}
                            >{tooltipKey}</div>
                        </Tooltip>
                    }
                >

                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }


    return (
        <>
            {/* <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Row className="mb-3">
                    <Col lg={12}>
                        <h2 className="h2 mb-0 ms-3">{t("header.Tools_Overview")}</h2>
                    </Col>
                </Row>
            </div> */}
            <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Card border="light" className="bg-white shadow-sm mb-3 ms-3 p-3" >
                    <Card.Header className="pt-0 ps-0">
                        {t("header.Users_by_page_title_and_screen_class_over_time")}{createTooltip(t("header.TO_Users_by_page_title_and_screen_class_over_time_Tooltip"), "right")}
                    </Card.Header>
                    <Card.Body>
                        {
                            multiline.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <CommonMessageComponent transactionStatus={multiline.transactionStatus}></CommonMessageComponent>
                        }
                        {
                            multiline.transactionStatus === DataStatusMessage.DataAvailable &&
                            multilineChartComponent
                        }
                    </Card.Body>
                </Card>
            </div >
            <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Card border="light" className="bg-white shadow-sm mb-3 ms-3" >
                    <Card.Header className="p-3">
                        {t("header.Views_by_page_title_and_screen_class")}{createTooltip(t("header.TO_Views_by_page_title_and_screen_class_Tooltip"), "right")}
                    </Card.Header>
                    <Card.Body>

                        {
                            piechart.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    <CommonMessageComponent transactionStatus={piechart.transactionStatus}></CommonMessageComponent>
                                </Col>
                            </Row>
                        }
                        {
                            piechart.transactionStatus === DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={6}>
                                    {pieChartComponent}
                                </Col>
                                <Col md={6}>
                                    {
                                        tableDataPie.transactionStatus === DataStatusMessage.DataAvailable && pieChartTableComponent
                                    }
                                </Col>
                            </Row>
                        }

                    </Card.Body>
                </Card>
            </div >
            <div className="d-inline-block tools-dashboard-card-loader w-100">

                <Card border="light" className="bg-white shadow-sm mb-3 ms-3" >
                    <Card.Header className="p-3">
                        {t("header.Tools_Event_count_by_country")}{createTooltip(t("header.TO_Events_Count_By_Country_Tooltip"), "right")}
                    </Card.Header>
                    <Card.Body>
                        {
                            mapData.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    <CommonMessageComponent transactionStatus={mapData.transactionStatus}></CommonMessageComponent>
                                </Col>
                            </Row>
                        }
                        {
                            mapData.transactionStatus === DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    {mapComponent}
                                </Col>
                                <Col md={12} className='mt-2'>
                                    {
                                        tableDataMap.transactionStatus === DataStatusMessage.DataAvailable && mapTableComponent
                                    }
                                </Col>
                            </Row>
                        }

                    </Card.Body>
                </Card>
            </div >
            <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Card border="light" className="bg-white shadow-sm mb-3 ms-3 pt-3" >
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                {
                                    tableDataDetail.transactionStatus !== DataStatusMessage.DataAvailable
                                    && <CommonMessageComponent transactionStatus={tableDataDetail.transactionStatus}></CommonMessageComponent>
                                }
                                {
                                    tableDataDetail.transactionStatus === DataStatusMessage.DataAvailable &&
                                    detailTableComponent
                                }

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>



    )
});

export default ToolsOverview;