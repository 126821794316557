export const PDFCardHeader = `
<div class="ms-2 mb-2">
<div class="row pdf-company-logo">
    {{logo}}
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <div class="ms-2 mb-0 pdf-download-title">{{websiteWebsiteProfile}}</div>
        <div class="ms-2 mb-0 pdf-download-subtitle">{{dateRange}}</div>
    </div>
    <div class="ms-2 mb-0 pdf-download-subtitle">{{pageTitle}}</div>
</div>
`;